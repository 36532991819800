import React, { useEffect } from 'react';
import './Login.scss';
import LoginLeftImage from '../../assets/images/login_left_image.png';
import login_bottom_image from '../../assets/images/login_bottom_image.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { addData, generateOtp } from './registerSlice';
import { toast } from "react-toastify";
import LoadingComponent from '../../components/Loader/LoadingComponent';
import Logo2 from '../../assets/images/polo.png';

function SignUp() {
    const { t } = useTranslation();
    let navigate = useNavigate();
    const { state } = useLocation();
    const dispatch = useDispatch();
    const date = new Date().getFullYear();

    const { register, handleSubmit, setValue, formState: { errors } } = useForm();

    const { isLoading } = useSelector(state => state?.registerSlice);

    useEffect(() => {
        if (state?.fromInvite) {
            setValue('companyName', state?.companyName);
            setValue('firstName', state?.firstName);
            setValue('lastName', state?.lastName);
            setValue('email', state?.email);
        }
    }, [state?.fromInvite]);

    const onSubmit = async (data) => {
        const payload = {
            type: "email",
            email: data?.email
        };
        if (state?.fromInvite)
            payload["inviteCode"] = state?.inviteCode;
        dispatch(generateOtp(payload))
            .unwrap()
            .then(() => {
                dispatch(addData({ data, fromRegister: true }));
                if (state?.fromInvite)
                    navigate('/emailVerification', { state: { registerData: state } });
                else
                    navigate('/emailVerification', { state: { registerData: data, forgotData: {} } });
            }).catch((error) => {
                toast.error(error?.message);
            });
    };

    const routeLoginChange = () => {
        let path = `/login`;
        navigate(path);
    };

    const handleInputChange = (e) => {
        const trimmedValue = e.target.value.trim();

        setValue('email', trimmedValue, { shouldValidate: true });
    };

    return (
        <section>
            <div className="container-fluid" style={{ backgroundColor: '#24473D', position: 'relative' }}>
                <div className="row login-forms " >
                    <div className="col-md-7 col-lg-5 col-xl-6 my-2 soop" style={{position: 'relative'}}>
                        {isLoading && <LoadingComponent />}
                        <div className='d-flex flex-column justify-content-between align-items-center h-100'>
                            <div></div>
                            <form className='form' onSubmit={handleSubmit(onSubmit)}  >
                                <div>
                                    <img src={Logo2} className="logo mt-1 mb-4" alt="Sample image" />
                                </div>
                                <h1 className='text-blue-login my-2'>
                                    {t('login.let’s_get_you_setup')}
                                </h1>
                                <div className="divider d-flex align-items-center my-2">
                                    <p className="mb-0 text-subtitle">
                                        {t('login.tell_us_a_little_about_yourself')}
                                    </p>
                                </div>
                                {
                                    state?.fromInvite &&
                                    <div className="form-outline mt-4 mb-3">
                                        <label className="form-label" htmlFor="companyName">{t('login.company_name')}</label>
                                        <input
                                            type="text"
                                            id="companyName"
                                            disabled={state?.fromInvite}
                                            className={`form-control form-control-lg ${errors.companyName ? 'is-invalid' : ''}`}
                                            placeholder={`${t('login.company_name')}`}
                                            {...register('companyName', {
                                                required: `${t('login.enter_your_first_name')}`,
                                            })}
                                        />
                                        {errors?.companyName && (
                                            <div className="invalid-feedback">{errors?.companyName?.message}</div>
                                        )}
                                    </div>
                                }
                                <div className="form-outline mt-4 mb-3">
                                    <label className="form-label" htmlFor="firstName">{t('login.first_name')}</label>
                                    <input
                                        type="text"
                                        id="firstName"
                                        disabled={state?.fromInvite}
                                        className={`form-control form-control-lg ${errors.firstName ? 'is-invalid' : ''}`}
                                        placeholder={`${t('login.enter_your_first_name')}`}
                                        {...register('firstName', {
                                            required: `${t('login.enter_your_first_name')}`,
                                            minLength: {
                                                value: 2,
                                                message: `${t('login.min_name_length')}`,
                                            },
                                            maxLength: {
                                                value: 30,
                                                message: `${t('login.max_name_length')}`,
                                            },
                                            pattern: {
                                                value: /^[A-Za-z\s]+$/,
                                                message: `${t('login.enter_valid_first_name')}`,
                                            },
                                        })}
                                    />
                                    {errors?.firstName && (
                                        <div className="invalid-feedback">{errors?.firstName?.message}</div>
                                    )}
                                </div>
                                <div className="form-outline mt-3 mb-3">
                                    <label className="form-label" htmlFor="lastName">{t('login.last_name')}</label>
                                    <input
                                        type="text"
                                        id="lastName"
                                        disabled={state?.fromInvite}
                                        className={`form-control form-control-lg ${errors.lastName ? 'is-invalid' : ''}`}
                                        placeholder={`${t('login.enter_your_last_name')}`}
                                        {...register('lastName', {
                                            required: `${t('login.enter_your_last_name')}`,
                                            minLength: {
                                                value: 2,
                                                message: `${t('login.min_name_length')}`,
                                            },
                                            maxLength: {
                                                value: 30,
                                                message: `${t('login.max_name_length')}`,
                                            },
                                            pattern: {
                                                value: /^[A-Za-z\s]+$/,
                                                message: `${t('login.enter_valid_first_name')}`,
                                            },
                                        })}
                                    />
                                    {errors?.lastName && (
                                        <div className="invalid-feedback">{errors?.lastName?.message}</div>
                                    )}
                                </div>
                                <div className="form-outline mt-3 mb-3">
                                    <label className="form-label" htmlFor="email">{t('login.email_address')}</label>
                                    <input
                                        type="text"
                                        id="email"
                                        disabled={state?.fromInvite}
                                        className={`form-control form-control-lg ${errors.email ? 'is-invalid' : ''}`}
                                        placeholder={`${t('login.enter_your_email')}`}
                                        {...register('email', {
                                            required: `${t('login.enter_your_email')}`,
                                            pattern: {
                                                value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/,
                                                message: 'Please enter valid Email Id'
                                            },
                                            onChange: handleInputChange
                                        })}
                                    />
                                    {errors?.email && (
                                        <div className="invalid-feedback">{errors?.email?.message}</div>
                                    )}
                                </div>

                                <div className="text-center text-lg-start mt-4 w-100">
                                    <button type="submit" className="btn btn-primary btn-lg btn-block w-100" id='continue'>{t('login.continue')}</button>
                                </div>
                                <div className="divider d-flex align-items-center justify-content-center mt-4 pt-2">
                                    <p className="text-center mx-3 mb-0 text-or" id='loginRoute'>
                                        {t('login.have_an_account?')} <a href='#!' onClick={routeLoginChange} className='text-blue forgotPassword' id='login'>{t('login.login')}</a>
                                    </p>
                                </div>
                            </form>

                            <div className='copyright'>
                                <p className='copyright-text'>{`© Xpendless ${date}`}</p>
                            </div>

                        </div>
                    </div>
                    <div className="col-md-8 col-lg-7 col-xl-6 h-100 d-flex justify-content-center align-items-center imagecontainer">
                        <img src={LoginLeftImage} className='loginImage' />
                        <img className='login_bottom_image' src={login_bottom_image} />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SignUp;
