import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import "./index.scss";
import { useTranslation } from 'react-i18next';
import SecondaryButton from './Buttons/SecondaryButton';
import PrimaryButton from './Buttons/PrimaryButton';

const ConfirmationDialog = ({ show, onClose, onConfirm, message, showHeaderTitle = false, confirmTitle = 'confirm' }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const handleButtonClick = () => {
    setIsLoading(true);

    const timerId = setTimeout(() => {
      setIsLoading(false);
    }, 5000); 

    return () => clearTimeout(timerId);
  };

  return (
    <Modal show={show} onHide={onClose} centered id='ConfirmationDialog' backdrop="static">
      {showHeaderTitle &&
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
      }
      <Modal.Body className='text-center'>
        {message}
      </Modal.Body>

      <Modal.Footer>
        <SecondaryButton 
          type={'button'}
          onClick={onClose}
          buttonText={t('cancel')}
          id={'cancel'}
          style={{width: '96px'}}
        />

        <PrimaryButton 
          type={'button'}
          onClick={() =>{onConfirm(); handleButtonClick();}}
          buttonText={t(confirmTitle)}
          isEnabled={true}
          id={'confirm'}
          style={{width: '149px'}}
          isLoading={isLoading}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationDialog;


// how to use this
{/* <ConfirmationDialog
      show={showConfirmation}
      onConfirm={handleConfirm}
      onClose={onClose}
      message="Are you sure you want to proceed to delete?"
  /> */}