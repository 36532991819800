/* eslint-disable no-unsafe-optional-chaining */
import React, { useState } from 'react';
import { useEffect } from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import { toast } from 'react-toastify';
import ConfirmationDialog from '../ConfirmationDialog';
import RedCross from '../../assets/images/close-square-red.png';
import { formatQAR } from '../../utils/XpendlessUtils';
import CurrencyInput from 'react-currency-input-field';
import { MAX_VALUE } from '../../utils/constants';
import SecondaryButton from '../Buttons/SecondaryButton';

const DynamicFieldArray = ({ name, control, reset, tags, t, errors, details, setValue, existingValue, watch, onUpdate, placeholderData, disabled }) => {
    const { fields, append, remove } = useFieldArray({
        control,
        name,
    });

    const [error, setError] = useState(false);

    const watchFields = watch();
    const [deleteItemIndex, setDeleteItemIndex] = useState(null);
    const [showConfirmation, setShowConfirmation] = useState(null);
    const [placeholderValue, setPlaceholderValue] = useState(placeholderData);
    const [splitAmounts, setSplitAmounts] = useState({});
    const [selectedTags, setSelectedTags] = useState(new Set());

    useEffect(() => {
        const appendValues = async () => {
            if (existingValue) {
                for (const [index, tag] of existingValue.entries()) {
                    await append(tag);
                    await setValue(`${(tag?.label).replace(/ /g, '_')}[${index}].amount`, formatQAR(parseFloat(tag?.amount)));
                    await setValue(`${(tag?.label).replace(/ /g, '_')}[${index}].tagId`, tag?.tagId);
                    setSelectedTags(prev => new Set(prev).add(tag?.tagId));
                }
                const initialSplitAmounts = existingValue.map(tag => tag.amount);
                setSplitAmounts({ [name]: initialSplitAmounts });
                const totalAmountForGroup = initialSplitAmounts.reduce((sum, amt) => sum + parseFloat(amt), 0);
                setPlaceholderValue(placeholderData - totalAmountForGroup);
            }
        };
        appendValues();
        return () => {
            reset();
        };
    }, []);

    useEffect(() => {
        const totalAmount = watchFields[name]?.reduce((acc, curr) => { return acc + parseFloat(curr.amount); }, 0);
        if ((totalAmount < details?.amount || totalAmount > details?.amount) && watchFields[name]?.length > 0)
            setError(true);
        else
            setError(false);
    }, [watchFields]);


    const addBlackItem = async () => {
        const specifiedAmount = details.amount;
        const totalAmountForGroup = splitAmounts[name] ? splitAmounts[name].reduce((sum, amt) => sum + parseFloat(amt), 0) : 0;
        setPlaceholderValue(specifiedAmount - totalAmountForGroup);
        const checkEmpty = watchFields[name]?.some(field => field.amount === undefined || field.amount === '');
        if (checkEmpty) {
            toast.error("Please add split to the previous line");
            return;
        }
        if (totalAmountForGroup < specifiedAmount) {
            append({});
        }
        else {
            toast.error("Split amount should not exceed the actual amount");
            return;
        }
        onUpdate(true);
    };

    const handleTagChange = (index, tagId) => {
        setSelectedTags(prev => {
            const updated = new Set(prev);
            const oldTagId = watchFields[name][index]?.tagId;
            if (oldTagId) {
                updated.delete(oldTagId);
            }
            if (tagId) {
                updated.add(tagId);
            }
            return updated;
        });
        setValue(`${name}[${index}].tagId`, tagId);
    };

    const handleRemove = async (index) => {
        const tagId = watchFields[name][index].tagId;
        remove(index);
        setSplitAmounts(prev => ({
            ...prev,
            [name]: prev[name] ? [...prev[name].slice(0, index), ...prev[name].slice(index + 1)] : []
        }));

        setSelectedTags(prev => {
            const newSet = new Set(prev);
            newSet.delete(tagId);
            return newSet;
        });

        setShowConfirmation(false);
        setDeleteItemIndex(null);
        onUpdate(true);
    };

    const isSelected = function (tagId) {
        for (let id of selectedTags) {
            if (id == tagId) {
                return true;
            }
        }
        return false;
    };
    return (
        <div>
            {fields?.map((field, index) => (
                <div className='border-grey p-3 mb-3' style={{ borderRadius: 15 }} key={field.id}>
                    <div className=''>
                        <div className="form-outline mb-2">
                            <div className='d-flex justify-content-end mb-2'>
                                <img src={RedCross} style={{ width: 20 }} onClick={() => { setShowConfirmation(true); setDeleteItemIndex(index); }} />
                            </div>
                            <Controller
                                name={`${name}[${index}].tagId`}
                                control={control}
                                render={({ field }) => (
                                    <select
                                        {...field}
                                        className="form-control form-select"
                                        id="exampleFormControlSelect1"
                                        disabled={disabled}
                                        onChange={e => handleTagChange(index, e.target.value)}
                                    >
                                        {existingValue[index] && existingValue[index]?.isActive === false ? <option value=''>{existingValue[index]?.value} </option> : <option value=''>Select {name}</option>}
                                        {tags['tags'].map((obj, j) => {
                                            return (
                                                <option value={obj?.tagId} key={j} disabled={isSelected(obj?.tagId)} className={isSelected(obj?.tagId) ? 'disabled-option' : ''}>{obj?.value}</option>
                                            );
                                        })}
                                    </select>
                                )}
                            />
                            {errors[name] && errors[name][index] && errors[name][index].tagId && (
                                <p className='pt-1 text-danger small'>{errors[name][index].tagId.message}</p>
                            )}
                        </div>
                    </div>
                    <div className=''>
                        <div className="form-outline">
                            <Controller
                                name={`${name}[${index}].amount`}
                                control={control}
                                render={({ field }) =>
                                    <CurrencyInput
                                        id={`${name}_${index}`}
                                        type="text"
                                        className="form-control form-control-lg"
                                        placeholder={formatQAR(placeholderValue)}
                                        disabled={disabled}
                                        decimalsLimit={2}
                                        decimalScale={2}
                                        disablenegative={'true'}
                                        onKeyDown={(e) => {
                                            if (e.key === '-') {
                                                e.preventDefault();
                                            }
                                        }}
                                        value={field.value || ''}
                                        onValueChange={(value) => {
                                            console.log(value);
                                            const numericValue = parseFloat(value?.replace(/,/g, '')) || 0;
                                            if (numericValue < MAX_VALUE) {
                                                field.onChange(value === '' ? '' : value?.startsWith('.') ? `0${value}` : value);
                                                setSplitAmounts(prev => ({
                                                    ...prev,
                                                    [name]: prev[name] ? [...prev[name].slice(0, index), value, ...prev[name].slice(index + 1)] : [value]
                                                }));
                                            }
                                        }}
                                    />
                                }
                            />
                            {errors[name] && errors[name][index] && errors[name][index].amount && (
                                <p className='pt-1 text-danger small'>{errors[name][index].amount.message}</p>
                            )}
                        </div>
                    </div>
                    {(showConfirmation && deleteItemIndex === index) &&
                        <ConfirmationDialog
                            show={showConfirmation}
                            onConfirm={() => handleRemove(index)}
                            onClose={() => { setShowConfirmation(false); setDeleteItemIndex(null); }}
                            message="Are you sure you want to proceed to delete?"
                        />
                    }
                </div>
            ))}
            {
                error &&
                <p className='pt-1 text-danger small'>The total amount should match with transaction amount</p>
            }
            <div className='d-flex align-items-center justify-content-between expense-detail-css mt-3'>
                <SecondaryButton 
                    type={'button'}
                    onClick={addBlackItem}
                    buttonText={t('add_line')}
                    id={'add_line'}
                    isHighlighted={true}
                    isEnabled={!disabled}
                    style={{width: '100%', textAlign: 'center'}}
                />
            </div>
        </div>
    );
};

export default DynamicFieldArray;