import "./index.scss";
import React, { useEffect, useState } from "react";
import {
  addTax,
  getAllTax,
  getAllTaxType,
  getTaxDetail,
  getTaxList,
  removeTax,
  updateMapping,
  updateTax,
} from "../../pages/Settings/taxSlice";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationDialog from "../ConfirmationDialog";
import ImagesCross from "../../assets/images/close-square-8.png";
import Modal from "react-bootstrap/Modal";
import cookies from "js-cookie";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import LoadingComponent from "../Loader/LoadingComponent";
import { formatQAR } from "../../utils/XpendlessUtils";

function TaxCodes(props) {
  const { connectedIntegration } = props;
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    setValue,
    reset,
  } = useForm();
  const { taxData, isLoading, taxDetail, oddoTaxList } =
    useSelector((state) => state?.taxSlice);
  const [pageNo, setPageNo] = useState(1);
  const [taxId, setTaxId] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedMappingValue, setSelectedMappingValue] = useState('');
  const [confirmRateDifference, setConfirmRateDifference] = useState(false);

  const onPreviousClick = () => {
    setPageNo((prev) => prev - 1);
  };

  const onNextClick = () => {
    setPageNo((prev) => prev + 1);
  };

  useEffect(() => {
    dispatch(getAllTax(pageNo));
  }, [dispatch, pageNo]);

  useEffect(() => {
    dispatch(getAllTaxType());
  }, [dispatch]);

  useEffect(() => {
    if (connectedIntegration?.isConnected) {
      dispatch(getTaxList());
    }
  }, [connectedIntegration]);

  useEffect(() => {
    if (taxDetail && Object.keys(taxDetail)?.length > 0) {
      setValue("name", taxDetail?.name);
      setValue("rate", taxDetail?.rate);
      setValue("taxId", taxDetail?.taxId);
      handleShow();
    }
  }, [taxDetail]);

  const languages = [
    {
      code: "en",
      name: "English",
      country_code: "gb",
    },
    {
      code: "ar",
      name: "العربية",
      dir: "rtl",
      country_code: "sa",
    },
  ];

  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);

  useEffect(() => {
    document.body.dir = currentLanguage.dir || "ltr";
    document.title = t("app_title");
  }, [currentLanguage, t]);

  const getDetail = (taxId) => {
    setTaxId(taxId);
    dispatch(getTaxDetail(taxId));
  };

  const onSubmit = (data) => {
    if (taxId) {
      console.log("isDirty", isDirty);
      if (!isDirty) {
        handleClose();
        reset();
        return;
      }
      dispatch(updateTax(data))
        .unwrap()
        .then((res) => {
          toast.success(res?.message);
          handleClose();
          dispatch(getAllTax(pageNo));
        })
        .catch((error) => {
          toast.error(error?.message);
        });
    } else {
      dispatch(addTax(data))
        .unwrap()
        .then((res) => {
          handleClose();
          reset();
          toast.success(res?.message);
          dispatch(getAllTax(pageNo));
        })
        .catch((error) => {
          toast.error(error?.message);
        });
    }
  };

  const handleConfirm = () => {
    if (taxId) {
      dispatch(removeTax(taxId))
        .unwrap()
        .then((res) => {
          toast.success(res?.message);
          dispatch(getAllTax(pageNo));
          setShowConfirmation(false);
          setTaxId(null);
        })
        .catch((error) => {
          toast.error(error?.message);
          setShowConfirmation(false);
          setTaxId(null);
        });
    } else {
      console.log('tax id not found');
    }
  };

  const handleTaxChange = (value, taxId = '') => {
    if (value) {
      const { companyIntegrationId } = connectedIntegration;
      const payload = {
        companyIntegrationId: companyIntegrationId,
        taxIntegrationId: value || '',
        taxId
      };
      dispatch(updateMapping(payload)).unwrap()
        .then((res) => {
          toast.success(res?.message);
          dispatch(getAllTax(pageNo));
          setConfirmRateDifference(false);
        })
        .catch((error) => {
          toast.error(error?.message);
        });
    }

  };

  const showRateDifferenceConfirmation = (e, taxId = '') => {
    const { value } = e.target;
    setTaxId(taxId);
    setSelectedMappingValue(value);
    setConfirmRateDifference(true);
  };

  return (
    <div className="taxcodes">
      <div
        className="d-flex align-items-center justify-content-between"
        style={{ marginBottom: 24 }}
      >
        <h2 className="card-title-black-text ">
          {t("settingstab.list_of_current_tax_codes")}
        </h2>
        <button
          onClick={() => {
            handleShow();
            setTaxId(null);
            reset();
          }}
          className="btn-primary-small"
          id="show_add_tax_group"
        >
          {t("settingstab.add_new")}
        </button>
      </div>
      <div style={{ position: 'relative' }}>
        {isLoading && <LoadingComponent />}
        {taxData?.rows?.length > 0 && (
          <>
            <div className="table-responsive p-3 pb-0">
              <table
                id="taxCodesTable"
                style={{ borderCollapse: "separate", borderSpacing: "0 16px" }}
                cellSpacing="0"
                width="100%"
              >
                <thead>
                  <tr className="table-head">
                    <th scope="col">{t("name")}</th>
                    <th scope="col">{t("settingstab.tax_rate")}</th>
                    {connectedIntegration?.isConnected && <th scope="col">{t("settingstab.mapping")}</th>}
                    <th scope="col">{t("actions")}</th>
                  </tr>
                </thead>
                <tbody className="table-body">
                  {taxData &&
                    taxData?.rows?.map((tax) => (
                      <tr
                        className={`${currentLanguageCode === "en"
                          ? "table-body-tr"
                          : "table-body-tr-arabic"
                          }`}
                        key={tax?.taxId}
                      >
                        <td>{tax?.name}</td>
                        <td>{formatQAR(parseFloat(tax?.rate))}%</td>

                        {connectedIntegration?.isConnected && <td>
                          <select
                            id={`tax-${tax?.taxId}`}
                            className="form-select form-control-lg"
                            onChange={(e) => showRateDifferenceConfirmation(e, tax?.taxId)}
                            value={tax?.taxIntegrationId}
                          >
                            <option value="">Select Tax</option>
                            {oddoTaxList
                              ?.filter((tax) => tax?.type_tax_use === "purchase")
                              .map((filteredTax, index) => (
                                <option
                                  key={index}
                                  value={filteredTax?.id}
                                >
                                  {filteredTax?.name}
                                </option>
                              ))}
                            )
                          </select>
                        </td>}

                        <td>
                          <a style={{ cursor: "pointer" }} id={`edit_tax_${tax?.taxId}`}>
                            <span
                              onClick={() => getDetail(tax?.taxId)}
                              className="tableEditBtn"
                            >
                              {t("edit")}
                            </span>
                          </a>
                          <a style={{ cursor: "pointer" }} id={`delete_tax_${tax?.taxId}`}>
                            <span
                              onClick={() => {
                                setTaxId(tax?.taxId);
                                setShowConfirmation(true);
                              }}
                              className="tableDeleteBtn"
                              style={{ marginLeft: 10, marginRight: 10 }}
                            >
                              {t("delete")}
                            </span>
                          </a>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div
              className="d-flex align-items-center justify-content-between mt-2"
              style={{ width: "100%", borderTop: "0px !imporant" }}
            >
              <nav aria-label="Page navigation example">
                <ul className="pagination my-3 pe-0">
                  <li className="page-item">
                    <button
                      className="btn page-link"
                      disabled={pageNo === 1}
                      onClick={() => onPreviousClick()}
                      id="prevous"
                    >
                      Previous
                    </button>
                  </li>
                  <li className="page-item mx-3">
                    <button
                      className="btn page-link"
                      disabled={
                        pageNo === taxData?.totalPages ||
                        taxData?.totalPages == 0
                      }
                      id="next"
                      onClick={() => onNextClick()}
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </nav>
              <div
                className="page-count d-flex align-items-center"
                style={{ color: "#344054" }}
              >
                <h4 className="pagination-textcss">
                  Page {taxData?.currentPage} of {taxData?.totalPages}
                </h4>
              </div>
            </div>
          </>
        )}
      </div>
      <Modal
        className="UserModal"
        show={show}
        onHide={handleClose}
        style={{ paddingRight: 0 }}
      >
        <Modal.Body>
          <div
            className="d-flex align-items-center justify-content-between green-header px-3"
            style={{ marginBottom: 8 }}
          >
            <h2 className="green-header-text">
              {taxId
                ? t("settingstab.update_tax_code")
                : t("settingstab.add_tax_code")}
            </h2>
            <div
              onClick={() => {
                handleClose();
                reset();
                setTaxId(null);
              }}
              className="clo-2"
              style={{ cursor: "pointer" }}
            >
              <img
                src={ImagesCross}
                style={{ width: 24, objectFit: "contain" }}
              />
            </div>
          </div>

          <form>
            <div className="form-outline mt-4 mb-3">
              <label className="form-label" htmlFor="name">
                {t("name")}
              </label>
              <input
                type="text"
                id="name"
                className="form-control form-control-lg"
                placeholder={`${t("enter_name")}`}
                {...register("name", {
                  required: `${t("enter_name")}`,
                  pattern: {
                    value: /^[A-Za-z0-9 ]+$/,
                    message: `${t(
                      "settingstab.Only_alphaNumberic_values_are_required"
                    )}`,
                  },
                })}
              />
              {errors?.name && (
                <p style={{ color: "red" }}>{errors?.name?.message}</p>
              )}
            </div>

            <div className="form-outline mt-4 mb-4">
              <label className="form-label" htmlFor="taxRate">
                {t("settingstab.tax_rate")}
              </label>
              <input
                type="text"
                id="taxRate"
                className="form-control form-control-lg border-0"
                placeholder={`${t("settingstab.enter_rate")}`}
                maxLength={3}
                {...register("rate", {
                  required: `${t("settingstab.enter_rate")}`,
                  pattern: {
                    value: /^(100|\d{1,2})$/,
                    message: `${t(
                      "settingstab.Enter_a_number_between_0_and_100"
                    )}`,
                  },
                })}
              />
              {errors?.rate && (
                <p style={{ color: "red" }}>{errors?.rate?.message}</p>
              )}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className="px-0 pt-3">
          <button
            onClick={() => {
              handleClose();
              reset();
              setTaxId(null);
            }}
            id="cancel_add_tax"
            className="btn-secondary-small"
          >
            {t("cancel")}
          </button>
          <button
            onClick={handleSubmit(onSubmit)}
            className="btn-primary-small"
            id="confirm_add_tax"
          >
            {taxId ? t("update") : t("add")}
          </button>
        </Modal.Footer>
      </Modal>
      <ConfirmationDialog
        show={showConfirmation}
        onConfirm={handleConfirm}
        onClose={() => {
          setShowConfirmation(false);
          setTaxId(null);
        }}
        message="Are you sure you want to proceed to delete?"
      />
      <ConfirmationDialog
        show={confirmRateDifference}
        onConfirm={() => handleTaxChange(selectedMappingValue, taxId)}
        onClose={() => {
          setConfirmRateDifference(false);
          setTaxId(null);
          setSelectedMappingValue('');
        }}
        message="There is the difference with the tax rate and mapping value. Are you sure you want to proceed?"
      />
    </div>
  );
}

export default TaxCodes;
