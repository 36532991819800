import React, { useEffect, useRef, useState } from 'react';
import './CustomDropdown.scss';
import DroupdownArrow from '../../assets/images/dropdownDownArrow.png';

const CustomDropdown = ({ options, onChange, selectedOption, placeholder, backgroundColor, onKeyUp, id, isSearchHidden }) => {
    const [isActive, setIsActive] = useState(false);
    const [selectedValue, setSelectedValue] = useState(selectedOption?.value || -1);
    const [focusedIndex, setFocusedIndex] = useState(-1);
    const [filterText, setFilterText] = useState('');
    const optionRefs = useRef([]);
    const dropdownRef = useRef(null);
    const filteredOptions = options?.filter(option =>
        option.label.toLowerCase().includes(filterText.toLowerCase())
    ) || [];
    useEffect(() => {
        if (selectedOption) {
            setSelectedValue(selectedOption.value || -1);
        }
    }, [selectedOption]);

    const selectedLabel = selectedValue === -1
        ? selectedOption
        : options?.find(option => option?.value === selectedValue)?.label || selectedOption;

    const handleCloseMenu = (option) => {
        setSelectedValue(option.value);
        setIsActive(false);
        setFilterText('');
        onChange(option);
    };

    const handleKeyDown = (e) => {
        if (isActive) {
            switch (e.key) {
                case 'ArrowDown':
                    e.preventDefault();
                    setFocusedIndex((prevIndex) => (prevIndex + 1) % filteredOptions.length);
                    break;
                case 'ArrowUp':
                    e.preventDefault();
                    setFocusedIndex((prevIndex) => (prevIndex - 1 + filteredOptions.length) % filteredOptions.length);
                    break;
                case 'Enter':
                    if (focusedIndex !== -1) {
                        e.preventDefault();
                        handleCloseMenu(filteredOptions[focusedIndex]);
                        onKeyUp(e, filteredOptions[focusedIndex]);
                    }
                    break;
                case 'Escape':
                    setIsActive(false);
                    break;
                default:
                    break;
            }
        }
    };

    const handleKeyUp = (e) => {
        if (onKeyUp) {
            onKeyUp(e);
        }
    };

    const handleFilterChange = (e) => {
        setFilterText(e.target.value);
        setFocusedIndex(-1);
    };

    useEffect(() => {
        if (focusedIndex !== -1 && optionRefs.current[focusedIndex]) {
            optionRefs.current[focusedIndex].scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
            });
        }
    }, [focusedIndex]);

    useEffect(() => {
        if (isActive) {
            window.addEventListener('keydown', handleKeyDown);
            window.addEventListener('keyup', handleKeyUp);
        } else {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
        }

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
        };
    }, [isActive, focusedIndex]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsActive(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);

    return (
        <div className='custom-dropdown' ref={dropdownRef}>
            <div className="dropdown">
                <div
                    onClick={() => setIsActive(!isActive)}
                    className={`dropdown-btn ${isActive ? 'active' : ''} ${backgroundColor ? 'green-border' : 'normal-border'}`}
                    style={{ backgroundColor: backgroundColor ? backgroundColor : '#FFFFFF', fontSize: backgroundColor ? '20px' : '16px' }}
                    id={id}
                >
                    {selectedLabel || placeholder}
                    <img
                        src={DroupdownArrow}
                        width={'12.81px'}
                        height={'6.41px'}
                        style={{ objectFit: 'contain', transform: isActive ? 'rotate(180deg)' : 'rotate(0)' }}
                    />
                </div>
                {isActive && (
                    <div className="dropdown-content">
                        {!isSearchHidden === 'hide' && (<input
                            type="text"
                            placeholder="Search..."
                            id={`${id}_search`}
                            value={filterText}
                            onChange={handleFilterChange}
                            className="filter-input"
                        />)}
                        <div className="dropdown-items" style={{ maxHeight: '150px', overflowY: 'auto' }}>
                            {filteredOptions.map((option, index) => (
                                <div
                                    key={index}
                                    ref={(el) => (optionRefs.current[index] = el)}
                                    className={`item text-capitalize ${index === focusedIndex ? 'focused' : ''}`}
                                    onClick={() => handleCloseMenu(option)}
                                    tabIndex={0}
                                >
                                    {option.label}
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CustomDropdown;
