import React from 'react';
import './index.scss';

function LoadingComponent() {
    return (
        <div className="loader-component position-absolute d-flex justify-content-center align-items-center">
            <div className="spinner-border text-light" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    );
}

export default LoadingComponent;
