import React from 'react';
import { useTranslation } from 'react-i18next';
import AddIcon from '../../assets/images/Add+Icon.png';
import LimitIcon from '../../assets/images/limit_icon.png';
import RenderImage from '../RenderImage';
import IconDelete from '../../assets/images/delete-icon.png';

function UsersCard(props) {
    const { t } = useTranslation();
    const {username,numberOfCards,price1,price2,onClick,plusIcon, profilePic = null, lastName="", setDetails, data, showDelete, isDeleted=false, id} = props;

    return (
        <div style={{cursor:'pointer',position:'relative'}} id={`user_${id}`}>
            <div onClick={onClick} className='border-grey cards-custom p-3' style={{maxHeight: '170px'}}>
                <div className='d-flex align-items-center justify-content-between'>
                        <div className='d-flex align-items-center'>
                            {
                                profilePic ?
                                    <RenderImage imageUrl={profilePic} imageStyle={{ objectFit: 'cover',borderRadius:15 }}/> :
                                    <span className='mb-0 d-flex align-items-center justify-content-center text-uppercase' style={{ fontSize: '20px', fontWeight: 600, backgroundColor: '#F2F4F7', borderRadius: 15, width: 56, height: 56 }}>
                                        {username && lastName && `${username?.charAt(0)}${lastName?.charAt(0)}`}
                                    </span>
                            }
                            <div style={{ marginLeft: 20, marginRight: 20}}>
                                <p className='navitems fw-bold mb-0 text-capitalize sidetext text-dark-grey card-truncated-text'>{username}</p>
                                <p className='navitems mb-0 sidetext text-blue ' style={{ fontSize: 14, fontWeight: '600', textDecoration: 'Underline' }}>{numberOfCards} Cards</p>
                            </div>
                        </div>
                        <div className='d-flex align-items-center justify-content-between' >
                            <div className="form-check form-switch" >
                                <input
                                    disabled={isDeleted}
                                    className="form-check-input"
                                    type="checkbox"
                                    name='isActive'
                                    id={`activate_user_${id}`}
                                    onClick={(e) => e.stopPropagation()}
                                    checked={data?.isActive}
                                    onChange={(event) => { setDetails((prev) => ({ ...prev, showModal: true, item: data, checked: event.target.checked, isDeleted: false })); }}
                                />
                            </div>
                           {showDelete !== false && <div 
                                style={{borderRadius: "10px", width: 34, height: 34, display: 'flex', justifyContent: 'center' }} 
                                onClick={(e) => { 
                                    e.stopPropagation(); 
                                    setDetails((prev) => ({ ...prev, showModal: true, item: data, isDeleted: true}));
                                }}
                                id={`delete_user_${id}`}
                                >
                                <img src={IconDelete} style={{ width: 20, objectFit: 'contain', paddingBottom:'2px' }} />
                            </div>}
                        </div>

                   {plusIcon !=false && <div>
                        <img src={AddIcon} style={{ width: 36, objectFit: 'contain' }} />
                    </div>}
                </div>
                <div className='d-flex align-items-center justify-content-between mt-2'>
                    <div className='d-flex align-items-center'>
                        <img src={LimitIcon} style={{ width: 32, objectFit: 'contain' }} />
                        <div style={{ marginLeft: 10,marginRight: 10 }}>
                            <p className='navitems mb-0 sidetext text-dark-grey ' style={{ fontSize: 14, fontWeight: '500', }}>{t('users.card_balance')}</p>
                        </div>
                    </div>
                    <div>
                        <p className='navitems mb-0 sidetext' style={{ fontSize: 16,fontWeight:600 }}>{price1}</p>
                    </div>
                </div>
                <div className='d-flex align-items-center justify-content-between mt-2'>
                    <div className='d-flex align-items-center'>
                        <img src={LimitIcon} style={{ width: 32, objectFit: 'contain' }} />
                        <div style={{ marginLeft: 10,marginRight: 10 }}>
                            <p className='navitems mb-0 sidetext text-dark-grey ' style={{ fontSize: 14, fontWeight: '500', }}>{t('users.cash_on_hand')}</p>
                        </div>
                    </div>
                    <div>
                        <p className='navitems mb-0 sidetext' style={{ fontSize: 16,fontWeight:600 }}>{price2}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UsersCard;
