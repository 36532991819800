import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';
import './pettyCash.scss';
import Export from '../../assets/images/export.png';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from 'yup';
import moment from 'moment';
import CrossImage from '../../assets/images/close-square-8.png';
import CrossBlue from '../../assets/images/close-square-blue.svg';
import Modal from 'react-bootstrap/Modal';
import { getpettyCashBalances, getPettyCashes, getEmployees, addCash, withdrawCash, disburseCash, returnCash, getEmployeesCashes, deleteTransaction } from './pettyCashSlice';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import LoadingComponent from '../../components/Loader/LoadingComponent';
import Logo2 from '../../assets/images/polo.png';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { createStartDateWithTimezone, createEndDateWithTimezone, truncateDescription } from '../../utils/XpendlessUtils';
import { formatTime, formatQAR } from '../../utils/XpendlessUtils';
import CustomDropdown from '../../components/CustomDropdown/CustomDropdown';
import PettyCashModal1 from '../../components/PettyCash/PettyCashModal1';
import notFound from '../../assets/images/not_found.png';
import CurrencyInput from 'react-currency-input-field';
import { MAX_VALUE } from '../../utils/constants';
import { isCompanyActive } from '../../utils/verifyCompanyStatus';

function PettyCash() {
  const { t } = useTranslation();
  const languages = [
    {
      code: 'en',
      name: 'English',
      country_code: 'gb',
    },
    {
      code: 'ar',
      name: 'العربية',
      dir: 'rtl',
      country_code: 'sa',
    },
  ];

  const currentLanguageCode = cookies.get('i18next') || 'en';
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [pettyCashStatus, setPettyCashStatus] = useState('completed');
  const [pageNo, setPageNo] = useState(1);
  const [employeesPageNo, setEmployeesPageNo] = useState(1);
  const [filterQuery, setFilterQuery] = useState(`?status=${pettyCashStatus}`);
  const [show, setShow] = useState(false);
  const [showTwo, setShowTwo] = useState(false);
  const [showThree, setShowThree] = useState(false);
  const [showFour, setShowFour] = useState(false);
  const [showFive, setShowFive] = useState(false);
  const [pendingCashesCount, setPendingCashesCount] = useState(null);
  const dispatch = useDispatch();
  const { isLoading, isBalancesLoading, pettyCashBalances, transactions, employees, cashWithEmployees } = useSelector(state => state.pettycashReducer);
  const selectOptions = [
    {
      "label": `${t('pettyCash.all')}`,
      "value": "all"
    },
    {
      "label": `${t('pettyCash.add')}`,
      "value": "add"
    },
    {
      "label": `${t('pettyCash.withdraw')}`,
      "value": "withdraw"
    },
    {
      "label": `${t('pettyCash.disburse')}`,
      "value": "disburse"
    },
    {
      "label": `${t('pettyCash.return')}`,
      "value": "return"
    },
    {
      "label": `${t('pettyCash.settlement')}`,
      "value": "settlement"
    },
  ];
  const [transToDelete, setTransToDelete] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredRowIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredRowIndex(null);
  };

  const handleShowDeleteModal = (transID) => {
    setTransToDelete(transID);
    setShowDeleteModal(true);
  };
  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  const handleFilterClick = (index) => {
    setSelectedIndex(index);
    if (index === 0) {
      setPettyCashStatus('completed');
    } else if (index === 1) {
      setPettyCashStatus('pending');
    }
  };

  useEffect(() => {
    setFilterQuery(`?status=${pettyCashStatus}`);
    handleResetForm();
  }, [pettyCashStatus]);


  useEffect(() => {
    setFilterQuery((prev) => {
      const urlParams = new URLSearchParams(prev);
      urlParams.set('page', pageNo);
      return `?${urlParams.toString()}`;
    });
  }, [pageNo]);

  useEffect(() => {
    if (pettyCashStatus === 'pending') {
      setPendingCashesCount(transactions?.data?.count || 0);
    }
  }, [transactions]);


  useEffect(() => {
    document.body.dir = currentLanguage.dir || 'ltr';
    document.title = t('app_title');
  }, [currentLanguage, t]);

  useEffect(() => {
    dispatch(getpettyCashBalances());
    fetchPendingPettyCashes();
    dispatch(getEmployees());
    dispatch(getEmployeesCashes());
  }, []);

  const fetchPendingPettyCashes = () => {
    if (pendingCashesCount === null) {
      dispatch(getPettyCashes('?status=pending'))
        .unwrap()
        .then((res) => {
          setPendingCashesCount(res.data.count || 0);
        }).catch((error) => {
          console.log(error?.name);
        });
    }
  };

  useEffect(() => {
    dispatch(getPettyCashes(filterQuery));
  }, [filterQuery]);

  const {
    control: controlFilterForm,
    handleSubmit: handleFilterSubmit,
    formState: { errors: errorsFilterForm },
    reset: resetFilterForm,
    watch,
  } = useForm({
    resolver: yupResolver(yup.object().shape({
      type_petty_cash: yup.string().optional(),
      from_date_petty_cash: yup.string().optional(),
      to_date_petty_cash: yup.string().optional().test('is-valid-end-date', 'End date should not be before start date', function (value) {
        const startDate = this.resolve(yup.ref('from_date_petty_cash'));
        return !startDate || !value || value >= startDate;
      }),
      performed_by_petty_cash: yup.string().optional(),
    })
    )
  });

  const onFilterFormSubmit = (requestData) => {
    setFilterQuery((prev) => {
      const urlParams = new URLSearchParams(prev);

      if (requestData.type_petty_cash) {
        if (requestData.type_petty_cash !== 'all') {
          urlParams.set('type', requestData.type_petty_cash);
        } else {
          urlParams.delete('type');
        }
      }
      if (requestData.from_date_petty_cash) {
        requestData.from_date_petty_cash = createStartDateWithTimezone(requestData.from_date_petty_cash);
        urlParams.set('fromDate', requestData.from_date_petty_cash);
      } else {
        urlParams.delete('fromDate');
      }
      if (requestData.to_date_petty_cash) {
        requestData.to_date_petty_cash = createEndDateWithTimezone(requestData.to_date_petty_cash);
        urlParams.set('toDate', requestData.to_date_petty_cash);
      } else {
        urlParams.delete('toDate');
      }
      if (requestData.performed_by_petty_cash) {
        urlParams.set('performedBy', requestData.performed_by_petty_cash);
      } else {
        urlParams.delete('performedBy');
      }

      return `?${urlParams.toString()}`;
    });
  };

  const handleSelectChange = (selectedEmployee) => {
    if (selectedEmployee === 'all') {
      dispatch(getEmployeesCashes());
    } else {
      dispatch(getEmployeesCashes(`?employeeId=${selectedEmployee}`));
    }
  };

  const handleResetForm = () => {
    setFilterQuery(`?status=${pettyCashStatus}`);
    resetFilterForm();
  };

  const onPreviousClick = () => {
    setPageNo(prev => prev - 1);
  };

  const onNextClick = () => {
    setPageNo(prev => prev + 1);
  };

  const onClickAddModal = (data) => {
    const requestData = {
      amount: data.add_amount_petty_cash,
      description: data.add_desc_petty_cash
    };
    dispatch(addCash(requestData))
      .unwrap()
      .then((res) => {
        dispatch(getpettyCashBalances());
        dispatch(getPettyCashes(filterQuery));
        resetFilterForm();
        toast.success(res?.message);
        setShow(false);
      }).catch((error) => {
        toast.error(error?.name);
      });
  };

  const deletePendingTrans = () => {
    dispatch(deleteTransaction(transToDelete)).unwrap()
      .then((res) => {
        dispatch(getpettyCashBalances());
        dispatch(getPettyCashes(filterQuery));
        dispatch(getEmployeesCashes());
        resetFilterForm();
        handleCloseDeleteModal();
        toast.success(res?.message);
      }).catch((error) => {
        toast.error(error?.name);
      });
  };

  const onClickWithdrawModal = (data) => {
    const requestData = {
      amount: data.withraw_amount_petty_cash,
      description: data.withdraw_desc_petty_cash
    };
    dispatch(withdrawCash(requestData))
      .unwrap()
      .then((res) => {
        dispatch(getpettyCashBalances());
        dispatch(getPettyCashes(filterQuery));
        resetFilterForm();
        toast.success(res?.message);
        setShowTwo(false);
      }).catch((error) => {
        toast.error(error?.name);
      });
  };

  const onClickDisburseModal = (data) => {
    const requestData = {
      "amount": data.amount,
      "description": data.description,
      "recipientEmployeeId": data.employee_name
    };
    dispatch(disburseCash(requestData))
      .unwrap()
      .then((res) => {
        dispatch(getpettyCashBalances());
        dispatch(getPettyCashes('?status=pending'))
          .unwrap()
          .then((res) => {
            setPendingCashesCount(res.data.count || 0);
          }).catch((error) => {
            console.log(error?.name);
          });
        dispatch(getEmployeesCashes());
        resetFilterForm();
        toast.success(res?.message);
        setShowThree(false);
      }).catch((error) => {
        toast.error(error?.name);
      });
  };

  const onClickReturnModal = (data) => {
    const requestData = {
      "amount": data.amount,
      "description": data.description,
      "employeeId": data.employee_name
    };
    dispatch(returnCash(requestData))
      .unwrap()
      .then((res) => {
        dispatch(getpettyCashBalances());
        dispatch(getPettyCashes(filterQuery));
        dispatch(getEmployeesCashes());
        resetFilterForm();
        toast.success(res?.message);
        setShowFour(false);
      }).catch((error) => {
        toast.error(error?.name);
      });
  };

  const handleCloseModal = () => setShow(false);

  const handleShow = () => {
    if (!isCompanyActive()) return;
    resetAddForm();
    resetWithdrawForm();
    reset();
    setShow(true);
  };

  const handleCloseModalTwo = () => setShowTwo(false);

  const handleShowTwo = () => {
    if (!isCompanyActive()) return;
    resetAddForm();
    resetWithdrawForm();
    reset();
    setShowTwo(true);
  };

  const handleCloseModalThree = () => setShowThree(false);

  const handleShowThree = () => {
    if (!isCompanyActive()) return;
    resetAddForm();
    resetWithdrawForm();
    reset();
    setShowThree(true);

  };

  const handleCloseModalFour = () => setShowFour(false);

  const handleShowFour = () => {
    if (!isCompanyActive()) return;
    dispatch(getEmployees());
    dispatch(getEmployeesCashes());
    resetAddForm();
    resetWithdrawForm();
    reset();
    setShowFour(true);
  };

  const handleCloseModalFive = () => {
    setShowFive(false);
  };

  const handleShowFive = () => {
    setShowFive(true);
  };

  const { control: controlAddForm,
    handleSubmit: handleAddSubmit,
    formState: { errors: errorsAddForm }, reset: resetAddForm, } = useForm({
      resolver: yupResolver(yup.object().shape({
        add_amount_petty_cash: yup
          .string()
          .required(`${t('pettyCash.amount_required')}`)
          .matches(/^\d*\.?\d*$/, 'Please enter valid amount'),
        add_desc_petty_cash: yup
          .string()
          .min(2, `${t('pettyCash.desc_min_length')}`)
          .max(255, `${t('pettyCash.desc_max_length')}`)
          .required(`${t('pettyCash.desc_required')}`),
      })),
      defaultValues: {
        add_amount_petty_cash: '',
        add_desc_petty_cash: ''
      }
    });

  const { control: controlWithdrawForm,
    handleSubmit: handleWithdrawSubmit,
    formState: { errors: errorsWithdrawForm }, reset: resetWithdrawForm, } = useForm({
      resolver: yupResolver(yup.object().shape({
        withraw_amount_petty_cash: yup
          .string()
          .required(`${t('pettyCash.amount_required')}`)
          .matches(/^\d*\.?\d*$/, 'Please enter valid amount'),
        withdraw_desc_petty_cash: yup
          .string()
          .min(2, `${t('pettyCash.desc_min_length')}`)
          .max(255, `${t('pettyCash.desc_max_length')}`)
          .required(`${t('pettyCash.desc_required')}`),
      })),
      defaultValues: {
        withraw_amount_petty_cash: '',
        withdraw_desc_petty_cash: ''
      }
    });

  const schema = yup.object().shape({
    amount: yup
      .string()
      .required(`${t('pettyCash.amount_required')}`)
      .matches(/^\d+(\.\d{2})$/, `${t('pettyCash.decimal_error')}`)
      .test('is-valid-number', `${t('pettyCash.type_error')}`, value => !isNaN(parseFloat(value))),
    description: yup
      .string()
      .min(2, `${t('pettyCash.desc_min_length')}`)
      .max(255, `${t('pettyCash.desc_max_length')}`)
      .required(`${t('pettyCash.desc_required')}`),
    employee_name: yup.string().required(t('pettyCash.employee_name_required')),
  });

  const { reset } = useForm({
    resolver: yupResolver(schema),
  });

  const options = employees?.data.map((employee) => ({
    value: employee.employeeId,
    label: `${employee.firstName} ${employee.lastName}`,
  }));

  const [selectedOption, setSelectedOption] = useState('');

  const handleDropdownChange = (selected) => {
    setSelectedOption(selected.value);
    handleSelectChange(selected.value);
  };

  return (
    <div className={`${currentLanguageCode === 'en' ? 'white-container' : 'white-container-arabic'}`}>
      <h1 className='text-green'>{t('pettyCash.title')}</h1>
      <div className='white-section'>
        <div className='responsive-grid'>
          <div className='header-box truncated-text' style={{ backgroundColor: '#FFA5001A' }}>
            {(isBalancesLoading || pettyCashBalances === null) && <LoadingComponent />}
            <h3 className='box-text subTitle'>{t('pettyCash.remaining_pettyCash')}</h3>
            <h2 className='box-text box-title' style={{ color: '#FFA500' }}>
              {`${Math.floor(pettyCashBalances?.companyBalance || 0).toLocaleString()}.`}
              <span className='light-text'>
                {typeof pettyCashBalances?.companyBalance === 'number'
                  ? (pettyCashBalances?.companyBalance?.toFixed(2).split('.')[1] !== "00"
                    ? pettyCashBalances?.companyBalance?.toFixed(2).split('.')[1]
                    : '00')
                  : '00'
                }
              </span>
              <span className='light-text'>QAR</span>
            </h2>
          </div>
          <div className='header-box truncated-text' style={{ backgroundColor: '#007BFF1A' }} onClick={handleShowFive} id='show_cash_with_employee'>
            {(isBalancesLoading || pettyCashBalances === null) && <LoadingComponent />}
            <h3 className='box-text subTitle'>{t('pettyCash.available_with_employee')}</h3>
            <Stack direction='column'>
              <Stack direction='row' style={{ alignItems: 'baseline' }}>
                <h3 className='box-text subTitle'>Confirmed: </h3>
                <h2 className='box-text box-title flex-start' style={{ color: '#007BFF' }}>
                  {`${Math.floor(pettyCashBalances?.employeeBalance || 0).toLocaleString()}.`}
                  <span className='light-text'>
                    {typeof pettyCashBalances?.employeeBalance === 'number'
                      ? (pettyCashBalances?.employeeBalance?.toFixed(2).split('.')[1] !== "00"
                        ? pettyCashBalances?.employeeBalance?.toFixed(2).split('.')[1]
                        : '00')
                      : '00'
                    }
                  </span>
                  <span className='light-text'>QAR</span>
                </h2>
              </Stack>
              <Stack direction='row' style={{ alignItems: 'baseline' }}>
                <h3 className='box-text subTitle'>Pending: </h3>
                <h2 className='box-text box-title flex-start' style={{ color: '#007BFF', marginLeft: '19px' }}>
                  {`${Math.floor(pettyCashBalances?.pendingBalance || 0).toLocaleString()}.`}
                  <span className='light-text'>
                    {typeof pettyCashBalances?.pendingBalance === 'number'
                      ? (pettyCashBalances?.pendingBalance?.toFixed(2).split('.')[1] !== "00"
                        ? pettyCashBalances?.pendingBalance?.toFixed(2).split('.')[1]
                        : '00')
                      : '00'
                    }
                  </span>
                  <span className='light-text'>QAR</span>
                </h2>
              </Stack>
              <button className='hidden-button' id='show_cash_with_employee'>
                <img src={Export} />
              </button>
            </Stack>
          </div>
          <div className='header-box truncated-text' style={{ backgroundColor: '#E6FBF580' }}>
            {(isBalancesLoading || pettyCashBalances === null) && <LoadingComponent />}
            <h3 className='box-text subTitle'>{t('pettyCash.total_pettyCash')}</h3>
            <h2 className='box-text box-title' style={{ color: '#00D094' }}>
              {`${Math.floor(pettyCashBalances?.totalBalance || 0).toLocaleString()}.`}
              <span className='light-text'>
                {typeof pettyCashBalances?.totalBalance === 'number'
                  ? (pettyCashBalances?.totalBalance?.toFixed(2).split('.')[1] !== "00"
                    ? pettyCashBalances?.totalBalance?.toFixed(2).split('.')[1]
                    : '00')
                  : '00'
                }
              </span>
              <span className='light-text'>QAR</span>
            </h2>
          </div>
        </div>
        <Stack direction={{ sm: 'column', md: 'row' }} className='mt-4' justifyContent={'space-between'}>
          <Stack direction={{ sm: 'column', md: 'row' }} className='btn-container mb-2'>
            <button onClick={handleShow} className='filled-btn btn-success-green cursor-pointer truncated-text' id='add_petty_cash'>{t('pettyCash.add_cash')}</button>
            <button onClick={handleShowTwo} className='filled-btn btn-danger cursor-pointer truncated-text' id='withdraw_petty_cash'>{t('pettyCash.withdraw_cash')}</button>
          </Stack>
          <Stack direction={{ sm: 'column', md: 'row' }} className='btn-container' justifyContent={'flex-end'}>
            <button onClick={handleShowThree} className='bordered-btn blue cursor-pointer truncated-text' id='disburse_petty_cash'>{t('pettyCash.disburse_cash')}</button>
            <button onClick={handleShowFour} className='bordered-btn orange cursor-pointer truncated-text' id='return_petty_cash'>{t('pettyCash.return_cash')}</button>
          </Stack>
        </Stack>
      </div>
      <div className='white-section' style={{ position: 'relative' }}>
        {isLoading && <LoadingComponent />}

        <div className='pettyCash-toggle'>
          <div className='col-lg-6 col-md-12 border toggle-col '>
            <div className='toggle-buttons-container justify-content-between p-2'>
              <div
                className={selectedIndex === 0 ? 'toggle-btn truncated-text selected' : 'toggle-btn truncated-text unselected'}
                key={0}
                onClick={() => handleFilterClick(0)}
                id='select_confirmed'
                style={{ paddingBlock: '9px' }}
              >
                {`${t('pettyCash.cofirmed')}`}
              </div>
              <div
                className={selectedIndex === 1 ? 'toggle-btn truncated-text selected' : 'toggle-btn truncated-text unselected'}
                key={1}
                onClick={() => handleFilterClick(1)}
                id='select_pending'
                style={{ paddingBlock: '5px' }}
              >
                <Stack direction='row' gap={1} alignItems={'baseline'}>
                  {`${t('pettyCash.pending')}`}
                  <Chip className='styled-chip' label={pendingCashesCount || 0} />
                </Stack>
              </div>
            </div>
          </div>
        </div>
        <form
          onSubmit={handleFilterSubmit(onFilterFormSubmit)}
          className='mt-3'
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              handleFilterSubmit(onFilterFormSubmit)();
            }
          }}
        >
          <div className='row align-items-end'>
            {pettyCashStatus !== 'pending' && (
              <div className="col-lg-3 col-md-6 col-sm-12 form-outline">
                <label className="label-form" htmlFor="type_petty_cash">
                  {t('pettyCash.filter_trans')}
                </label>
                <Controller
                  control={controlFilterForm}
                  name="type_petty_cash"
                  render={({ field }) => (
                    <CustomDropdown
                      id={'type_petty_cash'}
                      options={selectOptions}
                      selectedOption={selectOptions?.find(option => option.value === watch("type_petty_cash")) || 'All'}
                      placeholder={"All"}
                      onChange={(selectedOption) => {
                        field.onChange(selectedOption.value);
                      }}
                      onKeyUp={(e, selectedOption) => {
                        if (e.key === 'Enter') {
                          handleFilterSubmit(onFilterFormSubmit)();
                        } else {
                          field.onChange(selectedOption?.value);
                        }
                      }}
                    />
                  )}
                />
              </div>
            )}
            <div className="col-lg-3 col-md-6 col-sm-12 form-outline">
              <label className="label-form" htmlFor="from_date_petty_cash">{t('pettyCash.from_date')}</label>
              <Controller
                control={controlFilterForm}
                name="from_date_petty_cash"
                render={({ field }) => (
                  <input
                    type="date"
                    id="from_date_petty_cash"
                    className="form-control form-control-lg"
                    placeholder="dd-mm-yyyy"
                    autoComplete="off"
                    value={field.value || ''}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    max={moment().format('YYYY-MM-DD')}
                    style={{
                      color: field.value ? 'black' : 'grey',
                    }}
                  />
                )}
              />
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 form-outline">
              <label className="label-form" htmlFor="to_date_petty_cash">{t('pettyCash.to_date')}</label>
              <Controller
                control={controlFilterForm}
                name="to_date_petty_cash"
                render={({ field }) => (
                  <div>
                    <input
                      type="date"
                      id="to_date_petty_cash"
                      className="form-control form-control-lg"
                      placeholder="dd-mm-yyyy"
                      autoComplete="off"
                      value={field.value || ''}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      style={{
                        color: field.value ? 'black' : 'grey',
                      }}
                    />
                    {errorsFilterForm?.to_date_petty_cash && (
                      <div style={{ color: 'red' }}>{errorsFilterForm?.to_date_petty_cash?.message}</div>
                    )}
                  </div>
                )}
              />
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 form-outline">
              <label className="label-form" htmlFor="performed_by_petty_cash">{t('pettyCash.performed_by')}</label>
              <Controller
                control={controlFilterForm}
                name="performed_by_petty_cash"
                render={({ field }) => (
                  <CustomDropdown
                    options={options}
                    id={'performed_by_petty_cash'}
                    placeholder={"Select Employee"}
                    selectedOption={options?.find(option => option.value === watch("performed_by_petty_cash")) || "Select Employee"}
                    onChange={(selectedOption) => field.onChange({ target: { value: selectedOption.value } })}
                    onKeyUp={(e, selectedOption) => {
                      if (e.key === 'Enter') {
                        handleFilterSubmit(onFilterFormSubmit)();
                      } else {
                        field.onChange(selectedOption?.value);
                      }
                    }}
                  />
                )}
              />
            </div>
          </div>

          <div className="d-flex align-items-center justify-content-end mt-3">
            <button type='submit' id='submit_filter_petty_cash' className='search-btn'>{t('pettyCash.search')}</button>
            <button type='button' id='reset_filter_petty_cash' onClick={() => handleResetForm()} className='btn-secondary-small mx-3'>
              {t('pettyCash.reset')}
            </button>
          </div>
        </form>
        <div className='bordered-container mt-3'>
          <div className=' table-responsive' style={{ border: 0 }}>
            {(isLoading || transactions === null) && <LoadingComponent/>}
            <table id="transactionsTable" style={{ borderCollapse: 'separate', borderSpacing: '0 16px' }} cellSpacing="0" width="100%">
              <thead>
                <tr style={{ borderTopWidth: 0 }} className='table-head'>
                  <th scope="col" style={{ textAlign: 'center' }}>{t('pettyCash.type')}</th>
                  <th scope="col" style={{ textAlign: 'center' }}>{t('pettyCash.amount')}</th>
                  <th scope="col" style={{ textAlign: 'center' }}>{t('pettyCash.date')}</th>
                  <th scope="col" style={{ textAlign: 'center' }}>{t('pettyCash.desc')}</th>
                  <th scope="col" style={{ textAlign: 'center' }}>{t('pettyCash.preformed_by')}</th>
                  <th scope="col" style={{ textAlign: 'center' }}>{t('pettyCash.employee_name')}</th>
                  {pettyCashStatus === 'pending' && <th scope="col" style={{ textAlign: 'center' }}>{t('pettyCash.actions')}</th>}
                </tr>
              </thead>
              {
                pettyCashStatus === 'pending'
                  ? <tbody id='pending_transactions'>
                    {
                      transactions?.data?.rows?.map((list, index) => {
                        return <tr
                          key={index}
                          className={`${currentLanguageCode === 'en' ? 'table-body-tr' : 'table-body-tr-arabic'}`}
                        >
                          <td style={{ textAlign: 'center' }}>{list.type.charAt(0).toUpperCase() + list.type.slice(1)}</td>
                          <td style={{ textAlign: 'center' }}>{formatQAR(list.amount)}</td>
                          <td style={{ textAlign: 'center' }}>{formatTime(list.createdAt)}</td>
                          <td
                            style={{ textAlign: 'center' }}
                            onMouseOver={() => handleMouseEnter(index)}
                            onMouseLeave={() => handleMouseLeave()}
                          >
                            <OverlayTrigger
                              trigger={['hover', 'focus']}
                              placement="top"
                              show={hoveredRowIndex === index}
                              delay={{ show: 100, hide: 0 }}
                              overlay={list?.comments?.length > 50 ?
                                <Tooltip
                                  className="custom-tooltip"
                                  onMouseEnter={() => handleMouseEnter(index)}
                                  onMouseLeave={() => handleMouseLeave()}
                                >
                                  {list?.comments}
                                </Tooltip> : <></>}
                            >
                              <div>
                                {truncateDescription(list?.comments || "")}
                              </div>
                            </OverlayTrigger>
                          </td>
                          <td style={{ textAlign: 'center' }}>{list.performedByName}</td>
                          <td style={{ textAlign: 'center' }}>{(list.type === 'return' || list.type === 'disburse') ? list.employeeName : ""}</td>
                          <td onClick={() => handleShowDeleteModal(list.pettyCashId)}>
                            <a id={`delete_${list.pettyCashId}`}><span style={{ marginLeft: 10, marginRight: 10, cursor: 'pointer', color: "#FF5733" }}>{t('delete')}</span></a>
                          </td>
                        </tr>;
                      }
                      )
                    }
                  </tbody>
                  : <tbody id='confirmed_transactions'>
                    {
                      transactions?.data?.rows?.map((list, index) => {
                        return <tr
                          key={index}
                          className={`${currentLanguageCode === 'en' ? 'table-body-tr' : 'table-body-tr-arabic'}`}
                        >
                          <td style={{ textAlign: 'center' }}>{list.type.charAt(0).toUpperCase() + list.type.slice(1)}</td>
                          <td style={{ textAlign: 'center' }}>{formatQAR(list.amount)}</td>
                          <td style={{ textAlign: 'center' }}>{formatTime(list.createdAt)}</td>
                          <td
                            style={{ textAlign: 'center' }}
                            onMouseOver={() => handleMouseEnter(index)}
                            onMouseLeave={() => handleMouseLeave()}
                          >
                            <OverlayTrigger
                              trigger={['hover', 'focus']}
                              placement="top"
                              show={hoveredRowIndex === index}
                              delay={{ show: 100, hide: 0 }}
                              overlay={list?.comments?.length > 50 ?
                                <Tooltip
                                  className="custom-tooltip"
                                  onMouseEnter={() => handleMouseEnter(index)}
                                  onMouseLeave={() => handleMouseLeave()}
                                >
                                  {list?.comments}
                                </Tooltip> : <></>}
                            >
                              <div>
                                {truncateDescription(list?.comments || "")}
                              </div>
                            </OverlayTrigger>
                          </td>
                          <td style={{ textAlign: 'center' }}>{list.performedByName}</td>
                          <td style={{ textAlign: 'center' }}>{(list.type === 'return' || list.type === 'disburse') ? list.employeeName : ""}</td>
                        </tr>;
                      }
                      )
                    }
                  </tbody>
              }
            </table>
            {!(transactions?.data?.rows && transactions?.data?.rows?.length > 0) && (
              <div className='d-flex align-items-center justify-content-center p-3 px-2'>
                <img src={notFound} width={200} height={200} />
              </div>)
            }
          </div>
        </div>
        <div className='d-flex align-items-center justify-content-between'
          style={{ width: "100%", borderTop: '0px !imporant' }}>
          <nav aria-label="Page navigation example">
            <ul className="pagination my-3 pe-0 pt-1">
              <li className="page-item">
                <button className="btn page-link" disabled={pageNo === 1} onClick={() => onPreviousClick()} id='prev_page_petty_cash'>Previous</button>
              </li>
              <li className="page-item mx-3">
                <button className="btn page-link" disabled={pageNo >= transactions?.data?.totalPages} onClick={() => onNextClick()} id='next_page_petty_cash'>Next</button>
              </li>
            </ul>
          </nav>
          <div className='page-count d-flex align-items-center'>
            <h4 className='pagination-textcss'>Page {pageNo} of {transactions?.data?.totalPages || 1}</h4>
          </div>
        </div>
      </div>

      <Modal className='TaxcodeModalOne' show={show} onHide={handleCloseModal} style={{ paddingRight: 0 }}>
        <form onSubmit={handleAddSubmit(onClickAddModal)}>
          <Modal.Body>
            <div className='d-flex justify-content-between align-items-start green-header'>
              <div className='col-9'>
                <p className='text-green addjust pettyCach-model-title' style={{ fontSize: 16, marginRight: 15 }}>
                  {t('pettyCash.add_cash_title')}
                </p>
              </div>
              <div onClick={handleCloseModal} className='clo-2' style={{ cursor: 'pointer' }} id='close_modal'>
                <img src={CrossImage} className='small-addjust' style={{ width: 20, objectFit: 'contain', marginLeft: 15 }} />
              </div>
            </div>
            <div className="form-outline mt-4 mb-3">
              <label className="label-form" htmlFor="add_amount_petty_cash">{t('pettyCash.amount')}</label>
              <Controller
                name="add_amount_petty_cash"
                control={controlAddForm}
                render={({ field }) => (
                  <CurrencyInput
                    type="text"
                    id="add_amount_petty_cash"
                    className="form-control form-control-lg"
                    placeholder={`${t('pettyCash.amount')}`}
                    decimalsLimit={2}
                    decimalScale={2}
                    value={field.value || ''}
                    autoComplete="off"
                    onBlur={field.onBlur}
                    disablenegative={'true'}
                    onKeyDown={(e) => {
                      if (e.key === '-') {
                        e.preventDefault();
                      }
                    }}
                    onValueChange={(value) => {
                      const numericValue = parseFloat(value?.replace(/,/g, '')) || 0;
                      if (numericValue < MAX_VALUE) {
                        field.onChange(value === '' ? '' : value?.startsWith('.') ? `0${value}` : value);
                      }
                    }}
                  />
                )}
              />
              <p className="text-danger">{errorsAddForm?.add_amount_petty_cash?.message}</p>
            </div>
            <div className="form-outline my-4">
              <label className="label-form" htmlFor="add_desc_petty_cash">{t('pettyCash.desc')}</label>
              <Controller
                name="add_desc_petty_cash"
                control={controlAddForm}
                defaultValue=""
                render={({ field }) => (
                  <input {...field} type="text" id="add_desc_petty_cash" className="form-control form-control-lg"
                    placeholder={`${t('pettyCash.desc')}`} />
                )}
              />
              <p className="text-danger">{errorsAddForm?.add_desc_petty_cash?.message}</p>
            </div>
            <div style={{ borderBottom: '1px solid #EAECF0', marginBottom: 24 }}></div>
            <div className='d-flex justify-content-end align-items-center'>
              <button type="submit" className='btn-primary-small' id='submit_add_petty_cash'>{t('pettyCash.submit')}</button>
            </div>
          </Modal.Body>
        </form>
      </Modal>

      <Modal className='TaxcodeModalOne' show={showTwo} onHide={handleCloseModalTwo} style={{ paddingRight: 0 }}>
        <form onSubmit={handleWithdrawSubmit(onClickWithdrawModal)}>
          <Modal.Body>
            <div className='d-flex justify-content-between align-items-start green-header'>
              <div className='col-9'>
                <p className='text-green addjust pettyCach-model-title' style={{ fontSize: 16, marginRight: 15 }}>
                  {t('pettyCash.withdraw_cash_title')}
                </p>
              </div>
              <div onClick={handleCloseModalTwo} className='clo-2' style={{ cursor: 'pointer' }} id='close_modal'>
                <img src={CrossImage} className='small-addjust' style={{ width: 20, objectFit: 'contain', marginLeft: 15 }} />
              </div>
            </div>
            <div className="form-outline mt-4 mb-3">
              <label className="label-form" htmlFor="withraw_amount_petty_cash">{t('pettyCash.amount')}</label>
              <Controller
                name="withraw_amount_petty_cash"
                control={controlWithdrawForm}
                defaultValue=""
                render={({ field }) => (
                  <CurrencyInput
                    type="text"
                    id="withraw_amount_petty_cash"
                    className="form-control form-control-lg"
                    placeholder={`${t('pettyCash.amount')}`}
                    value={field.value || ''}
                    autoComplete="off"
                    decimalsLimit={2}
                    decimalScale={2}
                    onBlur={field.onBlur}
                    disablenegative={'true'}
                    onKeyDown={(e) => {
                      if (e.key === '-') {
                        e.preventDefault();
                      }
                    }}
                    onValueChange={(value) => {
                      const numericValue = parseFloat(value?.replace(/,/g, '')) || 0;
                      if (numericValue < MAX_VALUE) {
                        field.onChange(value === '' ? '' : value?.startsWith('.') ? `0${value}` : value);
                      }
                    }}
                  />
                )}
              />
              <p className="text-danger">{errorsWithdrawForm?.withraw_amount_petty_cash?.message}</p>
            </div>
            <div className="form-outline mt-4 mb-4">
              <label className="label-form" htmlFor="withdraw_desc_petty_cash">{t('pettyCash.desc')}</label>
              <Controller
                name="withdraw_desc_petty_cash"
                control={controlWithdrawForm}
                defaultValue=""
                render={({ field }) => (
                  <input {...field} type="text" id="withdraw_desc_petty_cash" className="form-control form-control-lg"
                    placeholder={`${t('pettyCash.desc')}`} />
                )}
              />
              <p className="text-danger">{errorsWithdrawForm?.withdraw_desc_petty_cash?.message}</p>
            </div>
            <div style={{ borderBottom: '1px solid #EAECF0', marginBottom: 24 }}></div>
            <div className='d-flex justify-content-end align-items-center'>
              <button type="submit" className='btn-primary-small' id='submit_withdraw_petty_cash'>{t('pettyCash.submit')}</button>
            </div>
          </Modal.Body>
        </form>
      </Modal>

      <PettyCashModal1
        show={showThree}
        onClose={handleCloseModalThree}
        onSubmit={onClickDisburseModal}
        selectOptions={employees?.data?.map(employee => ({
          value: employee.employeeId,
          label: `${employee.firstName} ${employee.lastName}`
        }))}
        title={t('pettyCash.disburse_title')}
      />

      <PettyCashModal1
        show={showFour}
        onClose={handleCloseModalFour}
        onSubmit={onClickReturnModal}
        selectOptions={cashWithEmployees?.data?.rows?.map(employee => ({
          value: employee.employeeId,
          label: `${employee.firstName} ${employee.lastName} - ${formatQAR(employee.pettyCashBalance)}`
        }))}
        title={t('pettyCash.return_title')} />

      <Modal className='pettyCash-employee-model' show={showFive} onHide={handleCloseModalFive} style={{ paddingRight: 0 }}>
        <Modal.Body style={{ margin: '0px' }}>
          <div className='d-flex justify-content-between align-items-start blue-header w-100'>
            <div className='col-9'>
              <p className='blue-header-text addjust pettyCach-model-title' style={{ fontSize: 16, marginRight: 15 }}>
                {t('pettyCash.cash_with_employee')}
              </p>
            </div>
            <div onClick={handleCloseModalFive} className='clo-2' style={{ cursor: 'pointer' }} id='close_modal'>
              <img src={CrossBlue} className='small-addjust' style={{ width: 20, objectFit: 'contain', marginLeft: 15 }} />
            </div>
          </div>
          <div className='mt-3'>
            <Stack direction='row' className='blue-header-text' style={{ fontSize: '24px' }} gap={1} alignItems={'baseline'}>
              <h1>{t('pettyCash.total')}</h1>
              <h1>
                {`${Math.floor(pettyCashBalances?.employeeBalance || 0).toLocaleString()}.`}
                <span className='light-text'>
                  {`${(pettyCashBalances?.employeeBalance?.toFixed(2).split('.')[1]) !== "0000" ? pettyCashBalances?.employeeBalance?.toFixed(2).split('.')[1] : '00'}`}

                </span>
                <span className='light-text'>QAR</span>
              </h1>
            </Stack>
          </div>
          <Stack direction='row' gap={'8px'} className="form-outline mt-3 col-7" style={{ alignItems: 'baseline' }}>
            <label className="label-form" htmlFor="employee_name" style={{ width: '-webkit-fill-available' }}>{t('pettyCash.employee_name')}</label>
            <CustomDropdown
              options={options ? [
                { value: 'all', label: 'All' },
                ...options
              ] : []}
              selectedOption={options?.find(option => option.value === selectedOption)}
              placeholder={"Select Employee"}
              onChange={handleDropdownChange}
            />
          </Stack>
          <div className='bordered-container mt-3'>
            <div className=' table-responsive' style={{ border: 0 }}>
              <table id="emplyeesCashTable" style={{ borderCollapse: 'separate', borderSpacing: '0 16px' }} cellSpacing="0" width="100%">
                <thead>
                  <tr style={{ borderTopWidth: 0 }} className='table-head'>
                    <th scope="col" style={{ textAlign: 'left' }}>{t('pettyCash.employee_name')}</th>
                    <th scope="col" style={{ textAlign: 'left' }}>{`${t('pettyCash.amount')} (QAR)`}</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    cashWithEmployees?.data?.rows?.map((list, index) => {
                      return <tr key={index} className={`${currentLanguageCode === 'en' ? 'table-body-tr' : 'table-body-tr-arabic'}`}>
                        <td style={{ textAlign: 'left' }}>{`${list.firstName} ${list.lastName}`}</td>
                        <td style={{ textAlign: 'left' }}>{formatQAR(list.pettyCashBalance)}</td>
                      </tr>;
                    }
                    )
                  }
                </tbody>
              </table>
            </div>
            {!(cashWithEmployees?.data?.rows && cashWithEmployees?.data?.rows?.length > 0) && (
              <div className='d-flex align-items-center justify-content-center p-3 px-2'>
                <img src={notFound} width={200} height={200} />
              </div>)
            }
          </div>
          <div className='d-flex align-items-center justify-content-between mt-3 p-0 mb-0'
            style={{ width: "100%", borderTop: '0px !imporant' }}>
            <nav aria-label="Page navigation example">
              <ul className="pagination mb-0">
                <li className="page-item">
                  <button className="btn page-link" disabled={employeesPageNo === 1} onClick={() => setEmployeesPageNo(prev => prev - 1)} id='employee_prev_page'>Previous</button>
                </li>
                <li className="page-item mx-3">
                  <button className="btn page-link" disabled={employeesPageNo >= cashWithEmployees?.data?.totalPages} onClick={() => setEmployeesPageNo(prev => prev - 1)} id='employee_next_page'>Next</button>
                </li>
              </ul>
            </nav>
            <div className='page-count d-flex align-items-center'>
              <h4 className='pagination-textcss' style={{ color: '#007BFF' }}>Page {employeesPageNo} of {cashWithEmployees?.data?.totalPages || 1}</h4>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        aria-labelledby="contained-modal-title-center"
        centered
        show={showDeleteModal}
        onHide={handleCloseDeleteModal}
      >
        <div className='form' style={{ padding: 24, justifyContent: 'center', alignItems: 'center' }}>
          <div className='d-flex align-items-start justify-content-between'>
            <img src={Logo2} className="logoso mt-1 mb-4" alt="Sample image" />
            <div onClick={handleCloseDeleteModal} id='close_modal'>
              <img src={CrossImage} className='small-addjust' style={{ width: 20, objectFit: 'contain', marginLeft: 15 }} />
            </div>
          </div>
          <Stack direction='column' spacing={3} className='justify-content-center'>
            <h1 className='text-center mb-2' style={{ fontSize: '20px !important' }}>
              {t('pettyCash.delete_confirm')}
            </h1>
          </Stack>
          <Stack direction='row'>
            <div className="text-center text-lg-start mt-4 w-100">
              <button type="button" id='cancel_delete_petty_cash' onClick={() => { handleCloseDeleteModal(); }} className="btn btn-secondary btn-lg btn-block no-hover w-100" style={{ position: 'relative', zIndex: '1' }}>{t('cancel')} </button>
            </div>
            <div className="text-center text-lg-start mt-4 w-100">
              <button type="button" id='delete_trans_petty_cash' onClick={() => { deletePendingTrans(); }} className="btn btn-primary btn-lg btn-block w-100" style={{ position: 'relative', zIndex: '1' }}>{t('delete')} </button>
            </div>
          </Stack>
        </div>
      </Modal>
    </div>
  );
}

export default PettyCash;
