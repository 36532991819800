/* eslint-disable react/react-in-jsx-scope */
import './App.scss';
import { Routes, Route, Navigate } from "react-router-dom";
import { loginRoutes, routes as mainRoutes, signupRoutes } from './utils/routes';
import { XpendlessUtils } from './utils/XpendlessUtils';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from 'react';
import RotateMobile from './assets/images/orientation.png';
import { checkHasAccess } from './utils/checkAccess';
import { useSelector } from 'react-redux';

function App() {
  const [orientation, setOrientation] = useState(null);
  const {routes} = useSelector(state => state.userReducer);

  useEffect(() => {
    const lockOrientation = () => {
      if (window.screen.orientation && window.screen.orientation.lock) {
        if (isMobile()) {
          window.screen.orientation.lock('landscape-primary').then(() => {
            console.log('Orientation locked successfully');
          }).catch((error) => {
            console.error('Failed to lock orientation:', error);
          });
        }
      }
    };

    lockOrientation();

    const handleOrientationChange = () => {
      setOrientation(window.screen.orientation.type);
    };

    window.addEventListener('orientationchange', handleOrientationChange);

    return () => {
      window.removeEventListener('orientationchange', handleOrientationChange);
    };
  }, []);

  const isMobile = () => {
    return window.innerWidth < 920;
  };

  return (
    <>
      {(isMobile() && orientation === 'landscape-primary') ? (
        <div className='d-flex align-items-center justify-content-center flex-column' style={{ height: window.innerHeight / 1 }}>
          <img src={RotateMobile} width={80} style={{ objectFit: 'contain' }} />
          <h3 className='text-white mt-3' style={{ fontWeight: '700' }}>Please rotate your device to portrait mode</h3>
        </div>
      )
        :
        (
          <>
            <XpendlessUtils.ScrollToTop />
            <Routes>
              {
                loginRoutes.map((route, key) => {
                  return <Route key={key} {...route} />;
                })
              }
              {
                (routes?.length ? routes : mainRoutes.filter(route => checkHasAccess(route.path)))
                  .map((route, key) => <Route key={key} {...route} />)
              }
              {
                signupRoutes.map((route, key) => {
                  return <Route key={key} {...route} />;
                })
              }
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
            <ToastContainer
              bodyClassName="capitalize-first-letter"
            />
          </>
        )
      }
    </>
  );
}

export default App;
