import React from "react";
import './buttonsStyle.scss';

function SecondaryButton({ type, onClick, buttonText, isEnabled = true, id, style, isHighlighted=false }) {
  return (
    <button 
        type={type}
        {...(type === "button" && { onClick })}
        className={isEnabled ? isHighlighted ? 'btn-small' : 'btn-secondary-small truncated-text' : 'disabledButton truncated-text'}
        id={id}
        style={style}
        disabled={!isEnabled}
    >
        {buttonText}
    </button>
  );
}

export default SecondaryButton;
