import "./index.scss";

import React, { useEffect, useState } from "react";
import {
  addCategory,
  addCategoryGroup,
  deleteCategory,
  deleteCategoryGroup,
  getAccounts,
  getAllCategoryByGroup,
  getAllIcon,
  getAllTax,
  getCategoryDetail,
  updateCategories,
  updateCategory,
  updateCategoryGroup,
} from "../../pages/Settings/taxSlice";
import { useDispatch, useSelector } from "react-redux";

import AddIcon from "../../assets/images/table-add-icon.png";
import ConfirmationDialog from "../ConfirmationDialog";
import DeleteIcon from "../../assets/images/table-close-icon.png";
import Editicon from "../../assets/images/table-edit-icon.png";
import ImagesCross from "../../assets/images/close-square-8.png";
import LoadingComponent from "../../components/Loader/LoadingComponent";
import Modal from "react-bootstrap/Modal";
import ProfileIcon from "../ProfileIcon";
import Select from "react-select";
import cookies from "js-cookie";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { activeBlocked, verifyCompanyStatus } from "../../utils/verifyCompanyStatus";
import { allowedUserRoles, verifyUserRole } from "../../utils/verifyUserRole";
import PrimaryButton from "../Buttons/PrimaryButton";
import SecondaryButton from "../Buttons/SecondaryButton";

function Categories(props) {
  const { connectedIntegration } = props;
  const [show, setShow] = useState(false);
  const [showSecondModal, setShowSecondModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState(false);
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseShowSecondModal = () => setShowSecondModal(false);
  const handleShowSecondModal = () => setShowSecondModal(true);
  const {
    categoryGroupData,
    isLoading,
    iconData,
    taxData,
    categoryDetail,
    oddoAccountsList,
  } = useSelector((state) => state?.taxSlice);
  const dispatch = useDispatch();
  const [categoryGroupId, setCategoryGroupId] = useState(null);
  const [categoryId, setCategoryId] = useState(null);
  const form2 = useForm();
  const [updateConformation, setUpdateConformation] = useState(false);
  const form2Error = form2.formState?.errors;
  const form3 = useForm();
  const form3Error = form3?.formState?.errors;
  useEffect(() => {
    dispatch(getAllIcon());
    if (verifyCompanyStatus(activeBlocked) && verifyUserRole(allowedUserRoles.adminAndAccountant)) {
      dispatch(getAllCategoryByGroup());
    }
    if (verifyCompanyStatus(activeBlocked) && verifyUserRole(allowedUserRoles.adminAndAccountant)) {
      dispatch(getAllTax());
    }
  }, []);
  useEffect(() => {
    if (connectedIntegration?.isConnected) {
      dispatch(getAccounts());
    }
  }, [connectedIntegration]);

  useEffect(() => {
    if (categoryId && categoryDetail && categoryDetail?.length > 0) {
      form2.setValue("nominalCode", categoryDetail[0]?.nominalCode);
      form2.setValue("categoryName", categoryDetail[0]?.name);
      form2.setValue("taxId", categoryDetail[0]?.taxId);
      handleShowSecondModal();
    }
  }, [categoryDetail]);

  const handleCategoryDetail = (id) => {
    setCategoryId(id);
    if (verifyCompanyStatus(activeBlocked) && verifyUserRole(allowedUserRoles.adminAndAccountant)) {
      dispatch(getCategoryDetail(id));
    }
  };

  const onSubmit = (data) => {
    let payload = {
      categoryGroupName: data.categoryGroupName,
    };
    if (data.taxId) {
      payload.taxId = data.taxId;
    }
    if (verifyCompanyStatus(activeBlocked) && verifyUserRole(allowedUserRoles.adminAndAccountant)) {
      dispatch(addCategoryGroup(data))
        .unwrap()
        .then((res) => {
          toast.success(res?.name);
          handleClose();
          if (verifyCompanyStatus(activeBlocked) && verifyUserRole(allowedUserRoles.adminAndAccountant)) {
            dispatch(getAllCategoryByGroup());
          }
          setValue("iconId", "");
        })
        .catch((error) => {
          toast.error(error?.name);
        })
    }
  };

  const onSubmit2 = (data) => {
    if (categoryId) {
      data["categoryGroupId"] = categoryDetail[0]?.categoryGroupId;
      data["categoryId"] = categoryId;
      if (verifyCompanyStatus(activeBlocked) && verifyUserRole(allowedUserRoles.adminAndAccountant)) {
        dispatch(updateCategory(data))
          .unwrap()
          .then((res) => {
            toast.success(res?.name);
            handleCloseShowSecondModal();
            form2.reset();
            setCategoryGroupId(null);
            setCategoryId(null);
            if (verifyCompanyStatus(activeBlocked) && verifyUserRole(allowedUserRoles.adminAndAccountant)) {
              dispatch(getAllCategoryByGroup());
            }
          })
          .catch((error) => {
            toast.error(error?.name);
          })
      }
    } else {
      data["categoryGroupId"] = categoryGroupId;
      if (verifyCompanyStatus(activeBlocked) && verifyUserRole(allowedUserRoles.adminAndAccountant)) {
        dispatch(addCategory(data))
          .unwrap()
          .then((res) => {
            toast.success(res?.name);
            handleCloseShowSecondModal();
            form2.reset();
            setCategoryGroupId(null);
            setCategoryId(null);
            if (verifyCompanyStatus(activeBlocked) && verifyUserRole(allowedUserRoles.adminAndAccountant)) {
              dispatch(getAllCategoryByGroup());
            }
          })
          .catch((error) => {
            toast.error(error?.name);
          })
      }
    }
  };

  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleConfirm = () => {
    if (categoryGroupId) {
      dispatch(deleteCategoryGroup(categoryGroupId))
        .unwrap()
        .then((res) => {
          setShowConfirmation(false);
          toast.success(res?.message);
          setCategoryGroupId(null);
          setCategoryId(null);
          if (verifyCompanyStatus(activeBlocked) && verifyUserRole(allowedUserRoles.adminAndAccountant)) {
            dispatch(getAllCategoryByGroup());
          }
        })
        .catch((error) => {
          toast.error(error?.name);
        });
    }
    if (categoryId) {
      dispatch(deleteCategory(categoryId))
        .unwrap()
        .then((res) => {
          setShowConfirmation(false);
          toast.success(res?.message);
          setCategoryGroupId(null);
          setCategoryId(null);
          if (verifyCompanyStatus(activeBlocked) && verifyUserRole(allowedUserRoles.adminAndAccountant)) {
            dispatch(getAllCategoryByGroup());
          }
        })
        .catch((error) => {
          toast.error(error?.name);
        });
    }
  };

  const handleCategoryChange = (e, categoryId = "") => {
    const { value } = e.target;
    const payload = {
      accountIntegrationId: value || "",
      categoryId,
    };
    dispatch(updateCategories(payload))
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        if (verifyCompanyStatus(activeBlocked) && verifyUserRole(allowedUserRoles.adminAndAccountant)) {
          dispatch(getAllCategoryByGroup());
        }
        form3.resetField("name");
        form3.resetField("iconId");
      })
      .catch((error) => {
        toast.error(error?.message);
      });
  };

  const handleUpdateConformationToggle = () => {
    setUpdateConformation((prev) => !prev);
  };

  const handleUpdate = (data) => {
    dispatch(
      updateCategoryGroup({ categoryGroupId: categoryGroupId, body: data })
    )
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        setCategoryGroupId(null);
        setUpdateConformation();
        if (verifyCompanyStatus(activeBlocked) && verifyUserRole(allowedUserRoles.adminAndAccountant)) {
          dispatch(getAllCategoryByGroup());
        }
        form3.resetField("name");
        form3.resetField("iconId");
      })
      .catch((error) => {
        toast.error(error?.name);
      });
  };

  const [selectedIconObj, setSelectedIconObj] = useState({});
  const setSelectedIconValue = (id) => {
    const [icon] = iconData.filter((res) => res.iconId == id);
    setSelectedIconObj({
      value: icon?.iconId,
      label: icon?.iconName,
      image: icon?.iconPath,
    });
  };

  const languages = [
    {
      code: "en",
      name: "English",
      country_code: "gb",
    },
    {
      code: "ar",
      name: "العربية",
      dir: "rtl",
      country_code: "sa",
    },
  ];

  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);

  useEffect(() => {
    document.body.dir = currentLanguage.dir || "ltr";
    document.title = t("app_title");
  }, [currentLanguage, t]);

  return (
    <div className="taxcodes">
      <div
        className="d-flex align-items-center justify-content-between"
        style={{ marginBottom: 24 }}
      >
        <h2 className="card-title-black-text">
          {t("settingstab.list_of_current_group_categories")}
        </h2>

        <PrimaryButton
          type={'button'}
          onClick={handleShow}
          buttonText={t("settingstab.add_new")}
          isEnabled={true}
          id={'add_new_category'}
          style={{ width: '149px' }}
        />
      </div>
      <div className="" style={{ position: 'relative' }}>
        {isLoading && <LoadingComponent />}
        {categoryGroupData &&
          categoryGroupData?.map((category, index) => (
            <div
              className="border-grey p-3 pb-0"
              style={{ marginBottom: 16 }}
              key={index}
            >
              <div className=" d-flex align-items-center justify-content-between mb-3 green-header p-2 px-3">
                <div className=" d-flex align-items-center">
                  <ProfileIcon
                    imageUrl={category?.iconPath}
                    name={category?.name}
                    textStyle={{
                      color: "#248f71",
                      backgroundColor: "#c5f6e8",
                      fontSize: window.innerWidth <= 500 ? 13 : 16,
                      fontWeight: "600",
                      padding: 8,
                    }}
                    style={{ marginLeft: 0 }}
                  />
                  <h2 className="green-header-text">{t(category?.name)}</h2>
                </div>
                <div className="d-flex align-items-center">
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setShowConfirmation(true);
                      setCategoryGroupId(category?.categoryGroupId);
                      setCategoryId(null);
                    }}
                    id={`delete_group_${category?.categoryGroupId}`}
                  >
                    <img
                      src={DeleteIcon}
                      style={{ width: 24, objectFit: "contain" }}
                    />
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleUpdateConformationToggle();
                      setCategoryGroupId(category?.categoryGroupId);
                      form3.setValue("name", category?.name);
                      form3.setValue("iconId", category?.iconId);
                      setSelectedIconValue(category?.iconId);
                    }}
                    id={`edit_group_${category?.categoryGroupId}`}
                  >
                    <img
                      src={Editicon}
                      style={{
                        width: 24,
                        objectFit: "contain",
                        marginRight: 16,
                        marginLeft: 16,
                      }}
                    />
                  </div>
                  <div
                    onClick={() => {
                      form2.reset();
                      handleShowSecondModal();
                      setCategoryGroupId(category?.categoryGroupId);
                    }}
                    style={{ cursor: "pointer" }}
                    id={`add_category_to_${category?.categoryGroupId}`}
                  >
                    <img
                      src={AddIcon}
                      style={{ width: 24, objectFit: "contain" }}
                    />
                  </div>
                </div>
              </div>
              <div className="table-responsive" style={{ border: 0 }}>
                <table
                  id="categoriesTable"
                  style={{
                    borderCollapse: "separate",
                    borderSpacing: "0 16px",
                  }}
                  cellSpacing="0"
                  width="100%"
                >
                  <thead>
                    <tr className="table-head">
                      <th scope="col">{t("name")}</th>
                      <th scope="col">{t("settingstab.tax_code")}</th>
                      {connectedIntegration?.isConnected && (
                        <th scope="col">{t("settingstab.mapping")}</th>
                      )}
                      <th scope="col">{t("actions")}</th>
                    </tr>
                  </thead>
                  <tbody className="table-body">
                    {category?.categories &&
                      category?.categories?.map((cat, index) => (
                        <tr
                          key={index}
                          className={`${currentLanguageCode === "en"
                            ? "table-body-tr"
                            : "table-body-tr-arabic"
                            }`}
                        >
                          <td>{cat?.name}</td>
                          <td>
                            {cat?.taxName
                              ? `${cat?.taxName}(${cat?.rate}%)`
                              : ""}
                          </td>
                          {connectedIntegration?.isConnected && (
                            <td>
                              <select
                                id={`tax-${cat?.categoryId}`}
                                className="form-select form-control-lg"
                                onChange={(e) =>
                                  handleCategoryChange(e, cat?.categoryId)
                                }
                                value={cat?.accountIntegrationId}
                              >
                                <option value="">Select account</option>
                                {oddoAccountsList.map((acc, index) => (
                                  <option key={index} value={acc?.id}>
                                    {acc?.name}
                                  </option>
                                ))}
                                )
                              </select>
                            </td>
                          )}
                          <td>
                            <a style={{ cursor: "pointer" }} id={`edit_category_${cat?.categoryId}`}>
                              <span
                                onClick={() =>
                                  handleCategoryDetail(cat?.categoryId)
                                }
                                className="tableEditBtn"
                              >
                                {t("edit")}
                              </span>
                            </a>
                            <a
                              style={{
                                cursor: "pointer",
                                marginLeft: 10,
                                marginRight: 10,
                              }}
                              onClick={() => {
                                setShowConfirmation(true);
                                setCategoryGroupId(null);
                                setCategoryId(cat?.categoryId);
                              }}
                              id={`delete_category_${cat?.categoryId}`}
                            >
                              <span className="tableDeleteBtn">
                                {t("delete")}
                              </span>
                            </a>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          ))}
      </div>
      <Modal
        className="TaxcodeModalOne"
        show={show}
        onHide={handleClose}
        style={{ paddingRight: 0 }}
      >
        <Modal.Body>
          <div
            className="d-flex align-items-center justify-content-between green-header px-3"
            style={{ marginBottom: 8 }}
          >
            <h2 className="green-header-text">
              {" "}
              {t("settingstab.add_category_group")}
            </h2>
            <div
              onClick={() => {
                handleClose();
                reset();
              }}
              className="clo-2"
              style={{ cursor: "pointer" }}
            >
              <img
                src={ImagesCross}
                style={{ width: 24, objectFit: "contain" }}
              />
            </div>
          </div>
          <p className="modal-text-gray-line">
            {t("settingstab.add_category_group_desc")}
          </p>
          <form>
            <div className="form-outline mt-4 mb-3">
              <label className="form-label" htmlFor="selectIcon">
                {t("settingstab.icon")}
              </label>
              <Select
                id="selectIcon"
                {...register("iconId", {
                  required: `${t("settingstab.select_Icon")}`,
                })}
                options={iconData.map((res) => {
                  return {
                    value: res.iconId,
                    label: res.iconName,
                    image: res.iconPath,
                  };
                })}
                isSearchable
                formatOptionLabel={({ label, image }) => (
                  <div>
                    <img
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.style.display = "none";
                      }}
                      src={image}
                      alt={label}
                      style={{
                        width: "30px",
                        height: "30px",
                        marginRight: "10px",
                      }}
                    />
                  </div>
                )}
                onChange={(selectedOption) => {
                  if (
                    typeof selectedOption === "object" &&
                    selectedOption !== null
                  ) {
                    setValue("iconId", selectedOption.value);
                  } else {
                    // In case the selectedOption is not an object, set it directly
                    setValue("iconId", selectedOption);
                  }
                }}
              />

              {errors?.iconId && !selectedOption && (
                <p style={{ color: "red" }}>{errors?.iconId?.message}</p>
              )}
            </div>

            <div className="form-outline mt-4 mb-3">
              <label className="form-label" htmlFor="categoryGroupName">
                {t("name")}
              </label>
              <input
                type="text"
                id="categoryGroupName"
                className="form-control form-control-lg"
                placeholder={`${t("enter_name")}`}
                {...register("categoryGroupName", {
                  required: `${t("enter_name")}`,
                  pattern: {
                    value: /^[A-Za-z0-9\u0000-\uFFFF ]+$/,
                    message: `${t(
                      "settingstab.Only_alphaNumberic_values_are_required"
                    )}`,
                  },
                })}
              />
              {errors?.categoryGroupName && (
                <p style={{ color: "red" }}>
                  {errors?.categoryGroupName?.message}
                </p>
              )}
            </div>
            <Modal.Footer>
              <SecondaryButton
                type={'button'}
                onClick={(e) => {
                  e.preventDefault();
                  handleClose();
                  reset();
                }}
                buttonText={t('cancel')}
                id={'cancel_add_group'}
                style={{ width: '102px' }}
              />
              <PrimaryButton
                type={'button'}
                onClick={handleSubmit(onSubmit)}
                buttonText={t('confirm')}
                isEnabled={true}
                id={'confirm_add_group'}
                style={{ width: '149px' }}
                isLoading={isLoading}
              />
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>

      <Modal
        className="TaxcodeModalOne"
        show={showSecondModal}
        onHide={handleCloseShowSecondModal}
        style={{ paddingRight: 0 }}
      >
        <Modal.Body>
          <div
            className="d-flex align-items-center justify-content-between green-header px-3"
            style={{ marginBottom: 8 }}
          >
            <h2 className="green-header-text">
              {categoryId
                ? t("settingstab.update_category")
                : t("settingstab.add_category")}
            </h2>
            <div
              onClick={() => {
                handleCloseShowSecondModal();
                form2.reset();
                setCategoryGroupId(null);
                setCategoryId(null);
              }}
              className="clo-2"
              style={{ cursor: "pointer" }}
            >
              <img
                src={ImagesCross}
                style={{ width: 20, objectFit: "contain" }}
              />
            </div>
          </div>
          <p className="modal-text-gray-line">
            {categoryId ? "" : t("settingstab.add_category_desc")}
          </p>
          <form>
            {/**      <div className="form-outline mt-4 mb-3">
              <label className="form-label" htmlFor="nominalCode">
                {t("settingstab.nominal_code")}
              </label>
              <input
                type="text"
                id="nominalCode"
                className="form-control form-control-lg"
                placeholder={`${t("settingstab.enter_code")}`}
                {...form2.register("nominalCode", {
                  required: `${t("enter_name")}`,
                })}
              />
              {form2Error?.nominalCode && (
                <p style={{ color: "red" }}>
                  {form2Error?.nominalCode?.message}
                </p>
              )}
            </div>*/}
            <div className="form-outline mt-4 mb-3">
              <label className="form-label" htmlFor="categoryName">
                {t("name")}
              </label>
              <input
                type="text"
                id="categoryName"
                className="form-control form-control-lg"
                placeholder={`${t("enter_name")}`}
                {...form2.register("categoryName", {
                  required: `${t("enter_name")}`,
                  pattern: {
                    value: /^[A-Za-z0-9\u0000-\uFFFF ]+$/,
                    message: `${t(
                      "settingstab.Only_alphaNumberic_values_are_required"
                    )}`,
                  },
                })}
              />
              {form2Error?.categoryName && (
                <p style={{ color: "red" }}>
                  {form2Error?.categoryName?.message}
                </p>
              )}
            </div>
            <div className="form-outline my-4">
              <label className="form-label" htmlFor="taxId">
                {t("settingstab.tax_code")}
              </label>
              <select
                className="form-control form-select"
                id="taxId"
                {...form2.register("taxId", {
                  required: `${t("select_tax_code")}`,
                })}
              >
                <option selected value="">
                  {t("settingstab.select_tax_code")}
                </option>
                {taxData &&
                  taxData?.rows?.map((tax) => (
                    <option
                      value={tax?.taxId}
                      key={tax?.taxId}
                    >{`${tax.name}(${tax?.rate}%)`}</option>
                  ))}
              </select>
              {form2Error?.taxId && (
                <p style={{ color: "red" }}>{form2Error?.taxId?.message}</p>
              )}
            </div>
          </form>
          <Modal.Footer>
            <SecondaryButton
              type={'button'}
              onClick={() => {
                handleCloseShowSecondModal();
                form2.reset();
                setCategoryGroupId(null);
                setCategoryId(null);
              }}
              buttonText={t('cancel')}
              id={'cancel_add_category'}
              style={{ width: '102px' }}
            />
            <PrimaryButton
              type={'button'}
              onClick={form2.handleSubmit(onSubmit2)}
              buttonText={t('confirm')}
              isEnabled={true}
              id={'confirm_add_category'}
              style={{ width: '149px' }}
              isLoading={isLoading}
            />
          </Modal.Footer>
        </Modal.Body>
      </Modal>

      <ConfirmationDialog
        show={showConfirmation}
        onConfirm={handleConfirm}
        onClose={() => {
          setShowConfirmation(false);
          setCategoryId(null);
          setCategoryGroupId(null);
        }}
        message="Are you sure you want to proceed to delete?"
      />
      <Modal
        className="TaxcodeModalOne"
        show={updateConformation}
        onHide={() => {
          handleUpdateConformationToggle();
          form3.resetField("name");
          form3.resetField("iconId");
          setCategoryGroupId(null);
          setSelectedIconObj({});
        }}
        centered
        id=""
        backdrop="static"
      >
        <Modal.Body style={{ padding: "0 !important" }}>
          <div
            className="d-flex align-items-center justify-content-between green-header px-3"
            style={{ marginBottom: 8 }}
          >
            <h2 className="green-header-text"> {`Update Category Group`}</h2>
            <div
              onClick={() => {
                handleUpdateConformationToggle();
              }}
              className="clo-2"
              style={{ cursor: "pointer" }}
            >
              <img
                src={ImagesCross}
                style={{ width: 20, objectFit: "contain" }}
              />
            </div>
          </div>
          <div className="mt-4">
            <div className="form-outline mt-4 mb-3">
              <label className="form-label">{t("settingstab.icon")}</label>
              <Select
                {...form3.register("iconId", {
                  required: `${t("settingstab.select_Icon")}`,
                })}
                defaultValue={selectedIconObj}
                options={iconData.map((res) => {
                  return {
                    value: res.iconId,
                    label: res.iconName,
                    image: res.iconPath,
                  };
                })}
                isSearchable
                formatOptionLabel={({ label, image }) => (
                  <div className="test-blue">
                    <img
                      src={image}
                      alt={label}
                      style={{
                        width: "30px",
                        height: "30px",
                        marginRight: "10px",
                      }}
                    />
                    {label}
                  </div>
                )}
                onChange={(selected) => {
                  if (typeof selected === "object" && selected !== null) {
                    form3.setValue("iconId", selected?.value);
                    // Access the value property of the selected option object
                    setSelectedOption(selected?.value);
                  } else {
                    // In case the selectedOption is not an object, set it directly
                    form3.setValue("iconId", selected?.value);
                    setSelectedOption(selected?.value);
                  }
                }}
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    fontSize: 16,
                    fontWeight: 400,
                  }),
                }}
              />

              {form3Error?.iconId && (
                <p style={{ color: "red" }}>{form3Error?.iconId?.message}</p>
              )}
            </div>
            <div className="form-outline mt-4 mb-3">
              <label className="form-label" htmlFor="groupName">{t("name")}</label>
              <input
                type="text"
                id="groupName"
                className="form-control form-control-lg"
                placeholder={`${t("settingstab.enter_groupName")}`}
                {...form3.register("name", {
                  required: `${t("settingstab.enter_groupName")}`,
                })}
              />
              {form3Error?.name && (
                <p style={{ color: "red" }}>{form3Error?.name?.message}</p>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="px-0 pt-3">
          <SecondaryButton
              type={'button'}
              onClick={() => {
                handleUpdateConformationToggle();
                form3.resetField("name");
                form3.resetField("iconId");
                setCategoryGroupId(null);
                setSelectedIconObj({});
              }}
              buttonText={t('cancel')}
              id={'cancel_update_group'}
              style={{ width: '102px' }}
            />
            <PrimaryButton
              type={'button'}
              onClick={form3.handleSubmit(handleUpdate)}
              buttonText={t('confirm')}
              isEnabled={true}
              id={'confirm_update_group'}
              style={{ width: '149px' }}
              isLoading={isLoading}
            />
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Categories;
