import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { API_ENDPOINTS, METHOD_TYPE } from '../../../utils/apiUrls';
import api from '../../../utils/api';

const initialState = {
  cardLists: {},
  isLoading: false,
  error: null,
  cardDetails: null,
  mccCategory: null,
  spendings: null,
  addMoney: null,
  withdrawMoney: null,
  updateCardLimit: null,
  updateCardControl: null,
  employees: null,
  issueNewCardData: null,
  employeeSpendings: null,
  cardFreezResponse: {},
  cardCorporate: [],
  cardTypeDetails: null,
  networkDetails: null,
  bankDetails: null,
  deleteCorporateCard: null,
  addCorporateCard: {},
  updateCorporateCard: null,
};

export const getAllCards = createAsyncThunk("cards/getAllCards", async (queryParams) => {
  try {
    let data = {
      method: METHOD_TYPE.get,
      url: API_ENDPOINTS.getAllCards + queryParams
    };
    const response = await api(data);
    return response.data;

  } catch (error) {
    throw error?.response?.data;
  }
});

export const activatePhysicalCard = async (payload) => {
  try {
    let data = {
      method: METHOD_TYPE.put,
      url: API_ENDPOINTS.activatePhysicalCard,
      data: payload
    };
    const response = await api(data);
    return response?.data || {};

  } catch (error) {
    throw error?.response?.data;
  }
};

export const getPciWidgetUrl = async (payload) => {
  try {
    let data = {
      method: METHOD_TYPE.post,
      url: API_ENDPOINTS.getPciWidgetUrl,
      data: payload
    };
    const response = await api(data);
    return response?.data || {};

  } catch (error) {
    throw error?.response?.data;
  }
};

export const getCardDetails = createAsyncThunk("cards/getCardDetails", async (employeeId) => {
  try {
    let data = {
      method: METHOD_TYPE.get,
      url: API_ENDPOINTS.getCardDetails + `?employeeId=${employeeId}`
    };
    const response = await api(data);
    return response.data;

  } catch (error) {
    throw error?.response?.data;
  }
});

export const getMccCategoryDetails = createAsyncThunk("cards/getMccCategoryDetails", async () => {
  try {
    let data = {
      method: METHOD_TYPE.get,
      url: API_ENDPOINTS.getMccCategoryDetails
    };
    const response = await api(data);
    return response.data;
  } catch (error) {
    throw error?.response?.data;
  }
})

export const updateMcc = createAsyncThunk("cards/updateMcc", async (payload) => {
  try {
    let data = {
      method: METHOD_TYPE.post,
      url: API_ENDPOINTS.updateMcc,
      data: payload
    };
    const response = await api(data);
    return response.data;
  } catch (error) {
    throw error?.response?.data;
  }
})

export const fetchCardSpendings = createAsyncThunk("cards/fetchCardSpendings", async (queryParams) => {
  try {
    let data = {
      method: METHOD_TYPE.get,
      url: API_ENDPOINTS.fetchCardSpendings + queryParams
    };
    const response = await api(data);
    return response.data;

  } catch (error) {
    throw error?.response?.data;
  }
});

export const addCardMoney = createAsyncThunk("cards/addCardMoney", async (payload) => {
  try {
    let data = {
      method: METHOD_TYPE.post,
      url: API_ENDPOINTS.addCardMoney,
      data: payload
    };
    const response = await api(data);
    return response.data;
  } catch (error) {
    throw error?.response?.data;
  }
});

export const withdrawCardMoney = createAsyncThunk("cards/withdrawCardMoney", async (payload) => {
  try {
    let data = {
      method: METHOD_TYPE.post,
      url: API_ENDPOINTS.withdrawCardMoney,
      data: payload
    };
    const response = await api(data);
    return response.data;
  } catch (error) {
    throw error?.response?.data;
  }
});

export const updateCardLimit = createAsyncThunk("cards/updateCardLimit", async (payload) => {
  try {
    let data = {
      method: METHOD_TYPE.put,
      url: API_ENDPOINTS.updateCardLimit,
      data: payload
    };
    const response = await api(data);
    return response.data;
  } catch (error) {
    throw error?.response?.data;
  }
});

export const updateCardControl = createAsyncThunk("cards/updateCardControl", async ({ payload, cardId }) => {
  try {
    let data = {
      method: METHOD_TYPE.post,
      url: API_ENDPOINTS.updateCardControl + `?cardId=${cardId}`,
      data: payload
    };
    const response = await api(data);
    return response.data;
  } catch (error) {
    throw error?.response?.data;
  }
});

export const getEmployees = createAsyncThunk("cards/getEmployees", async () => {
  try {
    let data = {
      method: METHOD_TYPE.get,
      url: API_ENDPOINTS.getEmployees
    };
    const response = await api(data);
    return response.data;
  } catch (error) {
    throw error?.response?.data;
  }
});

export const issueNewCard = createAsyncThunk("cards/issueNewCard", async (payload) => {
  try {
    let data = {
      method: METHOD_TYPE.post,
      url: API_ENDPOINTS.issueNewCard,
      data: payload
    };
    const response = await api(data);
    return response.data;
  } catch (error) {
    throw error?.response?.data;
  }
});

export const fetchWebCardSpendings = createAsyncThunk("cards/fetchWebCardSpendings", async (queryParams) => {
  try {
    let data = {
      method: METHOD_TYPE.get,
      url: API_ENDPOINTS.fetchWebCardSpendings + queryParams
    };
    const response = await api(data);
    return response.data;
  } catch (error) {
    throw error?.response?.data;
  }
}
);

export const fetchCorporateCardsSearch = createAsyncThunk(
  'corporateCards/getCorporateCardsSearch',
  async (queryParams) => {
    try {
      let data = {
        method: METHOD_TYPE.get,
        url: API_ENDPOINTS.getCorporateCardsSearch + queryParams,
      };
      const response = await api(data);

      return response.data;
    } catch (error) {
      throw error?.response?.data;
    }
  }
);

export const getCardType = createAsyncThunk(
  'corporateCards/fetchCardTypeDetails',
  async (payload) => {
    try {
      let data = {
        method: METHOD_TYPE.get,
        url: API_ENDPOINTS.getCardCorporateType,
        data: payload,
      };
      const response = await api(data);

      return response.data;
    } catch (error) {
      throw error?.response?.data;
    }
  }
);
export const fetchNetworkDetail = createAsyncThunk(
  'corporateCards/fetchNetworkDetails',
  async (payload) => {
    try {
      let data = {
        method: METHOD_TYPE.get,
        url: API_ENDPOINTS.getNetworkDetails,
        data: payload,
      };
      const response = await api(data);

      return response.data;
    } catch (error) {
      throw error?.response?.data;
    }
  }
);
export const getBankDetails = createAsyncThunk(
  'corporateCards/fetchBanksDetails',
  async (payload) => {
    try {
      let data = {
        method: METHOD_TYPE.get,
        url: API_ENDPOINTS.getBankDetails,
        data: payload,
      };
      const response = await api(data);

      return response.data;
    } catch (error) {
      throw error?.response?.data;
    }
  }
);
export const addCorporateCards = createAsyncThunk(
  'corporateCards/addCorporateCard',
  async (payload) => {
    try {
      let data = {
        method: METHOD_TYPE.post,
        url: API_ENDPOINTS.createCorporateCard,
        data: payload,
      };
      const response = await api(data);

      return response.data;
    } catch (error) {
      throw error?.response?.data;
    }
  }
);
export const editCorporateCard = createAsyncThunk(
  'corporateCards/updateCorporateCard',
  async (payload) => {
    try {
      let data = {
        method: METHOD_TYPE.put,
        url: API_ENDPOINTS.updateCorporateCard,
        data: payload,
      };
      const response = await api(data);

      return response.data;
    } catch (error) {
      throw error?.response?.data;
    }
  }
);
export const removeCorporateCard = createAsyncThunk(
  'corporateCards/deleteCorporateCard',
  async ({ corporateCardId }) => {
    try {
      let data = {
        method: METHOD_TYPE.delete,
        url:
          API_ENDPOINTS.deleteCorporateCard +
          `?corporateCardId=${corporateCardId}`,
      };
      const response = await api(data);

      return response.data;
    } catch (error) {
      throw error?.response?.data;
    }
  }
);
export const freezeUnfreezeCard = createAsyncThunk(
  'cards/freezeUnfreezeCard',
  async (payload) => {
    try {
      let data = {
        method: METHOD_TYPE.post,
        url: API_ENDPOINTS.freezeUnfreezeCard,
        data: payload
      };
      const response = await api(data);
      return response.data;
    } catch (error) {
      throw error?.response?.data;
    }
  });

const cardList = createSlice({
  name: "cardList",
  initialState,
  reducers: {
    updateCardDetails(state, action) {
      const { index, maskCardNumber } = action.payload;
      state.cardDetails = state.cardDetails.map((item, i) => {
        if (i === index) {
          return { ...item, maskCardNumber };
        }
        return item;
      });
    },
  },
  extraReducers: (builder) => {

    builder
      .addCase(getAllCards.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.cardLists = payload.data;
      })
      .addCase(getCardDetails.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.cardDetails = payload.data;
      })
      .addCase(getMccCategoryDetails.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.mccCategory = payload.data;
      })
      .addCase(updateMcc.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.updateMcc = payload.data;
      })
      .addCase(fetchCardSpendings.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.spendings = payload.data;
      })
      .addCase(addCardMoney.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.addCardMoney = payload.data;
      })
      .addCase(withdrawCardMoney.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.withdrawMoney = payload.data;
      })
      .addCase(updateCardLimit.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.updateCardLimit = payload.data;
      })
      .addCase(updateCardControl.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.updateCardControl = payload.data;
      })
      .addCase(getEmployees.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.employees = payload.data;
      })
      .addCase(issueNewCard.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.issueNewCardData = payload.data;
      })
      .addCase(fetchWebCardSpendings.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.employeeSpendings = payload.data;
      })
      .addCase(fetchCorporateCardsSearch.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.cardCorporate = payload.data;
      })
      .addCase(getCardType.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.cardTypeDetails = payload.data;
      })
      .addCase(fetchNetworkDetail.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.networkDetails = payload.data;
      })
      .addCase(getBankDetails.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.bankDetails = payload.data;
      })
      .addCase(addCorporateCards.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.addCorporateCard = payload.data;
      })
      .addCase(editCorporateCard.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        const updateCorporateCard = payload.data;

        state.cardCorporate.rows = state.cardCorporate.rows.map((card) =>
          card.corporateCardId === updateCorporateCard?.corporateCardId
            ? { ...card, ...updateCorporateCard }
            : card
        );
      })
      .addCase(removeCorporateCard.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        const deletedCardId = action.payload?.corporateCardId;

        if (deletedCardId) {
          state.cardCorporate.rows = state.cardCorporate.rows.filter(
            (card) => card.corporateCardId !== deletedCardId
          );
        }
      })

      .addCase(freezeUnfreezeCard.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.cardFreezResponse = payload.data;
      })
      .addCase(getAllCards.pending, (state) => {
        state.isLoading = true;
        state.cardLists = {};
      })
      .addMatcher(
        isAnyOf(
          getCardDetails.pending,
          getMccCategoryDetails.pending,
          updateMcc.pending,
          fetchCardSpendings.pending,
          addCardMoney.pending,
          withdrawCardMoney.pending,
          updateCardLimit.pending,
          updateCardControl.pending,
          getEmployees.pending,
          issueNewCard.pending,
          fetchWebCardSpendings.pending,
          freezeUnfreezeCard.pending,
          fetchCorporateCardsSearch.pending,
          getCardType.pending,
          fetchNetworkDetail.pending,
          getBankDetails.pending,
          addCorporateCards.pending,
          removeCorporateCard.pending,
          editCorporateCard.pending
        ),
        (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        isAnyOf(
          getAllCards.rejected,
          getCardDetails.rejected,
          getMccCategoryDetails.rejected,
          updateMcc.rejected,
          fetchCardSpendings.rejected,
          addCardMoney.rejected,
          withdrawCardMoney.rejected,
          updateCardLimit.rejected,
          updateCardControl.rejected,
          getEmployees.rejected,
          issueNewCard.rejected,
          fetchWebCardSpendings.rejected,
          freezeUnfreezeCard.rejected,
          fetchCorporateCardsSearch.rejected,
          getCardType.rejected,
          fetchNetworkDetail.rejected,
          getBankDetails.rejected,
          addCorporateCards.rejected,
          removeCorporateCard.rejected,
          editCorporateCard.rejected
        ),
        (state, { error }) => {
          state.isLoading = false;
          state.error = error.message
            ? error.message
            : 'Request Failed Please Try Again ';
          // if (error.message === "Request failed with status code 415") {
          //     return (localStorage.removeItem("token"), window.location.reload());
          // }
        }
      );
  },
});

const { updateCardDetails } = cardList.actions;
const cardListReducer = cardList.reducer;

export { updateCardDetails, cardListReducer };
export default cardListReducer;