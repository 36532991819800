import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_ENDPOINTS, METHOD_TYPE } from "../../../utils/apiUrls";
import api from "../../../utils/api";

const initialState = {
    masterAccountTransactions: {},
    transtionDetails: null,
    isLoading: false,
    error: null,
    exportMasterAccountResponseData:{}
};

export const fetchMasterTransactionList = createAsyncThunk("masterAccount/fetchMasterTransactionList", async (quereParam) => {
    console.log(quereParam);
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.fetchMasterTransactionList + quereParam
        };
        console.log(`data: ${JSON.stringify(data)}`);
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const fetchMasterTransactionDetail = createAsyncThunk("masterAccount/fetchMasterTransactionDetail", async (transactionId) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.fetchMasterTransactionDetail + `?transactionId=${transactionId}`
        };
        const response = await api(data);
        return response.data;
        
    } catch (error) {
        throw error?.response?.data;
    }
});

export const exportMasterAccountRecords = createAsyncThunk("/masterAccount/exportToFileMasterAccount", async (postData) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.exportMasterAccountRequestFile,
            data: postData
        };
        const response = await api(data);
        return response;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const exportAllMasterAccountRecords = createAsyncThunk("/masterAccount/exportAllMasterAccountRecords", async (postData) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.exportAllMasterAccount,
            data: postData
        };
        const response = await api(data);
        return response;

    } catch (error) {
        throw error?.response?.data;
    }
});

const masterAccountTransaction = createSlice({
    name: "masterAccount",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchMasterTransactionList.pending, (state) => {
                state.isLoading = true;
                state.masterAccountTransactions = {}
            })
            .addCase(fetchMasterTransactionList.rejected, (state, { error }) => {
                state.isLoading = false;
                state.error = error.message ? error.message : "Request Failed Please Try Again ";
            })
            .addCase(exportMasterAccountRecords.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(exportMasterAccountRecords.rejected, (state, { error }) => {
                state.isLoading = false;
                state.error = error.message ? error.message : "Request Failed Please Try Again ";
            })
            .addCase(exportAllMasterAccountRecords.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(exportAllMasterAccountRecords.rejected, (state, { error }) => {
                state.isLoading = false;
                state.error = error.message ? error.message : "Request Failed Please Try Again ";
            })
            .addCase(fetchMasterTransactionList.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
                state.masterAccountTransactions = payload.data;
            })
            .addCase(fetchMasterTransactionDetail.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchMasterTransactionDetail.rejected, (state, { error }) => {
                state.isLoading = false;
                state.error = error.message ? error.message : "Request Failed Please Try Again ";
            })
            .addCase(fetchMasterTransactionDetail.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
                state.transtionDetails = payload.data;
            })
            .addCase(exportMasterAccountRecords.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
                state.exportMasterAccountResponseData = payload.data;
            })
            .addCase(exportAllMasterAccountRecords.fulfilled, (state, /*{ payload }*/) => {
                state.isLoading = false;
                state.error = null;
            });
    }
});

const masterAccountTransactionReducer = masterAccountTransaction.reducer; // Corrected the export

export default masterAccountTransactionReducer;