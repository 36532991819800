import React, { useState, useEffect } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import upload_icon from '../../assets/images/upload_ico.png';
import delete_icon from '../../assets/images/delete.png';
import upload_doc from '../../assets/images/upload_doc.png';
import uploaded_doc from '../../assets/images/docAcept1.png';
import { useTranslation } from 'react-i18next';

function FileUploadComponent({ docTypeId, uploadedDoc, docTitle, handleUpload, handleRemove, companyStatus }) {
  const [uploadedFile, setUploadedFile] = useState(uploadedDoc);
  const { t } = useTranslation();

  useEffect(() => {
    setUploadedFile(uploadedDoc);
  }, [uploadedDoc]);

  const extractFileName = () => {
    if (typeof uploadedFile === "string") {
      const url = uploadedFile;
      const parts = url.split('/');
      const filenameWithQuery = parts.pop();
      const filename = filenameWithQuery.split('?')[0];
      return filename;
    } else {
      return uploadedFile.name;
    }
  };

  const handleFileUpload = (event) => {
    if (companyStatus === 'InActive') {
      const file = event.target.files[0];
      const fileObj = { typeID: docTypeId, file: file };
      setUploadedFile(file);
      handleUpload(fileObj);
    }
  };

  const handleFileDelete = () => {
    if (companyStatus === 'InActive') {
      const fileObj = { typeID: docTypeId, file: null };
      setUploadedFile(null);
      handleRemove(fileObj);
    }
  };

  return (
    <Col>
      <Card.Title>
        <h2 className="form-label mb-2 ml-2">
          {docTitle}
          <span className="absolute top-0 left-0" style={{ transform: 'translate(-50%, -50%)', color: '#FF0000' }}>*</span>
        </h2>
      </Card.Title>
      <Card className='styledCard'>
        <Card.Body style={{ paddingBlock: '0px' }}>
          {uploadedFile ? (
            <Row className="uploaded-file">
              <Col sm={4}>
                <div className="upload-icon">
                  <img src={uploaded_doc} alt="" style={{ width: '42px', height: '42px' }} />
                </div>
              </Col>
              <Col sm={8} className="d-flex flex-column justify-content-center">
                <span className="upload-light">
                  <h4>{t('company.uploaded')}</h4>
                  <img src={delete_icon} alt="Delete icon" style={{ width: '11.81px', height: '11.81px' }} onClick={handleFileDelete} />
                </span>
                <div className="card-box">
                  <h2 className="upload-text truncated-text">{extractFileName()}</h2>
                </div>
              </Col>
            </Row>
          ) : (
            <Row className="upload-area">
              <label htmlFor={`upload-input-${docTypeId}`} style={{ width: '100%', display: 'flex' }}>
                <Col sm={4} className="d-flex justify-content-center align-items-center">
                  <div className="upload-icon">
                    <img src={upload_doc} alt="Document upload icon" style={{ width: '42px', height: '42px' }} />
                  </div>
                </Col>
                <Col sm={8} className="d-flex flex-column justify-content-center">
                  <span className="upload-light">
                    {t('company.click_to_upload_doc')}
                    <img src={upload_icon} alt="Upload icon" style={{ width: '11.81px', height: '11.81px' }} />
                  </span>
                  <div className="card-box">
                    <h2 className="upload-text truncated-text">{docTitle}</h2>
                    <h4 className="upload-light" style={{ fontWeight: '400' }}>Max 5MB accepted format: jpg, png, pdf</h4>
                    <input
                      type="file"
                      id={`upload-input-${docTypeId}`}
                      accept=".jpg,.png,.pdf"
                      onChange={handleFileUpload}
                      hidden
                    />
                  </div>
                </Col>
              </label>
            </Row>
          )}
        </Card.Body>
      </Card>
    </Col>
  );
}

export default FileUploadComponent;
