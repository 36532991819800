import { toast } from "react-toastify";

export const verifyCompanyStatus = (allowedCompanyStatus) => {
   if(!allowedCompanyStatus?.length){
      return true
   }
   const companyStatus = localStorage.getItem('companyStatus');
console.log("companyStatus===================>",companyStatus,"allowedCompanyStatus=======================>",allowedCompanyStatus);
   return companyStatus ? allowedCompanyStatus.includes(companyStatus) : true
}

export const isCompanyActive = () => {
   if (!verifyCompanyStatus(active)) {
      toast.error("Complete a company verification to unlock all the tools and features we offer.");
      return false;
    }
    return true;
}

// company statuses
export const active = ['Active']
const blocked = ['Blocked']
export const activeBlocked = [...active, ...blocked]