// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.debit-card {
  border: 1px solid linear-gradient(110.65deg, #FFFFFF 1.16%, rgba(255, 255, 255, 0.2) 100%);
  box-shadow: 8px 10px 16px 0px rgba(0, 0, 0, 0.0509803922);
  height: 192px;
  padding: 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media screen and (max-width: 1024px) {
  .debit-card {
    font-size: 10px;
  }
}
.debit-card .debit-number {
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.1em;
  text-align: left;
}
@media screen and (max-width: 1024px) {
  .debit-card .debit-number {
    font-size: 10px;
  }
}
.debit-card .cvv-bg {
  background-color: rgba(255, 255, 255, 0.25);
  padding: 4px 10px;
  border-radius: 6px;
  backdrop-filter: blur(0.8px);
}
.debit-card .space-mono-bold {
  font-family: "Space Mono", monospace;
  font-weight: 700;
  font-style: normal;
}`, "",{"version":3,"sources":["webpack://./src/components/Teams/DebitCard.scss"],"names":[],"mappings":"AAAA;EACI,0FAAA;EACA,yDAAA;EACA,aAAA;EACA,aAAA;EACA,mBAAA;EACA,aAAA;EACA,sBAAA;EACA,8BAAA;AACJ;AAAA;EATA;IAUI,eAAA;EAGF;AACF;AAFI;EACI,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,qBAAA;EACA,gBAAA;AAIR;AAHQ;EANJ;IAOQ,eAAA;EAMV;AACF;AAFI;EACI,2CAAA;EACA,iBAAA;EACA,kBAAA;EACA,4BAAA;AAIR;AAFI;EACI,oCAAA;EACA,gBAAA;EACA,kBAAA;AAIR","sourcesContent":[".debit-card {\n    border: 1px solid linear-gradient(110.65deg, #FFFFFF 1.16%, rgba(255, 255, 255, 0.2) 100%);\n    box-shadow: 8px 10px 16px 0px #0000000D;\n    height: 192px;\n    padding: 20px;\n    border-radius: 20px;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n@media screen and (max-width: 1024px) {\n    font-size: 10px;\n}\n    .debit-number {\n        font-size: 14px;\n        font-weight: 600;\n        line-height: 22px;\n        letter-spacing: 0.10em;\n        text-align: left;\n        @media screen and (max-width: 1024px) {\n            font-size: 10px;\n            \n        }\n\n    }\n    .cvv-bg{\n        background-color: rgb(255,255,255,0.25);\n        padding: 4px 10px;\n        border-radius: 6px;\n        backdrop-filter: blur(0.8px);\n    }\n    .space-mono-bold {\n        font-family: \"Space Mono\", monospace;\n        font-weight: 700;\n        font-style: normal;\n      }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
