import React from "react";
import './buttonsStyle.scss';

function PrimaryButton({ type, onClick, buttonText, isEnabled, id, style, isExportBtn = false, isLoading = false, isAnimated=false }) {
  return (
    <button
      type={type}
      {...(type === "button" && { onClick })}
      className={isExportBtn ? 'btn btn-sm btn-primary-small truncated-text' : isEnabled ? isAnimated ? 'animated-btn-primary' : 'btn-primary-small truncated-text' : 'disabledButton truncated-text'}
      disabled={!isEnabled}
      id={id}
      style={style}
    >
      {isLoading ? (
        <>
          <div
            className="spinner-border spinner-border-sm"
            role="status"
          >
            <span className="sr-only">Loading...</span>
          </div>
          &nbsp;{buttonText}
        </>
      ) : (
        buttonText 
      )}
    </button>
  );
}

export default PrimaryButton;
