import React, { useEffect, useState } from 'react';
import SearchIcon from '../../assets/images/search_img.png';
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';
import './AuditLogViewer.scss';
import Modal from 'react-bootstrap/Modal';
import CrossImage from '../../assets/images/close-square-8.png';
import { useDispatch, useSelector } from 'react-redux';
import { exportAllAuditTrails, exportToFileAuditTrail, fetchAuditTrail } from './AuditLogSlice';
import { toast } from 'react-toastify';
import momentTimezone from 'moment-timezone';
import moment from 'moment';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from 'yup';
import LoadingComponent from '../../components/Loader/LoadingComponent';
import { createEndDateWithTimezone, createStartDateWithTimezone, formatTime, formatDate } from '../../utils/XpendlessUtils';
import { activeBlocked, verifyCompanyStatus } from '../../utils/verifyCompanyStatus';
import { allowedUserRoles, verifyUserRole } from '../../utils/verifyUserRole';
import SecondaryButton from '../../components/Buttons/SecondaryButton';
import PrimaryButton from '../../components/Buttons/PrimaryButton';

function AuditLogViewer() {
    const { t } = useTranslation();
    const { fetchAudit, isLoading } = useSelector(state => state?.auditLogSlice);
    const dispatch = useDispatch();
    const currentLanguageCode = cookies.get('i18next') || 'en';
    const [show, setShow] = useState(false);
    const handleCloseModal = () => setShow(false);
    const handleShow = () => setShow(true);
    const [pageNo, setPageNo] = useState(1);
    const [checkedAllPages, setCheckedAllPages] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [pseudoCheckedAll, setPseudoCheckedAll] = useState(false);
    const [uncheckedTransaction, setUncheckedTransaction] = useState([]);
    const [employeeData, setEmployeeData] = useState('');
    const [minEndDate, setMinEndDate] = useState('');

    const {
        control: controlFilterForm,
        handleSubmit: handleFilterSubmit,
        reset: resetFilterForm,
        watch: watchFilterForm,
        setValue: setFilterValue,
    } = useForm({
        resolver: yupResolver(yup.object().shape({
            startDate: yup.string().optional(),
            endDate: yup.string().optional().test('is-valid-end-date', 'End date should not be before start date', function (value) {
                const startDate = this.resolve(yup.ref('startDate'));
                return !startDate || !value || value >= startDate;
            }),
            search: yup.string().optional(),
            eventType: yup.string().optional(),
            module: yup.string().optional(),
        })
        )
    });

    const dropdownModules = [
        { label: 'Dashboard', value: 'dashboard' },
        { label: 'Accounts', value: 'accounts' },
        { label: 'Teams', value: 'teams' },
        { label: 'Users', value: 'users' },
        { label: 'Employee', value: 'employee' },
        { label: 'Cards', value: 'cards' },
        { label: 'Expenses', value: 'expenses' },
        { label: 'Card Top Up', value: 'cardTopUp' },
        { label: 'Reimbursements', value: 'reimbursements' },
        { label: 'Analytic', value: 'analytic' },
        { label: 'Export', value: 'export' },
        { label: 'Company Verification', value: 'companyVerification' },
        { label: 'Settings', value: 'settings' },
    ];

    const modules = {
        employee: 'Employee',
        accounts: 'Accounts',
        teams: 'Teams',
        cards: 'Cards',
        settings: 'Settings',
        users: 'Users',
        expenses: 'Expenses',
        reimbursements: 'Reimbursements',
        cardTopUp: 'Card Top Up',
        export: 'Export',
        companyVerification: 'Company Verification',
        dashboard: 'Dashboard',
        notification: 'Notification',
        analytic: 'Analytics',
    };

    const onPreviousClick = () => {
        setPageNo(prev => prev - 1);
    };

    const exportRecords = async (type = 'csv') => {
        try {
            let response = null;
            if (pseudoCheckedAll) {
                let queryParams = `?`;
                const filterData = watchFilterForm();
                if (filterData?.startDate && filterData?.endDate) {
                    queryParams += `startDate=${filterData.startDate}&endDate=${filterData.endDate}&`;
                }
                if (filterData?.search !== undefined && filterData?.search !== "") {
                    queryParams += `search=${filterData.search}&`;
                }
                if (filterData?.eventType !== undefined) {
                    queryParams += `eventType=${filterData.eventType}&`;
                }
                if (filterData?.module !== undefined) {
                    queryParams += `module=${filterData.module}&`;
                }
                queryParams += `unchecked=${JSON.stringify(uncheckedTransaction)}&`;

                queryParams = queryParams.slice(0, -1);
                response = await dispatch(exportAllAuditTrails(queryParams)).unwrap();
            } else {
                const postData = { type: 'csv', selectedRecords: selectedItems };
                response = await dispatch(exportToFileAuditTrail(postData)).unwrap();
            }

            let blob = '';
            if (type == 'csv') {
                blob = new Blob([response.data], { type: response.headers['content-type'] });
            }
            if (type == 'json') {
                blob = await new Blob([JSON.stringify(response.data)], { type: response.headers['content-type'] });
            }
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            const contentDisposition = response.headers['Content-disposition'];
            const match = contentDisposition && contentDisposition.match(/filename=(.*)/);
            const filename = match ? match[1] : 'exported';
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            setSelectedItems([]);
            setCheckedAllPages(false);
            setPseudoCheckedAll(false);
            setUncheckedTransaction([]);
            toast.success('Record exported successfully');
        } catch (error) {
            toast.error(error.message || "Request Failed Please Try Again ");
        }
    };

    const onNextClick = () => {
        setPageNo(prev => prev + 1);
    };

    const handleCheckboxChange = (id) => {
        const selected = [...selectedItems];
        const unchecked = [...uncheckedTransaction];
        const index = selected.findIndex(
            (item) => item.auditTrailId == id
        );

        if (index === -1) {
            if (pseudoCheckedAll) {
                const uncheckedIndex = unchecked.findIndex((trans) => trans == id);
                unchecked.splice(uncheckedIndex, 1);
                setUncheckedTransaction(unchecked);
            }
            selected.push({ auditTrailId: id });
        } else {
            if (pseudoCheckedAll) {
                unchecked.push(id);
                setUncheckedTransaction(unchecked);
            }
            selected.splice(index, 1);
        }
        if (checkedAllPages)
            setCheckedAllPages(false);
        setSelectedItems(selected);
    };

    const handleCheckAllPagesboxChange = () => {
        if (checkedAllPages) {
            setSelectedItems([]);
            setCheckedAllPages(false);
            setUncheckedTransaction([]);
            setPseudoCheckedAll(false);
        } else {
            let allRecords = [];
            fetchAudit?.rows?.map((element) => allRecords.push({
                auditTrailId: element?.auditTrailId,
            })
            );
            setSelectedItems(allRecords);
            setCheckedAllPages(true);
            setUncheckedTransaction([]);
            setPseudoCheckedAll(true);
        }
    };

    const handleResetForm = () => {
        resetFilterForm();
        setSelectedItems([]);
        setCheckedAllPages(false);
        setPseudoCheckedAll(false);
        setUncheckedTransaction([]);
        setMinEndDate("");
    };

    const onFilterFormSubmit = (requestData) => {
        setSelectedItems([]);
        setCheckedAllPages(false);
        setPseudoCheckedAll(false);
        setUncheckedTransaction([]);
        let queryParams = `?page=${1}`;

        if (requestData?.startDate) {
            const startDate = createStartDateWithTimezone(requestData.startDate);
            queryParams += `&startDate=${startDate}`;
        }
        if (requestData?.endDate) {
            const endDate = createEndDateWithTimezone(requestData.endDate);
            queryParams += `&endDate=${endDate}`;
        }
        if (requestData?.search !== undefined & requestData?.search !== "") {
            queryParams += `&search=${requestData.search}`;
        }
        if (requestData?.eventType !== undefined) {
            queryParams += `&eventType=${requestData.eventType}`;
        }
        if (requestData?.module !== undefined) {
            queryParams += `&module=${requestData.module}`;
        }
        if (verifyCompanyStatus(activeBlocked) && verifyUserRole(allowedUserRoles.admin)) {
            dispatch(fetchAuditTrail(queryParams)).unwrap().then(() => {
                setPageNo(1);
            }).catch((error) => {
                toast.error(error.message || "Request Failed Please Try Again ");
            })
        }
    };

    const fetchAuditLogs = () => {
        let queryParams = `?page=${pageNo}`;
        const filterData = watchFilterForm();

        if (filterData?.startDate) {
            const startDate = createStartDateWithTimezone(filterData?.startDate);
            queryParams += `&startDate=${startDate}`;
        }
        if (filterData?.endDate) {
            const endDate = createEndDateWithTimezone(filterData?.endDate);
            queryParams += `&endDate=${endDate}`;
        }
        if (filterData?.search !== undefined & filterData?.search !== "") {
            queryParams += `&search=${filterData.search}`;
        }
        if (filterData?.eventType !== undefined) {
            queryParams += `&eventType=${filterData.eventType}`;
        }
        if (filterData?.module !== undefined) {
            queryParams += `&module=${filterData.module}`;
        }

        if (verifyCompanyStatus(activeBlocked) && verifyUserRole(allowedUserRoles.admin)) {
            dispatch(fetchAuditTrail(queryParams)).unwrap().then((res) => {
                if (pseudoCheckedAll) {
                    if (res?.data?.rows?.length > 0) {
                        const selected = [...selectedItems];
                        for (const row of res.data.rows) {
                            if (!uncheckedTransaction?.includes(row?.auditTrailId) && !selected.some(element => element?.auditTrailId == row?.auditTrailId))
                                selected.push({ auditTrailId: row?.auditTrailId });
                        }
                        setSelectedItems(selected);
                    }
                }
            }).catch((error) => {
                toast.error(error.message || "Request Failed Please Try Again ");
            })
        }
    };

    useEffect(() => {
        fetchAuditLogs();
    }, [pageNo]);

    const handleStartDate = (onChange, value) => {
        onChange(value);
        setFilterValue("endDate", '');
        setMinEndDate(value);
    };

    return (
        <>
            <div className={`auditLogViewerCss ${currentLanguageCode === 'en' ? 'white-container' : 'white-container-arabic'}`}>
                <div className='d-flex align-items-center justify-content-between'>
                    <h1 className='text-green mb-4'>{t('sidebar.auditLogViewer')}</h1>
                </div>
                <div className='white_container p-3 py-0'>
                    <div className='d-flex align-items-center justify-content-between green-header px-2'>
                        <div className='d-flex align-items-center'>
                            <img src={SearchIcon} style={{ width: 24, objectFit: 'contain' }} />
                            <h2 className='text-green px-1'>{t('cards.search')}</h2>
                        </div>
                    </div>
                    <form
                        onSubmit={handleFilterSubmit(onFilterFormSubmit)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                handleFilterSubmit(onFilterFormSubmit)();
                            }
                        }}
                    >
                        <div className='row mb-3'>
                            <div className="col-lg-4 col-md-6 col-sm-12 form-outline mt-4 ">
                                <label className="form-label" htmlFor="startDate">From Date</label>
                                <Controller
                                    name="startDate"
                                    control={controlFilterForm}
                                    render={({ field }) => (
                                        <input
                                            type="date"
                                            id="startDate"
                                            className="form-control form-control-lg"
                                            placeholder="Start Date"
                                            autoComplete="off"
                                            value={field.value || ''}
                                            onChange={(e) => handleStartDate(field.onChange, e.target.value)}
                                            onBlur={field.onBlur}
                                            style={{
                                                color: field.value ? 'black' : 'grey',
                                            }}
                                            max={moment().format('YYYY-MM-DD')}
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 form-outline mt-4 ">
                                <label className="form-label" htmlFor="endDate">To Date</label>
                                <Controller
                                    name="endDate"
                                    control={controlFilterForm}
                                    render={({ field }) => (
                                        <input
                                            type="date"
                                            id="endDate"
                                            className="form-control form-control-lg"
                                            autoComplete="off"
                                            value={field.value || ''}
                                            onChange={field.onChange}
                                            onBlur={field.onBlur}
                                            style={{
                                                color: field.value ? 'black' : 'grey',
                                            }}
                                            min={minEndDate}
                                        />
                                    )}
                                />
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-12 form-outline mt-4 ">
                                <label className="form-label" htmlFor="search">{t('cards.employee_name')}</label>
                                <Controller
                                    name="search"
                                    control={controlFilterForm}
                                    render={({ field }) => (
                                        <input
                                            type="text"
                                            id="search"
                                            className="form-control form-control-lg"
                                            placeholder="Name"
                                            autoComplete="off"
                                            value={field.value || ''}
                                            onChange={field.onChange}
                                            onBlur={field.onBlur}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div className='row align-items-end'>
                            <div className='col-lg-3 col-md-12'>
                                <label className="form-label" htmlFor="eventType">Event Type </label>
                                <Controller
                                    name="eventType"
                                    control={controlFilterForm}
                                    render={({ field }) => (
                                        <select
                                            value={field.value || ''}
                                            id='eventType'
                                            onChange={field.onChange}
                                            onBlur={field.onBlur}
                                            className={`form-select form-control-lg ${currentLanguageCode === 'en' ? 'form-control' : 'form-control-arabic'}`}
                                            style={{ color: field.value ? 'black' : 'grey' }}>
                                            <option disabled value="">{'Select Options'}</option>
                                            {
                                                [{ label: 'Create', value: 'create' }, { label: 'Update', value: 'update' }, { label: 'Delete', value: 'delete' }, { label: 'Read', value: 'read' }, { label: 'Access', value: 'access' }, { label: 'Export', value: 'export' }].map((option, i) => (
                                                    <option value={option?.value} key={'eventType' + i}>{option?.label}</option>

                                                ))}
                                        </select>
                                    )}
                                />
                            </div>
                            <div className="col-lg-3 form-outline">
                                <label className="form-label" htmlFor="module">Module</label>
                                <Controller
                                    name="module"
                                    control={controlFilterForm}
                                    render={({ field }) => (
                                        <select
                                            value={field.value || ''}
                                            id='module'
                                            onChange={field.onChange}
                                            onBlur={field.onBlur}
                                            className={`form-select form-control-lg ${currentLanguageCode === 'en' ? 'form-control' : 'form-control-arabic'}`}
                                            style={{ color: field.value ? 'black' : 'grey' }}>
                                            <option disabled value="">{'Select Options'}</option>
                                            {
                                                dropdownModules?.map((option, i) => (
                                                    <option value={option?.value} key={'module' + i}>{option?.label}</option>

                                                ))}
                                        </select>
                                    )}
                                />
                            </div>
                            <div className='col-lg-6'>
                                <div className="d-flex align-items-center justify-content-end">
                                    <SecondaryButton
                                        type={'button'}
                                        onClick={handleResetForm}
                                        buttonText={t("cards.clear_search")}
                                        isEnabled={true}
                                        id={'clear'}
                                        style={{ marginTop: '16px', marginInlineEnd: '16px', width: '15%' }}
                                    />

                                    <PrimaryButton
                                        type={'submit'}
                                        buttonText={t("cards.search")}
                                        isEnabled={true}
                                        id={'search'}
                                        style={{ marginTop: '16px', width: '15%' }}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div className='custom-table border-grey mt-4 pb-0'>
                    <div className='d-flex align-items-center justify-content-between mb-4 green-header p-2'>
                        <div className='d-flex align-items-center'>
                            <input className="form-check-input m-2" type="checkbox" id='selectAllCheckbox' value="" checked={checkedAllPages} onChange={handleCheckAllPagesboxChange} />
                            <label className="form-label mb-0" htmlFor="selectAllCheckbox">Select All</label>
                        </div>

                        <PrimaryButton
                            type={'button'}
                            onClick={() => exportRecords('csv')}
                            buttonText={t('export.export')}
                            isEnabled={selectedItems.length >= 1}
                            id={'export'}
                            isExportBtn={true}
                            isLoading={isLoading}
                        />
                    </div>
                    <div className='table-responsive border-zero p-3 pb-0' style={{ position: 'relative' }}>
                        {(isLoading || !fetchAudit?.rows) && <LoadingComponent />}
                        <table id="auditLogsTable" style={{ borderCollapse: 'separate', borderSpacing: '0 16px' }} cellSpacing="0" width="100%">
                            <thead>
                                <tr style={{ borderTopWidth: 0 }} className='table-head'>
                                    <th scope="col">{t('company.select')}</th>
                                    <th scope="col">Action Date Time</th>
                                    <th scope="col">Employee Name</th>
                                    <th scope="col">Event type</th>
                                    <th scope="col">Module</th>
                                    <th scope="col">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    fetchAudit?.rows?.length > 0 &&
                                    fetchAudit?.rows?.map((item, index) => {
                                        return <tr key={index} className={`${currentLanguageCode === 'en' ? 'table-body-tr' : 'table-body-tr-arabic'}`}>
                                            <td>
                                                <div>
                                                    <input className="form-check-input m-2" type="checkbox" value=""
                                                        id={item?.auditTrailId}
                                                        checked={selectedItems.some(
                                                            (res) =>
                                                                (res.auditTrailId === item?.auditTrailId)
                                                        )}
                                                        onChange={() => handleCheckboxChange(item.auditTrailId)}
                                                    /></div>
                                            </td>
                                            <td>{formatTime(item?.createdAt)}</td>
                                            <td>{item?.firstName} {item?.lastName}</td>
                                            <td>{item?.eventType}</td>
                                            <td>{modules[item?.module]}</td>
                                            <td><a id={`view_${item?.auditTrailId}`}><span onClick={() => { handleShow(); setEmployeeData(item); }} style={{ marginLeft: 10, marginRight: 10, cursor: 'pointer', color: "#00D094" }}>{t('view')}</span></a></td>
                                        </tr>;
                                    }
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className='d-flex align-items-center justify-content-between' style={{ width: "100%", borderTop: '0px !imporant' }}>
                        <nav aria-label="Page navigation example">
                            <ul className="pagination my-3 pt-1 pe-0">
                                <li className="page-item">
                                    <SecondaryButton
                                        type={'button'}
                                        onClick={() => onPreviousClick()}
                                        buttonText={t("previous")}
                                        isEnabled={pageNo !== 1}
                                        id={'previous'}
                                    />
                                </li>
                                <li className="page-item mx-3">
                                    <SecondaryButton
                                        type={'button'}
                                        onClick={() => onNextClick()}
                                        buttonText={t("next")}
                                        isEnabled={!(pageNo == fetchAudit?.totalPages || fetchAudit?.totalPages == 0)}
                                        id={'next'}
                                    />
                                </li>
                            </ul>
                        </nav>
                        <div className='page-count d-flex align-items-center'>
                            <h4 style={{ color: "#00D094" }}>Page {fetchAudit?.currentPage} of {fetchAudit?.totalPages}</h4>
                        </div>
                    </div>
                </div>
            </div>
            <Modal className='TaxcodeModalOne auditLogViewerCss' show={show} onHide={handleCloseModal} style={{ paddingRight: 0 }}>
                <Modal.Body>
                    <div className='d-flex justify-content-between align-items-start green-header'>
                        <div className='col-9'>
                            <p className='text-green addjust modal-title' style={{ fontSize: 20, marginRight: 15 }}>
                                Details
                            </p>
                        </div>
                        <div onClick={handleCloseModal} className='clo-2' style={{ cursor: 'pointer' }} id='close_modal'>
                            <img src={CrossImage} className='small-addjust' style={{ width: 20, objectFit: 'contain', marginLeft: 15 }} />
                        </div>
                    </div>
                    <div className='d-flex justify-content-between' style={{ marginTop: 40, marginBottom: 24 }}>
                        <div>
                            <div className='text1title'>Action Date :</div>
                            <div className='text2title'>{formatDate(employeeData?.createdAt)}</div>
                        </div>
                        <div>
                            <div className='text1title'>Action Time:</div>
                            <div className='text2title' style={{ textAlign: 'right' }}>{moment(employeeData?.createdAt).format('hh:mm A')}</div>
                        </div>
                    </div>
                    <div className='d-flex justify-content-between' style={{ marginBottom: 24 }}>
                        <div>
                            <div className='text1title'>Employee Name:</div>
                            <div className='text2title text-capitalize'>{employeeData?.firstName} {employeeData?.lastName}</div>
                        </div>
                        <div>
                            <div className='text1title'>Event type:</div>
                            <div className='text2title text-capitalize' style={{ textAlign: 'right' }}>{employeeData?.eventType}</div>
                        </div>
                    </div>
                    <div className='d-flex justify-content-between' style={{ marginBottom: 24 }}>
                        <div>
                            <div className='text1title'>Module:</div>
                            <div className='text2title text-capitalize'>{employeeData?.module}</div>
                        </div>
                        <div>
                            <div className='text1title'>IP Address:</div>
                            <div className='text2title' style={{ textAlign: 'right' }}>{employeeData?.ipAddress}</div>
                        </div>
                    </div>
                    <div className='d-flex justify-content-start' style={{ marginBottom: 24 }}>
                        <div>
                            <div className='text1title'>Description:</div>
                            <div className='text2title'>{employeeData?.description}</div>
                        </div>
                    </div>
                    <div className='d-flex justify-content-start' style={{ marginBottom: 24 }}>
                        <div>
                            <div className='text1title'>Platform:</div>
                            <div className='text2title text-capitalize'>{employeeData?.platform}</div>
                        </div>
                    </div>
                    {employeeData?.oldValue && (
                        <div className='d-flex justify-content-start' style={{ marginBottom: 24 }}>
                            <div>
                                <div className='text1title'>Old Value:</div>
                                {employeeData.oldValue.map((item, index) => (
                                    <div key={index} className='text2title'>
                                        <strong>{item.key}: </strong>{item.value}
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    {employeeData?.newValue && employeeData.newValue.length > 0 && (
                        <div className='d-flex justify-content-start' style={{ marginBottom: 24 }}>
                            <div>
                                <div className='text1title'>New Value:</div>
                                {employeeData.newValue.map((item, index) => (
                                    <div key={index} className='text2title'>
                                        <strong>{item.key}: </strong>{item.value}
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                </Modal.Body>
            </Modal>
        </>
    );
}

export default AuditLogViewer;
