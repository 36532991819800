// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TaxcodeModalOne {
  z-index: 1000000;
}
.TaxcodeModalOne select option {
  color: black;
}

@media only screen and (max-width: 600px) {
  .expenses-tab-pad {
    margin-top: 70px;
  }
}

.css-1jqq78o-placeholder {
  color: #344054 !important;
  font-weight: 400 !important;
}

.css-135zynr-control:hover {
  border-color: #ededed !important;
}

.css-tj5bde-Svg {
  color: #333333 !important;
}

.css-1u9des2-indicatorSeparator {
  background-color: white !important;
}

.expensesPageCss .accountBalanceCardCss {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
}
@media only screen and (max-width: 500px) {
  .expensesPageCss .accountBalanceCardCss {
    display: inline-block;
  }
}
@media only screen and (max-width: 500px) {
  .expensesPageCss .customMarginTopExpenses {
    margin-top: 10px;
  }
}
.expensesPageCss .green-icon {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #00d094;
  right: 18px;
}

.simulate .modal-body {
  padding: 30px !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/Expenses/expense.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;AAAE;EACE,YAAA;AAEJ;;AAGE;EADF;IAEI,gBAAA;EACF;AACF;;AAEA;EACE,yBAAA;EACA,2BAAA;AACF;;AACA;EACE,gCAAA;AAEF;;AAAA;EACE,yBAAA;AAGF;;AADA;EACE,kCAAA;AAIF;;AADE;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,gBAAA;AAIJ;AAFI;EANF;IAOI,qBAAA;EAKJ;AACF;AAFI;EADF;IAEI,gBAAA;EAKJ;AACF;AAFE;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EAEA,yBAAA;EACA,WAAA;AAGJ;;AACA;EACE,wBAAA;AAEF","sourcesContent":[".TaxcodeModalOne {\n  z-index: 1000000;\n  select option {\n    color: black;\n  }\n}\n\n.expenses-tab-pad {\n  @media only screen and (max-width: 600px) {\n    margin-top: 70px;\n  }\n}\n\n.css-1jqq78o-placeholder {\n  color: #344054 !important;\n  font-weight: 400 !important;\n}\n.css-135zynr-control:hover {\n  border-color: #ededed !important;\n}\n.css-tj5bde-Svg {\n  color: #333333 !important;\n}\n.css-1u9des2-indicatorSeparator {\n  background-color: white !important;\n}\n.expensesPageCss {\n  .accountBalanceCardCss {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin-top: 24px;\n\n    @media only screen and (max-width: 500px) {\n      display: inline-block;\n    }\n  }\n  .customMarginTopExpenses {\n    @media only screen and (max-width: 500px) {\n      margin-top: 10px;\n    }\n  }\n\n  .green-icon {\n    width: 15px;\n    height: 15px;\n    border-radius: 50%;\n    // border:1px solid black;\n    background-color: #00d094;\n    right: 18px;\n  }\n}\n\n.simulate .modal-body {\n  padding: 30px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
