import React from 'react';
import './Login.scss';
import Close from '../../assets/images/close.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Loader from '../../components/Loader/Loader';
import login_bottom_image from '../../assets/images/login_bottom_image.png';
import Logo2 from '../../assets/images/polo.png';
import LoadingComponent from '../../components/Loader/LoadingComponent';

function SearchCompanyResult() {
    const { t } = useTranslation();
    const { state } = useLocation();
    let navigate = useNavigate();

    const { isLoading } = useSelector(state => state?.registerSlice);

    const handleClose = () => {
        navigate(-1);
    };

    const handleClick = () => {
        navigate('/numberVerification', { state: { registerData: state?.registerData, companyData: state?.companyData } });
    };

    console.log(JSON.stringify(state?.companyData));
    console.log(JSON.stringify(state?.registerData));
    return (
        <section className="vh-100">
            <div className="container-fluid vh-100" style={{ backgroundColor: '#24473D' }}>
                <div className="row d-flex justify-content-between align-items-center h-100 " >
                    <div className=" d-flex flex-column justify-content-between align-items-center my-2">
                        <div className='form-div search-form-dev'>
                            <form className='form' >
                                <div>
                                    <img src={Logo2} className="logoso mt-1 mb-4" alt="Sample image" />
                                </div>
                                <h1 className='text-blue-login mb-2'>
                                    {t('login.find_your_company')}
                                </h1>
                                <div className="divider d-flex align-items-center my-2">
                                    <p className="mb-0 text-subtitle2">
                                        {t('login.varify_your_company')}
                                    </p>
                                </div>

                                <div className="form-outline mt-4 mb-3 d-flex justify-content-between align-items-center ps-2 py-2"
                                    style={{ boxShadow: '0px 12px 16px -4px #10182814', borderRadius: '12px', padding: 10, border: '1px solid #E5E5E5E5' }}>
                                    <div className="d-flex align-items-center">
                                        <div style={{ width: '70px', height: '70px', borderRadius: '12px' }}>
                                            {
                                                state?.companyData?.companyLogo ? (
                                                    <img src={state.companyData.companyLogo} style={{ width: '100%', display: 'block' }} alt="company image" />
                                                ) : (
                                                    <span className='mb-0 d-flex align-items-center justify-content-center text-uppercase' style={{ fontSize: '30px', fontWeight: 600, backgroundColor: '#F2F4F7', borderRadius: 12, width: 70, height: 70 }}
                                                    >
                                                        {state?.companyData?.companyName ? state.companyData.companyName.slice(0, 2) : ''}
                                                    </span>
                                                )
                                            }
                                        </div>
                                        <div className='d-flex align-items-center justify-content-between ms-3'>
                                            <h2 className='me-4' style={{ fontSize: '20px', fontWeight: 600 }}>{state?.companyData?.companyName}</h2>
                                        </div>
                                    </div>
                                    <div className='' style={{ marginLeft: '20px' }} onClick={handleClose} >
                                        <img src={Close} style={{ width: '20px', height: '20px' }} className="" alt="close image" />
                                    </div>
                                </div>

                                <div className="text-center text-lg mt-4 w-100">
                                    <button onClick={handleClick} type="button" className="btn btn-primary btn-lg btn-block form-btn">{t('login.this_is_my_company')}</button>
                                </div>

                                <div className="form-outline text-center text-lg-start mt-4 w-100">
                                    <span className='mb-0 w-100' style={{ fontSize: '14px', fontWeight: 400, color: '#8B9299' }}>{t('login.search_company_result_desc1')} <p className='d-inline' style={{ color: '#8B9299', textDecorationLine: 'underline', }}>{t('login.search_company_result_desc2')}</p></span>
                                </div>

                            </form>
                        </div>

                        <img className='login_bottom_image-1' src={login_bottom_image} />
                    </div>

                </div>
            </div>
        </section>
    );
}

export default SearchCompanyResult;
