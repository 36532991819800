import React from 'react'
import { useTranslation } from 'react-i18next';

function PaginationPart() {
    const { t } = useTranslation();

  return (
    <div> <div className='d-flex align-items-center justify-content-between pagination-part' style={{ width: "100%", borderTop: '0px !important' }}>
    <nav aria-label="Page navigation example">
      <ul className="pagination my-3 pt-1 pe-0">
        <li className="page-item">
          <button
            className="btn page-link"
            id="prevPage"
          >
            {t("previous")}
          </button>
        </li>
        <li className="page-item mx-3">
          <button className="btn page-link" >{t('next')}</button>
        </li>
      </ul>
    </nav>
    <div className="page-count d-flex align-items-center">
      <h4 style={{ color: "#00D094" }}>
        {t('page_info')}
      </h4>
    </div>
</div> </div>
  )
}

export default PaginationPart