/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';
import BackArrow from '../../assets/images/backarrow1.png';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Accordion, Modal, Tab, Tabs } from 'react-bootstrap';
import RecepitIcon from '../../assets/images/greyRecipt.png';
import GreenRecepitIcon from '../../assets/images/doneRecipt.png';
import ReviewGreyIcon from '../../assets/images/defDoc.png';
import ReviewGreenIcon from '../../assets/images/docAcept.png';
import ReviewRedIcon from '../../assets/images/rejected.png';
import CategoryGreyIcon from '../../assets/images/menu.png';
import CategoryGreenIcon from '../../assets/images/menuGreen.png';
import ExportGreyIcon from '../../assets/images/docArrow.png';
import ExportGreenIcon from '../../assets/images/export_icon_green.png';
import { useDispatch, useSelector } from 'react-redux';
import { fetchReimbursementDetails, updateReimbursementTransaction, updateReimbursementByIdAndFile, fetchReimbursementActivityLog, approveRejectReimbursement } from '../../pages/Reimbursement/reimbursementSlice';
import { fetchCurrencies, getAllTagByGroup, getCategoryList } from '../../pages/Expenses/expensesSlice';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import DynamicFieldArray from '../DynamicFieldArray/DynamicFieldArray';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import ErrorImage from '../../assets/images/warning-orange-icon.png';
import { decodeToken } from 'react-jwt';
import { TransformWrapper } from 'react-zoom-pan-pinch';
import { TransformComponent } from 'react-zoom-pan-pinch';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import { Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import PdfComponent from './PdfComponent';
import CloseImage from '../../assets/images/close_square_white.png';
import uploadDoc from '../../assets/images/upload_document.png';
import { Viewer } from '@react-pdf-viewer/core';
import ImagesCross from '../../assets/images/close-square-8.png';
import { formatTimeHours, getDecimalNo } from '../../utils/XpendlessUtils';
import LoadingComponent from '../Loader/LoadingComponent';
import { isCompanyActive } from '../../utils/verifyCompanyStatus';

function ReimbursementDetails() {
    const { state, pathname } = useLocation();
    const { transactionId } = useParams();

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [event, setEvent] = useState("1");
    const dispatch = useDispatch();
    const { isLoading, reimbursementDetailLoader, reimbursementDetails, reimbursementActivityLogs } = useSelector((state) => state?.reimbursementSlice);
    const { categoryList, tags, currencies } = useSelector(state => state.expenseReducer);
    const [imageUrl, setImageUrl] = useState(false);
    const [isEnabled, setIsEnabled] = useState(false);

    const wholePart = reimbursementDetails?.amountQAR;
    const decimalDigits = getDecimalNo(reimbursementDetails?.code, currencies);
    const [wholePart2, decimalPart2] = (reimbursementDetails?.amount)
        ? reimbursementDetails.amount.toString().split('.')
        : ['', ''];

    const formattedDecimalPart2 = decimalDigits > 0
        ? (decimalPart2 !== undefined
            ? decimalPart2.slice(0, decimalDigits).padEnd(decimalDigits, '0')
            : '0'.repeat(decimalDigits))
        : '';

    useEffect(() => {
        dispatch(fetchCurrencies());
    }, []);

    const validationSchemas = {};
    const token = localStorage.getItem('token');
    const decodedToken = decodeToken(token);
    const currentLanguageCode = cookies.get('i18next') || 'en';
    const languages = [
        {
            code: 'en',
            name: 'English',
            country_code: 'gb',
        },
        {
            code: 'ar',
            name: 'العربية',
            dir: 'rtl',
            country_code: 'sa',
        },
    ];
    const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
    const [description, setDiscription] = useState('');
    const [showFullScreen, setShowFullScreen] = useState(false);
    const isFirstRender = useRef(true);
    const fileInputRef = useRef(null);
    const [forReview, setForReview] = useState(false);
    const [showRejectModal, setShowRejectModal] = useState(false);

    const reviewIcons = {
        pending: ReviewGreyIcon,
        approve: ReviewGreenIcon,
        reject: ReviewRedIcon,
        null: ReviewGreyIcon
    };

    const filePermission = useMemo(() => {
        return decodedToken?.isAccountant || decodedToken?.isAdmin || decodedToken?.isGlobalReviewer || decodedToken?.reviewer?.length > 0;
    }, [decodedToken]);

    const isWrongDetail = useMemo(() => {
        return reimbursementDetails &&
            reimbursementDetails?.receiptOCRdata !== null && (
                (reimbursementDetails?.receiptOCRdata?.amount !== reimbursementDetails?.amount)
                || (reimbursementDetails?.receiptOCRdata?.merchantName?.toLowerCase() !== reimbursementDetails?.merchantName?.toLowerCase())
                || (moment(reimbursementDetails?.createdAt).format('YYYY-MM-DD') !== moment(reimbursementDetails?.receiptOCRdata?.date).format('YYYY-MM-DD'))
            );
    }, [reimbursementDetails]);

    tags?.forEach((tagGroup) => {
        const fieldName = tagGroup.label.replace(/ /g, '_');
        const fieldSchema = yup.object().shape({
            amount: yup.number().min(0).typeError('Amount Should be Number').required("Amount is required"),
            tagId: yup.string().required("Please select Tag"),
        });
        validationSchemas[fieldName] = yup.array().of(fieldSchema);
    });

    const { handleSubmit, control, register, formState: { errors }, reset, setValue, watch } = useForm({
        resolver: yupResolver(yup.object().shape(validationSchemas)),
    });

    const calculateTotalAmount = (data) => {
        if (Array.isArray(data)) {
            return data.reduce((result, category) => {
                const totalAmount = category
                    .map(item => (item?.amount) ? parseFloat(item.amount) : 0)
                    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

                return result + totalAmount;
            }, 0);
        } else if (typeof data === 'object') {
            return Object.values(data).reduce((result, category) => {
                const totalAmount = category
                    .map(item => (item?.amount) ? parseFloat(item.amount) : 0)
                    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

                return result + totalAmount;
            }, 0);
        }
        return 0;
    };

    const onSubmit = async (data) => {
        const formData = [];
        const cardTransactionId = transactionId;
        const balance = reimbursementDetails?.amount;
        for (const key in data) {
            if (Object.prototype.hasOwnProperty.call(data, key)) {
                const totalAmount = data[key].reduce((sum, { amount }) => sum + parseFloat(amount), 0);
                if (totalAmount !== balance) {
                    toast.error(`The total splits for ${key} does not match the total amount.`);
                    return;
                }
                const transformedArray = data[key].map(({ tagId, amount }) => ({
                    tagId,
                    amount
                }));
                formData.push(...transformedArray);
            }
        }
        dispatch(updateReimbursementTransaction(
            {
                reimbursementRequestId: cardTransactionId,
                amount: reimbursementDetails?.amount,
                date: moment.utc(reimbursementDetails?.date).format('YYYY-MM-DD'),
                time: moment.utc(reimbursementDetails?.date).format('hh:mm A'),
                tags: formData,
                currencyId: reimbursementDetails?.currencyId,
                merchantName: reimbursementDetails?.merchantName
            }))
            .unwrap()
            .then(() => {
                toast.success("Tags saved successfully");
            }).catch((error) => {
                toast.error(error?.message);
            });
        setIsEnabled(false);
    };

    const handleImageClick = () => {
        setShowFullScreen(true);
    };

    const uploadReceiptChange = (event) => {
        isFirstRender.current = false;
        const file = event.target.files[0];
        if (file) {
            const allowedExtensions = ['jpg', 'png', 'jpeg', 'pdf'];
            const fileExtension = file.name.slice(((file.name.lastIndexOf(".") - 1) >>> 0) + 2);
            if (allowedExtensions?.includes(fileExtension.toLowerCase())) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    setImageUrl(e.target.result);
                    updateCardTransactionData(file, 'imageUpload');
                };
                reader.readAsDataURL(file);
            } else {
                alert('Please select a JPG or PNG image.');
                event.target.value = '';
            }
        }
    };

    const fetchTransactionData = () => {
        let queryparam = `?reimbursementRequestId=${transactionId}`;
        if (state?.eventType) {
            queryparam += `&eventType=${state?.eventType}`;
        }
        dispatch(fetchReimbursementDetails(queryparam));
    };

    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        sidebarTabs: () => [],
    });

    useEffect(() => {
        dispatch(getAllTagByGroup());
        dispatch(getCategoryList());

    }, []);

    useEffect(() => {
        if (reimbursementDetails?.receiptPath && reimbursementDetails.receiptPath) {
            setImageUrl(reimbursementDetails?.receiptPath);
        } else {
            setImageUrl(null);
        }
    }, [reimbursementDetails]);

    useEffect(() => {
        if (transactionId) {
            dispatch(getAllTagByGroup());
            fetchTransactionData();
            setForReview(state?.forReview || false);
        }
    }, [transactionId]);

    const {
        control: controlRejectForm,
        handleSubmit: handleRejectSubmit,
        formState: { errors: errorsRejectForm },
        reset: resetRejectForm, } = useForm({
            resolver: yupResolver(yup.object().shape({
                comments: yup.string().required()
            })
            )
        });

    const handleCloseRejectModal = () => setShowRejectModal(false);
    const handleShowRejectModal = () => setShowRejectModal(true);
    const approveRejectRequest = (data) => {
        if(data.type === "approve"){
            if (!isCompanyActive()) return;
        }
        console.log(data);
        if (transactionId) {
            let requestData = {
                approveStatus: data.type,
                reimbursementRequestId: transactionId
            };
            if (typeof data.comments !== 'undefined') {
                requestData.comments = data.comments;
            }
            dispatch(approveRejectReimbursement(requestData)).unwrap().then(() => {
                toast.success('Status Changed successfully');
                resetRejectForm();
                handleCloseRejectModal();
                setForReview(false);
                navigate(-1);
            }).catch((error) => {
                toast.error(error.message || "Request Failed Please Try Again ");
            });
        }
    };

    const updateCardTransactionData = (requestData, imageUpload) => {
        if (transactionId && requestData && requestData.categoryId !== '' && !imageUpload) {
            requestData.reimbursementRequestId = transactionId;
            if (requestData.categoryId === 'Select category') {
                requestData.categoryId = null;
            }

            dispatch(updateReimbursementTransaction(requestData)).unwrap().then(() => {
                toast.success('Data updated successfully');
                if (transactionId) {
                    dispatch(fetchReimbursementDetails(`?reimbursementRequestId=${transactionId}`));
                    dispatch(getAllTagByGroup());
                }
            }).catch((error) => {
                toast.error(error.message || "Request Failed Please Try Again ");
            });
        }

        if (transactionId && requestData && (imageUpload && imageUpload === 'imageUpload')) {
            dispatch(updateReimbursementByIdAndFile({ requestData, reimbursementRequestId: transactionId })).unwrap().then(() => {
                if (transactionId) {
                    dispatch(fetchReimbursementDetails(`?reimbursementRequestId=${transactionId}`));
                    dispatch(getAllTagByGroup());
                }
                toast.success('Data updated successfully');
            }).catch((error) => {
                toast.error(error.message || "Receipt Upload Failed Please Try Again ");
            });
        }
    };


    useEffect(() => {
        document.body.dir = currentLanguage.dir || 'ltr';
        document.title = t('app_title');
    }, [currentLanguage, t]);

    useEffect(() => {
        setDiscription(reimbursementDetails?.description);
    }, [reimbursementDetails?.description]);

    const changeTab = (eventKey) => {
        if (eventKey === '3') {
            const reimbursementQuery = `?reimbursementRequestId=${transactionId}`;
            const exportQueryParam = '&eventType=export';
            const query = state?.eventType === 'export' ? `${reimbursementQuery}${exportQueryParam}` : reimbursementQuery;
            dispatch(fetchReimbursementActivityLog(query));
        }
    };

    const uploadReceiptClick = () => {
        if (filePermission) {
            fileInputRef.current.click();
        }
    };

    useEffect(() => {
        if (event === '3') {
            changeTab(event);
        }
    }, [reimbursementDetails, event]);

    return (
        <div className={`expense-detail-css settings ${currentLanguageCode === 'en' ? 'white-container' : 'white-container-arabic'}`}>
            <div className='d-flex align-items-center justify-content-between'>
                <h1 className='text-green'>{t('sidebar.reimbursement_title')}</h1>
                <button onClick={() => navigate(state?.prevPath,
                    {
                        state:
                        {
                            prevPath: pathname,
                            parentTab: state?.parentTab,
                            type: state?.type,
                            activeKey: state?.activeKey
                        },
                        replace: true
                    })}
                    className='btn-secondary-small' id='back'><img src={BackArrow} style={{ width: 20, objectFit: 'contain', marginLeft: 5, marginRight: 5, transform: currentLanguageCode === 'en' ? 'rotate(0deg)' : 'rotate(180deg)' }} />Back</button>
            </div>
            <p className='small-text mb-4'>{t('expenses.reimbursement_details_desc')}</p>
            <Tabs defaultActiveKey={1} animation="true" id="reimbursement-tabs" onSelect={(eventKey) => setEvent(eventKey)}>
                <Tab eventKey={1} title={`${t('expenses.details')}`}>
                    <div className='green-border'>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <div className='green-box px-2 py-3' style={{position: 'relative'}}>
                                    {reimbursementDetailLoader && <LoadingComponent />}
                                    <div className='d-flex justify-content-between'>
                                        <div className='dark-text mb-0'>
                                            {`${wholePart2?.toLocaleString()}`}
                                            <span className="text-grey" style={{ fontSize: 12, fontWeight: '500' }}>
                                                {`.`}
                                                {`${formattedDecimalPart2}`}
                                            </span>
                                            <span className="text-grey" style={{ fontSize: 12, fontWeight: '500' }}>
                                                {` ${(reimbursementDetails?.code) ? reimbursementDetails?.code : 'QAR'}`}
                                            </span>
                                            {reimbursementDetails?.code !== "QAR" && (
                                                <p className='dark-text mb-0'>
                                                    {`${Math.floor(wholePart || 0).toLocaleString()}.`}
                                                    <span className='light-text'>
                                                        {`${(wholePart?.toFixed(2).split('.')[1]) !== "0000" ? wholePart?.toFixed(2).split('.')[1] : '00'}`}
                                                    </span>
                                                    <span className='light-text'>QAR</span>
                                                </p>
                                            )}
                                        </div>
                                        <p className='dark-text'>{reimbursementDetails?.merchantName}</p>
                                    </div>
                                    <div className='d-flex justify-content-between align-items-center mt-3'>
                                        <p className='dark-grey-text'>{moment.utc(reimbursementDetails?.date).format('dddd, DD MMMM YYYY')}</p>
                                        <p className='dark-grey-text' style={{ fontSize: 16, fontWeight: 400 }}>{formatTimeHours(reimbursementDetails?.date)}</p>
                                    </div>
                                </div>
                                <div className='border-grey p-3 mt-3 expense-card-1' style={{ borderRadius: 15, position: 'relative' }}>
                                    {reimbursementDetailLoader && <LoadingComponent />}
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <img src={imageUrl ? GreenRecepitIcon : RecepitIcon} style={{ width: 38, objectFit: 'contain', marginRight: 15, marginLeft: 15 }} title={imageUrl ? "Receipt is uploaded" : "No Receipt"} />
                                        <img src={reviewIcons[(reimbursementDetails?.decision) || null]} style={{ width: 38, objectFit: 'contain', marginRight: 15, marginLeft: 15 }} title={(reimbursementDetails?.decision) === 'reject' ? "Rejected" : (reimbursementDetails?.decision) ? "Approved" : "No Action"} />
                                        <img src={reimbursementDetails?.categoryId ? CategoryGreenIcon : CategoryGreyIcon} style={{ width: 38, objectFit: 'contain', marginRight: 15, marginLeft: 15 }} title={reimbursementDetails?.categoryId ? "Category is selected" : "No Category"} />
                                        <img src={reimbursementDetails?.isExported ? ExportGreenIcon : ExportGreyIcon} style={{ width: 35, objectFit: 'contain', marginRight: 15, marginLeft: 15 }} title={reimbursementDetails?.isExported ? "Exported" : "Not Exported Yet"} />
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='border-grey p-3' style={{ borderRadius: 15, position: 'relative' }}>
                                    {reimbursementDetailLoader && <LoadingComponent />}
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <p className='dark-text m-0'>{t('name')}</p>
                                        <p className='dark-text m-0'>{reimbursementDetails?.firstName} {reimbursementDetails?.lastName}</p>
                                    </div>
                                    <div className='d-flex justify-content-between align-items-center mt-2'>
                                        <p className='dark-text'>{t('teams.departments')}</p>
                                        <p className='dark-text'>{reimbursementDetails?.departmentName}</p>
                                    </div>
                                    <div className='row mb-2 align-items-center'>
                                        <div className='d-flex align-items-center justify-content-between mt-3'>
                                            <p className='text-black mb-0 fw-bold' style={{ fontSize: 14 }}>{t('expenses.category')}</p>
                                            {
                                                reimbursementDetails?.iconPath &&
                                                <img onError={(e) => { e.target.onerror = null; e.target.style.display = 'none'; }} src={reimbursementDetails?.iconPath} style={{ display: 'flex', width: 32, height: 32, objectFit: 'fill', borderRadius: 20, marginRight: 10, marginLeft: 10 }} />
                                            }
                                            {
                                                !filePermission ? <p className='text-grey mb-0 fw-bold' style={{ fontSize: 14 }}>{reimbursementDetails?.category}</p>
                                                    : (<select
                                                        className="form-control form-select form-control-lg"
                                                        value={reimbursementDetails?.categoryId || ''}
                                                        style={{ width: ' 60%' }}
                                                        onChange={(event) => { /*state?.eventType !== 'export' && */ updateCardTransactionData({ categoryId: event.target.value, }); }}
                                                        //disabled={state?.eventType === 'export' ? true : false}
                                                        id='select_category'
                                                    >
                                                        <option value="">Select category</option>
                                                        {
                                                            categoryList && categoryList.length > 0 &&
                                                            categoryList.map((option, i) => (
                                                                <option value={option?.categoryId} key={'cardlist' + i}  >{option?.name}</option>
                                                            ))}

                                                    </select>)
                                            }
                                        </div>
                                    </div>
                                    <div className='row align-items-center'>
                                        <div className='col-lg-6 '>
                                            <p className='dark-text mb-0'>{t('teams.description')}</p>
                                        </div>
                                        <div className='col-lg-6'>
                                            <textarea
                                                type="text"
                                                id="description_reimbursement"
                                                className='form-control form-control-lg'
                                                placeholder='Descriptions'
                                                value={description || ''}
                                                onChange={(event) => (setDiscription(event.target.value))}
                                                disabled={filePermission ? false : true}
                                                onBlur={() => { state?.eventType !== 'export' && updateCardTransactionData({ description: description }); }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='cards-custom p-3 mt-3' style={{ borderRadius: 30, position: 'relative' }}>
                        {isLoading && <LoadingComponent />}
                        <div style={{ marginBottom: 8 }} className='text-header'>{t('upload_document')}</div>
                        {
                            isWrongDetail && reimbursementDetails?.receiptPath &&
                            <div className='row g-1'>
                                <div className='col-lg-12 col-md-12 mt-2'>
                                    <div className='d-flex align-items-center justify-content-center'>
                                        <div className="border-orange p-3 d-flex flex-column" style={{ width: '100%', backgroundColor: '#FFFAF2' }}>
                                            <div className='d-flex justify-content-start align-items-center'>
                                                <div>
                                                    <img src={ErrorImage} style={{ height: '20px', objectFit: 'contain', width: '20px', marginRight: 10 }} />
                                                </div>
                                                <div className='d-flex flex-nowrap'>
                                                    <h3 className='text-warning mb-0 mt-1'>{t('expenses.error_heading')}</h3>
                                                </div>
                                            </div>
                                            <div style={{ color: '#FFA500', fontWeight: 400, marginTop: 10 }}>
                                                {
                                                    reimbursementDetails?.receiptOCRdata?.amount !== reimbursementDetails?.amount &&
                                                    <p className='mb-0'>Amount entered does’nt match with uploaded receipt</p>
                                                }
                                                {
                                                    (reimbursementDetails?.receiptOCRdata?.merchantName?.toLowerCase() !== reimbursementDetails?.merchantName?.toLowerCase()) &&
                                                    <p className='mb-0'>Merchant name entered does’nt match with uploaded receipt</p>
                                                }
                                                {
                                                    (moment(reimbursementDetails?.createdAt).format('YYYY-MM-DD') !== moment(reimbursementDetails?.receiptOCRdata?.date).format('YYYY-MM-DD')) &&
                                                    <p className='mb-0'>Date entered does’nt match with uploaded receipt</p>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className='row align-items-end' style={{ justifyContent: 'center' }}>
                            <div className='col-lg-7 p-3'>
                                <div className='d-flex'>
                                    {
                                        (imageUrl || reimbursementDetails?.receiptPath) &&
                                        <>
                                            {
                                                imageUrl && !reimbursementDetails?.receiptPath?.includes('.pdf') ?
                                                    <div className='p-1' style={{ minWidth: '204px', borderRadius: 20 }}>
                                                        <img
                                                            src={imageUrl}
                                                            className='receipt_img_big'
                                                            onClick={handleImageClick}
                                                            alt="Receipt Image"
                                                            style={{ height: 204, objectFit: 'cover', width: 204, cursor: 'pointer', background: ' radial-gradient(#E0D7DA, #B7B0B7)', borderRadius: '20px' }}
                                                        />
                                                    </div> :
                                                    <div className='p-1' style={{ width: '204px' }}>
                                                        <PdfComponent handleImageClick={handleImageClick} url={reimbursementDetails?.receiptPath}></PdfComponent>
                                                    </div>
                                            }
                                        </>
                                    }
                                    {showFullScreen && (
                                        <div
                                            className="fullscreen-overlay active"
                                        >

                                            <div className="close-image">
                                                <img
                                                    src={CloseImage}
                                                    onClick={(e) => { e.stopPropagation(); setShowFullScreen(false); }}
                                                    className='close-image-fullscreen'
                                                />
                                            </div>
                                            {imageUrl && !reimbursementDetails?.receiptPath?.includes('.pdf') ? (
                                                <>
                                                    <div className='d-flex align-items-center justify-content-center'>
                                                        <TransformWrapper
                                                            initialScale={1}
                                                            initialPositionX={0}
                                                            initialPositionY={0}
                                                            centerOnInit={true}
                                                            smooth={true}
                                                        >
                                                            <TransformComponent wrapperStyle={{ width: '100%', height: window.innerHeight, }}>
                                                                <img
                                                                    src={imageUrl}
                                                                    className='receipt_img_big'
                                                                    onClick={handleImageClick}
                                                                    alt="Receipt Image"
                                                                    style={{ width: '100%', objectFit: 'contain', cursor: 'pointer', height: window.innerHeight, }}
                                                                />
                                                            </TransformComponent>
                                                        </TransformWrapper>
                                                    </div>
                                                </>
                                            ) : (
                                                <div style={{ width: '50%', height: '100%' }}>
                                                    <Worker workerUrl={pdfjsWorker}>
                                                        <Viewer
                                                            fileUrl={reimbursementDetails?.receiptPath}
                                                            plugins={[defaultLayoutPluginInstance]}
                                                        />
                                                    </Worker>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    <div className='uplaod-box mx-3'>
                                        <div className='d-flex align-items-center justify-content-center' onClick={uploadReceiptClick}>
                                            <div>
                                                <div className='d-flex justify-content-center'>
                                                    <img src={uploadDoc} style={{ width: 60, alignItems: 'center' }} />
                                                </div>
                                                <p className='text-center mt-1'>
                                                    <span className='upload-text'>Upload Document</span><br />
                                                    <span className='document-name'>Receipt</span><br />
                                                    <span className='small-text-upload'>(max 5MB Accepted format: jpg, png, pdf)</span>
                                                </p>
                                                {filePermission &&
                                                    <input type="file" accept=".pdf, .jpg, .jpeg, .png" ref={fileInputRef} style={{ display: 'none' }} onChange={uploadReceiptChange} />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        forReview &&
                        <div className='d-flex justify-content-end align-items-center flex-wrap'>
                            <button onClick={handleShowRejectModal} style={{ zIndex: 2 }} className='btn-danger cursor-pointer mt-3' id='reject'>{t('reject')}</button>
                            <div className='pe-3'></div>
                            <button onClick={() => approveRejectRequest({ type: 'approve' })} style={{ zIndex: 2 }} className='btn-success-green cursor-pointer mt-3' id='approve'>{t('approve')}</button>
                        </div>
                    }
                </Tab>
                <Tab eventKey={2} title={`${t('split')}`}>
                    <div className='row mt-2'>
                        {
                            !filePermission ? (
                                typeof reimbursementDetails?.tags !== 'undefined' && reimbursementDetails?.tags.length > 0 ? (
                                    reimbursementDetails?.tags.map((tagGroup, i) =>
                                        <div className='col-lg-6 col-md-12 p-2' key={tagGroup?.tagGroupId}>
                                            <Accordion defaultActiveKey={'tagGroups0'}>
                                                <Accordion.Item eventKey={'tagGroups' + i}>
                                                    <Accordion.Header>
                                                        <p className='text-black mb-0 fw-bold text-capitalize'>{tagGroup?.label}</p>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className='row'>
                                                            <div className='border-grey p-3' style={{ borderRadius: 15 }}>
                                                                <input type="text" id="form3Example3" className='form-control form-control-lg mt-2' placeholder='Banglore' value={tagGroup?.value} disabled={true} />
                                                                <input type="text" id="form3Example3" className='form-control form-control-lg mt-2' placeholder='Hello' value={tagGroup?.amount.toLocaleString()} disabled={true} />
                                                            </div>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </div>
                                    )
                                ) : (
                                    <div className='col-12'>
                                        <p className='text-center text-muted'>No tags available. Please add tags to display.</p>
                                    </div>
                                )
                            ) : (
                                (typeof tags !== 'undefined' && tags.length > 0 && reimbursementDetails) ? (
                                    <form onSubmit={handleSubmit(onSubmit)} className='row'>
                                        {tags.map((tagGroup, i) =>
                                            <div className='col-lg-6 col-md-12 p-2' key={`tag${tagGroup?.tagGroupId}`}>
                                                <Accordion defaultActiveKey={'tagGroups0'}>
                                                    <Accordion.Item eventKey={'tagGroups' + i}>
                                                        <Accordion.Header>
                                                            <p className='text-black mb-0 fw-bold text-capitalize'>{tagGroup?.label}</p>
                                                        </Accordion.Header>
                                                        <Accordion.Body>
                                                            <DynamicFieldArray
                                                                key={i}
                                                                t={t}
                                                                tags={tagGroup}
                                                                register={register}
                                                                deletedTag={reimbursementDetails?.tags?.length > 0 && reimbursementDetails?.tags}
                                                                existingValue={reimbursementDetails?.tags?.length > 0 && reimbursementDetails?.tags?.filter(tag => tag?.label === tagGroup?.label)}
                                                                setValue={setValue}
                                                                name={(tagGroup?.label).replace(/ /g, '_')}
                                                                control={control}
                                                                errors={errors}
                                                                watch={watch}
                                                                onUpdate={(isUpdated) => { setIsEnabled(isUpdated); }}
                                                                details={reimbursementDetails}
                                                                calculateTotalAmount={calculateTotalAmount}
                                                                reset={reset}
                                                                placeholderData={wholePart2 ? wholePart2 : wholePart}
                                                            />
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </Accordion>
                                            </div>
                                        )}
                                        <button disabled={!isEnabled} type="submit" className={isEnabled ? 'filled-green-button w-100' : 'disabled-filled-button w-100'} id='apply'>{t('apply')}</button>
                                    </form>
                                ) : (
                                    <div className='col-12'>
                                        <p className='text-center text-muted'>No tags available. Please add tags to display.</p>
                                    </div>
                                )
                            )
                        }
                    </div>

                </Tab>
                <Tab eventKey={3} title={`${t('expenses.activities')}`} style={{position: 'relative'}}>
                    {isLoading && <LoadingComponent />}
                    {reimbursementActivityLogs && reimbursementActivityLogs?.length > 0 &&
                        reimbursementActivityLogs?.map((activity, index) => {
                            return (
                                <div className='my-4' key={`date-${index}`}>
                                    <p className='text-black mb-0 fw-bold' style={{ fontSize: 20 }}>{moment(activity?.date).format('D MMM YYYY')}</p>
                                    <div className='col-12 border-grey mt-3 p-3'>
                                        {activity.records && (activity.records).length > 0 &&
                                            (activity.records).map((record, index) => {
                                                return (
                                                    <div className='row mt-2 table-body-tr' key={index}>
                                                        <p className='col-lg-4 text-black mb-0 fw-bold' style={{ fontSize: 14 }}>{moment(record?.time).format('hh:mm A')}</p>
                                                        <p className='col-lg-8 text-grey mb-0 fw-bold' style={{ fontSize: 14 }}>{record?.description}</p>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                </div>
                            );
                        })
                    }
                </Tab>
            </Tabs>
            <Modal className='TaxcodeModalOne' show={showRejectModal} onHide={handleCloseRejectModal} style={{ paddingRight: 0 }}>
                <form onSubmit={handleRejectSubmit(approveRejectRequest)}>
                    <Modal.Body>
                        <div className='d-flex justify-content-between align-items-start'>
                            <div className='col-9'>
                                <p className='text-black modal-title' style={{ fontSize: 20 }}>
                                    {t('reject')}
                                </p>
                                <p className='text-grey'>{t('reject')}</p>
                            </div>
                            <div onClick={handleCloseRejectModal} className='clo-2' style={{ cursor: 'pointer' }}>
                                <img src={ImagesCross} style={{ width: 20, objectFit: 'contain' }} />
                            </div>
                        </div>
                        <Controller
                            name="type"
                            control={controlRejectForm}
                            defaultValue="reject"
                            render={({ field }) => (
                                <input
                                    {...field}
                                    type="hidden"
                                    id="amt"
                                    className="form-control form-control-lg"
                                    placeholder={t('cards.comments')}
                                />
                            )}
                        />
                        <div className="form-outline mt-4 mb-3">
                            <label className="form-label" htmlFor="comments">{t('cards.comments')}</label>
                            <Controller
                                name="comments"
                                control={controlRejectForm}
                                render={({ field }) => (
                                    <input type="text"  {...field} id="comments" className="form-control form-control-lg"
                                        placeholder={`${t('cards.comments')}`} />
                                )}
                            />
                            <p className="text-danger">{errorsRejectForm?.comments?.message}</p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button onClick={handleCloseRejectModal} className='btn-secondary-small' id='cancel_reject'>{t('cancel')}</button>
                        <button className='btn-primary-small' id='submit_reject'>{t('save')}</button>
                    </Modal.Footer>
                </form>
            </Modal>
        </div >
    );
}

export default ReimbursementDetails;