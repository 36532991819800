import ExpenseIcon from '../../assets/images/Expense-icon.png';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import InfoSection from './ContentSection';
import CategoryGreenIcon from '../../assets/images/menuGreen.png';
import ExportGreenIcon from '../../assets/images/export_icon_green.png';
import GreenRecepitIcon from '../../assets/images/doneRecipt.png';
import ReviewRedIcon from '../../assets/images/rejected.png';
import PaginationPart from './PaginationPart';

export default function ExpensePage() {
    const [selectedIndex, setSelectedIndex] = useState(1);
    const { t } = useTranslation();

    
  const handleFilterClick = (index) => {
    setSelectedIndex(index);
  };
  
  const items = [
    { label: t("walkThrough.all") },
    { label: t("walkThrough.my_transaction") },
    { label: t("walkThrough.for_your_review") }

  ];

  const content = [
    t("walkThrough.expense_content_1"),
    t("walkThrough.expense_content_2")

    ]
    return (
<div className='overflow-hidden' style={{overflow: "hidden", overflowY: "hidden"}}>
     
          <div className="export-dropdown mt-5" >

          <div className="col-lg-3 col-md-6 balance-box" >
      < div className='p-3'>
      <div className="balance-card-box pt-5 pb-5">
        <div className="balance-item">
          <div className="balance-label">{t("walkThrough.currrent_balance")}</div>
          <div className="balance-value">{t("walkThrough.balance_value")}.<span className='mt-5 ms-1' style={{fontSize:"12px", color:"white", opacity: 0.8}}>00 QAR</span></div>
        </div>

        <div className="balance-item">
          <div className="card-status">{t("walkThrough.active_card")}</div>
        </div>
        </div>

        <div className="card-spending-limit mt-3">
          <div className="spending-limit-label">{t("walkThrough.card_spending-limit")}</div>
          <button className="change-button">{t("walkThrough.change_btn")}</button>

        </div>
        <div className="progress-container mt-2">
          <div className="progress-bar">
            <div className="progress-bar-fill"></div>
          </div>
        </div>
        <div className="card-spending-limit mt-3">
          <div className="spending-limit">{t("walkThrough.spending_limit")} <span className='text-blue'>{t("walkThrough.total_limit")}</span></div>
          <div className="spending-limit">{t("walkThrough.monthly_limit")}</div>

        </div>
             </div>
          
    </div></div>
    <div className="export-dropdown mt-3">

  <div 
    className="col-lg-3 col-md-6 "
    style={{
      border: '1px solid #00D094',
      borderRadius: 20,
    }}
  >
    <div className="d-flex justify-content-between" style={{ padding: 5 }}>
      {items.map((item, index) => (
        <div
          key={index}
          onClick={() => handleFilterClick(index)}
            className={`dropdown-item ${selectedIndex === index ? 'selected' : 'text-white'}`}
        
        >
          {item.label}
        </div>
      ))}
    </div></div>
  </div>
  <div className="button-container">
    <button className="btn btn-primary add-expense-button">{t('walkThrough.add_expenses')}</button>
 
</div>
<div className="amount-table-container col-lg-6 col-md-6">
<div className='amount-table  position-relative' style={{overflowX:"auto"}}>
<div className='d-flex align-items-center inner-tabs mb-2 green-header p-2'>

              <div className=" d-flex align-items-center gap-4 tabs  me-3">
                      <div>{t('expenses.all_pending')}</div>
                      <div>{t('expenses.no_receipts')}</div>

                  <div>{t('expenses.rejected')}</div>
                  <div>{t('expenses.approved')}</div>

               
              </div></div>
              
                      <div className="table-responsive border-zero mt-4 pb-2">
                      <div className="p-3 mb-2">
                        <h3 className="tabs">{t('walkThrough.expense_date')}</h3>
                      </div>
                      {Array.from({ length: 2 }).map((_, index) => (
  <div className="mt-2 table-body-tr p-2" style={{ cursor: "pointer" }}>

    <div className="row align-items-center">
      <div className="d-flex align-items-center justify-content-start">
        <div className="checkbox">
          <input className="form-check-input" type="checkbox" id="checkAllPages" />
        </div>

        <div className="time">
          <p className="mb-0 me-2 mr-2 ms-2 tabs">{t('walkThrough.expense_time')}</p>
       
          <div className="d-flex flex-column ms-2 ml-5">
            <p className="text-black mb-0 merchant" >{t('walkThrough.merchant')}</p>
            <p className="text-grey mb-0 merchant-name">{t('walkThrough.john_doe')}</p>
          </div>
        </div>

        <div className="d-flex align-items-center" style={{ width: '350px' }}>
          <img
            src={GreenRecepitIcon}
            className='expenseIcon ms-5'
          />
          <img
            src={ReviewRedIcon}
            className='expenseIcon ms-4'
          />
          <img
            src={ExportGreenIcon}
            className='expenseIcon ms-4'
          />
          <img
            src={CategoryGreenIcon}
            className='expenseIcon ms-4 me-5'
 />
        </div>

        <div className="d-flex">
          
          <span className='ms-2'>{t("walkThrough.wallet_amount")}</span><span className='ms-1' >{t("walkThrough.wallet_currency")}</span>
        </div>
      </div>
    </div>
  </div>))}
</div>
     <PaginationPart/>
</div>
</div>
<InfoSection content={content} title={t("walkThrough.manageExpense")} iconSrc={ExpenseIcon} className="expense-content-container"/>

      </div>
    );
  }