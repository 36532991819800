import "./index.scss";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cookies from "js-cookie";
import { fetchCorporateCardsSearch } from "../../pages/Cards/slice/cardListSlice";
import { getCorporateCardsList } from "../../pages/Settings/taxSlice";
import { toast } from "react-toastify";
import { updateCorporateCards } from "../../pages/Settings/taxSlice";
import { useTranslation } from "react-i18next";
import LoadingComponent from "../Loader/LoadingComponent";

function CorporateCards(props) {
  const { connectedIntegration } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { taxData, isLoading } = useSelector((state) => state?.taxSlice);

  const [pageNo, setPageNo] = useState(1);
  const { cardCorporate } = useSelector((state) => state.cardListReducer);
  const { oddoCorporateCardsList } = useSelector((state) => state.taxSlice);

  const onPreviousClick = () => {
    setPageNo((prev) => prev - 1);
  };

  const onNextClick = () => {
    setPageNo((prev) => prev + 1);
  };

  useEffect(() => {
    if (connectedIntegration?.isConnected) {
      dispatch(fetchCorporateCardsSearch(`?page=${pageNo}`));
    }
  }, [connectedIntegration]);

  useEffect(() => {
    dispatch(getCorporateCardsList());
  }, [dispatch]);

  const languages = [
    {
      code: "en",
      name: "English",
      country_code: "gb",
    },
    {
      code: "ar",
      name: "العربية",
      dir: "rtl",
      country_code: "sa",
    },
  ];

  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);

  useEffect(() => {
    document.body.dir = currentLanguage.dir || "ltr";
    document.title = t("app_title");
  }, [currentLanguage, t]);

  const handleCorporateCardsMapping = (e, corporateCardId) => {
    const { value } = e.target;
    const payload = {
      corporateCardIntegrationId: value || "",
      corporateCardId,
    };
    dispatch(updateCorporateCards(payload))
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        setPageNo(1);
        dispatch(fetchCorporateCardsSearch(`?page=${1}`));
      })
      .catch((error) => {
        toast.error(error?.message);
      });
  };

  return (
    <div className="taxcodes">
      <div
        className="d-flex align-items-center justify-content-between"
        style={{ marginBottom: 24 }}
      >
        <h2 className="card-title-black-text ">
          {t("settingstab.list_of_corporate_cards")}
        </h2>
      </div>
      <div style={{position: 'relative'}}>
        {isLoading && <LoadingComponent/>}
        {cardCorporate?.rows?.length > 0 && (
          <>
            <div className="table-responsive p-3 pb-0">
              <table
                id="corporateCardsTable"
                style={{ borderCollapse: "separate", borderSpacing: "0 16px" }}
                cellSpacing="0"
                width="100%"
              >
                <thead>
                  <tr className="table-head">
                    <th scope="col">{t("name")}</th>
                    <th scope="col">{t("settingstab.currency")}</th>
                    <th scope="col">{t("settingstab.card_type_name")}</th>
                    {connectedIntegration?.isConnected && (
                      <th scope="col">{t("settingstab.mapping")}</th>
                    )}
                  </tr>
                </thead>
                <tbody className="table-body">
                  {cardCorporate &&
                    cardCorporate?.rows?.map((corporate) => (
                      <tr
                        className={`${currentLanguageCode === "en"
                          ? "table-body-tr"
                          : "table-body-tr-arabic"
                          }`}
                        key={corporate?.corporateCardId}
                      >
                        <td>{corporate?.accountName}</td>
                        <td>{corporate?.currency}</td>
                        <td>{corporate?.cardTypeName}</td>

                        {connectedIntegration?.isConnected && (
                          <td>
                            <select
                              id={`corporate-select-${corporate?.corporateCardId}`}
                              className="form-select form-control-lg"
                              onChange={(e) => { handleCorporateCardsMapping(e, corporate?.corporateCardId); }}
                              value={corporate?.corporateCardIntegrationId}
                            >
                              <option value="">Select bank</option>
                              {oddoCorporateCardsList?.map((card) => (
                                <option key={card?.id} value={card?.id}>
                                  {card?.name}
                                </option>
                              ))}
                            </select>
                          </td>
                        )}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div
              className="d-flex align-items-center justify-content-between mt-2"
              style={{ width: "100%", borderTop: "0px !imporant" }}
            >
              <nav aria-label="Page navigation example">
                <ul className="pagination my-3 pe-0">
                  <li className="page-item">
                    <button
                      className="btn page-link"
                      disabled={pageNo === 1}
                      onClick={() => onPreviousClick()}
                    >
                      Previous
                    </button>
                  </li>
                  <li className="page-item mx-3">
                    <button
                      className="btn page-link"
                      disabled={
                        pageNo === taxData?.totalPages ||
                        taxData?.totalPages == 0
                      }
                      onClick={() => onNextClick()}
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </nav>
              <div
                className="page-count d-flex align-items-center"
                style={{ color: "#344054" }}
              >
                <h4 className="pagination-textcss">
                  Page {taxData?.currentPage} of {taxData?.totalPages}
                </h4>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default CorporateCards;
