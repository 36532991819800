import React, {  useState } from "react";
import "./customSlider.scss";
import { useNavigate } from "react-router-dom";
import cookies from 'js-cookie';
import CompanyWalletPage from "./CompanyWallet";
import TransactionPage from "./Transactions";
import login_bottom_image from '../../assets/images/login_bottom_image.png';
import ReimbursementPage from "./Reimbursement";
import ExpensePage from "./Expense";
import i18next from 'i18next';
import Logo3 from '../../assets/images/newXpendlessLogo.png';

const languages = [
  {
    code: 'en',
    name: 'English',
    country_code: 'gb',
  },
  {
    code: 'ar',
    name: 'العربية',
    dir: 'rtl',
    country_code: 'sa',
  },
];


export default function WalkThrough() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const navigate = useNavigate();
  
  const currentLanguageCode = cookies.get('i18next') || 'en';


  const handleNavigate = () => {
    navigate('/companyverification');
  };

  function NextBtn(props) {
    const {  onClick, currentSlide, totalSlides } = props;
    const isLastSlide = currentSlide === totalSlides - 1;
    return (
      <div  onClick={isLastSlide ? handleNavigate : onClick}>
        
      <button className="next-btn finish-button" >{currentSlide===3 ? (currentLanguageCode === 'ar' ? "إنهاء" : "Finish") : (currentLanguageCode === 'ar' ? "التالي" : "Next")}
      </button>
      </div>
    );
  }
 const handleNextSlide = () => {
    if (currentSlide === totalSlides - 1) {
      handleNavigate();
    } else {
      setTimeout(() => setCurrentSlide(currentSlide + 1), 50); 
    }
  };
  const settings = {
    dots: false,
    infinite: false,
    speed: 5,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex),
    swipe: false,
    draggable: false
  };

  const totalSlides = 4;

  
  const handleFilterClick = (index, code) => {
    setSelectedIndex(index);
    i18next.changeLanguage(code);
  };
  return (
    <div className="walk-through" style={{ overflowX: 'hidden', maxHeight: '100vh', width: '100vw', overflowY:"auto" }}>
        <div className='d-flex flex-column overflow-hidden onboarding-container' >
      <div className="d-flex flex-column" style={{ overflowY: "auto", flexGrow: 1 }}>
        <div className="d-flex flex-column" style={{ position: 'relative', alignItems: 'flex-start', overflow: "hidden" }}>
          <img style={{ height: 60 }} src={Logo3} alt="Logo" />
        </div>
      {currentSlide === 0 && (
            <>
              <CompanyWalletPage/>
              <div className="btns-container" style={{marginLeft:"15%"}}>
                <div  className="skip-styles mt-2" onClick={handleNavigate}>
                  {currentLanguageCode === 'ar' ? "يتخطى" : "Skip"}
                </div>
                <NextBtn currentSlide={currentSlide} totalSlides={totalSlides} onClick={handleNextSlide} />
              </div>
            </>
          )}
      
      {currentSlide === 1 && (
            <>
              <TransactionPage />
              <div className="btns-container">
                <div onClick={handleNavigate} className="skip-styles mt-2 ">
                  {currentLanguageCode === 'ar' ? "يتخطى" : "Skip"}
                </div>
                <NextBtn currentSlide={currentSlide} totalSlides={totalSlides} onClick={handleNextSlide} />
              </div>
            </>
          )}
           {currentSlide === 2 && (
            <>
              <ReimbursementPage />
              <div className="btns-container" style={{marginRight:"20%"}}>
                <div onClick={handleNavigate} className="skip-styles mt-2 ">
                  {currentLanguageCode === 'ar' ? "يتخطى" : "Skip"}
                </div>
                <NextBtn currentSlide={currentSlide} totalSlides={totalSlides} onClick={handleNextSlide} />
              </div>
            </>
          )}
           {currentSlide === 3 && (
            <>
              <ExpensePage />
              <div className="btns-container finish-btn" style={{marginLeft:"15%", }}>
              
                <NextBtn currentSlide={currentSlide} totalSlides={totalSlides} onClick={handleNavigate} />
              </div>
            </>
          )}
        
</div></div>
    
      <div className='export-dropdown mt-3'>
        <div className='col-lg-2 col-md-2 col-sm-2 language-selector-container'>
                    <div className="language-selector">

            {languages?.map(({ code, name }, index) => (
              <div
                key={index}
                onClick={() => handleFilterClick(index, code)}
                className={`language-option ${selectedIndex === index ? 'selected' : ''}`}
              >
                {name}
              </div>
            ))}
          </div>
        </div>
        </div>
        

         <img className='login_bottom_image-1' src={login_bottom_image} />
      
         <div className="radio-buttons-container " style={{ position: "absolute", bottom: "2%", width: "100%", display: "flex", justifyContent: "center", gap: "15px",zIndex: 1000 }}>
        {Array.from({ length: totalSlides }).map((_, index) => (
          <input
            key={index} 
            type="radio"
            checked={currentSlide === index}
            onChange={() => setCurrentSlide(index)}
            className="radio-button form-check-input"
            style={{ cursor: "pointer" }}
          />
        ))}
      </div>
      </div>
  );
}