import React from 'react';

const InfoSection = ({ title, content, iconSrc,className }) => {
  return (
    <div className={`d-flex flex-column max-w-3xl  ${className}`}>
      <div className="d-flex">
        <img
          src={iconSrc}
          style={{ height: 50, marginRight: "15px", color: "white" }}
          alt="Icon"
          className="align-items-center text-white"
        />
        <h1 className="text-white ml-5 card-title mt-2 align-items-center">{title}</h1>
      </div>
      <div className="mt-4">
        <ul className="list-unstyled custom-list">
        {content.map((text, index) => (
  <li key={index} className="d-flex mb-4">
    <span className="custom-circle mt-1 rounded-circle text-white bg-green d-flex justify-content-center"></span>
    <span className="list-text text-white">{text}</span>
  </li>
))}
        </ul>
      </div>
    </div>
  );
};

export default InfoSection;