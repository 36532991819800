import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CrossImage from '../../assets/images/close-square-8.png';
import { Modal } from 'react-bootstrap';
import cookies from "js-cookie";
import { Stack } from '@mui/material';
import CustomDropdown from '../CustomDropdown/CustomDropdown';
import printer from '../../assets/images/printer.png';

function TermsConditionsModal({ showTerm, handleCloseTermsModal, terms, handleChangeLanguage }) {
  const { t } = useTranslation();
  const currentLanguageCode = cookies.get('i18next') || 'en';
  const termsContainerRef = useRef(null);
  const languageOptions = [
    {
      "label": 'English',
      "value": "en"
    },
    {
      "label": 'العربية',
      "value": "ar"
    },
  ];
  const languages = [
    { code: 'en', name: 'English', country_code: 'gb' },
    { code: 'ar', name: 'العربية', dir: 'rtl', country_code: 'sa' },
  ];
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);

  useEffect(() => {
    const handleKeyDown = (e) => {
      const container = document.querySelector('.bordered-container');
      if (!container) return;

      if (e.key === 'ArrowDown') {
        container.scrollBy({ top: 30, behavior: 'smooth' });
      } else if (e.key === 'ArrowUp') {
        container.scrollBy({ top: -30, behavior: 'smooth' });
      }
    };

    if (showTerm) {
      document.addEventListener('keydown', handleKeyDown);
    } else {
      document.removeEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [showTerm]);

  const handlePrint = () => {
    const printWindow = window.open('', '', 'height=600,width=800');
    printWindow.document.write('<html><head><title>' + t('terms_and_condition') + '</title>');
    printWindow.document.write('</head><body>');
    printWindow.document.write(terms);
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
  };

  return (
    <Modal className='pettyCash-employee-model' show={showTerm} onHide={handleCloseTermsModal} style={{ paddingRight: 0, maxHeight: '610px' }}>
      <Modal.Body style={{ margin: '0px' }}>
        <div
          className='d-flex justify-content-between align-items-start green-header w-100'
          style={{ position: 'sticky', top: '0px' }}
        >
          <div className='col-9'>
            <p className='green-header-text addjust pettyCach-model-title' style={{ fontSize: 16, marginRight: 15 }}>
              {t('terms_and_condition')}
            </p>
          </div>
          <div
            className='clo-2'
            id='close_modal'
            onClick={handleCloseTermsModal}
          >
            <img src={CrossImage} className='small-addjust' style={{ width: 20, objectFit: 'contain', marginLeft: 15 }} />
          </div>
        </div>
        <Stack className='my-1' style={{position: 'sticky', top: '50px', width:'100%', backgroundColor: '#FFFFFF' }}>
          <div style={{ width: '200px'}}>
            <CustomDropdown
              options={languageOptions}
              onChange={handleChangeLanguage}
              selectedOption={'English'}
              placeholder={'Select Language'}
              isSearchHidden={'hide'}
            />
          </div>
        </Stack>
        <div
          ref={termsContainerRef}
          className='bordered-container mt-1 p-2'
          style={{ height: '390px', overflowY: 'scroll', overflowX: 'hidden' }}
        >
          <div dangerouslySetInnerHTML={{ __html: terms }} />
        </div>
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: 'flex-end', position:'sticky', bottom:'0px', backgroundColor:'#FFFF' }}>
        <Stack direction={'row'} spacing={1}>
          <button
            onClick={handleCloseTermsModal}
            className={'btn-secondary-small'}
            id='cancel_terms'
          >
            {t('Close')}
          </button>
          <button className='btn-primary-small' id='print_terms' onClick={handlePrint}>
            <img src={printer} style={{paddingRight: '5px'}} />
            {t('Print')}
          </button>
        </Stack>
      </Modal.Footer>
    </Modal>
  );
}

export default TermsConditionsModal;