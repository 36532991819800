import React, { useState } from 'react';
// import IconButton from '@mui/material/IconButton';
// import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';
import { CChart } from '@coreui/react-chartjs';
import { FaCircle } from "react-icons/fa";
import { useTranslation } from 'react-i18next';
import './DonutChart.scss';
import { dashboardTransactionByVendorColor } from '../../utils/XpendlessUtils';
// import Loader from '../../components/Loader/Loader';
import ImagesCross from '../../assets/images/close-square-8.png';
import { Modal } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import List1 from '../../assets/images/list.png';
import List2 from '../../assets/images/list1.png';
import Dropdown from 'react-bootstrap/Dropdown';


// const options = [
//     { value: 'current_month', label: 'Current Month' },
//     { value: 'current_year', label: 'Current Year' },
//     { value: 'last_3_year', label: 'Last 3 years' },
//     { value: 'custom', label: 'Custom' },
// ];
// const ITEM_HEIGHT = 48;

function DonutChart(props) {
    const { transactionByVendorsLoading, transactionByVendors, handleFilterClick, section, globalSelectedFil, setSelectedFilterDounut, selectedFilterDounut } = props;
    const { t } = useTranslation();
    const options = [
        { value: 'current_month', label: t('dashboard.current_month') },
        { value: 'current_year', label: t('dashboard.current_year') },
        { value: 'last_3_year', label: t('dashboard.last_3_years') },
        { value: 'custom', label: t('dashboard.custom') },
    ];
    // const [anchorEl, setAnchorEl] = useState(null);
    const [show, setShow] = useState(false);

    const schema = yup.object().shape({
        startDate: yup
            .string()
            .required('Start Date is required'),
        endDate: yup
            .string()
            .required('End Date is required')
            .test('is-valid-end-date', 'End date should not be before start date', function (value) {
                const startDate = this.resolve(yup.ref('startDate'));
                return !startDate || !value || value >= startDate;
            }),
    });

    const { control, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });

    // const open = Boolean(anchorEl);
    // const handleClick = (event) => {
    //     setAnchorEl(event.currentTarget);
    // };
    const handleClose = () => setShow(false);

    // const handleCloseMenu = (option) => {
    //     if (option === 'current_month') {
    //         setSelectedFilterDounut('Current Month')
    //     }
    //     if (option === 'current_year') {
    //         setSelectedFilterDounut('Current Year')
    //     }

    //     if (option === 'last_3_year') {
    //         setSelectedFilterDounut('Last 3 Years')
    //     }

    //     setAnchorEl(null);
    //     if (option != '' && typeof option === 'string') {
    //         if (option === 'custom') {
    //             setShow(true)
    //         } else {
    //             handleFilterClick(section, option, {}, true);
    //         }
    //     }
    // };
    const handleCloseMenu = (selectedOption) => {
        if (selectedOption) {
            const { value, label } = selectedOption;
            setSelectedFilterDounut(label);

            if (value === 'custom') {
                setShow(true);
            } else {
                handleFilterClick(section, value, {}, true);
            }
        }

        // setAnchorEl(null);
    };


    const onSubmit = (data) => {
        setSelectedFilterDounut(`${moment(data?.startDate).format('DD/MM/YYYY')} - ${moment(data?.endDate).format('DD/MM/YYYY')}`);
        handleFilterClick(section, 'custom', data, true);
        setShow(false);
    };
    return (
        <div className="p-3 position-relative donutchatPage-Css">
            {transactionByVendorsLoading && <div className="dashboardloader-container" style={{ height: '100%', width: '100%', left: 0, top: 0 }}>
                <div className="spinner-border text-light" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>}
            <div className='d-flex align-items-center justify-content-between green-header'>
                {/* <img src={List2} className='' /> */}
                <div className='d-flex align-items-center justify-content-center'>
                    <img src={List2} width={32} style={{ objectFit: 'contain' }} className='mx-2' />
                    <h2 className='green-header-text mb-0'>{t('dashboard.transactions')}{t('dashboard.by_vendors')}</h2>
                    {/* <h4 className='text-green'></h4> */}
                </div>
                <div className='d-flex align-items-center'>
                    <p style={{ fontSize: '12px' }} className='text-grey mb-0' >{selectedFilterDounut !== null ? selectedFilterDounut : globalSelectedFil}</p>
                    {/* <IconButton
                        className='color-text'
                        aria-label="more"
                        id="long-button"
                        aria-controls={open ? 'long-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={handleClick}
                    >
                        <img src={List1} className='option-image' />
                    </IconButton> */}
                    {/* <Menu
                        id="long-menu"
                        MenuListProps={{
                            'aria-labelledby': 'long-button',
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleCloseMenu}
                        PaperProps={{
                            style: {
                                maxHeight: ITEM_HEIGHT * 4.5,
                                width: '20ch',
                            },
                        }}
                    >
                        {options?.map((option, index) => (
                            <MenuItem key={index} onClick={() => { handleCloseMenu(option.value); console.log('option.value', option.value) }}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Menu> */}
                    <Dropdown>
                        <Dropdown.Toggle id="dropdown-basic">
                            <img src={List1} style={{ objectFit: 'contain' }} className='option-image' alt="Options" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {options.map((option, index) => (
                                <Dropdown.Item key={index} onClick={() => handleCloseMenu(option)}>
                                    {option.label}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
            <hr />
            {transactionByVendors && transactionByVendors.length < 1 &&
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {!transactionByVendorsLoading && 'No data'}
                </div>
            }
            {transactionByVendors && transactionByVendors.length > 0 &&
                <CChart className='doughnut-chart-dashboard'
                    type="doughnut"
                    data={{
                        datasets: [
                            {
                                backgroundColor: dashboardTransactionByVendorColor,
                                data: transactionByVendors?.map((item) => {
                                    return item?.totalAmount || 0;
                                }),
                            },
                        ],
                        labels: transactionByVendors?.map((item) => item?.merchantName),
                    }}
                    options={{
                        plugins: {
                            legend: {
                                display: false,
                                labels: {
                                    // color: getStyle('--cui-body-color'),
                                }
                            },
                            tooltip: {
                                enabled: true,
                                callbacks: {
                                    label: (tooltipItem) => {
                                        const label = tooltipItem?.label;
                                        const value = tooltipItem?.parsed;
                                        return `${label}: ${value} QAR`;
                                    },
                                },
                            },
                        },
                    }}
                />
            }
            <div className="row g-2 mt-3">

                {transactionByVendors?.map((item, index) => (
                    <div className="col-6" key={index}>
                        <div className="d-flex align-items-center">
                            <FaCircle size={8} color={dashboardTransactionByVendorColor[index]} />
                            <h4 className='text-grey' style={{ marginLeft: 5, marginRight: 5 }}>{item.merchantName}</h4>
                        </div>
                    </div>
                ))}

                {/* <div className="col-6">
                    <div className="d-flex align-items-center">
                        <FaCircle size={8} color='#2E90FA' />
                        <h4 className='text-grey' style={{ marginLeft: 5,marginRight: 5 }}>{t('dashboard.amirates_airline')}</h4>
                    </div>
                </div>
                <div className="col-6">
                    <div className="d-flex align-items-center">
                        <FaCircle size={8} color='#53B1FD' />
                        <h4 className='text-grey' style={{ marginLeft: 5,marginRight: 5 }}>{t('dashboard.google')}</h4>
                    </div>
                </div>
                <div className="col-6">
                    <div className="d-flex align-items-center">
                        <FaCircle size={8} color='#D1E9FF' />
                        <h4 className='text-grey' style={{ marginLeft: 5,marginRight: 5 }}>{t('dashboard.apple')}</h4>
                    </div>
                </div> */}
            </div>
            <Modal className='TaxcodeModalOne' show={show} onHide={handleClose} style={{ paddingRight: 0 }}>
                <form className='form' onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Body>
                        <div className='d-flex justify-content-between align-items-start green-header p-2'>
                            <div className='col-9'>
                                <p className='text-green modal-title' style={{ fontSize: 18 }}>
                                    Select Custom Date
                                </p>
                            </div>
                            <div onClick={handleClose} className='clo-2' style={{ cursor: 'pointer' }}>
                                <img src={ImagesCross} style={{ width: 20, objectFit: 'contain' }} />
                            </div>
                        </div>
                        <div className="form-outline my-3">
                            <label className="form-label" htmlFor="startDate">
                                Start Date
                            </label>

                            <Controller
                                name="startDate"
                                control={control}
                                defaultValue=''
                                render={({ field }) => (
                                    <input
                                        type="date"
                                        id="startDate"
                                        className="form-control form-control-lg"
                                        placeholder="Start Date"
                                        autoComplete="off"
                                        {...field}
                                    />
                                )}
                            />
                            {errors?.startDate && (
                                <div style={{ color: 'red' }}>{errors?.startDate?.message}</div>
                            )}
                        </div>
                        <div className="form-outline my-3">
                            <label className="form-label" htmlFor="endDate">
                                End Date
                            </label>

                            <Controller
                                name="endDate"
                                control={control}
                                defaultValue=''
                                render={({ field }) => (
                                    <input
                                        type="date"
                                        id="endDate"
                                        className="form-control form-control-lg"
                                        placeholder="Start Date"
                                        autoComplete="off"
                                        {...field}
                                    />
                                )}
                            />
                            {errors?.endDate && (
                                <div style={{ color: 'red' }}>{errors?.endDate?.message}</div>
                            )}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button onClick={handleClose} className='btn-secondary-small' id='cancel_custom_date'>{t('cancel')}</button>
                        <button className='btn-primary-small' type='submit' id='submit_custom_date'>{t('submit')}</button>
                    </Modal.Footer>
                </form>
            </Modal>
        </div>
    );
}

export default DonutChart;
