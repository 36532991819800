import React from 'react';
import { Navigate, useLocation } from "react-router-dom";

const WithAuthCheck = ({
    component: Component,
}) => {
    const location = useLocation();

    const token = localStorage.getItem('token');
    const hasAccess = localStorage.getItem('hasAccess');

    if (token) {
        if (hasAccess === 'true')
            return <Navigate to="/companyverification" replace state={{ from: location.pathname }} />;
        else
            return <Navigate to="/expenses" replace state={{ from: location.pathname }} />;
    } else {
        return <Component />;
    }
};

export default WithAuthCheck;
