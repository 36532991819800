import Transaction from '../../assets/images/transaction.png';
import AccountIcon from '../../assets/images/Accounts_icon.png';
import React from 'react'
import { useTranslation } from 'react-i18next';
import InfoSection from './ContentSection';
import PaginationPart from './PaginationPart';
import List from '../../assets/images/list.png';

export default function CompanyWalletPage() {
    const { t } = useTranslation();

const content = [
  t("walkThrough.wallet_content_1"),
  t("walkThrough.wallet_content_2"),
  t("walkThrough.wallet_content_3"),
    ]


    
  return (

<div>
  <div className="mt-3">            
    <button className='text-green amount-box'>
      <span className='text-white amount'>{t("walkThrough.wallet_amount")}.</span><span className='mt-5 ms-1' style={{fontSize:"12px", color:"white", }}>{t("walkThrough.wallet_currency")}</span>
    </button>
  </div>

  <div className="d-flex align-items-center  flex-wrap ">
      <button
        style={{ zIndex: 2 , fontWeight: 500}}
        className="btn-success-green cursor-pointer mt-3"
      >
      {t("walkThrough.add_funds")}
      </button>
      <div className="pe-3"></div>
      <button
        style={{ zIndex: 2,fontWeight: 500 }}
        className="btn-danger cursor-pointer mt-3"
      >
              {t("walkThrough.withdraw_funds")}
      </button>
    </div>
   

    <div className="amount-table-container mt-4 col-lg-6 col-md-12 " >

    <div className='amount-table  position-relative' style={{overflowX:"auto",}}>

    <div className='d-flex align-items-center justify-content-between mb-2 green-header p-2'>
      <div className='d-flex align-items-center'>
        <img src={Transaction} style={{ width: 35 }} className='small-img-size1 me-2' />
        <span className='text-green table-title'>              {t("walkThrough.transaction_msg")}        </span>
    </div>                    <img src={List} width={15} height={15} style={{ objectFit: 'contain' }} className='mx-2' />
    </div>
    <div className='d-flex align-items-center justify-content-between p-2 mb-3'>
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          value=""
          id="checkAllPages"
         
        />
        <label className="form-check-label select-label ms-1 " htmlFor="checkAllPages">
          {t("export.select_all")}
        </label>
      </div>
      <button  id='export_wallet' style={{ borderRadius: 20, backgroundColor: "green",  }} className='btn btn-primary btn-sm export-btn-style'>  
                {t('export.export')}

      </button>        

    </div>
<div className='table-responsive border-zero pb-2'>
<table id="masterAccountTransactions" className="text-xs" style={{ borderCollapse: 'separate', borderSpacing: '0 16px' }} cellSpacing="2" width="100%">
  <thead>
    <tr style={{ borderTopWidth: 0 ,}} className="table-head">
      <th scope="col "></th>
      <th scope="col">{t("cards.no")}</th>
      <th scope="col">{t("cards.date_and_time")}</th>
      <th scope="col">{t("accounts.type_of_transaction")}</th>
      <th scope="col" className="text-end">
        {t("settingstab.Amount")}
      </th>
      <th scope="col"></th>

    </tr>
  </thead>
  <tbody>
    {Array.from({ length: 2 }).map((_, index) => (
      <tr
        key={index}
      >
        <td>
          <input className="form-check-input custom-checkbox m-2" type="checkbox" value="" checked={true} />
        </td>
        <td> {t("walkThrough.transaction_id")} </td>
        <td>{t("walkThrough.date_time")}</td>
        <td>{t("walkThrough.transaction_type")}</td>
        <td className={index % 2 === 0 ? "amount-green text-end" : "text-red text-end"}>
         {t("walkThrough.wallet_amount")}
             </td>

        <td className='view-text' >{t("walkThrough.view_text")}</td>

      </tr>
    ))}
  </tbody>
</table>

  </div> 
    <PaginationPart/>
    
     </div></div> 

 <div style={{ left: "30%"}}>
  <InfoSection content={content} title={t("walkThrough.wallet")} iconSrc={AccountIcon} className="wallet-content-container"/>
  </div>
  </div>
  )
}