/* eslint-disable react/react-in-jsx-scope */
import Dashboard from "../pages/Dashboard/Dashboard";
import Login from "../pages/Login/Login";
import OtpVerification from "../pages/Login/OtpVerification";
import SignUp from "../pages/Login/SignUp";
import EmailVerifictaion from "../pages/Login/EmailVerifictaion";
import CompanyEmployeeCounting from "../pages/Login/CompanyEmployeeCounting";
import SetPasscode from "../pages/Login/SetPasscode";
import ConfirmPasscode from "../pages/Login/ConfirmPasscode";
import MobileNumber from "../pages/Login/MobileNumber";
import NumberVerification from "../pages/Login/NumberVerification";
import SearchCompany from "../pages/Login/SearchCompany";
import SearchCompanyResult from "../pages/Login/SearchCompanyResult";
import Settings from "../pages/Settings/Settings";
import Teams from "../pages/Teams/Teams";
import Users from "../pages/Users/Users";
import MainCompany from "../pages/Teams/MainCompany";
import TeamDetails from "../pages/Teams/TeamDetails";
import Cards from "../pages/Cards/Cards";
import SingleCardDetails from "../pages/Cards/SingleCardDetails";
import Export from "../pages/Export/Export";
import Accounts from "../pages/Accounts/Accounts";
import Expenses from "../pages/Expenses/Expenses";
import Reimbursement from "../pages/Reimbursement/Reimbursement";
import CompanyVerification from "../pages/CompanyVerification/CompanyVerification";
import Analytics from "../pages/Analytics/Analytics";
import PrivateRoute from "./privateRoute";
import TopUpRequest from "../pages/TopUpRequest/TopUpRequest";
import ForgotPassword from "../pages/Login/ForgotPassword";
import ExpensesDetails from "../components/Expenses/ExpensesDetails";
import InvitationCode from "../pages/Login/InvitationCode";
import AuditLogViewer from "../pages/AuditLogViewer/AuditLogViewer";
import WalkThrough from "../pages/WalkThrough/WalkThrough";
import PettyCash from "../pages/PettyCash/PettyCash";
import CorporateCards from "../pages/Cards/CorporateCards";
import CorporateExpenses from "../pages/Expenses/CorporateExpenses";
import WithAuthCheck from "./withAuthCheck";
import ReimbursementDetails from "../components/Expenses/ReimbursementDetails";
import Subscription from "../pages/ChooseSubscription/Subscription";
import { activeBlocked } from "./verifyCompanyStatus";



export const loginRoutes = [
  {
    path: "/login",
    exact: true,
    element: <Login />,
  },
  {
    path: "/otpVerification",
    exact: true,
    element: <OtpVerification />,
  },
  {
    path: "/forgotPassword",
    exact: true,
    element: <ForgotPassword />,
  },
  {
    path: "/walkThrough",
    exact: true,
    element: <WalkThrough />,
  },
  {
    path: "/subscription",
    exact: true,
    element:  <Subscription />,
  },
];

export const signupRoutes = [
  {
    path: "/signUp",
    exact: true,
    element: <WithAuthCheck component={SignUp} />,
  },
  {
    path: "/invitationCode",
    exact: true,
    element: <WithAuthCheck component={InvitationCode} />,
  },
  {
    path: "/emailVerification",
    exact: true,
    element: <WithAuthCheck component={EmailVerifictaion} />,
  },
  {
    path: "/companyEmployeeCounting",
    exact: true,
    element: <WithAuthCheck component={CompanyEmployeeCounting} />,
  },
  {
    path: "/setPassword",
    exact: true,
    element: <WithAuthCheck component={SetPasscode} />,
  },
  {
    path: "/confirmPassword",
    exact: true,
    element: <WithAuthCheck component={ConfirmPasscode} />,
  },
  {
    path: "/mobileNumber",
    exact: true,
    element: <WithAuthCheck component={MobileNumber} />,
  },
  {
    path: "/numberVerification",
    exact: true,
    element: <WithAuthCheck component={NumberVerification} />,
  },
  {
    path: "/searchCompany",
    exact: true,
    element: <WithAuthCheck component={SearchCompany} />,
  },
  {
    path: "/searchCompanyResult",
    exact: true,
    element: <WithAuthCheck component={SearchCompanyResult} />,
  }, 
];

export const routes = [
  {
    path: "/",
    exact: true,
    element: <PrivateRoute component={Dashboard} allowedCompanyStatus={activeBlocked} />,
  },
  {
    path: "/settings",
    exact: true,
    element: <PrivateRoute component={Settings} allowedCompanyStatus={activeBlocked} />,
  },
  {
    path: "/users",
    exact: true,
    element: <PrivateRoute component={Users} allowedCompanyStatus={activeBlocked} />,
  },
  {
    path: "/teams",
    exact: true,
    element: <PrivateRoute component={Teams} allowedCompanyStatus={activeBlocked} />,
  },
  {
    path: "/team/maincompany/:departmentName/:departmentId",
    exact: true,
    element: <PrivateRoute component={MainCompany} allowedCompanyStatus={activeBlocked} />,
  },
  {
    path: "/team/team-details/:employeeId",
    exact: true,
    element: <PrivateRoute component={TeamDetails} />,
  },
  {
    path: "/xpendlessCards",
    exact: true,
    element: <PrivateRoute component={Cards} allowedCompanyStatus={activeBlocked} />,
  },
  {
    path: "/corporateCards",
    exact: true,
    element: <PrivateRoute component={CorporateCards} />,
  },
  {
    path: "/card/card-details/:employeeId",
    exact: true,
    element: <PrivateRoute component={SingleCardDetails} />,
  },
  {
    path: "/export",
    exact: true,
    element: <PrivateRoute component={Export} allowedCompanyStatus={activeBlocked} />,
  },
  {
    path: "/account",
    exact: true,
    element: <PrivateRoute component={Accounts} allowedCompanyStatus={activeBlocked} />,
  },
  {
    path: "/pettyCash",
    exact: true,
    element: <PrivateRoute component={PettyCash} allowedCompanyStatus={activeBlocked} />,
  },
  {
    path: "/expenses",
    exact: true,
    element: <PrivateRoute component={Expenses} allowedCompanyStatus={activeBlocked} />,
  },
  {
    path: "/corporateExpenses",
    exact: true,
    element: <PrivateRoute component={CorporateExpenses} />,
  },
  {
    path: "/reimbursement",
    exact: true,
    element: <PrivateRoute component={Reimbursement} allowedCompanyStatus={activeBlocked} />,
  },
  {
    path: "/analytics",
    exact: true,
    element: <PrivateRoute component={Analytics} />,
  },
  {
    path: "/companyverification",
    exact: true,
    element: <PrivateRoute component={CompanyVerification} />,
  },
  {
    path: "/topuprequest",
    exact: true,
    element: <PrivateRoute component={TopUpRequest} allowedCompanyStatus={activeBlocked} />,
  },
  {
    path: "/expenses/expense-details/:transactionId",
    exact: true,
    element: <PrivateRoute component={ExpensesDetails} />,
  },
  {
    path: "/reimbursement/reimbursement-details/:transactionId",
    exact: true,
    element: <PrivateRoute component={ReimbursementDetails} />,
  },
  {
    path: "/audit-log-viewer",
    exact: true,
    element: <PrivateRoute component={AuditLogViewer} />,
  },
];

