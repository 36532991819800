import React from 'react'
import PhysicalCard from "../../assets/images/physical_card.png";
import VirtualCard from "../../assets/images/virtual_card.png";
import XpendlessLeftCurveCard from "../../assets/images/xpendless_left_curve_card.png"
import XpendlessRightCurveCard from "../../assets/images/xpendless_right_curve_card.png"
import InfoSection from './ContentSection';
import { useTranslation } from 'react-i18next';
import CardIcon from '../../assets/images/Card-icon.png';

export default function TransactionPage() {

  const {t} = useTranslation()

  const content = [
    t("walkThrough.transaction_content_1"),
    t("walkThrough.transaction_content_2")
  ];
  
    return (
        <div className="d-flex flex-column" style={{ position: 'relative', alignItems: 'flex-start' , overflowX: "hidden"}}>
       
  
          <div className="xpendless-card-container mt-5">
            <img
              src={XpendlessRightCurveCard}
              alt="Xpendless Card"
              style={{
                borderRadius: '20px' ,  width: '500px', 
              }}
                   className="w-72 h-50 rounded-2xl"
            />
            <img
              src={XpendlessLeftCurveCard}
              alt="Xpendless Card"
              style={{
                position:'absolute',
                zIndex: 999,
                bottom: 90,  
                left: 10  , width: '540px',
             
              }}
              className="w-76 h-62 rounded-2xl"
            />
          </div>
  
          <div className="d-flex align-items-center  flex-wrap text-white card-selection-container relative" style={{ gap: "25px" , zIndex:50}}>

            <div className="d-flex align-items-center">
            <input className="form-check-input m-2 custom-radio" type="radio" value="physical" checked={true}/>
            <img
              src={PhysicalCard}
              alt="Physical Card Icon"
              className="mx-3"
            
            />
            <span>{t("walkThrough.physical_card")}</span>
           </div>
          <div className="d-flex align-items-center ml-5">

            <input className="form-check-input m-2 custom-radio" type="radio" value="virtual" />
            <img
              src={VirtualCard}
              alt="Virtual Card Icon"
              className="mx-3"
            />
            <span>{t("walkThrough.virtual_card")}</span>
          </div>
          </div>
          <InfoSection content={content} title={t("walkThrough.createAndManageCards")} iconSrc={CardIcon} className="transaction-content-container"/>
        </div>
    );
  }