import React from "react";
import WhiteLogo from "../../assets/images/newXpendlessLogo.png";
import Chip from "../../assets/images/chip.png";
import PayPassIconNew from "../../assets/images/PayPass_icon_new.png";
import MastercardLogo from "../../assets/images/mastercard_logo.png";
import card from "../../assets/images/cards.png";
import "./DebitCard.scss";
import { useSelector } from "react-redux";
const moment = require("moment");

const maskCardNumber = (cardNumber, showUnmasked) => {
  const digitsOnly = cardNumber.replace(/\D/g, "");
  const masked =
    digitsOnly.slice(0, -4).replace(/\d/g, "*") + digitsOnly.slice(-4);
  const maskedCardNumber = masked.replace(/(.{4})/g, "$1 ").trim();
  return typeof showUnmasked !== "undefined" && showUnmasked
    ? cardNumber
    : maskedCardNumber;
};

const isMaskedFormat = (cardToken) => {
  return cardToken.includes("*");
};

const maskCVVNumber = (cardCvv, showUnmasked) => {
  if (typeof cardCvv !== "string") {
    cardCvv = String(cardCvv);
  }

  const digitsOnly = cardCvv.replace(/\D/g, "");
  const maskedCVVNumber = digitsOnly.replace(/\d/g, "*");
  return typeof showUnmasked !== "undefined" && showUnmasked
    ? cardCvv
    : maskedCVVNumber;
};

function DebitCard(props) {
  const {
    detail,
    handleCardFreezUnfreezAction = () => { },
    freezeDisabled = false,
  } = props;
  const { loggedInUser } = useSelector((state) => state.userReducer);

  const cardImage = card;

  return (
    <div style={{ display: "flex", justifyContent: "center", padding: "10" }}>
      <div
        dir="ltr"
        className="debit-card"
        style={{
          backgroundImage: `url(${cardImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          marginLeft: 4,
          width: 350,
        }}
      >
        <div
          dir="ltr"
          className="d-flex justify-content-between align-items-start"
        >
          <img src={WhiteLogo} style={{ width: 87.16, objectFit: "contain" }} />
          <p className="text-white" style={{ fontSize: 11, fontWeight: "300" }}>
            <span>{detail?.cardType ? detail.cardType.charAt(0).toUpperCase() + detail.cardType.slice(1) : ''}</span>

          </p>
        </div>
        <div className="col-12 d-flex text-end align-items-center justify-content-between">
          <div className="d-flex">
            <img
              src={Chip}
              style={{ width: 33.04, objectFit: "contain", marginRight: 20 }}
            />
            {/**    <div className="form-check form-switch ">
                        <input
                            id={`${detail?.isFreezed}_${detail?.cardId}`}
                            disabled={freezeDisabled}
                            className="form-check-input"
                            type="checkbox"
                            checked={detail?.isFreezed}
                            onChange={() => handleCardFreezUnfreezAction(!detail?.isFreezed, detail?.cardId)}
                        />

                        <label htmlFor={`${detail?.isFreezed}_${detail?.cardId}`} className="form-check-label text-white" >Freeze card</label>
                    </div> **/}
          </div>
          <img
            src={PayPassIconNew}
            style={{ width: 13.13, objectFit: "contain" }}
          />
        </div>
        {/**   <div className='d-flex justify-content-end' >
                <div className='d-flex align-items-center cvv-bg'>
                    <div className='text-white' style={{ fontSize: 10, marginRight: detail?.cvv ? 10 : 0 }}>CVV</div>
                     <p className='text-white mb-0 debit-number' style={{ fontSize: 12, fontWeight: '700' }}>{(detail?.cvv) ? maskCVVNumber(detail.cvv, detail?.maskCardNumber) : ''}</p>
                </div>
            </div> **/}
        <div className="d-flex justify-content-between align-items-center ">
          <div className="col-7">
            <div className="d-flex justify-content-between align-items-center">
              <p className="text-white mb-0" style={{ fontSize: 12 }}>
                {detail?.firstName ? detail?.firstName : loggedInUser.firstName}{" "}
                {detail?.lastName ? detail?.lastName : loggedInUser.lastName}
              </p>
              <p className="text-white mb-0" style={{ fontSize: 12 }}>
                {moment(detail?.expiryDate).format("MM/DD")}
              </p>
            </div>
            <p className="text-white mb-0 debit-number">
              {detail?.cardToken
                ? isMaskedFormat(detail.cardToken)
                  ? detail.cardToken
                  : maskCardNumber(detail.cardToken, detail.maskCardNumber)
                : ""}
            </p>
          </div>
          <div className="">
            <div
              className="col-12 d-flex text-end align-items-start"
              style={{ alignSelf: "flex-end" }}
            >
              <img
                src={MastercardLogo}
                style={{ width: 45.04, objectFit: "contain" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DebitCard;
