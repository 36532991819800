import "./index.scss";

import { Card, Modal } from "react-bootstrap";
import React, { memo, useEffect, useState } from "react";
import {
  connectIntegration,
  getCompany,
  getConnectedIntegration,
  getExpenseCategory,
  getJournals,
  updateData,
} from "../../pages/Settings/taxSlice";

import LoadingComponent from "../Loader/LoadingComponent";
import cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { active, activeBlocked, verifyCompanyStatus } from "../../utils/verifyCompanyStatus";
import { allowedUserRoles, verifyUserRole } from "../../utils/verifyUserRole";
import DangerButton from "../Buttons/DangerButton";
import SecondaryButton from "../Buttons/SecondaryButton";
import PrimaryButton from "../Buttons/PrimaryButton";
const languages = [
  {
    code: "en",
    name: "English",
    country_code: "gb",
  },
  {
    code: "ar",
    name: "العربية",
    dir: "rtl",
    country_code: "sa",
  },
];

function Mapping(props) {
  const {
    setAccountingTabs,
    handleDisconnect,
    connectedIntegration,
  } = props;
  const { t } = useTranslation();

  const { isLoading } = useSelector((state) => state?.taxSlice);
  const [showDisconnectModal, setShowDisconnectModal] = useState(false);
  const [showReconnectModal, setShowReconnectModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [connectionStatus, setConnectionStatus] = useState("connected");
  const [loading, setLoading] = useState(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const {
    oddoCompanyList,
    oddoJournalsList,
    oddoExpenseCategory,
    oddoReimbursementJournalsList,
  } = useSelector((state) => state?.taxSlice);
  const [url, setUrl] = useState("");
  const [db, setDb] = useState("");
  const dispatch = useDispatch();
  const {
    register: connectionRegister,
    handleSubmit: connectionHandleSubmit,
    formState: { errors: connectionErrors },

  } = useForm();
  useEffect(() => {
    dispatch(getJournals());
    dispatch(getCompany());
  }, []);

  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);

  useEffect(() => {
    document.body.dir = currentLanguage.dir || "ltr";
    document.title = t("app_title");
  }, [currentLanguage, t]);

  const handleStatusChange = (e) => {
    const value = e.target.value;
    setConnectionStatus(value);

    if (value === "disconnected") {
      setShowDisconnectModal(true);
    } else if (value === "reconnecting") {
      setShowReconnectModal(true);
    } else {
      handleDisconnect();
    }
  };
  useEffect(() => {
    if (connectedIntegration && connectedIntegration.configData) {
      const { url, db } = connectedIntegration.configData;
      setUrl(url || "");
      setDb(db || "");
    }
  }, [connectedIntegration]);

  const integrationName = connectedIntegration?.name || "";

  const confirmDisconnect = () => {
    if (inputValue === integrationName) {
      handleDisconnect();
      setShowDisconnectModal(false);
    } else {
      toast.error("Please enter correct integration name");
    }
  };

  const handleConnectionSubmit = (data) => {
    if (!connectedIntegration?.accountIntegrationId) {
      toast.error("Account Integration ID is missing");
      return;
    }

    const payload = {
      accountIntegrationId: connectedIntegration?.accountIntegrationId,
      configData: {
        ...data,
        url: data.url,
        db: data.db,
      },
    };
    setLoading(true);
    dispatch(connectIntegration(payload))
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        setConnectionStatus("connected");
        setShowReconnectModal(false);
        if (verifyCompanyStatus(active) && verifyUserRole(allowedUserRoles.adminAndAccountant)) {
          dispatch(getConnectedIntegration());
        }
      })
      .catch((error) => {
        toast.error(error?.message);
      })
      .finally(() => setLoading(false));
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleCancel = () => {
    setConnectionStatus("connected");
    setShowDisconnectModal(false);
    setShowReconnectModal(false);
  };

  const handleUpdateData = (e, reload = false) => {
    const { id, value } = e.target;
    let { data = {}, companyIntegrationId } = connectedIntegration;
    data = { ...data };
    data[id] = value || "";
    const payload = {
      companyIntegrationId: companyIntegrationId,
      data,
    };
    dispatch(updateData(payload))
      .unwrap()
      .then((res) => {
        setSelectedCompanyId(null);
        if (reload) {
          const currentUrl = new URL(window.location.href);
          currentUrl.searchParams.set("tab", "2");
          window.location.href = currentUrl.toString();
        }
      })
      .catch((error) => {
        setSelectedCompanyId(null);
        console.log(error?.message);
      });
  };

  const handleCompanyChange = (e) => {
    if (!connectedIntegration?.data?.company) {
      handleUpdateData(e, true);
    } else {
      setSelectedCompanyId(e.target.value);
      setShowModal(true);
    }
  };

  const handleClose = () => {
    setShowReconnectModal(false);
  };

  return (
    <div className="taxcodes">
      <div className="">
        <h2 className="card-title-black-text">
          {t("settingstab.application")}
        </h2>
      </div>
      <Card
        className={`setup-card text-center`}
        style={{ position: 'relative' }}
        id="setupCard"
      >
        {isLoading && <LoadingComponent />}{" "}
        <Card.Body>
          <div className="icon-container">
            <img
              src={connectedIntegration?.logoUrl}
              alt={"integration"?.name}
              className="card-icon"
            />
          </div>
          <Card.Title>{connectedIntegration?.name}</Card.Title>
          <Card.Text>{connectedIntegration?.shortDescription}</Card.Text>
        </Card.Body>
      </Card>
      <div className="d-flex justify-content-center">
        <div className="col-lg-2 col-md-6 col-sm-12 form-outline mb-3" style={{ position: 'relative' }}>
          {isLoading && <LoadingComponent />}
          <select
            className="form-select form-control-lg"
            aria-label="Default select example"
            onChange={handleStatusChange}
            value={connectionStatus}
            id="selectIntegration"
          >
            {" "}
            <option value="connected"> Connected</option>
            <option value="disconnected"> Disconnected</option>
            <option value="reconnecting"> Reconnect</option>
          </select>
        </div>
        <Modal centered show={showDisconnectModal} onHide={handleCancel} style={{ zIndex: 1000000 }}>
          <Modal.Header closeButton={handleCancel}>
            <Modal.Title>Are you sure you want to disconnect?</Modal.Title>{" "}
          </Modal.Header>
          <Modal.Body className="">
            <div className="px-3">
              <div className="text-red">
                <strong>{t("settingstab.warning")}:</strong>{" "}
                {t("settingstab.disconnect_paragrapgh")}
              </div>
              <div className="my-3">
                <label className="form-label">
                  {t("settingstab.to_confirm")}
                  <strong>{` "${integrationName}"`}</strong>
                  {t("settingstab.below")}
                </label>
                <input
                  className="form-control form-control-lg highlighted-input"
                  value={inputValue}
                  onChange={handleInputChange}
                />
              </div>
              <div className="d-flex justify-content-center my-3">
                <DangerButton
                  type={'button'}
                  onClick={confirmDisconnect}
                  buttonText={t("settingstab.disconnect_integration")}
                  id={'confirm_disconnect'}
                  style={{ marginBlock: '16px' }}
                />
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/***     <Modal centered show={showReconnectModal} onHide={handleCancel}>
          <Modal.Header closeButton={handleCancel}>
            <Modal.Title>Are you sure you want to disconnect?</Modal.Title>{" "}
          </Modal.Header>
          <Modal.Body className="d-flex justify-content-center">
            <div className="">
              <label className="form-labels" htmlFor="searchName">
                {t("settingstab.url")}
              </label>

              <input
                type="text"
                id="url"
                className="form-control form-control-lg"
                placeholder={t("settingstab.url_placeholder")}
                autoComplete="off"
              />
            </div>
          </Modal.Body>
        </Modal> */}

        <Modal
          centered
          id="integrationModal"
          backdrop="static"
          show={showReconnectModal}
          onHide={handleCancel}
          style={{ paddingRight: 0 }}
        >
          <Modal.Body>
            <div
              className="d-flex align-items-center justify-content-between px-3 p-2"
              style={{ marginBottom: 8 }}
            >
              <div
                onClick={() => { }}
                className="clo-2"
                style={{ cursor: "pointer" }}
              ></div>
            </div>

            <div className="p-3 mt-4">
              <div>
                <div className="d-flex justify-content-center  mb-3">
                  <div className="col-lg-10 col-md-6 col-sm-12 form-outline mt-4 ">
                    <label className="form-labels" htmlFor="searchName">
                      {t("settingstab.url")}
                    </label>

                    <input
                      type="text"
                      id="url"
                      value={url}
                      // disabled
                      className={`form-control form-control-lg ${connectionErrors?.url ? "is-invalid" : ""
                        }`}
                      placeholder={t("settingstab.url_placeholder")}
                      autoComplete="off"
                    // {...connectionRegister("url", {
                    //   required: `${t("settingstab.enter_url")}`,
                    // })}
                    />
                    {connectionErrors?.url && (
                      <div className="invalid-feedback">
                        {connectionErrors?.url?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  <div className=" col-lg-10 col-md-6 col-sm-12 form-outline ">
                    <label className="form-labels" htmlFor="searchName">
                      {t("settingstab.database_name")}
                    </label>

                    <input
                      type="text"
                      id="db"
                      value={db}
                      // disabled
                      className={`form-control form-control-lg ${connectionErrors?.db ? "is-invalid" : ""
                        }`}
                      placeholder="Enter the database name"
                      autoComplete="off"
                    // {...connectionRegister("db", {
                    //   required: `${t("settingstab.enter_database_name")}`,
                    // })}
                    />
                    {connectionErrors?.db && (
                      <div className="invalid-feedback">
                        {connectionErrors?.db?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  <div className=" col-lg-10 col-md-6 col-sm-12 form-outline ">
                    <label className="form-labels" htmlFor="searchName">
                      {t("settingstab.username")}
                    </label>

                    <input
                      type="text"
                      id="username"
                      className={`form-control form-control-lg ${connectionErrors?.username ? "is-invalid" : ""
                        }`}
                      placeholder="***********************"
                      autoComplete="off"
                      {...connectionRegister("username", {
                        required: `${t("settingstab.enter_username")}`,
                      })}
                    />
                    {connectionErrors?.username && (
                      <div className="invalid-feedback">
                        {connectionErrors?.username?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  <div className="col-lg-10 col-md-6 col-sm-12 form-outline mt-4 ">
                    <label className="form-labels" htmlFor="searchName">
                      {t("settingstab.password")}
                    </label>

                    <input
                      type="password"
                      id="password"
                      className={`form-control form-control-lg ${connectionErrors?.password ? "is-invalid" : ""
                        }`}
                      placeholder="***********************"
                      autoComplete="off"
                      {...connectionRegister("password", {
                        required: `${t("settingstab.enter_password")}`,
                      })}
                    />
                    {connectionErrors?.password && (
                      <div className="invalid-feedback">
                        {connectionErrors?.password?.message}
                      </div>
                    )}
                  </div>
                </div>

                <div className="d-flex justify-content-center mb-3 mt-4 gap-3">
                  <SecondaryButton
                    type={'button'}
                    onClick={() => {
                      handleCancel();
                    }}
                    buttonText={t('settingstab.cancel_connect')}
                    id={'cancel_connect'}
                    style={{ width: '102px' }}
                  />
                  <PrimaryButton
                    type={'button'}
                    onClick={connectionHandleSubmit(handleConnectionSubmit)}
                    buttonText={t("settingstab.connect")}
                    isEnabled={true}
                    id={'connect'}
                    style={{ width: '149px' }}
                    isLoading={loading}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
      <div className="py-3">
        <div>
          <h2 className="card-title-black-text">{t("settingstab.company")}</h2>
        </div>
        <div className="py-2 text-gray-400">
          <p>{t("settingstab.setup_description")}</p>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 form-outline" style={{ position: 'relative' }}>
          {isLoading && <LoadingComponent />}
          <select
            className="form-select form-control-lg"
            aria-label="Default select example"
            onChange={(e) => handleCompanyChange(e)}
            id="company"
            value={connectedIntegration?.data?.company}
          >
            {" "}
            <option value="">Select Company</option>
            {oddoCompanyList?.map((company, index) => (
              <option
                selected={connectedIntegration?.data?.company === company.id}
                key={index}
                value={company.id}
              >
                {company.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <Modal centered show={showModal} onHide={handleClose}>
        <Modal.Body className="mt-2 p-4">
          <div className="p-3">
            <div className="text my-1">
              If you change your company, your data will be removed. Are you
              sure you want to continue?
            </div>
            <div className="border-bottom pb-2"></div>
            <div className="d-flex justify-content-center gap-3 mt-4 mb-2 ">
              <SecondaryButton
                type={'button'}
                onClick={handleClose}
                buttonText={t('cancel')}
                id={'cancel'}
                style={{ width: '102px' }}
              />
              <PrimaryButton
                type={'button'}
                onClick={() =>
                  handleUpdateData(
                    { target: { id: "company", value: selectedCompanyId } },
                    true
                  )
                }
                buttonText={t("login.continue")}
                isEnabled={true}
                id={'continue'}
                style={{ width: '149px' }}
                isLoading={loading}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <div className="py-3">
        <div className="mt-3">
          <h2 className="card-title-black-text">{t("settingstab.account")}</h2>
        </div>
        <p>{t("settingstab.account_description")}</p>

        <div className="col-lg-4 col-md-6 col-sm-12 form-outline" style={{ position: 'relative' }}>
          {isLoading && <LoadingComponent />}
          <label className="form-labels" htmlFor="searchName">
            {t("settingstab.expense-journal")}
          </label>

          <select
            className="form-select form-control-lg"
            aria-label="Default select example"
            onChange={handleUpdateData}
            id="journal"
            value={connectedIntegration?.data?.journal}
          >
            <option value="">Select Journal</option>
            {oddoJournalsList.map((journals, index) => (
              <option
                selected={connectedIntegration?.data?.journal === journals.id}
                key={index}
                value={journals.id}
              >
                {journals.name}
              </option>
            ))}
          </select>
        </div>

        <div className="col-lg-4 col-md-6 col-sm-12 form-outline" style={{ position: 'relative' }}>
          <label className="form-labels mt-2" htmlFor="searchName">
            {t("settingstab.reimbursement_journal")}
          </label>
          {isLoading && <LoadingComponent />}
          <select
            className="form-select form-control-lg"
            aria-label="Default select example"
            id="reimbursementJournal"
            onChange={handleUpdateData}
            value={connectedIntegration?.data?.reimbursementJournal}
          >
            {" "}
            <option value=""> Select Reimbursement Journal</option>
            {oddoReimbursementJournalsList.map((journals, index) => (
              <option
                selected={
                  connectedIntegration?.data?.reimbursementJournal ===
                  journals.id
                }
                key={index}
                value={journals.id}
              >
                {journals.name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 form-outline" style={{ position: 'relative' }}>
          <label className="form-labels" htmlFor="searchName">
            {t("settingstab.payment_account")}
          </label>
          {isLoading && <LoadingComponent />}
          <select
            className="form-select form-control-lg"
            aria-label="Default select example"
            onChange={handleUpdateData}
            id="paymentAccount"
            value={connectedIntegration?.data?.paymentAccount}
          >
            {" "}
            <option value=""> Select Payment Account</option>
            {oddoExpenseCategory.map((expense, index) => (
              <option
                selected={
                  connectedIntegration?.data?.paymentAccount === expense.id
                }
                key={index}
                value={expense.id}
              >
                {expense.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="py-3" style={{ position: 'relative' }}>
        <div className="py-3">
          <h2 className="card-title-black-text">
            {t("settingstab.configure")}
          </h2>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 form-outline grid-container">
          {isLoading && <LoadingComponent />}
          <button
            onClick={() => setAccountingTabs("second")}
            className="styled-button"
            id="match_tax_codes"
          >
            Match Tax Codes With Taxes
          </button>
          <button
            className="styled-button"
            onClick={() => setAccountingTabs("third")}
            id="match_account_numbers"
          >
            Match Account Numbers With Caregories
          </button>
          <button
            className="styled-button"
            onClick={() => setAccountingTabs("forth")}
            id="match_tags"
          >
            Match Tag Codes With Tags
          </button>
        </div>
      </div>
    </div>
  );
}

export default memo(Mapping);
