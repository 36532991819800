import React, { useState, useEffect, useRef } from 'react';
import { useController } from 'react-hook-form';
const getDropdownWidth = () => {
  if (window.innerWidth >= 1024 && window.innerWidth <= 1280) {
    return '155px';
  }
  return '230px';
};
const SearchDropdown = ({ name, control, options, label, placeholder }) => {
  const { field } = useController({ name, control });
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [dropdownWidth, setDropdownWidth] = useState(getDropdownWidth());

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleOptionClick = (value) => {
    field.onChange(value);
    setIsOpen(false);
  };
  const selectedLabel = options?.find(
    (option) => option.value === field.value
  )?.label;
  useEffect(() => {
    const handleResize = () => {
      setDropdownWidth(getDropdownWidth());
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initial call

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      className='position-relative'
      ref={dropdownRef}
      style={{ width: dropdownWidth }}
    >
      <label className='form-label' htmlFor={name}>
        {label}
      </label>{' '}
      <div
        className='form-select form-control-lg cursor-pointer rounded-lg p-2'
        onClick={() => setIsOpen(!isOpen)}
        style={{ border: '1px solid lightgray' }}
      >
        {' '}
        <span
          className={selectedLabel ? '' : 'dropdown-placeholder'}
          style={{
            display: 'block',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          {selectedLabel || placeholder}
        </span>
        {isOpen && (
          <div
            className='position-absolute bg-white p-1 rounded-lg mt-3'
            style={{
              border: '1px solid lightgray',
              borderRadius: '10px',
              zIndex: 1000,
              minWidth: '100%',
              left: 0,
            }}
          >
            {options?.map((option, index) => (
              <div
                key={index}
                className='p-2 cursor-pointer   custom-dropdown-option rounded-lg border-b last:border-b-0'
                style={{
                  borderBottom: '1px solid #e0e0e0',
                  marginTop: '4px',
                  borderRadius: '10px',
                }}
                onClick={() => handleOptionClick(option?.value)}
              >
                {option?.label}
              </div>
            ))}
          </div>
        )}
      </div>
      <p className='text-danger'>{field.error?.message}</p>
    </div>
  );
};

export default SearchDropdown;