import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_ENDPOINTS, METHOD_TYPE } from "../../utils/apiUrls";
import api from "../../utils/api";

const initialState = {
    isLoading: false,
    fetchAudit:[],
    exportToFileAuditTrailData:{},
};

export const fetchAuditTrail = createAsyncThunk("employee/fetchAuditTrail", async (queryParams) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.fetchAuditTrail + queryParams,
        };
        const response = await api(data);
        return response.data;
        
    } catch (error) {
        throw error?.response?.data;
    }
});

export const exportToFileAuditTrail = createAsyncThunk("/employee/exportToFileAuditTrail", async (postData) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.exportToFileAuditTrail,
            data: postData
        };
        const response = await api(data);
        return response;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const exportAllAuditTrails = createAsyncThunk("/employee/exportAllAuditTrails", async (postData) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.exportAllAuditTrails+postData,
        };
        const response = await api(data);
        return response;

    } catch (error) {
        throw error?.response?.data;
    }
});

const auditLogSlice = createSlice({
    name: "auditLogViewer",
    initialState,
    reducers: {
    },

    extraReducers: (builder) => {
        builder
        .addCase(fetchAuditTrail.fulfilled, (state, action) => {
            state.fetchAudit = action.payload.data;
        })
        .addCase(fetchAuditTrail.pending, (state) => {
            state.fetchAudit = [];
        })
        .addCase(exportToFileAuditTrail.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(exportToFileAuditTrail.rejected, (state, { error }) => {
            state.isLoading = false;
            state.error = error.message ? error.message : "Request Failed Please Try Again ";
        })
        .addCase(exportToFileAuditTrail.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.error = null;
            state.exportToFileAuditTrailData = payload.data;
        })
        .addCase(exportAllAuditTrails.fulfilled, (state, /*{ payload }*/) => {
            state.isLoading = false;
            state.error = null;
        })
        .addCase(exportAllAuditTrails.pending, (state, /*{ payload }*/) => {
            state.isLoading = true;
        })
        .addCase(exportAllAuditTrails.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.error = payload?.message ? payload?.message : "Request Failed Please Try Again ";
        })
        .addMatcher(
            (action) =>
                action.type === fetchAuditTrail.pending.type ||
                action.type === fetchAuditTrail.fulfilled.type ||
                action.type === fetchAuditTrail.rejected.type,
            (state, action) => {
                state.isLoading = action.type === fetchAuditTrail.pending.type;
            }
        );
    }
});

// export const { } = auditLogSlice.actions;
export default auditLogSlice.reducer;