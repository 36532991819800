export const checkHasAccess = (route, listFromParams) => {
    try {
    const list = JSON.parse(localStorage.getItem('featuresList'));
    const featuresList =  listFromParams && listFromParams.length ? listFromParams : list
    if (featuresList) {
        if (!featuresList.length) {
            return true;
        }

        // Remove leading slashes and split the route into parts (e.g., "/reimbursement/reimbursement-details" -> ["reimbursement", "reimbursement-details"])
        const routeParts = route.split('/').filter(Boolean); // Removes empty strings from the split
        
        // Ensure we have route parts to avoid accessing undefined elements
        if (routeParts.length > 0) {
            // Extract the base route name (e.g., "reimbursement" from "/reimbursement/reimbursement-details")
            const baseRoute = routeParts[0].toLowerCase();

            // Check if the base route exists in the featuresList (ignoring case)
            return featuresList.some(feature => feature.toLowerCase() === baseRoute);
        }else{
            const routeName = route === '/' ? '/' : route.replace(/\//g, ""); // Handle root path separately
        
        // Return true for root path or check if processed features include the routeName
        return routeName === '/' || featuresList.includes(routeName);
        }
    }

    return true; // Default allow if no features list is available or route is malformed
} catch (error) {
        console.log('xx- error', error);
}
};
