import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Cards.scss';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  fetchCorporateCardsSearch,
  fetchNetworkDetail,
  getBankDetails,
  getCardType,
  issueNewCard,
  removeCorporateCard,
} from './slice/cardListSlice';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import ImagesCross from '../../assets/images/close-square-8.png';
import SearchImg from '../../assets/images/search_img.png';
import CardsImg from '../../assets/images/cards_img.png';
import Loader from '../../components/Loader/Loader';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { getAllDepartment, getDepartmentEmployee } from '../Teams/teamSlice';
import cookies from 'js-cookie';
import CardCooperateForm from './CardCorporateForm';
import { fetchCurrencies } from '../Expenses/expensesSlice';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import SearchDropdown from './SearchDropdown';
import { activeBlocked, verifyCompanyStatus } from '../../utils/verifyCompanyStatus';
import { allowedUserRoles, verifyUserRole } from '../../utils/verifyUserRole';

const languages = [
  {
    code: 'en',
    name: 'English',
    country_code: 'gb',
  },
  {
    code: 'ar',
    name: 'العربية',
    dir: 'rtl',
    country_code: 'sa',
  },
];

const maskCardNumber = (cardNumber, showUnmasked) => {
  if (!cardNumber) {
    return ''; // Return an empty string or any other default value if cardNumber is undefined or null
  }
  const digitsOnly = cardNumber.replace(/\D/g, '');
  const masked =
    digitsOnly.slice(0, -4).replace(/\d/g, '*') + digitsOnly.slice(-4);
  const maskedCardNumber = masked.replace(/(.{4})/g, '$1 ').trim();
  return typeof showUnmasked !== 'undefined' && showUnmasked
    ? cardNumber
    : maskedCardNumber;
};

function CorporateCards() {
  const [show, setShow] = useState(false);
  const [selectedDepartmentId, setSelectedDepartmentId] = useState({});

  const handleClose1 = () => {
    setShow(false);
    resetAddCardForm();
  };
  const {
    cardCorporate,
    isLoading,
    cardTypeDetails,
    networkDetails,
    bankDetails,
    addCorporateCard,
  } = useSelector((state) => state.cardListReducer);

  const { departmentData, departmentEmployees } = useSelector(
    (state) => state?.teamSlice
  );

  const { currencies } = useSelector((state) => state.expenseReducer);

  const [pageNo, setPageNo] = useState(1);
  const [issueCardData, setIssueCardData] = useState(null);
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const [timer, setTimer] = useState(60);
  const dispatch = useDispatch();
  const selectRef = useRef();
  const employeeRef = useRef();
  const { t } = useTranslation();
  const currentLanguageCode = cookies.get('i18next') || 'en';
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [corporateCardIdToDelete, setCorporateCardIdToDelete] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);

  const handleShow = () => {
    setSelectedCard(null);
    setShow(true);
  };

  const handleUpdateForm = (cardCorporateId) => {
    setSelectedCard(cardCorporateId);
    setShow(true);
  };

  const handleDeleteClick = (corporateCardId) => {
    setCorporateCardIdToDelete(corporateCardId);
    setShowDeleteModal(true);
  };
  const handleConfirmDelete = () => {
    if (corporateCardIdToDelete) {
      dispatch(
        removeCorporateCard({ corporateCardId: corporateCardIdToDelete })
      )
        .unwrap()
        .then((res) => {
          toast.success(res?.message);
          dispatch(fetchCorporateCardsSearch(`?page=${pageNo}`));
        })
        .catch((error) => {
          toast.error(error?.message);
        })
        .finally(() => {
          setShowDeleteModal(false);
        });
    } else {
      setShowDeleteModal(false);
    }
  };

  const {
    control: controlSearchForm,
    handleSubmit: handleSearchSubmit,    reset: resetForm,

    formState: { errors: errorsSearchForm },
    watch,
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        employeeName: yup.string(),
        department: yup.string(),
        cardType: yup.string(),
      })
    ),
  });

  const {
    control: controlAddCardForm,
    handleSubmit: handleAddCardSubmit,
    formState: { errors: errorsAddCardForm },
    watch: addFormWatch,
    setValue: addCardSetValue,
    reset: resetAddCardForm,
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        cardType: yup.string().required('Card type is required.'),
        employeeId: yup.string().required('Employee ID is required.'),
      })
    ),
  });

  const {
    control: controlVerifyModal,
    handleSubmit: handleOtpSubmit,
    formState: { errors: errorsOtpForm },
    reset: otpFormReset,
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        otp: yup
          .string()
          .required('Otp is required')
          .matches(/^123456$/, 'OTP must be exactly 6 digits'),
      })
    ),
  });

  const handleShowVerifyModal = () => setShowVerifyModal(true);
  const handleCloseVerifyModal = () => setShowVerifyModal(false);
  // const open = Boolean(anchorEl);
  // const handleClick = (event) => {
  //     setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //     setAnchorEl(null);
  // };

  const addFormValues = addFormWatch();
  const onPreviousClick = () => {
    setPageNo((prev) => prev - 1);
  };

  const onNextClick = () => {
    setPageNo((prev) => prev + 1);
  };

  useEffect(() => {
    let interval;

    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [timer]);
  useEffect(() => {
    if (selectedDepartmentId) {
      dispatch(
        getDepartmentEmployee({
          departmentId: selectedDepartmentId.departmentId,
          para: null,
          eventType: 'teams',
        })
      );
    }
  }, [selectedDepartmentId, dispatch]);
  useEffect(() => {
    if(verifyCompanyStatus(activeBlocked) && verifyUserRole(allowedUserRoles.adminAccountantReviewerAndCardHolder)){
    dispatch(getAllDepartment());
    }
    dispatch(getCardType());
    dispatch(fetchNetworkDetail());
    dispatch(fetchCurrencies());
    dispatch(getBankDetails());
  }, []);
  // Fetch master transaction list when 'pageNo' changes
  useEffect(() => {
    const allValues = watch();
    const { cardType, department, employeeName } = allValues;
    let queryParams = `?page=${pageNo}`;
    if (cardType || department || employeeName) {
      queryParams += '&';
      queryParams += Object.keys(allValues)
        .filter((key) => allValues[key] !== undefined && allValues[key] !== '')
        .map((key) => `${key}=${encodeURIComponent(allValues[key])}`)
        .join('&');
    }
    dispatch(fetchCorporateCardsSearch(queryParams));
  }, [dispatch, pageNo]);

  useEffect(() => {
    document.body.dir = currentLanguage.dir || 'ltr';
    document.title = t('app_title');
  }, [currentLanguage, t]);

  const onSearchFormSubmit = (data) => {
    setPageNo(1);
    const { cardType, department, employeeName } = data;
    let queryParams = `?page=${1}&`;
    if (!cardType && !department && !employeeName) {
      dispatch(fetchCorporateCardsSearch(queryParams));
    } else {
      queryParams += Object.keys(data)
        .filter((key) => data[key] !== undefined && data[key] !== '')
        .map((key) => `${key}=${encodeURIComponent(data[key])}`)
        .join('&');
      dispatch(fetchCorporateCardsSearch(queryParams))
        .unwrap()
        .then(() => {})
        .catch((error) => {
          toast.error(error.message || 'Request Failed Please Try Again ');
        });
    }
  }; 
  const handleReset = () => {
    resetForm(); 
    setPageNo(1);
    dispatch(fetchCorporateCardsSearch(`?page=${pageNo}`)); // Fetch data without filters
  };
 
  const handleAddCard = () => {
    issueCardData.limit = 0;
    issueCardData.status = 'active';
    issueCardData.currentBalance = 0;
    issueCardData.isFreezed = false;
    issueCardData.expiryDate = '2024-10-06';
    issueCardData.eventType = 'cards';
    dispatch(issueNewCard(issueCardData))
      .unwrap()
      .then(() => {
        toast.success('Card Added Successfully!');
        let queryParams = `?page=${pageNo}`;
        dispatch(fetchCorporateCardsSearch(queryParams));
        resetAddCardForm();
        if (employeeRef.current) employeeRef.current.value = '';
        handleClose1();
        handleCloseVerifyModal();
        otpFormReset({ otp: '' });
      })
      .catch((error) => {
        toast.error(error.message || 'Request Failed Please Try Again ');
      });
  };

  const onClickVerifyModal = (data) => {
    console.log("Hello");
    setIssueCardData(data);
    setTimer(60);
    handleClose1();
    otpFormReset({ otp: '' });
    setTimeout(() => {
      handleShowVerifyModal();
    }, 200);
  };

  const employeeOptions = departmentEmployees?.data?.map((employee) => ({
    value: `${employee.firstName} ${employee.lastName}`,
    label: `${employee.firstName} ${employee.lastName}`,
  }));
  const departmentOptions = departmentData?.map((department) => ({
    value: department.departmentName,
    label: department.departmentName,
  }));

  const cardTypeOptions = cardTypeDetails?.map((card) => ({
    value: card.cardTypeName,
    label: card.cardTypeName,
  }));

  return (
    <>
      <div className='white-container cards-section-css'>
      
        <div className='d-flex align-items-center justify-content-between'>
          <h1 className='text-green  ms-3'>Corporate Cards</h1>
          <button onClick={handleShow} className='btn-primary-small ms-3'>
            {t('cards.+_add_new_card')}
          </button>
        </div>
        <div className='col-12 mt-3'>
          <div className='border-grey cards-custom  p-3'>
            <div className=' d-flex align-items-center mb-4  green-header'>
              <img src={SearchImg} className='small-img-size1 me-3' />
              <h2 className='text-green ps-1 me-1'>{t('cards.search')}</h2>
            </div>
            <form
              className='row'
              onSubmit={handleSearchSubmit(onSearchFormSubmit)}
            >
              <div className='cards-search'>
                <div className='row'>
                  <div className='col-lg-4 col-md-12 col-sm-2 mb-3'>
                    <SearchDropdown
                      name='employeeName'
                      control={controlSearchForm}
                      options={employeeOptions}
                      label={t('cards.employee_name')}
                      placeholder={t('cards.employee_name')}
                    />
                  </div>
                  <div className='col-lg-4 col-md-12 mb-3'>
                    <SearchDropdown
                      name='department'
                      control={controlSearchForm}
                      options={departmentOptions}
                      label={t('cards.department')}
                      placeholder={t('cards.department_name')}
                    />
                  </div>
                  <div className='col-lg-4 col-md-12 mb-3'>
                    <SearchDropdown
                      name='cardType'
                      control={controlSearchForm}
                      options={cardTypeOptions}
                      label={t('cards.card_type')}
                      placeholder={t('cards.select_card_type')}
                    />
                  </div>
                </div>
                <div className='col-lg-2 gap-2 col-sm-6 col-md-6 d-flex justify-content-end search--button '>
                  <button type='submit' className='btn-primary-small w-100'>
                    {t('cards.search')}
                  </button>
                  <button type='button' className='btn-primary-small w-100' onClick={handleReset}>
                    Reset
                  </button> 
                </div>
              </div>
              {errorsSearchForm.atLeastOneField && (
                <p className='text-danger'>
                  {errorsSearchForm?.atLeastOneField?.message}
                </p>
              )}
            </form>
          </div>
        </div>

        <div className='cards-custom mt-4 p-3'>
          <div className=' d-flex align-items-center mb-3 green-header'>
            <img src={CardsImg} className='small-img-size1 me-3' />
            <h2 className='text-green ps-1'>Cards</h2>
          </div>
          <div className='table-responsive  p-0.7 py-0'>
            <table
              id='corporateCards'
              style={{
                borderCollapse: 'separate',
                borderSpacing: '0px 16px',
                width: '100%',
                tableLayout: 'fixed',
                display: 'block',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
              cellSpacing='0'
            >
              <thead>
                <tr className='table-head'>
                  <th scope='col' style={{ width: '20%' }}>
                    Account Name
                  </th>
                  <th scope='col' style={{ width: '15%' }}>
                    Type
                  </th>
                  <th scope='col' style={{ width: '15%' }}>
                    Currency
                  </th>
                  <th scope='col' style={{ width: '25%' }}>
                    Bank
                  </th>
                  <th scope='col' style={{ width: '25%' }}>
                    Employee
                  </th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(cardCorporate).length > 0 &&
                  Object.keys(cardCorporate['rows']).length > 0 &&
                  cardCorporate['rows'].map((cardCorporate, index) => {
                    const [wholePart, decimalPart] = cardCorporate.limit
                      ? cardCorporate.limit.toString().split('.').map(Number)
                      : ['0', '00'];
                    return (
                      <tr
                        style={{ cursor: 'pointer' }}
                        key={index}
                        className={`${
                          currentLanguageCode === 'en'
                            ? 'table-body-tr'
                            : 'table-body-tr-arabic'
                        }`}
                      >
                        <td>{cardCorporate.accountName}</td>
                        <td>{cardCorporate.cardTypeName}</td>
                        <td>{cardCorporate.currency}</td>
                        <td>{cardCorporate.bankName}</td>
                        <td>{cardCorporate.employeeName}</td>
                        <td>
                          <a>
                            <span
                              style={{
                                marginRight: 10,
                                cursor: 'pointer',
                                color: '#28a745',
                                borderRadius: '9px',
                                padding: '4px 16px',
                                backgroundColor: '#d4edda',
                                border: '1px solid #28a745',
                                fontSize: '12px',
                              }}
                            >
                              Statement
                            </span>
                          </a>
                          <a>
                            <span
                              onClick={() => handleUpdateForm(cardCorporate)}
                              style={{
                                marginRight: 10,
                                cursor: 'pointer',
                                color: '#007BFF',
                                border: '1px solid #007BFF',
                                backgroundColor: '#E3F2FD',
                                borderRadius: '9px',
                                padding: '4px 16px',
                                fontSize: '12px',
                              }}
                            >
                              {t('edit')}
                            </span>
                          </a>
                          <a>
                            <span
                              onClick={() =>
                                handleDeleteClick(cardCorporate.corporateCardId)
                              }
                              style={{
                                marginRight: 0,
                                cursor: 'pointer',
                                color: '#FF0000',
                                backgroundColor: '#FFEBEE',
                                border: '1px solid #FF0000',
                                borderRadius: '9px',
                                padding: '4px 16px',
                                fontSize: '12px',
                              }}
                            >
                              {t('delete')}
                            </span>
                          </a>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <div
            className='d-flex align-items-center justify-content-between mt-4'
            style={{ width: '100%', borderTop: '0px !imporant' }}
          >
            <nav aria-label='Page navigation example'>
              <ul className='pagination mt-2 pt-1 mb-3 pe-0'>
                <li className='page-item'>
                  <button
                    className='btn page-link'
                    disabled={pageNo === 1}
                    onClick={() => onPreviousClick()}
                  >
                    {t('previous')}
                  </button>
                </li>
                <li className='page-item mx-3'>
                  <button
                    className='btn page-link'
                    disabled={
                      pageNo == cardCorporate?.totalPages ||
                      cardCorporate?.totalPages == 0
                    }
                    onClick={() => onNextClick()}
                  >
                    {t('next')}
                  </button>
                </li>
              </ul>
            </nav>
            <div
              className='page-count d-flex align-items-center'
              style={{ color: '#00D094' }}
            >
              <h4>
                Page {cardCorporate?.currentPage} of {cardCorporate?.totalPages}
              </h4>
            </div>
          </div>
        </div>
      </div>
      <Modal
        className='TeamModalOne'
        show={show}
        onHide={handleClose1}
        style={{ paddingRight: 0 }}
      >
        <form 
          onSubmit={handleAddCardSubmit(onClickVerifyModal)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              handleAddCardSubmit(onClickVerifyModal)();
            }
          }}
          >
          <Modal.Body>
            <div className='d-flex justify-content-between align-items-start green-header p-2 ps-3'>
              <div className='col-9'>
                <p className='text-green modal-title' style={{ fontSize: 20 }}>
                  {t('teams.add_card')}
                </p>
              </div>
              <div
                onClick={handleClose1}
                className='clo-2'
                style={{ cursor: 'pointer' }}
              >
                <img
                  src={ImagesCross}
                  style={{ width: 20, objectFit: 'contain' }}
                />
              </div>
            </div>

            <CardCooperateForm
              cardTypeDetails={cardTypeDetails}
              networkDetails={networkDetails}
              departmentEmployees={departmentEmployees}
              currencies={currencies}
              bankDetails={bankDetails}
              addCorporateCard={addCorporateCard}
              handleClose1={handleClose1}
              updateCorporateCard={selectedCard}
              pageNo={pageNo}
              setShow={setShow}
            />
          </Modal.Body>
        </form>
      </Modal>{' '}
      <ConfirmationDialog
        show={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={handleConfirmDelete}
        message={'Are you sure you want to proceed to delete?'}
      />
    </>
  );
}

export default CorporateCards;