export const METHOD_TYPE = {
    get: "get",
    post: "post",
    put: "put",
    delete: "delete",
};

export const API_ENDPOINTS = {
    generateOtp: 'employee/generateOtp',
    verifyOtp: 'employee/verifyOtp',
    getCompanyList: 'company/listCompanyMasterByRegistrationNo',
    registerCompany: 'company/registerCompany',
    registration: 'employee/register',
    verifyCredentialsAndSendOtp: 'employee/verifyCredentialsAndSendOtp',
    employeeStateChange: 'employee/employeeStateChange',
    verifyLoginOtp: 'employee/verifyLoginOtp',
    fetchMasterAccountBalance: 'masterAccount/fetchMasterAccountBalance',
    fetchMasterTransactionList: 'masterAccount/fetchMasterTransactionList',
    fetchQuestionaire: 'company/listQuestionsWithAnswer',
    submitQuestions: 'company/submitQuestionaries',
    addFund: 'masterAccount/addFund',
    withDrawFund: 'masterAccount/withDrawFund',
    updateCompanyProfile: 'company/updateCompanyProfile',
    uploadCompanyDoc: 'company/uploadCompanyDoc',
    deleteDoc: 'company/deleteCompanyDoc',
    sendInvitation: '/employee/sendInvite',
    addTax: '/setting/addTaxCode',
    updateTax: '/setting/updateTaxCode',
    deleteTax: '/setting/deleteTaxCode',
    taxGetById: '/setting/deleteTaxCode',
    getAllTax: '/setting/getAllTax',
    getAllTaxType: '/setting/getAllTaxType',
    getTaxDetailsById: '/setting/getTaxDetailsById',
    addCategoryGroup: '/setting/addCategoryGroup',
    addCategory: '/setting/addCategory',
    updateCategory: '/setting/updateCategory',
    getAllCategoryByGroup: '/setting/getAllCategoryByGroup',
    getCategoryDetail: '/setting/getCategoryDetail',
    getAllIcon: '/icon/getAllIcon',
    addTagGroup: '/setting/addTagGroup',
    addTag: '/setting/addTag',
    getAllIntegrations: "/accountingIntegration/list",
    getConnectedIntegration: "/accountingIntegration/connected",
    disconnectIntegration: "/accountingIntegration/disconnect",
    connectIntegration: "/accountingIntegration/connect",
    getAllTagByGroup: '/setting/getAllTagByGroup',
    getTagDetailsById: '/setting/getTagDetailsById',
    updateTag: '/setting/updateTag',
    getAllSubcription: '/setting/getAllSubcription',
    addDepartment: "/department/addDepartment",
    getAllReviewerPolicy: '/department/fetchReviewerPolicy',
    deleteReviewerPolicy: '/setting/deleteReviewerPolicy',
    getReviewerPolicyDetailsById: '/setting/getReviewerPolicyDetailsById',
    updateReviewerPolicy: '/setting/updateReviewerPolicy',
    addReviewerPolicy: '/department/addReviewerPolicy',
    fetchGlobalReviewerEmp: '/department/fetchGlobalReviewerEmp',
    getAllCards: '/card/fetchAllCards',
    activatePhysicalCard: '/card/activatePhysicalCard',
    getPciWidgetUrl: '/card/createToken',
    fetchAvaliableBalance: '/expense/fetchAvaliableBalance',
    fetchEmployeeExpenses: '/expense/fetchEmployeeExpenses',
    fetchExpensesForApproval: '/expense/fetchExpensesForApproval',
    getCardDetails: '/card/fetchCardDetails',
    getMccCategoryDetails: '/card/fetchMccCategoryDetails',
    updateMcc: '/card/updateMcc',
    fetchCardSpendings: '/card/fetchCardSpendings',
    addCardMoney: '/card/addMoney',
    freezeUnfreezeCard: '/card/freezeUnfreezeCard',
    withdrawCardMoney: '/card/withdrawlMoney',
    updateCardLimit: '/card/updateCardLimit',
    updateSubscription: '/setting/updateSubscription',
    updateCardControl: '/card/updateCardControl',
    getEmployeeDetails: 'employee/getEmployee/',
    getEmployeeCardList: '/card/getEmployeeCardList',
    addCardTopUpRequest: '/card/addCardTopUpRequest',
    getEmployees: '/employee/getEmployees',
    getOwners: '/employee/getEmployees',
    deleteOwner: '/employee/deleteOwner',
    issueNewCard: '/card/issueNewCard',
    fetchWebCardSpendings: '/card/fetchWebCardSpendings',
    approveRejectExpense: '/expense/approveRejectExpense',
    fetchExpenseDetails: '/expense/fetchExpenseDetails',
    fetchExpenseActivityLog: '/expense/fetchExpenseActivityLog',
    updateCardTransaction: '/expense/updateCardTransaction',
    fetchCategory: '/expense/fetchCategory',
    fetchEmpDetails: 'department/fetchEmpDetails',
    getBillingAmount: '/setting/getBillingAmount',
    postPayNow: '/setting/postPayNow',
    updateEmployeeDetails: 'employee/updateEmployee',
    updateOwner: '/employee/updateOwner',
    fechReviewers: 'department/fechReviewers',
    employeeCount: '/department/employeeCount',
    addEmployee: '/employee/addEmployee',
    getAllDepartment: '/department/getAllDepartment',
    getAllCountries: '/country/getCountries',
    getAllDocuments: '/attachmentType/getAttachmentType',
    updateEmpReviewer: 'department/updateEmpReviewer',
    uploadTransactionReceipt: '/expense/uploadTransactionReceipt',
    saveTags: '/setting/saveTags',
    getCompanyDocument: '/company/getCompanyDocument',
    uploadDocument: '/employee/uploadDocument',
    deleteTag: '/setting/deleteTag',
    deleteTagGroup: '/setting/deleteTagGroup',
    deleteCategory: '/setting/deleteCategory',
    deleteCategoryGroup: '/setting/deleteCategoryGroup',
    getAllExpenseListByCompany: '/expense/fetchAllExpenses',
    getCardByEmployeeId: '/card/fechCardsByEmpId',
    addExpenseTransaction: '/expense/addExpenses',
    companyDetails: '/company/getCompanyDetails',
    fetchCompanyStatus: 'company/getCompanyStatusDetails',
    setKybStatus: '/company/setKybStatus',
    dashboardFetchTransactionByVendors: '/employee/dashboardFetchTransactionByVendors',
    dashboardSpendPerCategory: '/employee/dashboardSpendPerCategory',
    dashboardCardIssueCount: '/employee/dashboardCardIssueCount',
    dashboardTotalUsersCount: '/employee/dashboardTotalUsersCount',
    dashboardTotalTransaction: '/employee/dashboardTotalTransaction',
    dashboardTransactionCount: '/employee/dashboardTransactionCount',
    dashboardFetchTransactionByVolume: '/employee/dashboardFetchTransactionByVolume',
    fetchReviewerCardTopUpRequest: '/card/fetchReviewerCardTopUpRequest',
    approveRejectCardTopUpRequest: '/card/approveRejectCardTopUpRequest',
    updateTagGroupName: '/setting/updateTagGroupName',
    updateCategoryGroupName: '/setting/updateCategoryGroupName',
    dashboardMostSpend: '/employee/dashboardMostSpendPerCategory',
    sendForgotPasswordMail: '/employee/sendForgotPasswordMail',
    resetPassword: '/employee/resetPassword',
    fetchCurrencies: '/expense/fetchCurrencies',
    fetchAllDepartments: '/department/fetchAllDepartments',
    getExportRecords: '/export/getExportRecords',
    getQueueRecords: 'export/getQueueRecords',
    moveRecordToQueue: 'export/moveRecordToQueue',
    exportToFile: 'export/exportToFile',
    exportToOddo: '/oddo/export',
    getFetchEmployees: 'reimbursement/fetchEmployees',
    getreimbursementEmployeeDetails: 'reimbursement/fetchOutstanding',
    getreimbursementForReview: 'reimbursement/reviewReibursement',
    fetchReimbursementDetails: 'reimbursement/fetchReimbursementDetails',
    updateReimbursementTransaction: 'reimbursement/updateReimbursment',
    updateReimbursementTransactionWeb: 'reimbursement/updateReimbursmentWeb',
    updateReimbursementByIdAndFile: 'reimbursement/updateReimbursementByIdAndFile',
    fetchReimbursementActivityLog: 'reimbursement/fetchReimbursementActivityLog',
    approveRejectReimbursement: 'reimbursement/approveRejectReimbursement',
    fetchsettledReimbursement: 'reimbursement/getSettledReimbursement',
    uploadSettleReimbursement: 'reimbursement/settleReimbursement',
    uploadSettleReimbursementWithoutDoc: '/reimbursement/settleReimbursementWithoutFile',
    deleteDepartment: 'department/deleteDepartment',
    resendInviteLink: 'employee/resendInviteLink',
    moveBackToUnExport: 'export/moveBackToUnExport',
    fetchMasterTransactionDetail: 'masterAccount/fetchMasterTransactionDetail',
    exportCardTopUpRequestFile: '/card/exportToFileTopUpRequest',
    exportMasterAccountRequestFile: '/masterAccount/exportToFileMasterAccount',
    getCompanySettings: '/setting/getCompanyDetails',
    updateCompanyDetails: '/setting/updateCompanyDetails',
    getInvitationDetails: 'employee/getInvitationCode',
    joinCompany: 'employee/mobileSignup',
    dashboardReimbursmentTransaction: 'dashboard/reimbursmentTransaction',
    fetchAuditTrail: 'employee/fetchAuditTrail',
    exportToFileAuditTrail: 'employee/exportToFileAuditTrail',
    updateRate: 'reimbursement/updateConversionRate',
    exportAllMasterAccount: 'masterAccount/exportMasterTransactionList',
    exportAllCardTopUpRequest: 'card/exportAllCardTopUpRequest',
    exportAllAuditTrails: 'employee/exportAllAuditTrail',
    exportAllPagesRecords: 'export/exportAllRecords',
    exportAllExpenses: 'expense/exportAllExpenses',
    addAuditTrail: 'employee/addAuditTrail',
    getPettyChashBalances: 'pettycash/balances',
    getPettyCashedTransactions: '/pettycash/transactions',
    addCash: '/pettycash/addCash',
    withdrawCash: '/pettycash/withdraw',
    disburseCash: '/pettycash/disburse',
    returnCash: '/pettycash/return',
    getEmployeesCashes: '/pettycash/employees/balances',
    deleteTransaction: '/pettycash/transaction',
    getEmployeeTransactions: 'pettycash/employee/transactions',
    getAccounts: '/oddo/accounts',
    updateCategories: '/accountingIntegration/updateCategoryMapping',
    updateTags:'/accountingIntegration/updateTagMapping',
    updateEmployee: '/accountingIntegration/updateEmployeeMapping',
    updateCorporateCards: '/accountingIntegration/updateCorporateCardsMapping',
    getJournals:'/oddo/journals',
    getTaxList:'/oddo/tax',
    getTagsList:'/oddo/tags',
    getCompany:'/oddo/company',
    getExpenseCategory:'/oddo/expenseCategory',
    updateData:'/accountingIntegration/updateJson',
    updateMapping:'/accountingIntegration/updateTaxMapping',
    getCorporateCardsSearch: '/corporateCards/getCorporateCardsSearch',
    getCardCorporateType: '/corporateCards/fetchCardTypeDetails',
    getNetworkDetails: '/corporateCards/fetchNetworkDetails',
    getBankDetails: '/corporateCards/fetchBanksDetails',
    deleteCorporateCard: '/corporateCards/deleteCorporateCard',
    createCorporateCard: '/corporateCards/addCorporateCard',
    updateCorporateCard: '/corporateCards/updateCorporateCard',
    getAllCorporateExpense:'/corporateCardsExpense/fetchAllCorporateCardsExpenses',
    getCardCorporateTransaction:'/corporateCardsExpense/getEmployeeCorpCardExpenses',
    getCardCropExpenseForApproval:'/corporateCardsExpense/fetchCorpCardExpensesForApproval',
    getOddoEmployeeList: '/oddo/employee',
    getCorporateCardsList: '/oddo/corporateCards',
    getTermsConditions: '/termsAndConditions/fetchTerms',
    addConfirmedTerms: '/termsAndConditions/addConfirmedAgreement'
};
