import React, { useEffect } from 'react';
import './Login.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { generateOtp } from './registerSlice';
import { toast } from 'react-toastify';
import LoadingComponent from '../../components/Loader/LoadingComponent';
import Logo2 from '../../assets/images/polo.png';
import login_bottom_image from '../../assets/images/login_bottom_image.png';

function MobileNumber() {
    let navigate = useNavigate();
    const { state } = useLocation();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { register, handleSubmit, setValue, formState: { errors } } = useForm();

    useEffect(() => {
        if (state?.registerData?.fromInvite)
            setValue("mobile", state?.registerData?.mobileNumber?.replace(/^\+974/, ''));
    }, [state?.registerData?.fromInvite]);


    const { isLoading } = useSelector(state => state?.registerSlice);

    const onSubmit = (data) => {
        const fullMobileNumber = state?.registerData?.fromInvite ? data?.mobile : `+974${data?.mobile}`;
        const payload = {
            type: "mobile",
            mobile: `+974${data?.mobile}`
        };

        dispatch(generateOtp(payload))
            .unwrap()
            .then(() => {
                if (state?.registerData) navigate('/numberVerification', { state: { registerData: { ...state?.registerData, mobileNumber: fullMobileNumber } } });
            }).catch((error) => {
                toast.error(error?.message);
            });
    };

    return (
        <section className="vh-100">
            <div className="container-fluid vh-100" style={{ backgroundColor: '#24473D' }}>
                <div className="row d-flex justify-content-between align-items-center h-100 " >
                    <div className=" d-flex flex-column justify-content-between align-items-center my-2">
                        <div className='form-div'>
                            {isLoading && <LoadingComponent />}
                            <form className='form' onSubmit={handleSubmit(onSubmit)} >
                                <div>
                                    <img src={Logo2} className="logoso mt-1 mb-4" alt="Sample image" />
                                </div>
                                <h1 style={{ maxWidth: '320px' }} className='text-blue-login mb-2'>
                                    {t('login.enter_your_mobile_number')}
                                </h1>
                                <div style={{ maxWidth: '320px' }} className="divider d-flex align-items-center my-2">
                                    <p className="mb-0 text-subtitle2" style={{ color: '#8B9299' }} >
                                        {t('login.mobile_number_desc')}
                                    </p>
                                </div>
                                <div className="form-outline mt-4 mb-3">
                                    <label className="form-label" for="mobile">{t('mobile_number')}</label>
                                    <div style={{ maxWidth: '320px' }} className="input-group">
                                        {
                                            <span className="input-group-text">+974</span>
                                        }
                                        <input
                                            type="text"
                                            id="mobile"
                                            disabled={state?.registerData?.fromInvite}
                                            className={`form-control form-control-lg ${errors?.mobile ? 'is-invalid' : ''}`}
                                            placeholder={`${t('login.enter_your_mobile_number')}`}
                                            {...register('mobile', {
                                                required: `${t('login.enter_your_mobile_number')}`,
                                                minLength: {
                                                    value: 8,
                                                    message: 'Mobile number must be at least 8 digits',
                                                },
                                                maxLength: {
                                                    value: 16,
                                                    message: 'Mobile number should not exceed 16 digits',
                                                },
                                                pattern: {
                                                    value: /^\d+$/,
                                                    message: 'Mobile number must contain only digits',
                                                },
                                            })}
                                            onInput={(e) => {
                                                const value = e.target.value;
                                                if (!/^\d*$/.test(value)) {
                                                    e.target.value = value.slice(0, -1);
                                                }
                                            }}
                                        />
                                        {errors?.mobile && (
                                            <div className="invalid-feedback">{errors?.mobile?.message}</div>
                                        )}
                                    </div>
                                </div>

                                <div className="text-center text-lg-start mt-4 w-100">
                                    <button type="submit" className="btn btn-primary btn-lg btn-block w-100" style={{ position: 'relative', zIndex: '1' }} id='continue'>{t('login.continue')}</button>
                                </div>

                            </form>
                        </div>

                        <img className='login_bottom_image-1' src={login_bottom_image} />
                    </div>

                </div>
            </div>
        </section>
    );
}

export default MobileNumber;
