import React from "react";
import './buttonsStyle.scss';

function IconButton({ type, onClick, buttonText, id, src, iconStyle, btnStyle, currentLanguageCode, isFilled }) {
    const dynamicIconStyle = {
        ...iconStyle,
        transform: currentLanguageCode === 'en' ? 'rotate(0deg)' : 'rotate(180deg)',
    };
    return (
        <button type={type} className={ isFilled ? 'btn-primary-small' : 'btn-secondary-small'} id={id} onClick={onClick} style={btnStyle}>
            <img src={src} className='iconBtn' style={dynamicIconStyle} />
            {buttonText}
        </button>
    );
}

export default IconButton;
