import React, { useCallback, useEffect, useRef, useState } from 'react';
import { addReviewerPolicy, getAllReviewerPolicy, getAllSubcription, getBillingAmount, getCompanySettings, updateSubscription, getConnectedIntegration, getReviewerPolicyDetails, postPayNow, removeReviewerPolicy, updateCompanyDetails, updateReviewerPolicy, uploadLogo } from '../../pages/Settings/taxSlice';
import { useDispatch, useSelector } from 'react-redux';

import CheckIconGreen from '../../assets/images/Check_icon_green.png';
import CompanyColorLogo from '../../assets/images/company-color-logo.png';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import Cropper from 'react-easy-crop';
import CurrencyInput from 'react-currency-input-field';
import ImagesCross from '../../assets/images/close-square-8.png';
import Loader from '../../components/Loader/Loader';
import Modal from 'react-bootstrap/Modal';
import { SketchPicker } from 'react-color';
import UploadImgColorPickerSec from '../../assets/images/uploadImgColorPicker.png';
import UploadLogoIcon from '../../assets/images/uploadlogoIcon.png';
import cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MAX_VALUE } from '../../utils/constants';
import Logo2 from '../../assets/images/polo.png';
import login_bottom_image from '../../assets/images/login_bottom_image.png';
import { updateFeaturesList, updateRoutes } from '../Users/userSlice';

function Subscriptions() {
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState('');
  const handleClose = () => setShow(false);
  const handleShow = (title) => {
    if (title === 'add') {
      setTitle(`${t('settingstab.add_new_policy')}`);
    }
    else if (title === 'edit') {
      setTitle(`${t('settingstab.edit_policy')}`);
    }
    setShow(true);
  };
  const [showColorPicker, setShowColorPicker] = useState(false);

  const { t } = useTranslation();
  const { register, handleSubmit, formState: { errors, isDirty }, setValue, reset, watch} = useForm();
  const { isLoading, subscriptionPlans, companyDetails } = useSelector((state) => state?.taxSlice);
  const dispatch = useDispatch();
  const [policyId, setPolicyId] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [details, setDetails] = useState({
    companyUrl: null,
    companyLogo: null,
    backgroundColor: "#FFFFF",
    file: null
  });
  const location = useLocation();
  const [color, setColor] = useState("#ffffff");
  const companyLogoRef = useRef(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [showCropper, setShowCropper] = useState(false);
  const [companyURlError, setCompanyURlError] = useState(false);
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setDetails(prev => ({
        ...prev,
        file
      }));
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setImageSrc(reader.result);
        setShowCropper(true);
      };
      event.target.value = null;
    }
  };

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedBlob = await getCroppedImg(imageSrc, croppedAreaPixels);
      const tempFile = new File([croppedBlob], details?.file?.name, { type: details?.file?.type });
      setDetails((prev) => ({
        ...prev,
        file: tempFile,
        companyLogo: URL.createObjectURL(croppedBlob)
      }));
      companyLogoRef.current.value = '';
      setCrop({ x: 0, y: 0 });
      setZoom(1);
      setShowCropper(false);
    } catch (e) {
      console.error('Error cropping image:', e);
    }
  }, [imageSrc, croppedAreaPixels]);

  const handleCloseColorPicker = () => {
    if (showCropper && imageSrc) {
      setDetails(prev => ({
        ...prev,
        file: null
      }));
      setImageSrc(null);
      setShowCropper(true);
    } else {
      setDetails((prev) => ({
        ...prev,
        companyLogo: companyDetails?.companyLogo || null,
        companyUrl: companyDetails?.companyUrl || null,
        backgroundColor: companyDetails?.backgroundColor || "#FFFFF",
      }));
      setColor(companyDetails?.backgroundColor);
      setShowColorPicker(false);
      setCompanyURlError(false);
    }
  };

  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener('load', () => resolve(image));
      image.addEventListener('error', (error) => reject(error));
      image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues
      image.src = url;
    });

  const getCroppedImg = async (imageSrc, pixelCrop) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error('Canvas is empty'));
          return;
        }
        resolve(blob);
      }, 'image/jpeg');
    });
  };

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleCloseColor = () => {
    setDisplayColorPicker(false);
  };

  const handleChange = (newColor) => {
    setColor(newColor.hex);
    setDetails((prev) => ({
      ...prev,
      backgroundColor: newColor.hex,
    }));
  };

  const languages = [
    {
      code: 'en',
      name: 'English',
      country_code: 'gb',
    },
    {
      code: 'ar',
      name: 'العربية',
      dir: 'rtl',
      country_code: 'sa',
    },
  ];

  const currentLanguageCode = cookies.get('i18next') || 'en';
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);

  useEffect(() => {
    document.body.dir = currentLanguage.dir || 'ltr';
    document.title = t('app_title');
  }, [currentLanguage, t]);

  const token = localStorage.getItem('token')
  
  useEffect(() => {
      if(token){
        dispatch(getAllSubcription());
        dispatch(getCompanySettings());
        dispatch(getConnectedIntegration());
      }
  }, [token]);

  useEffect(() => {
    if(token){
      dispatch(getAllReviewerPolicy());
      dispatch(getBillingAmount());
    }
  }, [dispatch, token]);

  useEffect(() => {
    if (companyDetails) {
      setDetails((prev) => ({
        ...prev,
        companyUrl: companyDetails?.companyUrl || null,
        companyLogo: companyDetails?.companyLogo,
        backgroundColor: companyDetails?.backgroundColor || "#FFFFF",
      }));
      setColor(companyDetails?.backgroundColor);
    }
  }, [companyDetails]);

  const onSubmit = (data) => {
    if (policyId) {
      console.log('isDirty', isDirty);
      if (!isDirty) {
        handleClose();
        reset();
        return;
      }
      dispatch(updateReviewerPolicy(data))
        .unwrap()
        .then((res) => {
          toast.success(res?.message);
          handleClose();
          dispatch(getAllReviewerPolicy());
        }).catch((error) => {
          toast.error(error?.message);
        });
    } else {
      dispatch(addReviewerPolicy(data))
        .unwrap()
        .then((res) => {
          handleClose();
          reset();
          toast.success(res?.message);
          dispatch(getAllReviewerPolicy());
        }).catch((error) => {
          toast.error(error?.message);
        });
    }
  };

  const handleSave = async () => {
    const urlPattern = /^(https?:\/\/|www\.)\w+(\.\w+)+([/?].*)?$/;
    if (!details?.companyLogo) {
      toast.error("Please select company logo");
      return;
    }
    if (details?.companyUrl && !urlPattern.test(details?.companyUrl)) {
      // toast.error("Please enter proper url");
      setCompanyURlError(true);
      return;
    }
    const requestData = { ...details };

    if (details?.file) {
      const formData = new FormData();
      formData.append('file', details?.file);
      const response = await dispatch(uploadLogo(formData)).unwrap();
      requestData["companyLogo"] = response[0]?.data?.url;
    }

    setShowColorPicker(false);

    delete requestData.file;

    if (!requestData?.companyUrl || requestData?.companyUrl === '')
      requestData['companyUrl'] = null;

    await dispatch(updateCompanyDetails(requestData))
      .unwrap()
      .then(res => {
        toast.success(res?.message);
        dispatch(getCompanySettings());
      })
      .catch(error => {
        toast.error(error?.message);
      });
  };

  const requiredFieldLabel = (labelText, htmlFor) => {
    return (
      <label className="form-label" htmlFor={htmlFor}>
        {labelText}
        <span
          className="absolute top-0 left-0"
          style={{ transform: "translate(-50%, -50%)", color: "#FF0000" }}
        >
          {" "}
          *
        </span>
      </label>
    );
  };

  const handleUpdateSubscription = (id) => {
    const requestData = {
      subscriptionId: id,
    };
    dispatch(updateSubscription(requestData))
      .unwrap()
      .then((data) => {
        const response = data.response.data;
        if(response.name === 'Success'){
          toast.success(response.message || "Updated Successfully");
          dispatch(updateFeaturesList(subscriptionPlans.find(item => item.subscriptionId == id)));
          dispatch(updateRoutes());
          navigate('/walkThrough');
        }else{
          toast.error(response.message || "Something went wrong");
        }
        handleClose();
      })
      .catch((error) => {
        toast.error(error.message || "Request Failed Please Try Again ");
      });
  };

  
  const isAnyCustomPlan = subscriptionPlans.filter(item => item.isCustom)
  return (
    <>
      <div className={`settings h-auto ${currentLanguageCode === 'en' ? '' : 'white-container-arabic'}`}>
        {
          isLoading && <Loader />
        }
        <center>
        <br/><br/><br/>
            <div className='col-7 border-grey mt-4 p-3 inner-tabs'>
           
        <div className="row ml-3 subscriptionPlansCss">
          <div>
          <img
            src={Logo2}
            className="logo mt-1 mb-4"
            alt="Sample image"
            style={{ width: '200px', height: 'auto' }} 
          />
        </div>

        <h1>Choose your Plan</h1>
        <br/><br/>
        <p style={{ color: 'gray' }}>Get the right plan for your business. Plans can be upgraded in the future</p>
        {subscriptionPlans && 
            subscriptionPlans.map((sub, index) => {
              const isActive = sub?.activesub === "1"; 
              const isCurrent = sub?.currentSubs === true; 
              const colorStyle ={color: isCurrent || isActive ? "#333333": '#8B9299'}
              return (
                
                <div style={{ width: '240px' }} key={index} className="col-lg-3 col-md-4 col-sm-6 mb-2">
                  <div className={`subscriptionPlansCard ${isActive ? "subscriptionPlansCard-Active" : ""}`} 
                  style={{backgroundColor: isCurrent ? '#5DDCB7' : "#fff"}}>
                    <div className="cardContent">
                      <p className={isActive ? "subscriptionPlanMainTittle-Active" : "subscriptionPlanMainTittle"}>
                        {t(sub?.subscriptionName)}
                      </p>
                      <p className={isActive ? "billedMonthlyText-Active" : "billedMonthlyText"}
                      style={colorStyle}>
                        {t("settingstab.billed_monthly.")}
                      </p>
                      <div style={{maxHeight:'2rem'}}>
                        <p className={`${isActive ? "PlanGrayDes-Active" : "PlanGrayDes"}`}
                        style={colorStyle}>
                          {sub.description}
                        </p>
                      </div>
                      <div className="d-flex align-items-end mb-2">
                        <p className={isActive ? "subscriptionPlanMainTittle-Active mb-0" : "subscriptionPlanMainTittle mb-0"}>
                          {sub.licensePrice} QAR
                        </p>
                        <p className={isActive ? "PlanGrayText-Active mb-1 ps-1" : "PlanGrayText mb-1 ps-1"}
                        style={colorStyle}>/monthly</p>
                      </div>
                      <p className={isActive ? "whatIncludedText-Active" : "whatIncludedText"}>What’s included</p>
                      {[sub?.pricePerCard, sub?.noOfUsers, sub?.noOfCards].map((item, idx) => (
                        <div className="d-flex align-items-center mb-2" key={idx}>
                          <img src={CheckIconGreen} style={{ width: 24, objectFit: "contain", marginRight: 10, marginLeft: 10 }} />
                          <p className={isActive ? "PlanGrayText-Active mb-0" : "PlanGrayText mb-0"}
                          style={colorStyle}>
                              {t(idx === 1 ? "settingstab.users_numbers" : idx === 2 ? "settingstab.cards_numbers" : "settingstab.QAR_for_card", {
                                number: item,
                                cards: 1
                              })}
                          </p>
                        </div>
                      ))}
                        
                        {/* Render modules */}
                      {sub?.modules?.map((el, idx) => (
                        <div className="d-flex align-items-center mb-2" key={idx}>
                          <img src={CheckIconGreen} style={{ width: 24, objectFit: "contain", marginRight: 10, marginLeft: 10 }} />
                            <p className={`${isActive ? "PlanGrayText-Active mb-0" : "PlanGrayText mb-0"}`} 
                            style={colorStyle}>
                              {t(el)}
                            </p>
                        </div>
                      ))}
                    </div>
                    {!isAnyCustomPlan?.length ? 
                      <button
                        onClick={() => {
                          if (!isCurrent) {
                            handleUpdateSubscription(sub?.subscriptionId);
                          }
                        }}
                        style={{
                          backgroundColor: isCurrent ? "#AEEDDB" : "#0EDB9B",
                          color: isCurrent ? "#49DCB2" : "#fff",
                          border: "none",
                          padding: "12px 20px",
                          borderRadius: "20px",
                        }}
                        className="w-100 mt-3"
                        disabled={isCurrent}
                      >
                        {isCurrent ? "Current Plan" : "Get Subscription"}
                      </button>
                    : <></>}

                  </div>
                </div>
              );
        })}
      </div>

      </div>
      </center>
      </div>
      <Modal className='UserModal' show={show} onHide={handleClose} style={{ paddingRight: 0 }}>
        {
          isLoading && <Loader />
        }
        <Modal.Body>
          <div className='d-flex align-items-center justify-content-between green-header px-3' style={{ marginBottom: 8 }}>
            <h2 className='green-header-text'> {title}</h2>
            <div onClick={handleClose} style={{ cursor: 'pointer' }}>
              <img src={ImagesCross} style={{ width: 24, objectFit: 'contain' }} />
            </div>
          </div>

          <form>
            <div className="form-outline mt-4 mb-3">
              {requiredFieldLabel(t('settingstab.policy_name'), 'policyName')}
              <input type="text" id="policyName" className="form-control form-control-lg"
                placeholder={`Enter ${t('settingstab.policy_name')}`}
                {...register('policyName', {
                  required: `${t('settingstab.policy_name')} Is Required.`,
                  pattern: {
                    message: "Only alphabets are required"
                  }
                })}
                onInput={(e) => {
                  const value = e.target.value;
                  if (!/^[a-zA-Z]*$/.test(value)) {
                    e.target.value = value.slice(0, -1);
                  }
                }}
              />
              {errors?.policyName && (
                <p style={{ color: 'red' }}>{errors?.policyName?.message}</p>
              )}
            </div>
            <div className="form-outline mt-4 mb-3">
              {requiredFieldLabel(t('settingstab.max_card_transactions'))}
              <CurrencyInput
                type="text"
                id="maxCardTransactions"
                className="form-control form-control-lg"
                placeholder={`Enter ${t('settingstab.max_card_transactions')}`}
                autoComplete="off"
                value={watch('maxcardTransactionsAmount') || ''}
                decimalsLimit={2}
                decimalScale={2}
                disablenegative={'true'}
                onKeyDown={(e) => {
                  if (e.key === '-') {
                    e.preventDefault();
                  }
                }}
                {...register('maxcardTransactionsAmount', {
                  required: `${t('settingstab.max_card_transactions')} Is Required.`,
                })}
                onValueChange={(value) => {
                  const numericValue = parseFloat(value?.replace(/,/g, '')) || 0;
                  if (numericValue < MAX_VALUE) {
                    setValue('maxcardTransactionsAmount', value === '' ? '' : value ? parseFloat(value) : '');
                  }
                  else {
                    setValue('maxcardTransactionsAmount', value?.slice(0,6));
                  }
                }}
              />
              {errors?.maxcardTransactionsAmount && (
                <p style={{ color: 'red' }}>{errors?.maxcardTransactionsAmount?.message}</p>
              )}
            </div>
            <div className="form-outline mt-4 mb-3">
              {requiredFieldLabel(t('settingstab.max_topup_requests'))}
              <CurrencyInput
                type="text"
                id="maxTopupRequests"
                className="form-control form-control-lg"
                placeholder={`Enter ${t('settingstab.max_topup_requests')}`}
                decimalsLimit={2}
                decimalScale={2}
                disablenegative={'true'}
                value={watch('maxCardTopupAmount') || ''}
                onKeyDown={(e) => {
                  if (e.key === '-') {
                    e.preventDefault();
                  }
                }}
                {...register('maxCardTopupAmount', {
                  required: `${t('settingstab.max_topup_requests')} Is Required.`,
                })}
                onValueChange={(value) => {
                  const numericValue = parseFloat(value?.replace(/,/g, '')) || 0;
                  if (numericValue < MAX_VALUE) {
                    setValue('maxCardTopupAmount', value === '' ? '' : value ? parseFloat(value) : '');
                  }
                  else {
                    setValue('maxCardTopupAmount', value?.slice(0,6));
                  }
                }}
              />
              {errors?.maxCardTopupAmount && (
                <p style={{ color: 'red' }}>{errors?.maxCardTopupAmount?.message}</p>
              )}
            </div>
            <div className="form-outline mt-4 mb-4">
              {requiredFieldLabel(t('settingstab.max_reimbursement_requests'))}
              <CurrencyInput
                type="text"
                id="maxReimbursementRequests"
                className="form-control form-control-lg"
                placeholder={`Enter ${t('settingstab.max_reimbursement_requests')}`}
                decimalsLimit={2}
                decimalScale={2}
                disablenegative={'true'}
                value={watch('maxReimbursementsAmount') || ''}
                onKeyDown={(e) => {
                  if (e.key === '-') {
                    e.preventDefault();
                  }
                }}
                {...register('maxReimbursementsAmount', {
                  required: `${t('settingstab.max_reimbursement_requests')} Is Required.`,
                })}
                onValueChange={(value) => {
                  const numericValue = parseFloat(value?.replace(/,/g, '')) || 0;
                  if (numericValue < MAX_VALUE) {
                    setValue('maxReimbursementsAmount', value === '' ? '' : value ? parseFloat(value) : '');
                  }
                  else {
                    setValue('maxReimbursementsAmount', value?.slice(0,6));
                  }
                }}
              />
              {errors?.maxReimbursementsAmount && (
                <p style={{ color: 'red' }}>{errors?.maxReimbursementsAmount?.message}</p>
              )}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className='px-0 pt-4'>
          <button onClick={() => { handleClose(); reset(); setPolicyId(null); }} className='btn-secondary-small' id='cancel_add_policy'>{t('cancel')}</button>
          <button onClick={handleSubmit(onSubmit)} className='btn-primary-small' id='submit_add_policy'>
            {policyId ? t('update') : t('add')}
          </button>
        </Modal.Footer>
      </Modal>
      <Modal size='lg' centered className='ColorPickerModal' show={showColorPicker} onHide={handleCloseColorPicker} animation='false'>
        <Modal.Header>
          <div onClick={handleCloseColorPicker} className=' align-items-center' style={{ cursor: 'pointer' }}>
            <img src={ImagesCross} width={24} style={{ objectFit: 'contain' }} />
          </div>
        </Modal.Header>
        <Modal.Body style={{ position: 'relative' }}>
          <div>
            <div className='colorPickerModalDiv'>
              <div className='uploadImgSection'>
                {showCropper && imageSrc ? (
                  <>
                    <div style={{ position: 'absolute', width: '770px', height: '430px', backgroundColor: '#fafafa', padding: 20, borderRadius: 20, border: 'solid 1px #000' }}>
                      <div style={{ position: 'absolute', width: '730px', height: '320px' }}>
                        <Cropper
                          image={imageSrc}
                          crop={crop}
                          zoom={zoom}
                          aspect={25 / 12}
                          onCropChange={setCrop}
                          onZoomChange={setZoom}
                          onCropComplete={onCropComplete}
                          style={{ borderRadius: 15 }}
                        />
                      </div>
                      <button className='btn-primary-small' style={{ position: 'absolute', bottom: 10 }} onClick={showCroppedImage} id='crop_image'>Crop Image</button>
                    </div>
                  </>
                ) : (
                  <>
                    {details?.companyLogo ? (
                      <img
                        src={details?.companyLogo ?? CompanyColorLogo}
                        style={{ backgroundColor: color }}
                        className="prvImgInUploadSec"
                      />
                    ) : (
                      <div
                        className="clickToUpload"
                        style={{ backgroundColor: color }}
                        onClick={() => companyLogoRef.current.click()}
                      >
                        <div
                          className="d-flex align-items flex-column"
                          style={{ height: "inherit" }}
                        >
                          <div className="mb-auto mx-auto">
                            <img
                              className="uploadImgsectionImg"
                              src={UploadImgColorPickerSec}
                              style={{ marginLeft: 10 }}
                            ></img>
                            <div className="clickToUploadText">
                              Click to upload
                            </div>
                          </div>
                          <div
                            className="logoText"
                            style={{ textAlign: "center" }}
                          >
                            LOGO
                          </div>
                          <div
                            className="pngSizeText"
                            style={{ textAlign: "center" }}
                          >
                            PNG (max 800 x 400px)
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className='colorPickerSection'>
                <div className="d-flex align-items-start flex-column" style={{ minHeight: 263 }}>
                  <div className='mb-auto'>
                    <div className='pickColorText'>Select Background Color <span className="absolute top-0 left-0" style={{ transform: 'translate(-50%, -50%)', color: '#FF0000' }}> *</span></div>
                    <div className='d-flex align-items-center'>
                      <div className='colorPickerCustom'>
                        <div className='swatch' onClick={handleClick}>
                          <div className='color' style={{ background: color }} />
                        </div>
                        {displayColorPicker && (
                          <div className='popover'>
                            <div className='cover' onClick={handleCloseColor} />
                            <SketchPicker disableAlpha color={color} onChange={handleChange} />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='uploadLogoLink' onClick={() => companyLogoRef.current.click()} >
                    <img className='uploadLogoImgLink' src={UploadLogoIcon}></img>
                    <input type="file" id='upload_image' accept=".jpg, .jpeg, .png" ref={companyLogoRef} style={{ display: 'none' }} onChange={handleImageUpload} />
                    <div className="pngSizeCss">{details?.companyLogo ? "Reupload Logo" : "Upload Logo"}</div>
                    </div>
                  </div>
                </div>
              </div>
            <label className="form-label" htmlFor="companyurl">Company URL</label>
            <input value={details?.companyUrl} placeholder='www.company.com' onChange={event => { setDetails(prev => ({ ...prev, companyUrl: event.target.value })); setCompanyURlError(false); }} type="text" id="companyurl" className="form-control form-control-lg" />
            {
              companyURlError && <p style={{ color: 'red', fontSize: 15, paddingLeft: 10 }}>Please enter a valid URL.</p>
            }
            <div className='d-flex justify-content-end'>
              <button onClick={handleSave} className='btn-primary-small mt-3'id='save' >Save</button>
            </div>

          </div>
        </Modal.Body>
      </Modal>
      <img className='login_bottom_image-1' src={login_bottom_image} />

    </>
  );
}

export default Subscriptions;