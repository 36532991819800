import React, { useEffect, useState } from 'react';
import { getAllSubscription, getCompanySettings, getSubscriptionWithoutAuth, updateSubscription } from '../../pages/Settings/taxSlice';
import { useDispatch, useSelector } from 'react-redux';
import cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BackArrow from '../../assets/images/backarrow1.png';
import CheckIconGreen from '../../assets/images/Check_icon_green.png';
import Loader from '../../components/Loader/Loader';
import Logo2 from '../../assets/images/polo.png';
import login_bottom_image from '../../assets/images/login_bottom_image.png';
import { setUserLoggedInData, updateFeaturesList, updateRoutes } from '../Users/userSlice';
import { registration } from '../Login/registerSlice';

function Subscriptions() {
  const { t } = useTranslation();
  const { isLoading, subscriptionPlansList } = useSelector((state) => state?.taxSlice);
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch();
  const { state } = useLocation();

  const navigate = useNavigate();

  const languages = [
    {
      code: 'en',
      name: 'English',
      country_code: 'gb',
    },
    {
      code: 'ar',
      name: 'العربية',
      dir: 'rtl',
      country_code: 'sa',
    },
  ];

  const currentLanguageCode = cookies.get('i18next') || 'en';
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);

  useEffect(() => {
    document.body.dir = currentLanguage.dir || 'ltr';
    document.title = t('app_title');
  }, [currentLanguage, t]);

  useEffect(() => {
    dispatch(getSubscriptionWithoutAuth());
  }, []);

  const handleUpdateSubscription = (id) => {
    if (!state?.registerPayload) {
      toast.error('Invalid payload. Please complete the registration process properly.');
      return
    }
    setLoading(true)
    dispatch(registration({ ...state?.registerPayload, subscriptionId: id }))
      .unwrap()
      .then((res) => {
        toast.success(res.message);
        dispatch(setUserLoggedInData(res?.data));
        dispatch(updateFeaturesList(subscriptionPlansList.find(item => item.subscriptionId == id)));
        dispatch(updateRoutes());
        navigate('/walkThrough');
      }).catch((error) => {
        setLoading(false);
        toast.error(error?.message);
      });

  };

  return (
    <>
      <div className={`settings h-auto ${currentLanguageCode === 'en' ? '' : 'white-container-arabic'}`}>
        {
          isLoading && <Loader />
        }
        <div onClick={() => { navigate(-1)}} className="back-styles">
          <img src={BackArrow} style={{ width: 20, objectFit: 'contain', marginLeft: 5, marginRight: 5, transform: currentLanguageCode === 'en' ? 'rotate(0deg)' : 'rotate(180deg)' }} />
          {currentLanguageCode === 'ar' ? "الرجوع" : "Back"}

        </div>
        <center>
          <br /><br /><br />
          <div className='col-7 border-grey mt-4 p-3 inner-tabs'>

            <div className="row ml-3 subscriptionPlansCss">
              <div>
                <img
                  src={Logo2}
                  className="logo mt-1 mb-4"
                  alt="Sample image"
                  style={{ width: '200px', height: 'auto' }}
                />
              </div>

              <h1>{t('users.choose_your_plan')}</h1>
              <br /><br />
              <p style={{ color: 'gray' }}>{t('users.subscription_plan_desc')}</p>
              <div className='responsiveSubscriptionGrid'>
                {subscriptionPlansList &&
                  subscriptionPlansList.map((sub, index) => {
                    const isActive = sub?.activesub === "1";
                    const isCurrent = sub?.currentSubs === true;
                    const colorStyle = { color: isCurrent || isActive ? "#333333" : '#8B9299' }
                    return (
                      <div key={index} className="mb-2 subscriptionPlanContainer">
                        <div className={`subscriptionPlansCard ${isActive ? "subscriptionPlansCard-Active" : ""}`}
                          style={{ backgroundColor: isCurrent ? '#5DDCB7' : "#fff" }}>
                          <div className="cardContent">
                            <p className={isActive ? "subscriptionPlanMainTittle-Active" : "subscriptionPlanMainTittle"}>
                              {t(sub?.subscriptionName)}
                            </p>
                            <p className={isActive ? "billedMonthlyText-Active" : "billedMonthlyText"}
                              style={colorStyle}>
                              {t("settingstab.billed_monthly.")}
                            </p>
                            <div style={{ maxHeight: '2rem' }}>
                              <p className={`${isActive ? "PlanGrayDes-Active" : "PlanGrayDes"}`}
                                style={colorStyle}>
                                {sub.description}
                              </p>
                            </div>
                            <div className="d-flex align-items-end mb-2">
                              <p className={isActive ? "subscriptionPlanMainTittle-Active mb-0" : "subscriptionPlanMainTittle mb-0"}>
                                {sub.licensePrice} QAR
                              </p>
                              <p className={isActive ? "PlanGrayText-Active mb-1 ps-1" : "PlanGrayText mb-1 ps-1"}
                                style={colorStyle}>/monthly</p>
                            </div>
                            <p className={isActive ? "whatIncludedText-Active" : "whatIncludedText"}>What’s included</p>
                            {[sub?.pricePerCard, sub?.noOfUsers, sub?.noOfCards].map((item, idx) => (
                              <div className="d-flex align-items-center mb-2" key={idx}>
                                <img src={CheckIconGreen} style={{ width: 24, objectFit: "contain", marginRight: 10, marginLeft: 10 }} />
                                <p className={isActive ? "PlanGrayText-Active mb-0" : "PlanGrayText mb-0"}
                                  style={colorStyle}>
                                  {t(idx === 1 ? "settingstab.users_numbers" : idx === 2 ? "settingstab.cards_numbers" : "settingstab.QAR_for_card", {
                                    number: item,
                                    cards: 1
                                  })}
                                </p>
                              </div>
                            ))}

                            {/* Render modules */}
                            {sub?.modules?.map((el, idx) => (
                              <div className="d-flex align-items-center mb-2" key={idx}>
                                <img src={CheckIconGreen} style={{ width: 24, objectFit: "contain", marginRight: 10, marginLeft: 10 }} />
                                <p className={`${isActive ? "PlanGrayText-Active mb-0 px-0" : "PlanGrayText mb-0"}`}
                                  style={colorStyle}>
                                  {t(el)}
                                </p>
                              </div>
                            ))}
                          </div>
                          <button
                            onClick={() => {
                              if (!isCurrent) {
                                handleUpdateSubscription(sub?.subscriptionId);
                              }
                            }}
                            style={{
                              backgroundColor: isCurrent ? "#AEEDDB" : "#0EDB9B",
                              color: isCurrent ? "#49DCB2" : "#fff",
                              border: "none",
                              padding: "12px 20px",
                              borderRadius: "20px",
                            }}
                            className="w-100 mt-3"
                            disabled={isCurrent}
                          >
                            {loading ? 'Loading...' : isCurrent ? t('users.current_Plan') : t('users.get_subscription')}
                          </button>

                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </center>
      </div>
      <img className='login_bottom_image-1' src={login_bottom_image} />
    </>
  );
}

export default Subscriptions;