import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const XpendlessUtils = {

  ScrollToTop() {
    const { pathname } = useLocation();
    useEffect(() => {
      document.documentElement.scrollTo({
        top: 0,
        left: 0,
        behavior: "instant",
      });
    }, [pathname]);
    return null;
  },
};

export function bytesToMB(bytes) {
  if (bytes === 0) return '0 MB';

  const megabytes = bytes / (1024 * 1024);
  return `${megabytes.toFixed(2)} MB`;
}

const formatTime = (utcTimestamp) => {
  const clientTimezone = momentTimezone.tz.guess();
  const localTime = momentTimezone.utc(utcTimestamp).tz(clientTimezone);
  const formattedTime = localTime.format('MMM DD, YYYY hh:mm A');
  return formattedTime;
};

const formatTimeHours = (utcTimestamp) => {
  const clientTimezone = momentTimezone.tz.guess();
  const localTime = momentTimezone.utc(utcTimestamp).tz(clientTimezone);
  const formattedTime = localTime.format('hh:mm A');
  return formattedTime;
};

const formatDate = (utcTimestamp) => {
  const clientTimezone = momentTimezone.tz.guess();
  const localTime = momentTimezone.utc(utcTimestamp).tz(clientTimezone);
  const formattedTime = localTime.format('MMM DD, YYYY');
  return formattedTime;
};


const formatQAR = (amount) => {
  const formattedAmount = amount?.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
  return formattedAmount;
};

const formatQARWithoutDecimal = (amount) => {
  const formattedAmount = amount.toLocaleString('en-US');
  return formattedAmount;
};

const timeUTCToLocal = (time) => {
  const utcMoment = moment.utc(time, "HH:mm A");
  const localMoment = utcMoment.local();
  const localTime = localMoment.format("HH:mm A");

  return localTime;
};

function createStartDateWithTimezone(dateString) {
  const [year, month, day] = dateString.split('-').map(Number);
  const localDate = new Date(Date.UTC(year, month - 1, day, 0, 0, 0));
  return localDate.toISOString();
}

function createEndDateWithTimezone(dateString) {
  const [year, month, day] = dateString.split('-').map(Number);
  const localDate = new Date(Date.UTC(year, month - 1, day, 21, 0, 0));
  return localDate.toISOString();
}

const dashboardTransactionByVendorColor = ['#FFC300', '#FF5733', '#1C6AF8', '#FFA07A', '#2E8B57', '#5787c9', '#6a83a6', '#1c529e', '#4583d9', '#77a8ed'];

const handleTrimmedValue = (value) => value.trim();

const cleanAmount = (amount) => {
  if (amount.endsWith('.')) {
    return amount.slice(0, -1);
  }
  if (amount.startsWith('.')) {
    return `0${amount}`;
  }
  return amount;
};

const truncateDescription = (description, maxLength = 50) => {
  if (!description) return '';
  if (description.length <= maxLength) return description;
  return description.substring(0, maxLength) + '...';
};

const getDecimalNo = (currencyCode, allCurrencies)=>{
  const currency = allCurrencies?.find(currency => currency.code === currencyCode);
  return currency ? currency.unit : 0;
};

export { XpendlessUtils, formatTime, dashboardTransactionByVendorColor, createStartDateWithTimezone, createEndDateWithTimezone, formatQAR, formatQARWithoutDecimal, timeUTCToLocal, handleTrimmedValue, cleanAmount, truncateDescription, getDecimalNo, formatDate, formatTimeHours };
