import React, { useEffect } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import { useForm } from "react-hook-form";
import Divider from "../../assets/images/Divider.png";
import LoadingComponent from '../Loader/LoadingComponent';

const CompanySchema = yup.object().shape({
  registrationNumber: yup
    .string()
    .trim()
    .required("Registration number is required")
    .matches(/^[A-Za-z0-9 ]+$/, "Special characters are not allowed"),

  companyName: yup.string().required("Company Name is required"),
  crCreationDate: yup.string().required("Creation Date is required"),
  crExpiryDate: yup.string().required("Expiry Date is required"),
  addressLine1: yup.string().required("Address Line one is required"),
  addressLine2: yup.string().trim().nullable(),
  city: yup.string().required("City is required"),

  postalCode: yup
    .string()
    .trim()
    .nullable()
    .test(
      "is-valid-postal-code",
      "Please enter valid postal code",
      function (value) {
        if (value && value.trim() !== "") {
          return /^[0-9]+$/.test(value);
        }
        return true; // Allows empty value
      }
    ),

  companyPhone: yup
    .string()
    .trim()
    .required("Phone Number is required")
    .matches(/^[0-9]+$/, "Please enter valid Phone Number")
    .max(10, "Phone Number must be 10 digits"),

  companyEmail: yup
    .string()
    .matches(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/,
      "Invalid email"
    )
    .required("Email is required")
    .email("Please enter valid email"),
});

function CompanyDetails({ companyDetail, onSubmit, companyStatus, isLoading }) {
  const { t } = useTranslation();
  const currentLanguageCode = cookies.get("i18next") || "en";
  const today = new Date().toISOString().split("T")[0];
  const {
    register,
    setValue: setCompanyValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(CompanySchema),
  });

  useEffect(() => {
    if (companyDetail) {
      setCompanyValue("registrationNumber", companyDetail?.registrationNumber);
      setCompanyValue("companyName", companyDetail?.companyName);
      setCompanyValue("crCreationDate", companyDetail?.crCreationDate);
      setCompanyValue("crExpiryDate", companyDetail?.crExpiryDate);
      setCompanyValue("addressLine1", companyDetail?.addressLine1);
      setCompanyValue("addressLine2", companyDetail?.addressLine2);
      setCompanyValue("city", companyDetail?.city);
      setCompanyValue("postalCode", companyDetail?.postalCode);
      setCompanyValue("companyPhone", companyDetail?.companyPhone);
      setCompanyValue("companyEmail", companyDetail?.companyEmail);
    }
  }, [companyDetail]);

  const languages = [
    {
      code: "en",
      name: "English",
      country_code: "gb",
    },
    {
      code: "ar",
      name: "العربية",
      dir: "rtl",
      country_code: "sa",
    },
  ];

  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);

  useEffect(() => {
    document.body.dir = currentLanguage.dir || "ltr";
    document.title = t("app_title");
  }, [currentLanguage, t]);

  const requiredFieldLabel = (labelText, htmlFor) => {
    return (
      <label className="form-label" htmlFor={htmlFor}>
        {labelText}
        <span
          className="absolute top-0 left-0"
          style={{ transform: "translate(-50%, -50%)", color: "#FF0000" }}
        >
          *
        </span>
      </label>
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{position:'relative'}}>
      {(isLoading || !companyDetail) && <LoadingComponent />}
      <div className="row">
        <div className="col-lg-6 col-md-12">
          <div className="form-outline mt-2 mb-3">
            {requiredFieldLabel(t("company.commercial_reg"), "commercialReg")}
            <input
              type="text"
              id="commercialReg"
              required={true}
              placeholder={t("company.enter_commercial_reg")}
              className={`form-control form-control-lg ${
                errors?.registrationNumber ? "is-invalid" : ""
              }`}
              {...register("registrationNumber")}
            />
            {errors?.registrationNumber && (
              <div className="invalid-feedback">
                {errors?.registrationNumber?.message}
              </div>
            )}
          </div>
        </div>
        <div className="col-lg-6 col-md-12">
          <div className="form-outline mt-2 mb-3">
            {requiredFieldLabel(t("company.trade_name"), "tradeName")}
            <input
              type="text"
              id="tradeName"
              required={true}
              placeholder={t("company.enter_trade_name")}
              className={`form-control form-control-lg ${
                errors?.companyName ? "is-invalid" : ""
              }`}
              {...register("companyName")}
            />
            {errors?.companyName && (
              <div className="invalid-feedback">
                {errors?.tradeName?.message}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 col-md-12">
          <div className="form-outline mt-2 mb-3">
            {requiredFieldLabel(t("company.creation_date"), "creationDate")}
            <input
              type="date"
              id="creationDate"
              required={true}
              max={today}
              className={`form-control form-control-lg ${
                errors?.crCreationDate ? "is-invalid" : ""
              }`}
              {...register("crCreationDate")}
            />
            {errors?.crCreationDate && (
              <div className="invalid-feedback">
                {errors?.crCreationDate?.message}
              </div>
            )}
          </div>
        </div>
        <div className="col-lg-6 col-md-12">
          <div className="form-outline mt-2 mb-3">
            {requiredFieldLabel(t("company.expiry_date"), "expiryDate")}
            <input
              type="date"
              id="expiryDate"
              required={true}
              min={today}
              className={`form-control form-control-lg ${
                errors?.crExpiryDate ? "is-invalid" : ""
              }`}
              {...register("crExpiryDate")}
            />
            {errors?.crExpiryDate && (
              <div className="invalid-feedback">
                {errors?.crExpiryDate?.message}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="form-outline mt-2 mb-3">
        {requiredFieldLabel(t("company.address_line_1"), "addressLine1")}
        <input
          type="text"
          id="addressLine1"
          required={true}
          placeholder={`${t("enter_address")}`}
          className={`form-control form-control-lg ${
            errors?.addressLine1 ? "is-invalid" : ""
          }`}
          {...register("addressLine1")}
        />
        {errors?.addressLine1 && (
          <div className="invalid-feedback">
            {errors?.addressLine1?.message}
          </div>
        )}
      </div>
      <div className="form-outline mt-2 mb-3">
        <label className="form-label" htmlFor="addressLine2">
          {t("company.address_line_2")}
        </label>
        <input
          type="text"
          id="addressLine2"
          placeholder={`${t("enter_address")}`}
          className={`form-control form-control-lg ${
            errors?.addressLine2 ? "is-invalid" : ""
          }`}
          {...register("addressLine2")}
        />
        {errors?.addressLine2 && (
          <div className="invalid-feedback">
            {errors?.addressLine2?.message}
          </div>
        )}
      </div>
      <div className="row ">
        <div className="col-lg-6 col-md-12">
          <div className="form-outline mt-2 mb-3">
            {requiredFieldLabel(t("settingstab.city"), "city")}
            <input
              type="text"
              id="city"
              required={true}
              placeholder={t("company.select_city")}
              className={`form-control form-control-lg ${
                errors?.city ? "is-invalid" : ""
              }`}
              {...register("city")}
            />
            {errors?.city && (
              <div className="invalid-feedback">{errors?.city?.message}</div>
            )}
          </div>
        </div>
        <div className="col-lg-6 col-md-12">
          <div className="form-outline mt-2 mb-3">
            <label className="form-label" htmlFor="postalCode">
              {t("company.postal_code")}
            </label>
            <input
              type="text"
              id="postalCode"
              placeholder={`${t("settingstab.enter_code")}`}
              className={`form-control form-control-lg ${
                errors?.postalCode ? "is-invalid" : ""
              }`}
              {...register("postalCode")}
            />
            {errors?.postalCode && (
              <div className="invalid-feedback">
                {errors?.postalCode?.message}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 col-md-12">
          <div className="form-outline mt-2 mb-3">
            {requiredFieldLabel(t("company.phone_number"), "phoneNumber")}
            <div className="input-group">
              {<span className="input-group-text">+974</span>}
              <input
                type="text"
                id="phoneNumber"
                required={true}
                placeholder={`${t("company.enter_phone_number")}`}
                className={`form-control form-control-lg ${
                  errors?.companyPhone ? "is-invalid" : ""
                }`}
                {...register("companyPhone")}
              />
              {errors?.companyPhone && (
                <div className="invalid-feedback">
                  {errors?.companyPhone?.message}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-12">
          <div className="form-outline mt-2 mb-3">
            {requiredFieldLabel(t("email_address"), "emailAddress")}
            <input
              type="text"
              id="emailAddress"
              required={true}
              placeholder={`${t("company.enter_email_address")}`}
              className={`form-control form-control-lg ${
                errors?.companyEmail ? "is-invalid" : ""
              }`}
              {...register("companyEmail", {
                pattern: {
                  value:
                    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/,
                  message: "Invalid email address",
                },
              })}
            />
            {errors?.companyEmail && (
              <div className="invalid-feedback">
                {errors?.companyEmail?.message}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="d-flex mb-4 mt-4">
        <img src={Divider} alt="" style={{ width: "100%" }} />
      </div>
      <div className="d-flex align-items-center justify-content-center">
        <button
          type="submit"
          className={
            companyStatus === "InActive"
              ? "btn-primary-small"
              : "disabledButton"
          }
          style={{ width: "70%" }}
          id="company_details_next"
        >
          {t("next")}
        </button>
      </div>
    </form>
  );
}
export default CompanyDetails;
