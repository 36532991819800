export const MAX_VALUE = 10000000;
export const CARD_TYPE = {
  VIRTUAL: "virtual",
  PHYSICAL: "physical",
};

export const PCI_WIDGET_OPERATION = {
  GET_PIN: "GET_PIN",
  SHOW_CLEAR_CARD_DETAILS: "SHOW_CLEAR_CARD_DETAILS",
  CHANGE_PIN: "CHANGE_PIN",
};
