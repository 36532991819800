import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  addCorporateCards,
  editCorporateCard,
  fetchCorporateCardsSearch,
} from './slice/cardListSlice';
import { toast } from 'react-toastify';

function CardCooperateForm({
  cardTypeDetails,
  networkDetails,
  departmentEmployees,
  currencies,
  bankDetails,
  handleClose1,
  updateCorporateCard,
  pageNo,
  setShow,
}) {
  const [formData, setFormData] = useState({
    networkId:
      networkDetails.find(
        (item) => item.networkName === updateCorporateCard?.networkName
      )?.networkId || '',
    cardTypeId:
      cardTypeDetails.find(
        (item) => item.cardTypeName === updateCorporateCard?.cardTypeName
      )?.cardTypeId || '',
    employeeId:
      departmentEmployees?.data.find(
        (item) =>
          item.firstName + item.lastName ===
          updateCorporateCard?.firstName + updateCorporateCard?.lastName
      )?.employeeId || '',

    accountName: updateCorporateCard?.accountName || '',
    currencyId:
      currencies.find((item) => item?.code === updateCorporateCard?.currency)
        ?.currencyId || '',
    bankId:
      bankDetails.find(
        (item) => item.bankName === updateCorporateCard?.bankName
      )?.bankId || '',
    expiryDate: updateCorporateCard?.expiryDate || '',
    description: updateCorporateCard?.description || '',
  });

  const dispatch = useDispatch();

  const handleSave = () => {
    let dataToSend = {
      ...formData,
      networkId: formData.networkId ? Number(formData.networkId) : '',
      cardTypeId: formData.cardTypeId ? Number(formData.cardTypeId) : '',
      employeeId: formData.employeeId ? Number(formData.employeeId) : '',
      currencyId: formData.currencyId ? Number(formData.currencyId) : '',
      bankId: formData.bankId ? Number(formData.bankId) : '',
    };

    if (!formData.expiryDate) delete dataToSend.expiryDate;
    if (!formData.description) delete dataToSend.description;

    if (updateCorporateCard && updateCorporateCard?.corporateCardId) {
      dispatch(
        editCorporateCard({
          ...dataToSend,
          corporateCardId: updateCorporateCard?.corporateCardId,
        })
      )
        .unwrap()
        .then((res) => {
          toast.success(res?.message);
          setShow(false);
          dispatch(fetchCorporateCardsSearch(`?page=${pageNo}`));
        })
        .catch((error) => {
          toast.error(error?.message);
        });
    } else {
      dispatch(addCorporateCards(dataToSend))
        .unwrap()
        .then((res) => {
          toast.success(res?.message);
          setShow(false);
          dispatch(fetchCorporateCardsSearch(`?page=${pageNo}`)); // Refresh the card list
        })
        .catch((error) => {
          toast.error(error?.message);
        });
    }
  };

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div>
      <div className='card-cooperate-group mt-4'>
        <label htmlFor='networkId'>Network*</label>
        <select
          id='networkId'
          name='networkId'
          value={formData.networkId}
          onChange={onInputChange}
          className={`card-cooperate-form ${
            !formData.networkId ? 'dropdown-placeholder' : ''
          }`}
        >
          <option value=''>Select Network</option>
          {networkDetails?.map((network) => (
            <option key={network.networkId} value={network.networkId}>
              {network.networkName}
            </option>
          ))}
        </select>
      </div>
      <div className='card-cooperate-group mt-4'>
        <label htmlFor='cardTypeId'>Card Type*</label>
        <select
          id='cardTypeId'
          name='cardTypeId'
          value={formData.cardTypeId}
          onChange={onInputChange}
          className={`card-cooperate-form ${
            !formData.cardTypeId ? 'dropdown-placeholder' : ''
          }`}
        >
          <option value=''>Select Card Type</option>
          {cardTypeDetails?.map((card) => (
            <option key={card.cardTypeId} value={card.cardTypeId}>
              {card.cardTypeName}
            </option>
          ))}
        </select>
      </div>
      <div className='card-cooperate-group mt-4'>
        <label htmlFor='employeeId'>Employee*</label>
        <select
          id='employeeId'
          name='employeeId'
          value={formData.employeeId}
          onChange={onInputChange}
          className={`card-cooperate-form ${
            !formData.employeeId ? 'dropdown-placeholder' : ''
          }`}
        >
          <option value=''>Select Employee</option>
          {departmentEmployees?.data?.map((employee) => (
            <option key={employee.employeeId} value={employee.employeeId}>
              {`${employee.firstName} ${employee.lastName}`}
            </option>
          ))}
        </select>
      </div>
      <div className='card-cooperate-group mt-4'>
        <label htmlFor='accountName'>Account Name*</label>
        <input
          id='accountName'
          type='text'
          name='accountName'
          value={formData.accountName}
          onChange={onInputChange}
          className={`card-cooperate-form ${
            !formData.accountName ? 'dropdown-placeholder' : ''
          }`}
          placeholder='Account Name'
        />
      </div>{' '}
      <div className='card-cooperate-group mt-4'>
        <label htmlFor='currencyId'>Currency*</label>
        <select
          id='currencyId'
          name='currencyId'
          value={formData.currencyId}
          onChange={onInputChange}
          className={`card-cooperate-form ${
            !formData.currencyId ? 'dropdown-placeholder' : ''
          }`}
        >
          <option value=''>Select Currency</option>
          {currencies?.map((currency) => (
            <option key={currency.currencyId} value={currency.currencyId}>
              {currency.code}
            </option>
          ))}
        </select>
      </div>
      <div className='card-cooperate-group mt-4'>
        <label htmlFor='bankId'>Bank Name*</label>
        <select
          id='bankId'
          name='bankId'
          value={formData.bankId}
          onChange={onInputChange}
          className={`card-cooperate-form ${
            !formData.bankId ? 'dropdown-placeholder' : ''
          }`}
        >
          <option value=''>Select Bank</option>
          {bankDetails?.map((bank) => (
            <option key={bank.bankId} value={bank.bankId}>
              {bank.bankName}
            </option>
          ))}
        </select>
      </div>
      <div className='card-cooperate-group mt-4'>
        <label htmlFor='expiryDate'>Expiry Date</label>
        <input
          id='expiryDate'
          name='expiryDate'
          type='date'
          value={formData.expiryDate}
          onChange={onInputChange}
          className={`card-cooperate-form ${
            !formData.expiryDate ? 'dropdown-placeholder' : ''
          }`}
          placeholder='Expiry Date'
        />
      </div>
      <div className='card-cooperate-group mt-4'>
        <label htmlFor='description'>Description</label>
        <textarea
          id='description'
          name='description'
          value={formData.description}
          onChange={onInputChange}
          className={`card-cooperate-form ${
            !formData.description ? 'dropdown-placeholder' : ''
          }`}
          placeholder='Expiry Date'
          rows='4'
        />
      </div>
      <div
        style={{
          display: 'flex',
          marginBottom: '20px',
          height: '180px',
          flexDirection: 'column',
        }}
      >
        <div
          className='mt-4 mb-10'
          style={{
            marginBottom: '8px',
            width: '100%',
            borderTop: '1px solid #ccc',
          }}
        />
        <div
          className='space-x-4 gap-4 mt-4 mb-10'
          style={{
            display: 'flex',
            gap: '8px',
            marginBottom: '20px',
            alignItems: 'center',
          }}
        >
          <button
            type='button'
            onClick={handleClose1}
            className='btn btn-secondary'
            style={{
              borderRadius: '20px',
              padding: '0px 18px 0px 18px',
            }}
          >
            Cancel
          </button>
          <button
            type='button'
            onClick={handleSave}
            className='btn btn-primary'
            style={{
              borderRadius: '20px',
              padding: '0px 18px 0px 18px',
            }}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

export default CardCooperateForm;