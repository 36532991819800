import React from 'react';
import './InValidCompanyStatusComponent.scss';
import { useNavigate } from 'react-router-dom';

function InValidCompanyStatusComponent() {
    const navigate = useNavigate();

    return (
      <div className='container'>
        <div className='inner-container'>
        <div className="invalid-company-status">
            <div className="verification-card">
                <div className="icon">
                    <span>!</span>
                </div>
                <p>
                    Complete a company verification to unlock all the tools and features we offer.
                </p>
                <button className="verify-button" onClick={() => navigate('/companyverification')}>Verify Now</button>
            </div>
        </div>
        </div>

       </div>
    );
}

export default InValidCompanyStatusComponent;
