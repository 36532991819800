/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';
import BackArrow from '../../assets/images/backarrow1.png';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Tab, Tabs } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import RecepitIcon from '../../assets/images/greyRecipt.png';
import GreenRecepitIcon from '../../assets/images/doneRecipt.png';
import ReviewGreyIcon from '../../assets/images/defDoc.png';
import ReviewGreenIcon from '../../assets/images/docAcept.png';
import ReviewRedIcon from '../../assets/images/rejected.png';
import CategoryGreyIcon from '../../assets/images/menu.png';
import CategoryGreenIcon from '../../assets/images/menuGreen.png';
import ExportGreyIcon from '../../assets/images/docArrow.png';
import ExportGreenIcon from '../../assets/images/export_icon_green.png';
import uploadDoc from '../../assets/images/upload_document.png';
import ErrorImage from '../../assets/images/warning-orange-icon.png';
import Accordion from 'react-bootstrap/Accordion';
import { useDispatch, useSelector } from 'react-redux';
import { approveRejectExpense, fetchExpenseActivityLog, getAllTagByGroup, getExpenseDetails, saveTags, updateCardTransaction, uploadDocument, uploadTransactionReceipt, fetchCurrencies } from '../../pages/Expenses/expensesSlice';
import LoadingComponent from '../../components/Loader/LoadingComponent';
import { toast } from 'react-toastify';
import DynamicFieldArray from '../DynamicFieldArray/DynamicFieldArray';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { fetchReimbursementActivityLog } from '../../pages/Reimbursement/reimbursementSlice';
import Slider from 'react-slick';
import DocRedCrossBtn from '../../assets/images/close-btn-img.png';
import btnPlusIcon from '../../assets/images/btn_plus_icon.png';
import PdfComponent from './PdfComponent';
import CloseImage from '../../assets/images/close_square_white.png';
import { Worker } from '@react-pdf-viewer/core';
import { Viewer } from '@react-pdf-viewer/core';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import ImagesCross from '../../assets/images/close-square-8.png';
import { formatTime, formatTimeHours, formatDate, getDecimalNo } from '../../utils/XpendlessUtils';
import moment from 'moment-timezone';
import { activeBlocked, verifyCompanyStatus } from '../../utils/verifyCompanyStatus';
import { allowedUserRoles, verifyUserRole } from '../../utils/verifyUserRole';
import IconButton from '../Buttons/IconButton';
import DangerButton from '../Buttons/DangerButton';
import PrimaryButton from '../Buttons/PrimaryButton';
import SecondaryButton from '../Buttons/SecondaryButton';

function ExpensesDetails() {
    const { state, pathname } = useLocation();
    const { transactionId } = useParams();

    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [event, setEvent] = useState("1");
    const [details, setDetails] = useState(null);
    const [isEnabled, setIsEnabled] = useState(false);
    const [imageUrl, setImageUrl] = useState(false);
    const [showFullScreen, setShowFullScreen] = useState(false);
    const [showFullScreen2, setShowFullScreen2] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [attachments, setAttachments] = useState([]);
    const [attachmentLoader, setAttachmentLoader] = useState(false);
    const [forReview, setForReview] = useState(false);
    const [showRejectModal, setShowRejectModal] = useState(false);
    const filePermission = state?.filePermission;
    const type = state?.type;
    const validationSchemas = {};
    const isFirstRender = useRef(true);
    const fileInputRef = useRef(null);
    const addAttachmentRef = useRef(null);

    const {
        control: controlRejectForm,
        handleSubmit: handleRejectSubmit,
        formState: { errors: errorsRejectForm },
        reset: resetRejectForm, } = useForm({
            resolver: yupResolver(yup.object().shape({
                comments: yup.string().required()
            })
            )
        });

    const languages = [
        {
            code: 'en',
            name: 'English',
            country_code: 'gb',
        },
        {
            code: 'ar',
            name: 'العربية',
            dir: 'rtl',
            country_code: 'sa',
        },
    ];

    const reviewIcons = {
        pending: ReviewGreyIcon,
        approve: ReviewGreenIcon,
        reject: ReviewRedIcon,
        null: ReviewGreyIcon
    };

    const currentLanguageCode = cookies.get('i18next') || 'en';
    const currentLanguage = languages.find((l) => l.code === currentLanguageCode);

    const { isLoading, isExpenseDetailLoading, isReceiptLoading, expenseDetails, categoryList, tags, expenseActivityLogs, currencies } = useSelector(state => state.expenseReducer);

    const { reimbursementActivityLogs } = useSelector(state => state.reimbursementSlice);

    tags?.forEach((tagGroup) => {
        const fieldName = tagGroup.label.replace(/ /g, '_');
        const fieldSchema = yup.object().shape({
            amount: yup.number().min(0).typeError('Amount Should be Number').required("Amount is required"),
            tagId: yup.string().required("Please select Tag"),
        });
        validationSchemas[fieldName] = yup.array().of(fieldSchema);
    });

    const { handleSubmit, control, register, formState: { errors }, reset, setValue, watch } = useForm({
        resolver: yupResolver(yup.object().shape(validationSchemas)),
    });

    //const wholePart = details?.amount;
    const wholePart = parseFloat(details?.amount) || 0.00;

    const decimalDigits = getDecimalNo(details?.code, currencies);
    const [wholePart2, decimalPart2] = (details?.selectedCurrencyAmount)
        ? details?.selectedCurrencyAmount.toString().split('.')
        : ['', ''];

    const formattedDecimalPart2 = decimalDigits > 0
        ? (decimalPart2 !== undefined
            ? decimalPart2.slice(0, decimalDigits).padEnd(decimalDigits, '0')
            : '0'.repeat(decimalDigits))
        : '';

    useEffect(() => {
        dispatch(fetchCurrencies());
    }, []);

    useEffect(() => {
        if (transactionId) {
            if (verifyCompanyStatus(activeBlocked) && verifyUserRole(allowedUserRoles.adminAndAccountant)) {
                dispatch(getAllTagByGroup());
            }
            fetchTransactionData();
            setForReview(state?.forReview || false);
        }
    }, [transactionId]);

    useEffect(() => {
        setDetails(expenseDetails);
    }, [expenseDetails]);

    useEffect(() => {
        if (details?.receiptPath && details.receiptPath) {
            setImageUrl(details?.receiptPath);
        } else {
            setImageUrl(null);
        }
    }, [details]);

    useEffect(() => {
        setAttachments(details?.attachments);
    }, [details?.attachments]);

    useEffect(() => {
        if (event === '3') {
            changeTab(event);
        }
    }, [details, event]);

    useEffect(() => {
        document.body.dir = currentLanguage.dir || 'ltr';
        document.title = t('app_title');
    }, [currentLanguage, t]);

    const fetchTransactionData = () => {
        let queryparam = `?cardTransactionId=${transactionId}`;
        if (state?.eventType) {
            queryparam += `&eventType=${state?.eventType}`;
        }
        dispatch(getExpenseDetails(queryparam));
    };

    const changeTab = (eventKey) => {
        if (eventKey === '3') {
            const reimbursementQuery = `?reimbursementRequestId=${transactionId}`;
            const expenseQuery = `?cardTransactionId=${transactionId}`;
            const exportQueryParam = '&eventType=export';

            if (type === 'reimbursement') {
                const query = state?.eventType === 'export' ? `${reimbursementQuery}${exportQueryParam}` : reimbursementQuery;
                dispatch(fetchReimbursementActivityLog(query));
            } else {
                const query = state?.eventType === 'export' ? `${expenseQuery}${exportQueryParam}` : expenseQuery;
                dispatch(fetchExpenseActivityLog(query));
            }
        }
    };
    const updateTransaction = (requestData, imageUpload) => {
        if (transactionId && requestData && requestData.categoryId !== '' && !imageUpload) {
            requestData.cardTransactionId = transactionId;
            dispatch(updateCardTransaction(requestData)).unwrap().then(() => {
                toast.success('Data updated successfully');
                fetchTransactionData();
            }).catch((error) => {
                toast.error(error.message || "Request Failed Please Try Again ");
            });
        }
        if (transactionId && requestData && (imageUpload && imageUpload === 'imageUpload')) {
            dispatch(uploadTransactionReceipt({ requestData, cardTransactionId: transactionId })).unwrap().then(() => {
                fetchTransactionData();
                toast.success('Data updated successfully');
            }).catch((error) => {
                toast.error(error.message || "Receipt Upload Failed Please Try Again ");
            });
        }
    };

    const calculateTotalAmount = (data) => {
        if (Array.isArray(data)) {
            return data.reduce((result, category) => {
                const totalAmount = category
                    .map(item => (item?.amount) ? parseFloat(item.amount) : 0)
                    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

                return result + totalAmount;
            }, 0);
        } else if (typeof data === 'object') {
            return Object.values(data).reduce((result, category) => {
                const totalAmount = category
                    .map(item => (item?.amount) ? parseFloat(item.amount) : 0)
                    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

                return result + totalAmount;
            }, 0);
        }
        return 0;
    };

    const onSubmit = async (data) => {
        const formData = [];
        const cardTransactionId = transactionId;

        const balance = details?.amount;

        for (const key in data) {
            if (Object.prototype.hasOwnProperty.call(data, key)) {
                const totalAmount = data[key].reduce((sum, { amount }) => sum + parseFloat(amount), 0);

                if (totalAmount !== balance) {
                    toast.error(`The total splits for ${key} does not match the total amount.`);
                    return;
                }

                const transformedArray = data[key].map(({ tagId, amount }) => ({
                    cardTransactionId,
                    tagId,
                    amount
                }));
                formData.push(...transformedArray);
            }
        }
        try {
            const response = await dispatch(saveTags({ postData: formData, cardTransactionId: cardTransactionId }));
            if (response) {
                toast.success("Tags saved successfully");
            }
        } catch (error) {
            toast.error("Failed to save tags");
        }
        setIsEnabled(false);
    };

    const handleImageClick = () => {
        setShowFullScreen(true);
    };

    const uploadReceiptClick = () => {
        if (filePermission)
            fileInputRef.current.click();
    };

    const addAttachmentClick = () => {
        if (filePermission)
            addAttachmentRef.current.click();
    };

    const uploadReceiptChange = (event) => {
        isFirstRender.current = false;
        const file = event.target.files[0];
        if (file) {
            const allowedExtensions = ['jpg', 'png', 'jpeg', 'pdf'];
            const fileExtension = file.name.slice(((file.name.lastIndexOf(".") - 1) >>> 0) + 2);
            if (allowedExtensions?.includes(fileExtension.toLowerCase())) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    setImageUrl(e.target.result);
                    updateTransaction(file, 'imageUpload');
                };
                reader.readAsDataURL(file);
            } else {
                alert('Please select a JPG or PNG image.');
                event.target.value = '';
            }
        }
    };

    const attachmentsUploads = (event) => {
        setAttachmentLoader(true);
        isFirstRender.current = false;
        const files = event.target.files;
        const allowedExtensions = ['jpg', 'png', 'jpeg', 'pdf'];

        const existingAttachments = attachments ? [...attachments] : [];
        const newAttachments = [];

        for (const file of files) {
            const fileExtension = file.name.slice(((file.name.lastIndexOf(".") - 1) >>> 0) + 2);
            const fileType = allowedExtensions.includes(fileExtension.toLowerCase())
                ? (fileExtension.toLowerCase() === 'pdf' ? 'pdf' : 'image')
                : 'invalid';

            if (fileType !== 'invalid') {
                const url = URL.createObjectURL(file);
                newAttachments.push({ type: fileType, url: url, file: file });
            } else {
                console.error(`Please select a file with one of the allowed extensions: ${allowedExtensions.join(', ')}`);
            }
        }
        handleAddAttachments([...existingAttachments, ...newAttachments]);
    };

    const createFilesArray = (inputArray) => {
        const resultArray = inputArray.map(obj => {
            let newObj;
            if (typeof obj !== 'string') {
                newObj = { ...obj };
                delete newObj.url;
                delete newObj.type;
            } else {
                newObj = obj;
            }
            return newObj;
        });
        return resultArray;
    };

    const handleAddAttachments = async (addedAttachments) => {
        const attachCopy = createFilesArray(addedAttachments);
        const newFile = [];
        for (let i = 0; i < attachCopy.length; i++) {
            if (attachCopy[i]?.file) {
                const formData = new FormData();
                formData.append('file', attachCopy[i]?.file);
                const response = await dispatch(uploadDocument(formData)).unwrap();
                attachCopy[i] = response[0]?.data?.url;
                newFile.push(response[0]?.data?.url);
            }
        }
        setAttachments(attachCopy);
        handleUpdateCardTransaction(attachCopy, newFile, null);
    };

    const handleUpdateCardTransaction = async (attachCopy, added, removed) => {
        let attach = {
            cardTransactionId: transactionId,
            attachments: attachCopy ? attachCopy : attachments
        };
        if (removed) attach["removed"] = removed;
        if (added) attach['added'] = added;
        await dispatch(updateCardTransaction(attach)).unwrap().then(() => {
            setAttachmentLoader(false);
            addAttachmentRef.current.value = '';
            toast.success(`${t('expenses.Attachments_updated_successfully')}`);
        }).catch((error) => {
            setAttachmentLoader(false);
            toast.error(error.message || "Request Failed Please Try Again ");
        });
    };

    const handleRemoveImage = (index) => {
        isFirstRender.current = false;
        const updatedAttachments = [...attachments];
        const removedElement = attachments[index];
        updatedAttachments.splice(index, 1);
        setAttachments(updatedAttachments);
        handleUpdateCardTransaction(updatedAttachments, null, removedElement);
    };

    const isWrongDetail = useMemo(() => {
        return details &&
            details.receiptOCRdata !== null && (
                (details?.receiptOCRdata?.amount !== details?.amount)
                || (details?.receiptOCRdata?.merchantName?.toLowerCase() !== details?.merchantName?.toLowerCase())
                || (formatTime(details?.createdAt) !== formatTime(details?.receiptOCRdata?.date))
            );
    }, [details]);

    const handleCloseRejectModal = () => setShowRejectModal(false);
    const handleShowRejectModal = () => setShowRejectModal(true);
    const approveRejectRequest = (data) => {
        if (transactionId) {
            let requestData = {
                approveStatus: data.type,
                cardTransactionId: transactionId
            };
            if (typeof data.comments !== 'undefined') {
                requestData.comments = data.comments;
            }

            dispatch(approveRejectExpense(requestData)).unwrap().then(() => {
                toast.success('Status Changed successfully');
                resetRejectForm();
                handleCloseRejectModal();
                setForReview(false);
                navigate(-1);
            }).catch((error) => {
                toast.error(error.message || "Request Failed Please Try Again ");
            });
        }
    };
    var settings = {
        dots: true,
        arrows: false,
        speed: 500,
        autoplaySpeed: 2000,
        slidesToShow: 2.5,
        slidesToScroll: 1,
        centerMode: false,
        infinite: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false,
                }
            },
        ],
    };

    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        sidebarTabs: () => [],
    });

    return (
        <div className={`expense-detail-css settings ${currentLanguageCode === 'en' ? 'white-container' : 'white-container-arabic'}`}>
            <div className='d-flex align-items-center justify-content-between'>
                <h1 className='text-green'>{t('expenses.expense_details')}</h1>
                <IconButton
                    type={'button'}
                    onClick={() => navigate(state?.prevPath,
                        {
                            state: {
                                prevPath: pathname,
                                parentTab: state?.parentTab,
                                type: state?.type,
                                activeKey: state?.activeKey
                            },
                        })}
                    buttonText={t('users.back')}
                    id={'back'}
                    src={BackArrow}
                    btnStyle={{ marginInlineEnd: '8px', width: '150px' }}
                    iconStyle={{ marginInlineEnd: '3px' }}
                    isFilled={false}
                    currentLanguageCode={currentLanguageCode}
                />
            </div>
            <p className='small-text mb-4'>Track and Review Expense Details with Comprehensive Audit Trail.</p>
            <Tabs defaultActiveKey={1} animation="true" id="expense-tabs" onSelect={(eventKey) => setEvent(eventKey)}>
                <Tab eventKey={1} title={`${t('expenses.details')}`}>
                    <div className='green-border'>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <div className='green-box px-2 py-3' style={{ position: 'relative' }}>
                                    {isExpenseDetailLoading && <LoadingComponent />}
                                    <div className='d-flex justify-content-between'>
                                        <div className='dark-text mb-0'>
                                            {`${wholePart2?.toLocaleString()}`}
                                            <span className="text-grey" style={{ fontSize: 12, fontWeight: '500' }}>
                                                {`.`}
                                                {`${formattedDecimalPart2}`}
                                            </span>
                                            <span className="text-grey" style={{ fontSize: 12, fontWeight: '500' }}>
                                                {` ${(details?.code) ? details?.code : 'QAR'}`}
                                            </span>
                                            {details?.code !== "QAR" && (
                                                <p className='dark-text mb-0'>
                                                    {`${Math.floor(wholePart || 0).toLocaleString()}.`}
                                                    <span className='light-text'>
                                                        {`${(wholePart?.toFixed(2).split('.')[1]) !== "0000" ? wholePart?.toFixed(2).split('.')[1] : '00'}`}
                                                    </span>
                                                    <span className='light-text'>QAR</span>
                                                </p>
                                            )}
                                        </div>
                                        <p className='dark-text'>{details?.merchantName}</p>
                                    </div>
                                    <div className='d-flex justify-content-between align-items-center mt-3'>
                                        <p className='dark-grey-text'>{formatDate(details?.createdAt)}</p>
                                        <p className='dark-grey-text' style={{ fontSize: 16, fontWeight: 400 }}>{formatTimeHours(details?.createdAt)}</p>
                                    </div>
                                </div>
                                <div className='border-grey p-3 mt-3 expense-card-1' style={{ borderRadius: 15, position: 'relative' }}>
                                    {isExpenseDetailLoading && <LoadingComponent />}
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <img src={imageUrl ? GreenRecepitIcon : RecepitIcon} style={{ width: 38, objectFit: 'contain', marginRight: 15, marginLeft: 15 }} title={imageUrl ? "Receipt is uploaded" : "No Receipt"} />
                                        <img src={reviewIcons[(details?.decision) || null]} style={{ width: 38, objectFit: 'contain', marginRight: 15, marginLeft: 15 }} title={(details?.decision) === 'reject' ? "Rejected" : (details?.decision) ? "Approved" : "No Action"} />
                                        <img src={details?.categoryId ? CategoryGreenIcon : CategoryGreyIcon} style={{ width: 38, objectFit: 'contain', marginRight: 15, marginLeft: 15 }} title={details?.categoryId ? "Category is selected" : "No Category"} />
                                        <img src={details?.isExported ? ExportGreenIcon : ExportGreyIcon} style={{ width: 35, objectFit: 'contain', marginRight: 15, marginLeft: 15 }} title={details?.isExported ? "Exported" : "Not Exported Yet"} />
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='border-grey p-3' style={{ borderRadius: 15, position: 'relative' }}>
                                    {isExpenseDetailLoading && <LoadingComponent />}
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <p className='dark-text m-0'>{t('name')}</p>
                                        <p className='dark-text m-0'>{details?.firstName} {details?.lastName}</p>
                                    </div>
                                    <div className='d-flex justify-content-between align-items-center mt-2'>
                                        <p className='dark-text'>{t('teams.departments')}</p>
                                        <p className='dark-text'>{details?.departmentName}</p>
                                    </div>
                                    <div className='row mb-2 align-items-center'>
                                        <div className='col-lg-5 col-sm-6 ed-label-1'>
                                            <p className='dark-text mb-0'>{t('expenses.category')}</p>
                                        </div>
                                        <div className='col-lg-7 col-sm-6 ed-label-1'>
                                            <div className='d-flex align-items-center'>
                                                {
                                                    details?.iconPath &&
                                                    <img onError={(e) => {
                                                        e.target.onerror = null;
                                                        e.target.style.display = 'none';
                                                    }} src={details?.iconPath} style={{ display: 'flex', width: 32, height: 32, objectFit: 'fill', borderRadius: 12, marginRight: 5, marginLeft: 10 }} />
                                                }
                                                <select
                                                    className="form-control form-select form-control-lg"
                                                    disabled={filePermission !== undefined ? filePermission ? false : true : false}
                                                    value={details?.categoryId ?? ''}
                                                    onChange={(event) => updateTransaction({ categoryId: event.target.value })}
                                                    id='select_category'
                                                >
                                                    {
                                                        type === "reimbursement" ?
                                                            <option value={null} key={'cardlist' + 'rem'}>Select category</option> :
                                                            <option value="">Select category</option>
                                                    }
                                                    {
                                                        categoryList && categoryList.length > 0 &&
                                                        categoryList.map((option, i) => (
                                                            <option value={option?.categoryId} key={'cardlist' + i}  >{option?.name}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row align-items-center'>
                                        <div className='col-lg-6 '>
                                            <p className='dark-text mb-0'>{t('teams.description')}</p>
                                        </div>
                                        <div className='col-lg-6'>
                                            <textarea
                                                type="text"
                                                id="description_expenses"
                                                className='form-control form-control-lg'
                                                placeholder='Descriptions'
                                                value={details?.description || ''}
                                                onChange={(event) => setDetails((prev) => ({ ...prev, description: event.target.value }))}
                                                disabled={filePermission !== undefined ? filePermission ? false : true : false}
                                                onBlur={() => updateTransaction({ description: details?.description })}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='cards-custom p-3 mt-3' style={{ borderRadius: 30, position: 'relative' }}>
                        {attachmentLoader && <LoadingComponent />}
                        <div style={{ marginBottom: 8 }} className='text-header'>{t('upload_document')}</div>
                        {
                            isWrongDetail &&
                            <div className='row g-1'>
                                <div className='col-lg-12 col-md-12 mt-2'>
                                    <div className='d-flex align-items-center justify-content-center'>
                                        <div className="border-orange p-3 d-flex flex-column" style={{ width: '100%', backgroundColor: '#FFFAF2' }}>
                                            <div className='d-flex justify-content-start align-items-center'>
                                                <div>
                                                    <img src={ErrorImage} style={{ height: '20px', objectFit: 'contain', width: '20px', marginRight: 10 }} />
                                                </div>
                                                <div className='d-flex flex-nowrap'>
                                                    <h3 className='text-warning mb-0 mt-1'>{t('expenses.error_heading')}</h3>
                                                </div>
                                            </div>
                                            <div style={{ color: '#FFA500', fontWeight: 400, marginTop: 10 }}>
                                                {
                                                    details?.receiptOCRdata?.amount !== details?.amount &&
                                                    <p className='mb-0'>Amount entered does’nt match with uploaded receipt</p>
                                                }
                                                {
                                                    (details?.receiptOCRdata?.merchantName?.toLowerCase() !== details?.merchantName?.toLowerCase()) &&
                                                    <p className='mb-0'>Merchant name entered does’nt match with uploaded receipt</p>
                                                }
                                                {
                                                    (formatTime(details?.createdAt) !== formatTime(details?.receiptOCRdata?.date)) &&
                                                    <p className='mb-0'>Date entered does’nt match with uploaded receipt</p>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className='row align-items-end'>
                            <div className='col-lg-7 p-3 pb-0'>
                                <div className='d-flex'>
                                    {
                                        (imageUrl || details?.receiptPath) &&
                                        <>
                                            {
                                                imageUrl && !details?.receiptPath?.includes('.pdf') ?
                                                    <div className='p-1' style={{ minWidth: '204px', borderRadius: 20 }}>
                                                        <img
                                                            src={imageUrl}
                                                            className='receipt_img_big'
                                                            onClick={handleImageClick}
                                                            alt="Receipt Image"
                                                            style={{ height: 204, objectFit: 'cover', width: 204, cursor: 'pointer', background: ' radial-gradient(#E0D7DA, #B7B0B7)', borderRadius: '20px' }}
                                                        />
                                                    </div> :
                                                    <div className='p-1' style={{ width: '204px' }}>
                                                        <PdfComponent handleImageClick={handleImageClick} url={details?.receiptPath}></PdfComponent>
                                                    </div>
                                            }
                                        </>
                                    }
                                    {showFullScreen && (
                                        <div
                                            className="fullscreen-overlay active"
                                        >

                                            <div className="close-image">
                                                <img
                                                    src={CloseImage}
                                                    onClick={(e) => { e.stopPropagation(); setShowFullScreen(false); }}
                                                    className='close-image-fullscreen'
                                                />
                                            </div>
                                            {imageUrl && !details?.receiptPath?.includes('.pdf') ? (
                                                <>
                                                    <div className='d-flex align-items-center justify-content-center'>
                                                        <TransformWrapper
                                                            initialScale={1}
                                                            initialPositionX={0}
                                                            initialPositionY={0}
                                                            centerOnInit={true}
                                                            smooth={true}
                                                        >
                                                            <TransformComponent wrapperStyle={{ width: '100%', height: window.innerHeight, }}>
                                                                <img
                                                                    src={imageUrl}
                                                                    className='receipt_img_big'
                                                                    onClick={handleImageClick}
                                                                    alt="Receipt Image"
                                                                    style={{ width: '100%', objectFit: 'contain', cursor: 'pointer', height: window.innerHeight, }}
                                                                />
                                                            </TransformComponent>
                                                        </TransformWrapper>
                                                    </div>
                                                </>
                                            ) : (
                                                <div style={{ width: '50%', height: '100%' }}>
                                                    <Worker workerUrl={pdfjsWorker}>
                                                        <Viewer
                                                            fileUrl={details?.receiptPath}
                                                            plugins={[defaultLayoutPluginInstance]}
                                                        />
                                                    </Worker>
                                                </div>
                                            )}
                                        </div>
                                    )}

                                    <div className='uplaod-box mx-3' style={{ position: 'relative' }}>
                                        {(isReceiptLoading || isLoading) && <LoadingComponent />}
                                        <div className='d-flex align-items-center justify-content-center' onClick={uploadReceiptClick}>
                                            <div>
                                                <div className='d-flex justify-content-center'>
                                                    <img src={uploadDoc} style={{ width: 60, alignItems: 'center' }} />
                                                </div>
                                                <p className='text-center mt-1'>
                                                    <span className='upload-text'>Upload Document</span><br />
                                                    <span className='document-name'>Receipt</span><br />
                                                    <span className='small-text-upload'>(max 5MB Accepted format: jpg, png, pdf)</span>
                                                </p>
                                                {filePermission &&
                                                    <input type="file" accept=".pdf, .jpg, .jpeg, .png" ref={fileInputRef} style={{ display: 'none' }} onChange={uploadReceiptChange} />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-5'>
                                <Slider {...settings}>
                                    {
                                        attachments?.length > 0 && attachments?.map((el, i) =>
                                            <>
                                                {
                                                    !el.includes('.pdf') ? (
                                                        <div className='d-flex align-items-center mt-3' key={`attachment${i}`}>
                                                            <div style={{ height: '100%', position: 'relative', background: ' radial-gradient(#E0D7DA, #B7B0B7)', borderRadius: 10 }}>
                                                                {
                                                                    filePermission &&
                                                                    <div className='clo-2' onClick={() => handleRemoveImage(i)} style={{ cursor: 'pointer', position: 'absolute', top: '0px', right: '-4px' }}>
                                                                        <img src={DocRedCrossBtn} style={{ width: 20, objectFit: 'contain', }} alt="Cross" />
                                                                    </div>
                                                                }
                                                                <div onClick={() => { setShowFullScreen2(true); setSelectedFile(el); }} style={{ height: 120, objectFit: 'cover', width: 120, cursor: 'pointer' }}>
                                                                    <img src={el} className='receipt_img' alt="attchments" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className='d-flex align-items-center' key={i}>
                                                            <div style={{ height: '100%', position: 'relative' }}>
                                                                <div className='clo-2' onClick={() => handleRemoveImage(i)} style={{ cursor: 'pointer', position: 'absolute', top: '0px', right: '-4px' }}>
                                                                    <img src={DocRedCrossBtn} style={{ width: 20, objectFit: 'contain' }} alt="Cross" />
                                                                </div>
                                                                <div onClick={() => { setShowFullScreen2(true); setSelectedFile(el); }} className='p-1' style={{ height: 120, objectFit: 'cover', width: 120, cursor: 'pointer' }}>
                                                                    <PdfComponent url={el} width={107} height={120}></PdfComponent>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        )
                                    }
                                </Slider>
                                {
                                    (details?.receiptOCRdata !== null && details?.receiptOCRdata !== undefined) &&
                                    <>
                                        <button className='btn-primary-small w-100 mt-4' id='add_attachment' onClick={addAttachmentClick}>Add Attachments<span><img style={{ marginLeft: '8px' }} src={btnPlusIcon} width={24} /></span></button>
                                        <input type="file" multiple accept=".pdf, .jpg, .jpeg, .png" ref={addAttachmentRef} style={{ display: 'none' }} onChange={attachmentsUploads} />
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    {
                        forReview &&
                        <div className='d-flex justify-content-end align-items-center flex-wrap'>
                            <DangerButton 
                                type={'button'}
                                onClick={handleShowRejectModal}
                                buttonText={t('reject')}
                                id={'reject'}
                                style={{ zIndex: 2, width: '116px', marginTop: '16px' }}
                            />
                            <div className='pe-3'></div>
                            <PrimaryButton 
                                type={'button'}
                                onClick={() => approveRejectRequest({ type: 'approve' })}
                                buttonText={t('approve')}
                                isEnabled={true}
                                id={'approve'}
                                style={{zIndex: 2, width: '116px', marginTop: '16px'}}
                                isLoading={isLoading}
                            />
                        </div>
                    }
                </Tab>
                <Tab eventKey={2} title={`${t('split')}`}>
                    <div className='row mt-2'>
                        {
                            !filePermission ? (
                                typeof details?.tags !== 'undefined' && details?.tags.length > 0 ? (
                                    details.tags.map((tagGroup, i) => (
                                        <div className='col-lg-6 col-md-12 p-2' key={tagGroup?.tagGroupId}>
                                            <Accordion defaultActiveKey={'tagGroups0'}>
                                                <Accordion.Item eventKey={'tagGroups' + i}>
                                                    <Accordion.Header>
                                                        <p className='text-black mb-0 fw-bold text-capitalize'>{tagGroup?.label}</p>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className='row'>
                                                            <div className='border-grey p-3' style={{ borderRadius: 15 }}>
                                                                <input
                                                                    type="text"
                                                                    id="form3Example3"
                                                                    className='form-control form-control-lg mt-2'
                                                                    placeholder='Banglore'
                                                                    value={tagGroup?.value}
                                                                    disabled={true}
                                                                />
                                                                <input
                                                                    type="text"
                                                                    id="form3Example3"
                                                                    className='form-control form-control-lg mt-2'
                                                                    placeholder='Hello'
                                                                    value={tagGroup?.amount.toLocaleString()}
                                                                    disabled={true}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </div>
                                    ))
                                ) : (
                                    <p className='text-center mt-3'>No tags available. Please add tags to display.</p>
                                )
                            ) : (
                                (typeof tags !== 'undefined' && tags.length > 0 && details) ? (
                                    <form onSubmit={handleSubmit(onSubmit)} className='row'>
                                        {
                                            tags.map((tagGroup, i) => (
                                                <div className='col-lg-6 col-md-12 p-2' key={`tag${tagGroup?.tagGroupId}`}>
                                                    <Accordion defaultActiveKey={'tagGroups0'}>
                                                        <Accordion.Item eventKey={'tagGroups' + i}>
                                                            <Accordion.Header>
                                                                <p className='text-black mb-0 fw-bold text-capitalize'>{tagGroup?.label}</p>
                                                            </Accordion.Header>
                                                            <Accordion.Body>
                                                                <DynamicFieldArray
                                                                    key={i}
                                                                    t={t}
                                                                    tags={tagGroup}
                                                                    register={register}
                                                                    deletedTag={details?.tags?.length > 0 && details?.tags}
                                                                    existingValue={details?.tags?.length > 0 && details?.tags?.filter(tag => tag?.label === tagGroup?.label)}
                                                                    setValue={setValue}
                                                                    name={(tagGroup?.label).replace(/ /g, '_')}
                                                                    control={control}
                                                                    errors={errors}
                                                                    watch={watch}
                                                                    onUpdate={(isUpdated) => { setIsEnabled(isUpdated); }}
                                                                    details={details}
                                                                    calculateTotalAmount={calculateTotalAmount}
                                                                    reset={reset}
                                                                    placeholderData={wholePart2 ? wholePart2 : wholePart}
                                                                />
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    </Accordion>
                                                </div>
                                            ))
                                        }

                                        <PrimaryButton
                                            type={'submit'}
                                            buttonText={t('apply')}
                                            isEnabled={isEnabled}
                                            id={'apply'}
                                            style={{width: '100%', marginTop: '5px'}}
                                            isLoading={isLoading}
                                            isAnimated={true}
                                        />
                                    </form>
                                ) : (
                                    <p className='text-center mt-3'>No tags available. Please add tags to display.</p>
                                )
                            )
                        }
                    </div>
                </Tab>
                <Tab eventKey={3} title={`${t('expenses.activities')}`} style={{ position: 'relative' }}>
                    {isLoading && <LoadingComponent />}
                    {
                        (type === 'reimbursement' ? reimbursementActivityLogs : expenseActivityLogs) && (type === 'reimbursement' ? reimbursementActivityLogs : expenseActivityLogs)?.length > 0 &&
                        (type === 'reimbursement' ? reimbursementActivityLogs : expenseActivityLogs)?.map((activity, index) => (
                            <div className='my-4 cards-custom p-3' key={index}>
                                <p className='text-date mb-0 ' style={{ fontSize: 20 }}>{moment(activity?.date).format('D MMM YYYY')}</p>
                                <div className='px-3'>
                                    {
                                        activity.records && (activity.records).length > 0 &&
                                        (activity.records).map((record, index) => (
                                            <div className='d-flex align-items-center justify-content-between my-3' key={`record${index}`}>
                                                {
                                                    record?.filePath ?
                                                        <p onClick={() => { setShowFullScreen2(true); setSelectedFile(record?.filePath); }} style={{ color: 'blue', cursor: 'pointer', textDecoration: 'underline' }} className='desc-text m-0'>{record?.description}</p>
                                                        :
                                                        <p className='desc-text m-0'>{record?.description}</p>
                                                }
                                                <p className='time-text m-0'>{formatTime(record?.time)}</p>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        ))
                    }
                </Tab>
            </Tabs>
            {showFullScreen2 && (
                <div className="fullscreen-overlay active">
                    <div className="close-image">
                        <img
                            src={CloseImage}
                            className='close-image-fullscreen'
                            onClick={(e) => { e.stopPropagation(); setShowFullScreen2(false); }}
                        />
                    </div>
                    {(typeof selectedFile === 'string' ? (selectedFile?.includes('.pdf') ? false : true) : selectedFile?.type !== 'pdf' ? true : false) ? (
                        <>
                            <div className='d-flex align-items-center justify-content-center'>
                                <TransformWrapper
                                    initialScale={1}
                                    initialPositionX={0}
                                    initialPositionY={0}
                                    centerOnInit={true}
                                >
                                    <TransformComponent wrapperStyle={{ width: '100%', height: window.innerHeight, }}>
                                        <img
                                            src={(selectedFile?.url ? selectedFile?.url : selectedFile)}
                                            className='receipt_img_big'
                                            onClick={handleImageClick}
                                            alt="Receipt Image"
                                            style={{ width: '100%', objectFit: 'contain', cursor: 'pointer', height: window.innerHeight, }}
                                        />
                                    </TransformComponent>
                                </TransformWrapper>
                            </div>
                        </>

                    ) : (
                        <div style={{ width: '50%', height: '100%' }}>
                            <Worker workerUrl={pdfjsWorker}>
                                <Viewer
                                    fileUrl={(selectedFile?.url ? selectedFile?.url : selectedFile)}
                                    plugins={[defaultLayoutPluginInstance]}
                                />
                            </Worker>
                        </div>
                    )
                    }
                </div>
            )
            }
            <Modal className='TaxcodeModalOne' show={showRejectModal} onHide={handleCloseRejectModal} style={{ paddingRight: 0 }}>
                <form onSubmit={handleRejectSubmit(approveRejectRequest)}>
                    <Modal.Body>
                        <div className='d-flex justify-content-between align-items-start'>
                            <div className='col-9'>
                                <p className='text-black modal-title' style={{ fontSize: 20 }}>
                                    {t('reject')}
                                </p>
                                <p className='text-grey'>{t('reject')}</p>
                            </div>
                            <div onClick={handleCloseRejectModal} className='clo-2' style={{ cursor: 'pointer' }}>
                                <img src={ImagesCross} style={{ width: 20, objectFit: 'contain' }} />
                            </div>
                        </div>
                        <Controller
                            name="type"
                            control={controlRejectForm}
                            defaultValue="reject"
                            render={({ field }) => (
                                <input
                                    {...field}
                                    type="hidden"
                                    id="amt"
                                    className="form-control form-control-lg"
                                    placeholder={t('cards.comments')}
                                />
                            )}
                        />
                        <div className="form-outline mt-4 mb-3">
                            <label className="form-label" htmlFor="comments">{t('cards.comments')}</label>
                            <Controller
                                name="comments"
                                control={controlRejectForm}
                                render={({ field }) => (
                                    <input type="text"  {...field} id="comments" className="form-control form-control-lg"
                                        placeholder={`${t('cards.comments')}`} />
                                )}
                            />
                            <p className="text-danger">{errorsRejectForm?.comments?.message}</p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <SecondaryButton 
                            type={'button'}
                            onClick={handleCloseRejectModal}
                            buttonText={t('cancel')}
                            id={'cancel_reject'}
                            style={{width: '102px'}}
                        />
                        <PrimaryButton 
                            type={'submit'}
                            buttonText={t('save')}
                            isEnabled={true}
                            id={'submit_reject'}
                            style={{width: '149px'}}
                            isLoading={isLoading}
                        />
                    </Modal.Footer>
                </form>
            </Modal>
        </div >
    );
}

export default ExpensesDetails;