import React from 'react';
import { Modal } from 'react-bootstrap';
import "./index.scss";
import { useTranslation } from 'react-i18next';

const ConfirmationDialog = ({ show, onClose, onConfirm, message, showHeaderTitle = false, confirmTitle = 'confirm' }) => {
  const { t } = useTranslation();
  return (
    <Modal show={show} onHide={onClose} centered id='ConfirmationDialog' backdrop="static">
      {showHeaderTitle &&
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
      }
      <Modal.Body className='text-center'>
        {message}
      </Modal.Body>

      <Modal.Footer>
        <button className='btn-secondary-small' onClick={onClose} id='cancel'> {t('cancel')} </button>
        <button className='btn-primary-small' onClick={onConfirm} id='confirm'> {t(confirmTitle)} </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationDialog;


// how to use this
{/* <ConfirmationDialog
      show={showConfirmation}
      onConfirm={handleConfirm}
      onClose={onClose}
      message="Are you sure you want to proceed to delete?"
  /> */}