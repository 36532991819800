import React, { useState, useEffect } from 'react';
import './NewSidebar.scss';
import logoutIcon from '../../assets/images/logoutIcon.png';
import { useTranslation } from 'react-i18next';
import { FaBars } from 'react-icons/fa';
import { motion } from "framer-motion";
import cookies from 'js-cookie';
import { useLocation, useNavigate, Link } from "react-router-dom";
import i18next from 'i18next';
import classNames from 'classnames';
import { decodeToken } from "react-jwt";
import Logo3 from '../../assets/images/newXpendlessLogo.png';
import SideBarSmallIcon from '../../assets/images/sidebarlogoCompress.png';
import DashboardIcon1 from '../../assets/images/Dashboard_icons.png';
import DashboardActive1 from '../../assets/images/DashbordActive.png';
import Accountsicons1 from '../../assets/images/Accounts_icon.png';
import AccountsActiveicon from '../../assets/images/Accounts_icon_active.png';
import TeamIcon1 from '../../assets/images/teams_icons.png';
import TeamActiveicon1 from '../../assets/images/teams_active_icon.png';
import UserIcon1 from '../../assets/images/usericons.png';
import UserActiveIcon1 from '../../assets/images/useractive.png';
import CorporateCardIcon from '../../assets/images/coorporate-card.png';
import XpendlessCardIcon from '../../assets/images/xpendless-card1.png';
import CardTopupIcon from '../../assets/images/request-topup1.png';
import CardsIcon1 from '../../assets/images/Cards-icon.png';
import SettingsIcon1 from '../../assets/images/SettingsIcons.png';
import SettingsActiveIcon1 from '../../assets/images/Settingsactiveicons.png';
import Language1 from '../../assets/images/language.png';
import ExpensesIcon1 from '../../assets/images/Expenses-icon.png';
import CompanyVerificationIcon1 from '../../assets/images/CompanyVerificationIcons.png';
import CompanyVerificationActiveIcon1 from '../../assets/images/CompanyVerificationActiveIcons.png';
import ExportIcon1 from '../../assets/images/ExportIcons.png';
import ExportActiveIcon1 from '../../assets/images/ExportActiveIcons.png';
import Out_of_pocket1 from '../../assets/images/out-of-pocket1.png';
import AnalyticsIcon1 from '../../assets/images/AnalyticsIcons.png';
import AnalyticsActiveIcon1 from '../../assets/images/AnalyticsActiveIcons.png';
import AuditIcon1 from '../../assets/images/AuditGreyImg.png';
import AuditActiveIcon1 from '../../assets/images/AuditWhiteImg.png';
import SidebarIcon1 from '../../assets/images/sidebarIcon1.png';
import SidebarIcon2 from '../../assets/images/sidebarIcon2.png';
import PettyCashIcon from '../../assets/images/pettycashIcon.svg';
import upArrow from '../../assets/images/up.png';
import downArrow from '../../assets/images/down.png';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanySettings } from '../../pages/Settings/taxSlice';
import { Stack } from 'react-bootstrap';
import { checkHasAccess } from '../../utils/checkAccess';

const languages = [
    {
        code: 'en',
        name: 'English',
        country_code: 'gb',
    },
    {
        code: 'ar',
        name: 'العربية',
        dir: 'rtl',
        country_code: 'sa',
    },
];
function NewSidebar(props) {
    const token = localStorage.getItem('token');
    const location = useLocation();
    const pathArray = window.location.href.split('/');
    const parentMenu = pathArray[3];
    const navigate = useNavigate();
    const currentLanguageCode = cookies.get('i18next') || 'en';
    const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
    const { t } = useTranslation();
    const decodedtoken = decodeToken(token);
    const dispatch = useDispatch();
    const { companyDetails } = useSelector((state) => state?.taxSlice);
    useEffect(() => {
        dispatch(getCompanySettings(false));
    }, []);
    useEffect(() => {
        document.body.dir = currentLanguage.dir || 'ltr';
        document.title = t('app_title');
    }, [currentLanguage, t]);
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const [expanded, setExpaned] = useState(false);
    const [cardsExpanded, setCardsExpanded] = useState(false);
    const [expensesExpanded, setExpensesExpanded] = useState(false);
    const [languageExpanded, setLanguageExpanded] = useState(false);
    const sidebarVariants = {
        true: {
            left: '0'
        },
        false: {
            left: '-100%'
        }
    };
    const logOut = (e) => {
        e.preventDefault();
        localStorage.removeItem('token');
        localStorage.removeItem('featuresList');
        navigate('/login');
    };
    const handleLogoClick = () => {
        if (companyDetails?.companyUrl) {
            let url = companyDetails.companyUrl.trim();
            if (!/^https?:\/\//i.test(url)) {
                url = `https://${url}`;
            }
            window.open(url, "_blank");
        }
    };
    return (
        <>
            <div className='col-lg-3 col-md-3 col-sm-12 leftbarapp' style={{ width: isOpen ? '8%' : '20%' }}>
                <>
                    <div className="bars" style={expanded ? { left: currentLanguageCode === 'en' ? '85%' : '85%', backgroundColor: 'transparent' } : { left: currentLanguageCode === 'en' ? '4%' : '85%', backgroundColor: '#fff' }} onClick={() => setExpaned(!expanded)}>
                        {expanded ?
                            <i className="fa fa-close" style={{ fontSize: 24, color: '#fff' }}></i> :
                            <FaBars color='rgb(36 143 113)' size={22} />}
                    </div>
                    <motion.div className="sidebar-sticky"
                        variants={sidebarVariants}
                        animate={window.innerWidth <= 992 ? `${expanded}` : ''}
                    >
                        <div className='d-flex flex-column justify-content-between'>
                            <div className='mb-3'>
                                <div className='d-flex align-items-center justify-content-between' style={{ position: 'relative' }}>
                                    <div className='d-flex flex-column'>
                                        <img
                                            style={{ height: 60 }}
                                            src={isOpen ? SideBarSmallIcon : Logo3}
                                            className={currentLanguageCode === 'en' ? (isOpen ? 'Leftbar-logo-offbar' : 'Leftbar-logo') : (isOpen ? 'Leftbar-logo-offbar-arabic' : 'Leftbar-logo-arabic')}
                                        />
                                    </div>
                                    <div className="bars-desktop" style={{ cursor: 'pointer', marginLeft: isOpen ? 25 : 0, marginTop: isOpen ? 20 : 0, marginBottom: isOpen ? 25 : 0 }} onClick={toggle}>
                                        {currentLanguageCode === 'en' ? (
                                            isOpen ? (
                                                <img src={SidebarIcon1} width={34} style={{ objectFit: 'contain', position: 'absolute', top: '34px', right: '-30px' }} />
                                            ) : (
                                                <img src={SidebarIcon2} width={34} style={{ objectFit: 'contain', position: 'absolute', right: '-30px' }} />
                                            )
                                        ) : (
                                            isOpen ? (
                                                <img src={SidebarIcon2} width={34} style={{ objectFit: 'contain', position: 'absolute', top: '34px', left: '-28px' }} />
                                            ) : (
                                                <img src={SidebarIcon1} width={34} style={{ objectFit: 'contain', position: 'absolute', left: '-28' }} />
                                            )
                                        )}
                                    </div>
                                </div>
                                <div className='menu-links' style={{ height: isOpen ? `${window.innerHeight - 300}px` : `${window.innerHeight - 390}px`, overflowY: 'scroll' }}>
                                    <ul className={currentLanguageCode === 'en' ? "nav flex-column" : "nav flex-column leftside"}>
                                        {(decodedtoken?.isAdmin || decodedtoken?.isAccountant)
                                            && <>
                                                {checkHasAccess('/') && (
                                                    <li onClick={() => setExpaned(!expanded)} className="nav-item">
                                                        <Link className={`${location.pathname === '/' ? "nav-link active" : "nav-link"}`} to="/">
                                                            <img src={location.pathname === '/' ? DashboardActive1 : DashboardIcon1} className='siderbar-icons' />
                                                            <p style={{ display: isOpen ? "none" : "block" }} className='navitems mb-0 sidetext'>{t('sidebar.dashboard')}</p>
                                                        </Link>
                                                    </li>
                                                )}
                                                {checkHasAccess('/account') && (
                                                    <li onClick={() => setExpaned(!expanded)} className="nav-item">
                                                        <Link className={`${location.pathname === '/account' ? "nav-link active" : "nav-link"}`} to="/account">
                                                            <img src={location.pathname === '/account' ? AccountsActiveicon : Accountsicons1} className='siderbar-icons' />
                                                            <p style={{ display: isOpen ? "none" : "block" }} className='navitems mb-0 sidetext'>{t('sidebar.company_wallet')}</p>
                                                        </Link>
                                                    </li>
                                                )}
                                                {checkHasAccess('/pettyCash') && (
                                                    <li onClick={() => setExpaned(!expanded)} className="nav-item">
                                                        <Link className={`${location.pathname === '/pettyCash' ? "nav-link active" : "nav-link"}`} to="/pettyCash">
                                                            <img src={location.pathname === '/pettyCash' ? PettyCashIcon : PettyCashIcon} className='siderbar-icons' />
                                                            <p style={{ display: isOpen ? "none" : "block" }} className='navitems mb-0 sidetext'>{t('sidebar.petty_cash')}</p>
                                                        </Link>
                                                    </li>
                                                )}

                                            </>}
                                        {(decodedtoken?.isAdmin || decodedtoken?.isAccountant) &&
                                            <>
                                                <li className={cardsExpanded ? 'submenu-container' : ''}>
                                                    <Link
                                                        className="nav-link menu"
                                                        to="#"
                                                        onClick={() => {
                                                            setExpaned(!expanded);
                                                            setCardsExpanded(!cardsExpanded);
                                                        }}>
                                                        <img
                                                            src={CardsIcon1}
                                                            className='siderbar-icons'
                                                        />
                                                        <Stack direction='horizontal' className='justify-content-between w-100'>
                                                            <p
                                                                style={{ display: isOpen ? "none" : "block" }}
                                                                className='navitems mb-0 sidetext'
                                                            >
                                                                {t('sidebar.cards')}
                                                            </p>
                                                            <img src={cardsExpanded ? upArrow : downArrow} className='submenu-icon' />
                                                        </Stack>
                                                    </Link>
                                                    <ul className={`submenu ${cardsExpanded ? 'expanded' : 'collapsed'}`}>
                                                        {checkHasAccess('/corporateCards') && (
                                                            <li className={`nav-item submenu-item ${cardsExpanded ? 'show' : 'hide'}`}>
                                                                <Link className={`${location.pathname === '/corporateCards' ? "nav-link active" : "nav-link"}`} to="/corporateCards">
                                                                    <img
                                                                        src={CorporateCardIcon}
                                                                        className='siderbar-icons'
                                                                    />
                                                                    <p style={{ display: isOpen ? "none" : "block" }} className='navitems mb-0 sidetext'>{t('sidebar.corporate_cards')}</p>
                                                                </Link>
                                                            </li>
                                                        )}
                                                        {checkHasAccess('/xpendlessCards') && (
                                                            <li className={`nav-item submenu-item ${cardsExpanded ? 'show' : 'hide'}`}>
                                                                <Link className={`${location.pathname === '/xpendlessCards' ? "nav-link active" : "nav-link"}`} to="/xpendlessCards">
                                                                    <img
                                                                        src={XpendlessCardIcon}
                                                                        className='siderbar-icons'
                                                                    />
                                                                    <p style={{ display: isOpen ? "none" : "block" }} className='navitems mb-0 sidetext'>{t('sidebar.xpendless_cards')}</p>
                                                                </Link>
                                                            </li>
                                                        )}
                                                        {checkHasAccess('/topuprequest') && (decodedtoken?.isAdmin) &&
                                                            <li className={`nav-item submenu-item ${cardsExpanded ? 'show' : 'hide'}`}>
                                                                <Link className={`${location.pathname === '/topuprequest' ? "nav-link active" : "nav-link"}`} to="/topuprequest">
                                                                    <img
                                                                        src={CardTopupIcon}
                                                                        className='siderbar-icons'
                                                                    />
                                                                    <p style={{ display: isOpen ? "none" : "block" }} className='navitems mb-0 sidetext'>{t('sidebar.card_topUp_request')}</p>
                                                                </Link>
                                                            </li>
                                                        }
                                                    </ul>
                                                </li>
                                            </>
                                        }
                                        <li style={{ marginTop: '5px' }} className={expensesExpanded ? 'submenu-container' : ''}>
                                            <Link
                                                className="nav-link menu"
                                                to="#"
                                                onClick={() => {
                                                    setExpaned(!expanded);
                                                    setExpensesExpanded(!expensesExpanded);
                                                }}>
                                                <img
                                                    src={ExpensesIcon1}
                                                    className='siderbar-icons'
                                                />
                                                <Stack direction='horizontal' className='justify-content-between w-100'>
                                                    <p
                                                        style={{ display: isOpen ? "none" : "block" }}
                                                        className='navitems mb-0 sidetext'
                                                    >
                                                        {t('sidebar.expenses')}
                                                    </p>
                                                    <img src={expensesExpanded ? upArrow : downArrow} className='submenu-icon' />
                                                </Stack>
                                            </Link>
                                            <ul className={`submenu ${expensesExpanded ? 'expanded' : 'collapsed'}`}>
                                                {checkHasAccess('/corporateExpenses') && (
                                                    <li className={`nav-item submenu-item ${expensesExpanded ? 'show' : 'hide'}`}>
                                                        <Link className={`${location.pathname === '/corporateExpenses' ? "nav-link active" : "nav-link"}`} to="/corporateExpenses">
                                                            <img
                                                                src={CorporateCardIcon}
                                                                className='siderbar-icons'
                                                            />
                                                            <p style={{ display: isOpen ? "none" : "block" }} className='navitems mb-0 sidetext'>{t('sidebar.corporate_cards')}</p>
                                                        </Link>
                                                    </li>
                                                )}
                                                {checkHasAccess('/expenses') && (
                                                    <li className={`nav-item submenu-item ${expensesExpanded ? 'show' : 'hide'}`}>
                                                        <Link className={`${location.pathname === '/expenses' ? "nav-link active" : "nav-link"}`} to="/expenses">
                                                            <img
                                                                src={XpendlessCardIcon}
                                                                className='siderbar-icons'
                                                            />
                                                            <p style={{ display: isOpen ? "none" : "block" }} className='navitems mb-0 sidetext'>{t('sidebar.xpendless_cards')}</p>
                                                        </Link>
                                                    </li>
                                                )}
                                                {checkHasAccess('/reimbursement') && (decodedtoken?.isAdmin || decodedtoken?.isAccountant) &&
                                                    <li className={`nav-item submenu-item ${expensesExpanded ? 'show' : 'hide'}`}>
                                                        <Link className={`${location.pathname === '/reimbursement' ? "nav-link active" : "nav-link"}`} to="/reimbursement">
                                                            <img
                                                                src={Out_of_pocket1}
                                                                className='siderbar-icons'
                                                            />
                                                            <p style={{ display: isOpen ? "none" : "block" }} className='navitems mb-0 sidetext'>{t('sidebar.reimbursements')}</p>
                                                        </Link>
                                                    </li>
                                                }
                                            </ul>
                                        </li>
                                        {(decodedtoken?.isAdmin || decodedtoken?.isAccountant) &&
                                            <>
                                                {checkHasAccess('/export') && (
                                                    <li onClick={() => setExpaned(!expanded)} className="nav-item">
                                                        <Link className={`${location.pathname === '/export' ? "nav-link active" : "nav-link"}`} to="/export">
                                                            <img src={location.pathname === '/export' ? ExportActiveIcon1 : ExportIcon1} className='siderbar-icons' />
                                                            <p style={{ display: isOpen ? "none" : "block" }} className='navitems mb-0 sidetext'>{t('sidebar.export')}</p>
                                                        </Link>
                                                    </li>
                                                )}
                                                {checkHasAccess('/teams') && (
                                                    <li onClick={() => setExpaned(!expanded)} className="nav-item">
                                                        <Link className={`${location.pathname === '/teams' || location.pathname.includes('/team/maincompany') || location?.state?.prevPath.includes('/team/maincompany') ? "nav-link active" : "nav-link"}`} to="/teams">
                                                            <img src={location.pathname === '/teams' || parentMenu === 'team' ? TeamActiveicon1 : TeamIcon1} className='siderbar-icons' />
                                                            <p style={{ display: isOpen ? "none" : "block" }} className='navitems mb-0 sidetext'>{t('sidebar.teams')}</p>
                                                        </Link>
                                                    </li>
                                                )}
                                                {checkHasAccess('/users') && (
                                                    <li onClick={() => setExpaned(!expanded)} className="nav-item">
                                                        <Link className={`${location.pathname === '/users' || location?.state?.prevPath === '/users' ? "nav-link active" : "nav-link"}`} to="/users">
                                                            <img src={location.pathname === '/users' ? UserActiveIcon1 : UserIcon1} className='siderbar-icons' />
                                                            <p style={{ display: isOpen ? "none" : "block" }} className='navitems mb-0 sidetext'>{t('sidebar.users')}</p>
                                                        </Link>
                                                    </li>
                                                )}
                                            </>
                                        }
                                        {checkHasAccess('/analytics') && (decodedtoken?.isAdmin || decodedtoken?.isAccountant) &&
                                            <li onClick={() => setExpaned(!expanded)} className="nav-item">
                                                <Link className={`${location.pathname === '/analytics' ? "nav-link active" : "nav-link"}`} to="/analytics">
                                                    <img src={location.pathname === '/analytics' ? AnalyticsActiveIcon1 : AnalyticsIcon1} className='siderbar-icons' />
                                                    <p style={{ display: isOpen ? "none" : "block" }} className='navitems mb-0 sidetext'>{t('sidebar.analytics')}</p>
                                                </Link>
                                            </li>
                                        }

                                        {checkHasAccess('/companyverification') && (decodedtoken?.isAdmin) && <li onClick={() => setExpaned(!expanded)} className="nav-item">
                                            <Link className={`${location.pathname === '/companyverification' ? "nav-link active" : "nav-link"}`} to="/companyverification">
                                                <img src={location.pathname === '/companyverification' ? CompanyVerificationActiveIcon1 : CompanyVerificationIcon1} className='siderbar-icons' />
                                                <p style={{ display: isOpen ? "none" : "block" }} className='navitems mb-0 sidetext'>{t('sidebar.companyVerification')}</p>
                                            </Link>
                                        </li>}
                                        {checkHasAccess('/audit-log-viewer') && (
                                            <li onClick={() => setExpaned(!expanded)} className="nav-item">
                                                <Link className={`${location.pathname === '/audit-log-viewer' ? "nav-link active" : "nav-link"}`} to="/audit-log-viewer">
                                                    <img src={location.pathname === '/audit-log-viewer' ? AuditActiveIcon1 : AuditIcon1} className='siderbar-icons' />
                                                    <p style={{ display: isOpen ? "none" : "block" }} className='navitems mb-0 sidetext'>{t('sidebar.auditLogViewer')}</p>
                                                </Link>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                            <div className='menu-links' style={{ height: isOpen ? '112px' : '316px', display: 'flex', flexDirection: 'column' }}>
                                <div  className='menu-links' style={{ overflowY: 'scroll', flex: 1, overflowX: 'hidden' }}>
                                    <ul className={currentLanguageCode === 'en' ? "nav flex-column" : "nav flex-column leftside"}>
                                        <li className={languageExpanded ? 'submenu-container w-100' : 'w-100'}>
                                            <Link
                                                className="nav-link menu"
                                                to="#"
                                                onClick={() => {
                                                    setExpaned(!expanded);
                                                    setLanguageExpanded(!languageExpanded);
                                                }}
                                            >
                                                <img src={Language1} className='tome' />
                                                <Stack direction='horizontal' className='justify-content-between w-100'>
                                                    <p
                                                        style={{ display: isOpen ? "none" : "block" }}
                                                        className='navitems mb-0 sidetext'
                                                    >
                                                        {t('select_language')}
                                                    </p>
                                                    <img src={languageExpanded ? upArrow : downArrow} className='submenu-icon' />
                                                </Stack>
                                            </Link>
                                            <ul className={`submenu ${languageExpanded ? 'expanded' : 'collapsed'}`}>
                                                {languages.map(({ code, name, country_code }) => (
                                                    <li key={country_code} className={`nav-item submenu-item ${languageExpanded ? 'show' : 'hide'}`}>
                                                        <Link
                                                            className={classNames(`${currentLanguageCode === code ? "nav-link active text-uppercase" : "nav-link text-uppercase"}`, {
                                                                disabled: currentLanguageCode === code,
                                                            })}
                                                            to="#"
                                                            onClick={() => { i18next.changeLanguage(code); setExpaned(!expanded); }}
                                                        >
                                                            <span className={`flag-icon  flag-icon-${country_code} mx-2`}></span>
                                                            {isOpen ? code : name}
                                                        </Link>
                                                    </li>
                                                ))}
                                            </ul>
                                        </li>
                                        {checkHasAccess('/settings') && (
                                            (decodedtoken?.isAdmin || decodedtoken?.isAccountant) && (
                                                <li onClick={() => setExpaned(!expanded)} className="nav-item">
                                                    <Link className={`${location.pathname === '/settings' ? "nav-link active" : "nav-link"}`} to="/settings">
                                                        <img src={location.pathname === '/settings' ? SettingsActiveIcon1 : SettingsIcon1} className='siderbar-icons' />
                                                        <p style={{ display: isOpen ? "none" : "block" }} className='navitems mb-0 sidetext'>{t('settings')}</p>
                                                    </Link>
                                                </li>
                                            )
                                        )}
                                        {companyDetails?.companyLogo && (
                                            <img
                                                style={{
                                                    display: isOpen ? "none" : "block",
                                                    height: 60,
                                                    backgroundColor: companyDetails?.backgroundColor ?? '#ffffff',
                                                    borderRadius: '18px',
                                                    cursor: 'pointer'
                                                }}
                                                src={companyDetails?.companyLogo}
                                                className={currentLanguageCode === 'en' ? 'Leftbar-logo' : 'Leftbar-logo-arabic'}
                                                onClick={handleLogoClick}
                                            />
                                        )}
                                    </ul>
                                </div>
                                <hr style={{ color: '#fff'}} />
                                <ul className="nav flex-column" style={{ position: 'sticky', bottom: 0 }}>
                                    <li onClick={() => setExpaned(!expanded)} className="nav-item my-4">
                                        <Link className={`nav-link ${isOpen ? "justify-content-center" : "justify-content-between"}`} onClick={logOut}>
                                            {localStorage.getItem('profilePic') ? (
                                                <img onError={(e) => { e.target.onerror = null; e.target.style.display = 'none'; }} style={{ display: isOpen ? "none" : "block", width: 40, borderRadius: '50%', objectFit: 'contain' }} src={localStorage.getItem('profilePic')} />
                                            ) : (
                                                <span className='mb-0 d-flex align-items-center justify-content-center text-uppercase text-primary' style={{ fontSize: '15px', fontWeight: 600, backgroundColor: '#F2F4F7', borderRadius: 4, width: 40, height: 40 }}>
                                                    {`${localStorage.getItem('firstName')?.charAt(0)}${localStorage.getItem('lastName')?.charAt(0)}`}
                                                </span>
                                            )}
                                            <div>
                                                <p style={{ display: isOpen ? "none" : "block" }} className='navitems fw-bold mb-0 sidetext'> {(localStorage.getItem('firstName'))} {(localStorage.getItem('lastName'))?.slice(0, 15)}</p>
                                                <p style={{ display: isOpen ? "none" : "block", fontSize: 14 }} className='navitems mb-0 sidetext text-white'>{(localStorage.getItem('email'))?.slice(0, 15)}</p>
                                            </div>
                                            <img onClick={logOut} src={logoutIcon} style={{ width: 40, objectFit: 'contain', transform: currentLanguageCode === 'en' ? 'rotate(0deg)' : 'rotate(180deg)' }} />
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </motion.div>
                </>
            </div>
            <div className='col-lg-9 col-md-12 col-sm-12 rightapp' style={{ width: isOpen ? '92%' : '80%' }}>
                {props?.component && <props.component />}
            </div>
        </>
    );
}

export default NewSidebar;
