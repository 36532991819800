import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { formatQARWithoutDecimal, formatTime, getDecimalNo } from '../../utils/XpendlessUtils';
import moment from 'moment-timezone';
import CategoryGreenIcon from '../../assets/images/menuGreen.png';
import CategoryGreyIcon from '../../assets/images/menu.png';
import ExportGreenIcon from '../../assets/images/export_icon_green.png';
import ExportGreyIcon from '../../assets/images/docArrow.png';
import GreenRecepitIcon from '../../assets/images/doneRecipt.png';
import { MdOutlineErrorOutline } from "react-icons/md";
/* eslint-disable no-unsafe-optional-chaining */
import React from 'react';
import RecepitIcon from '../../assets/images/greyRecipt.png';
import ReviewGreenIcon from '../../assets/images/docAcept.png';
import ReviewGreyIcon from '../../assets/images/defDoc.png';
import ReviewRedIcon from '../../assets/images/rejected.png';
import SettledIcon from '../../assets/images/settled.png';
import UnsettledIcon from '../../assets/images/unsettled.png';
import { useNavigate } from 'react-router-dom';

function ExpensesComponent(props) {
    const { data, prevPath, parentTab, currencies, showCheckBox = false, forReview = false, handleCheckboxChange, selectedItems, type, type1, filePermission, activeKey, allowCheckBoxTooltip = false, isUnexported = false, index, activeTap } = props;
    const reviewIcons = {
        pending: ReviewGreyIcon,
        approve: ReviewGreenIcon,
        reject: ReviewRedIcon,
        null: ReviewGreyIcon
    };
    const navigate = useNavigate();
    const routeChange = (item) => {
        if (type === "reimbursement") {
            if (type1 === "export") {
                let path = `/reimbursement/reimbursement-details/${item?.cardTransactionId}`;
                navigate(path, {
                    state: {
                        type: type,
                        transactionId: item?.cardTransactionId,
                        eventType: type1,
                        prevPath: prevPath,
                        activeKey: activeTap,
                        forReview
                    }
                });
            } else {
                let path = `/reimbursement/reimbursement-details/${item?.reimbursementRequestId}`;
                navigate(path, {
                    state: {
                        type: type,
                        transactionId: item?.reimbursementRequestId,
                        eventType: type1,
                        prevPath: prevPath,
                        activeKey: activeKey,
                        forReview
                    }
                });
            }
        }
        else if (type === 'corporateCardsExpense') {
            let path = `/expenses/expense-details/${item?.expenseCorporateCardsId}`;
            navigate(path, {
                state: {
                    type: 'corporateCardsExpense',
                    transactionId: item?.expenseCorporateCardsId,
                    filePermission,
                    eventType: type1,
                    parentTab: parentTab,
                    prevPath: prevPath,
                    activeKey: activeKey,
                    forReview
                }
            });
        }
        else {
            if (type1 === "export") {
                let path = `/expenses/expense-details/${item?.cardTransactionId}`;
                navigate(path, {
                    state: {
                        type: 'expense',
                        transactionId: item?.cardTransactionId,
                        eventType: type1,
                        prevPath: prevPath,
                        activeKey: activeTap,
                        forReview
                    }
                });
            } else {
                let path = `/expenses/expense-details/${item?.cardTransactionId}`;
                navigate(path, {
                    state: {
                        type: 'expense',
                        transactionId: item?.cardTransactionId,
                        filePermission,
                        eventType: type1,
                        parentTab: parentTab,
                        prevPath: prevPath,
                        activeKey: activeKey,
                        forReview
                    }
                });
            }
        }
    };
    const getCheckboxTooltipMessage = (item) => {
        if (!item.categoryId) return "Category is missing in expense record";
        if (!item.accountIntegrationId) return `Mapping missing for category ${item?.categoryName}`;
        if (!item.taxId) return "Tax is missing in expense record";
        if (!item.taxIntegrationId) return `Mapping missing for tax ${item?.taxName}`;
        // if (!item.employeeId) return "Employee is missing in expense record";
        // if (!item.employeeIntegrationId) return `Mapping missing for employee ${item?.firstName} ${item?.lastName}`;
        return null; // No issues, no tooltip message
    };

    if (data && data.length > 0) {
        return (
            <>
                {data && data.length > 0 &&
                    data?.map((item, i) => {
                        const decimalDigits = getDecimalNo(item.code, currencies);

                        const [wholePart2, decimalPart2] = (item?.selectedCurrencyAmount)
                            ? item?.selectedCurrencyAmount.toString().split('.')
                            : ['', ''];

                        const [wholePart, decimalPart] = (item?.amount)
                            ? item?.amount.toString().split('.')
                            : ['', ''];

                        const formattedDecimalPart2 = decimalDigits > 0
                            ? (decimalPart2 !== undefined
                                ? decimalPart2.slice(0, decimalDigits).padEnd(decimalDigits, '0')
                                : '0'.repeat(decimalDigits))
                            : '';

                        const formattedDecimalPart1 = decimalDigits > 0
                            ? (decimalPart !== undefined
                                ? decimalPart.slice(0, decimalDigits).padEnd(decimalDigits, '0')
                                : '0'.repeat(decimalDigits))
                            : '';
                        const showDecimal = decimalDigits > 0;

                        let RevieIcon = ReviewGreyIcon;
                        if (item?.decision) {
                            RevieIcon = reviewIcons[item.decision];
                        }
                        const tooltipMessage = allowCheckBoxTooltip && isUnexported ? getCheckboxTooltipMessage(item) : "";
                        const isDisabled = !!tooltipMessage;
                        return (
                            <div className='mt-2 table-body-tr' style={{ cursor: 'pointer', gap: 20 }} key={"expenseComp" + i}>
                                <div className='row align-items-center'>
                                    <div className='col-lg-1' style={{ width: '5%' }}>
                                        {showCheckBox &&
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <OverlayTrigger
                                                    overlay={
                                                        tooltipMessage ? (
                                                            <Tooltip id={`tooltip-${item.cardTransactionId}`}>
                                                                {tooltipMessage}
                                                            </Tooltip>
                                                        ) : (
                                                            <></>
                                                        )
                                                    }
                                                >
                                                    <div className="d-flex align-items-center">
                                                        <input
                                                            className={`form-check-input m-2 ${isDisabled ? "disabled-checkbox" : ""
                                                                }`}
                                                            type="checkbox"
                                                            value=""
                                                            id={"expenseComp" + i + index}
                                                            checked={selectedItems?.some(
                                                                (res) =>
                                                                    (res.exportQueueId
                                                                        ? res.exportQueueId === item?.exportQueueId
                                                                        : res.transactionId ===
                                                                        item?.cardTransactionId) &&
                                                                    res.transactionType === item?.type
                                                            )}
                                                            onChange={() =>
                                                                isDisabled
                                                                    ? () => { }
                                                                    : handleCheckboxChange(
                                                                        item.cardTransactionId,
                                                                        item.type,
                                                                        item.exportQueueId
                                                                    )
                                                            }
                                                        />
                                                        {isDisabled && (
                                                            <MdOutlineErrorOutline
                                                                color="red"
                                                            />
                                                        )}
                                                    </div>
                                                </OverlayTrigger>
                                            </div>
                                        }
                                    </div>
                                    <div className='col' onClick={() => routeChange(item)}>
                                        <div className='row'>
                                            <div className={`col-lg-5 col-md-5 col-sm-12`}>
                                                <div className='d-flex align-items-center'>
                                                    <h5 className='text-grey'>{type === "reimbursement" ? moment.utc(item?.date).format('dddd, DD MMMM YYYY') : formatTime(item?.createdAt)}</h5>
                                                    {
                                                        item.profilePic &&
                                                        <img onError={(e) => {
                                                            e.target.onerror = null;
                                                            e.target.style.display = 'none';
                                                            e.target.nextSibling.style.display = 'flex';
                                                        }} src={item?.profilePic} style={{ width: 32, height: 32, objectFit: 'fill', borderRadius: 20, marginRight: 10, marginLeft: 10 }} />
                                                    }
                                                    <span className='mb-0 align-items-center justify-content-center text-uppercase' style={{ display: item.profilePic ? 'none' : 'flex', fontSize: 14, fontWeight: 600, backgroundColor: '#F2F4F7', borderRadius: 20, width: 35, height: 35, marginRight: 10, marginLeft: 10 }}
                                                    >
                                                        {item?.firstName.slice(0, 1) + item?.lastName.slice(0, 1)}
                                                    </span>
                                                    <div>
                                                        <p className='text-black mb-0' style={{ fontWeight: '600', fontSize: 14 }}>{item?.merchantName}</p>
                                                        <h5 className='text-grey'>{item?.firstName} {item?.lastName}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                <div className='col-lg-5 col-md-5 col-sm-12 d-flex align-items-center ' style={{ marginTop: window.innerWidth < 500 ? 15 : 0 }}>
                                                    <img
                                                        src={item?.hasReceipt ? GreenRecepitIcon : RecepitIcon}
                                                        style={{ width: 28, objectFit: 'contain', filter: (!item?.hasReceipt) ? 'grayscale(100%)' : '', marginRight: 11, marginLeft: 11 }}
                                                        title={item?.hasReceipt ? 'Receipt is uploaded' : 'No Receipt'}
                                                    />
                                                    <img
                                                        src={RevieIcon}
                                                        style={{ width: 28, objectFit: 'contain', marginRight: 11, marginLeft: 11 }}
                                                        title={
                                                            item?.decision === 'reject'
                                                                ? 'Rejected'
                                                                : item?.decision === 'approve'
                                                                    ? 'Approved'
                                                                    : ' No action'
                                                        }
                                                        className={(item?.decision === null || item?.decision === 'pending') ? 'grayscale-image' : ''}
                                                    />
                                                    <img
                                                        src={item?.isExported ? ExportGreenIcon : ExportGreyIcon}
                                                        style={{ width: 28, objectFit: 'contain', marginRight: 11, marginLeft: 11 }}
                                                        title={item?.isExported ? 'Exported' : ' Not Exported Yet'}
                                                    />
                                                    <img
                                                        src={item?.categoryId ? CategoryGreenIcon : CategoryGreyIcon}
                                                        style={{ width: 28, objectFit: 'contain', marginRight: 11, marginLeft: 11 }}
                                                        title={item?.categoryId ? 'Category is selected' : 'No Category'}
                                                    />
                                                    {
                                                        type === 'reimbursement' &&
                                                        <img
                                                            src={item?.isSettled ? SettledIcon : UnsettledIcon}
                                                            style={{ width: 28, objectFit: 'contain', marginRight: 11, marginLeft: 11 }}
                                                            title={item?.isSettled ? 'Settled' : 'un-settled'}
                                                        />
                                                    }
                                                </div>}
                                            <div className='col-lg-2 col-md-2 col-sm-12  d-flex align-items-center' style={{ marginTop: window.innerWidth < 500 ? 15 : 0 }}>
                                                <div className='d-flex justify-content-end '>
                                                    <div className="d-flex justify-content-start">
                                                        {type === "reimbursement" ? (
                                                            <h3 className="text-black mb-0" style={{ fontSize: 14, fontWeight: '600' }}>
                                                                {`${Number(wholePart)?.toLocaleString() || 0}`}
                                                                {showDecimal && (
                                                                    <>
                                                                        <span className="text-grey" style={{ fontSize: 12, fontWeight: '500' }}>
                                                                            {`.`}
                                                                            {`${formattedDecimalPart1}`}
                                                                        </span>
                                                                    </>
                                                                )}
                                                                <span className="text-grey" style={{ fontSize: 12, fontWeight: '500' }}>
                                                                    {` ${(item?.code) ? item?.code : 'QAR'}`}
                                                                </span>
                                                            </h3>
                                                        ) : (<h3 className="text-black mb-0" style={{ fontSize: 14, fontWeight: '600' }}>
                                                            {`${Number(wholePart2) ? `${Number(wholePart2).toLocaleString()}` : `${formatQARWithoutDecimal(Number(wholePart))}`}`}
                                                            {showDecimal && (
                                                                <>
                                                                    <span className="text-grey" style={{ fontSize: 12, fontWeight: '500' }}>
                                                                        {`.`}
                                                                        {`${formattedDecimalPart2}`}
                                                                    </span>
                                                                </>
                                                            )}
                                                            <span className="text-grey" style={{ fontSize: 12, fontWeight: '500' }}>
                                                                {` ${(item?.code) ? item?.code : 'QAR'}`}
                                                            </span>
                                                        </h3>)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                }
            </>
        );
    }
}

export default ExpensesComponent;