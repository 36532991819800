import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BackArrow from "../../assets/images/backarrow1.png";
import showEye from "../../assets/images/solar_eye-broken.png";
import ImagesCross from "../../assets/images/close-square-8.png";
import { useNavigate, useParams } from "react-router-dom";
import SingleCardTabs from "../../components/Cards/SingleCardTabs";
import { Modal } from "react-bootstrap";
import Slider from "@mui/material/Slider";
import CustomDropdown from "../../components/CustomDropdown/CustomDropdown";
import CardSlider from "../../components/Cards/CardSlider";
import cookies from "js-cookie";
import {
  getCardDetails,
  fetchCardSpendings,
  addCardMoney,
  withdrawCardMoney,
  updateCardLimit,
  updateCardControl,
  updateCardDetails,
  freezeUnfreezeCard,
  activatePhysicalCard,
  getPciWidgetUrl,
} from "./slice/cardListSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import LoadingComponent from "../../components/Loader/LoadingComponent";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { formatQAR } from "../../utils/XpendlessUtils";
import "./Cards.scss";
import OtpInput from "react-otp-input";
import Logo2 from "../../assets/images/polo.png";
import CurrencyInput from "react-currency-input-field";
import { MAX_VALUE, PCI_WIDGET_OPERATION } from "../../utils/constants";
import { useMutation } from "@tanstack/react-query";
import IconButton from '../../components/Buttons/IconButton';
import SecondaryButton from "../../components/Buttons/SecondaryButton";
import DangerButton from "../../components/Buttons/DangerButton";
import PrimaryButton from "../../components/Buttons/PrimaryButton";

function valuetext(value) {
  return `${value}°C`;
}

function SingleCardDetails() {
  const queryCardId = new URLSearchParams(window.location.search).get("cardId");

  const [show, setShow] = useState(false);
  const [isShowPciWidget, setIsShowPciWidget] = useState(false);
  const [isShowActivate, setIsShowActivate] = useState(false);
  const [pciWidgetUrl, setPciWidgetUrl] = useState(null);
  const [pciWidgetOperation, setPciWidgetOperation] = useState(null);
  console.log("pciWidgetUrl", pciWidgetOperation);
  const [iframeHeight, setIframeHeight] = useState("100%");

  const [showAddMoney, setShowAddMoney] = useState(false);
  const [showWithdrawMoney, setShowWithdrawMoney] = useState(false);
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const currentLanguageCode = cookies.get("i18next") || "en";
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleShowAddMoney = () => setShowAddMoney(true);
  const handleShowVerifyModal = () => setShowVerifyModal(true);
  const handleCloseVerifyModal = () => setShowVerifyModal(false);
  const { employeeId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { cardDetails, isLoading, spendings } = useSelector(
    (state) => state.cardListReducer
  );
  const [activeCard, setActiveCard] = useState(null);
  const [activeTab, setActiveTab] = useState(1);
  const currentBalance =
    activeCard !== null && cardDetails[activeCard]
      ? cardDetails[activeCard].currentBalance
      : 0;
  const currentLimit =
    activeCard !== null && cardDetails[activeCard]
      ? cardDetails[activeCard].limit
      : 0;
  const [wholePart, decimalPart] = currentBalance
    .toString()
    .split(".")
    .map(Number);
  const [timer, setTimer] = useState(60);
  const [selectedType, setSelectedType] = useState("addFunds");
  const [selectedTypeData, setSelectedTypeData] = useState("");
  const [freezeCardId, setFreezeCardId] = useState(null);
  const {
    mutateAsync: activateCardStatus,
    isPending: isLoadingActivateCardStatus,
  } = useMutation({
    mutationFn: (payload) => activatePhysicalCard(payload),
    onSuccess: () => {
      toast.success("Card activated successfully");
      dispatch(getCardDetails(employeeId))
        .unwrap()
        .then((data) => {
          let cardIndex = 0;
          if (data?.data?.length > 0 && queryCardId) {
            cardIndex = data?.data.findIndex((x) => x.cardId == queryCardId);
          }
          setActiveCard(cardIndex);
          setIsShowActivate(false);
        })
        .catch((error) => {
          toast.error(error.message || "Request Failed Please Try Again ");
        });
    },
    onError: (error) => {
      toast.error(error.message || "Request Failed Please Try Again ");
    },
  });

  useEffect(() => {
    if (pciWidgetOperation) {
      if (PCI_WIDGET_OPERATION.SHOW_CLEAR_CARD_DETAILS === pciWidgetOperation) {
        setIframeHeight("300px");
      } else if (PCI_WIDGET_OPERATION.CHANGE_PIN === pciWidgetOperation) {
        setIframeHeight("600px");
      } else if (PCI_WIDGET_OPERATION.GET_PIN === pciWidgetOperation) {
        setIframeHeight("300px");
      } else {
        setIframeHeight("100%");
      }
    }
  }, [pciWidgetOperation]);
  const {
    mutateAsync: getPciWidgetUrlAsync,
    isPending: isLoadingGetPciWidgetUrl,
  } = useMutation({
    mutationFn: (payload) => getPciWidgetUrl(payload),
    onSuccess: (data) => {
      setIsShowPciWidget(true);
      setPciWidgetUrl(data.data);
      // setPciWidgetOperation(null);
    },
    onError: (error) => {
      toast.error(error.message || "Request Failed Please Try Again ");
    },
  });
  const {
    control: controlAddMoneyForm,
    handleSubmit: handleAddMoneySubmit,
    formState: { errors: errorsAddMoneyForm },
    reset: resetAddMoneyForm,
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        add_card_fund_amount: yup
          .number()
          .min(100, "Amount must be greater than or equal to 100")
          .required("Amount is required")
          .typeError("Please enter valid amount")
          .test(
            "max-amount",
            `The total balance should not exceed the limit of ${currentLimit}`,
            function (value) {
              return currentLimit >= value + currentBalance;
            }
          ),
        add_card_fund_comments: yup.string().required("Comment is required"),
      })
    ),
  });

  const {
    control: controlWithdrawMoneyForm,
    handleSubmit: handleWithdrawMoneySubmit,
    formState: { errors: errorsWithdrawMoneyForm },
    reset: resetWithdrawMoneyForm,
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        withdraw_card_fund_amount: yup
          .number()
          .min(1, "Amount must be greater than or equal to 1")
          .max(wholePart, "Amount should not be greater then the card balance.")
          .required("Amount is required")
          .typeError("Please enter valid amount"),
        withdraw_card_fund_comments: yup
          .string()
          .required("Comment is required"),
      })
    ),
  });

  const schema = yup.object().shape({
    card_limit: yup
      .number()
      .min(wholePart, `Minimum amount should be ${wholePart}`)
      .max(wholePart + 10000, t("accounts.Value_should_not_be_greater_than_card_limit"))
      .required("Card Spending Limit is required")
      .typeError("Card Spending Limit is required"),
    frequency: yup
      .string()
      .required("Frequency is required"),
  });

  const {
    control: controlCardLimitForm,
    handleSubmit: handleCardLimitSubmit,
    watch: watchCardLimit,
    setValue: setCardLimitValue,
    formState: { errors: errorsCardLimitForm },
    resetField,
    reset: resetCardLimitForm,
  } = useForm({
    resolver: yupResolver(schema
      // yup.object().shape({
      //   card_limit: yup
      //     .number()
      //     .min(wholePart, `Minimum amount should be ${wholePart}`)
      //     .max(
      //       wholePart + 10000,
      //       t("accounts.Value_should_not_be_greater_than_card_limit")
      //     )
      //     .required()
      //     .typeError("Card Spending Limit is required"),
      //     frequency: yup
      //     .string()
      //     .required("Frequency is required"),
      // })
    ),
    mode: "onChange",
  });

  const {
    control: controlVerifyModal,
    handleSubmit: handleOtpSubmit,
    formState: { errors: errorsOtpForm },
    reset: otpFormReset,
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        otp: yup
          .string()
          .required("Otp is required")
          .matches(/^123456$/, "OTP must be exactly 6 digits"),
      })
    ),
  });
  const limit = watchCardLimit(
    "card_limit",
    cardDetails != null && activeCard != null && cardDetails[activeCard]
      ? cardDetails[activeCard].cardSpendingLimit
      : 0
  );
  useEffect(() => {
    setTimeout(() => {
      dispatch(getCardDetails(employeeId))
        .unwrap()
        .then((data) => {
          let cardIndex = 0;
          if (data?.data?.length > 0 && queryCardId) {
            cardIndex = data?.data.findIndex((x) => x.cardId == queryCardId);
          }
          setActiveCard(cardIndex);
        })
        .catch((error) => {
          toast.error(error.message || "Request Failed Please Try Again ");
        });
    }, 500);
  }, [employeeId, dispatch]);

  const handleShowWithdrawMoney = () => {
    if (wholePart <= 0) {
      toast.error("No balance to withdraw!.");
      return;
    }
    setShowWithdrawMoney(true);
    setSelectedType("withdrawFunds");
  };

  const clickedSpendingTab = (cardId) => {
    let queryParams = `?page=1&cardId=${cardId}`;
    dispatch(fetchCardSpendings(queryParams))
      .unwrap()
      .then(() => { })
      .catch((error) => {
        toast.error(error.message || "Request Failed Please Try Again ");
      });
  };
  const handleCloseAddMoney = () => {
    setShowAddMoney(false);
    resetAddMoneyForm();
  };
  const handleCloseWithdrawMoney = () => {
    setShowWithdrawMoney(false);
    resetWithdrawMoneyForm();
  };

  const handleAddMoney = () => {
    const requestData = {
      eventType: "cards",
      cardId: cardDetails[activeCard].cardId,
      amount: selectedTypeData.add_card_fund_amount,
      comments: selectedTypeData.add_card_fund_comments,
    };
    dispatch(addCardMoney(requestData))
      .unwrap()
      .then((data) => {
        toast.success(data.message || "Money Added Successfully");
        dispatch(getCardDetails(employeeId));
        handleCloseAddMoney();
        handleCloseVerifyModal();
        resetAddMoneyForm();
        otpFormReset({ otp: "" });
      })
      .catch((error) => {
        handleCloseVerifyModal();
        toast.error(error.message || "Request Failed Please Try Again ");
      });
  };

  useEffect(() => {
    let interval;

    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [timer]);

  const onClickVerifyModal = (data) => {
    setTimer(60);
    setSelectedTypeData(data);
    handleCloseAddMoney();
    handleCloseWithdrawMoney();
    otpFormReset({ otp: "" });
    setTimeout(() => {
      handleShowVerifyModal();
    }, 200);
  };

  const handleWithdrawMoney = () => {
    const requestData = {
      cardId: cardDetails[activeCard].cardId,
      amount: selectedTypeData.withdraw_card_fund_amount,
      comments: selectedTypeData.withdraw_card_fund_comments,
    };

    resetWithdrawMoneyForm();
    dispatch(withdrawCardMoney(requestData))
      .unwrap()
      .then((data) => {
        toast.success(data.message || "Money Withdraw Successfully");
        dispatch(getCardDetails(employeeId));
        handleCloseWithdrawMoney();
        handleCloseVerifyModal();
        otpFormReset({ otp: "" });
      })
      .catch((error) => {
        handleCloseVerifyModal();
        toast.error(error.message || "Request Failed Please Try Again ");
      });
  };

  const status =
    cardDetails && activeCard !== null ? cardDetails[activeCard]?.status : null;
  const isStatusInactiveOrSuspended =
    status === "inactive" || status === "suspended";

  const handleCardLimit = (data) => {
    const requestData = {
      cardId: cardDetails[activeCard].cardId,
      userEmployeeId: employeeId,
      cardSpendingLimit: data.card_limit,
      frequency: data.frequency,
    };
    resetCardLimitForm();
    dispatch(updateCardLimit(requestData))
      .unwrap()
      .then((data) => {
        toast.success(data.message || "Limit Changed Successfully");
        dispatch(getCardDetails(employeeId));
        handleClose();
      })
      .catch((error) => {
        toast.error(error.message || "Request Failed Please Try Again ");
      });
  };

  const handleCardControl = (data) => {
    dispatch(
      updateCardControl({
        payload: data,
        cardId: cardDetails[activeCard].cardId,
      })
    )
      .unwrap()
      .then((data) => {
        toast.success(data.message || "Updated Successfully");
        dispatch(getCardDetails(employeeId));
      })
      .catch((error) => {
        toast.error(error.message || "Request Failed Please Try Again ");
      });
  };

  const handleSliderChange = (event, newValue) => {
    setCardLimitValue("card_limit", newValue);
  };

  useEffect(() => {
    if (activeTab == 3) {
      const details = activeCard !== null ? cardDetails[activeCard] : {};
      clickedSpendingTab(details?.cardId);
    }
  }, [activeTab, activeCard]);

  const toggleMask = () => {
    if (cardDetails.length > 0) {
      const currentMaskValue =
        typeof cardDetails[activeCard].maskCardNumber != "undefined"
          ? !cardDetails[activeCard].maskCardNumber
          : true;
      dispatch(
        updateCardDetails({
          index: activeCard,
          maskCardNumber: currentMaskValue,
        })
      );
      handleCloseVerifyModal();
      otpFormReset({ otp: "" });
    }
  };

  const handleCardFreeze = (data, cardId) => {
    setFreezeCardId(cardId);
    onClickVerifyModal(data);
    setSelectedType("freezeCard");
  };

  const handleCardFreezUnfreezAction = () => {
    const payload = { isFreezed: selectedTypeData, cardId: freezeCardId };
    dispatch(freezeUnfreezeCard(payload))
      .unwrap()
      .then((data) => {
        toast.success(data.message || "Updated Successfully");
        dispatch(getCardDetails(employeeId));
        otpFormReset({ otp: "" });
        handleCloseVerifyModal();
      })
      .catch((error) => {
        handleCloseVerifyModal();
        toast.error(error.message || "Request Failed Please Try Again ");
      });
  };

  const frequency = [
    {
      label: `DAILY`,
      value: "DAILY",
    },
    {
      label: `MONTHLY`,
      value: "MONTHLY",
    },
    {
      label: `YEARLY`,
      value: "YEARLY",
    },
    {
      label: `ALL TIME`,
      value: "ALL_TIME",
    },
  ];

  const requiredFieldLabel = (labelText, htmlFor) => {
    return (
      <label className="form-label" htmlFor={htmlFor}>
        {labelText}
        <span
          className="absolute top-0 left-0"
          style={{ transform: "translate(-50%, -50%)", color: "#FF0000" }}
        >
          *
        </span>
      </label>
    );
  };

  return (
    <>
      {activeCard === null ? null : (
        <div className="white-container cardslider cards-section-css singleCradDetailsPageCss">
          <div
            className="d-flex align-items-center justify-content-between"
            style={{ position: "relative" }}
          >
            {isLoading && <LoadingComponent />}
            <h1 className="text-green mb-3">{`${cardDetails[activeCard]?.cardName} Card`}</h1>
            <IconButton
              type={'button'}
              onClick={() => navigate(-1)}
              buttonText={t('cards.back_to_cards')}
              id={'back'}
              src={BackArrow}
              btnStyle={{ marginBottom: '16px' }}
              isFilled={false}
              currentLanguageCode={currentLanguageCode}
            />
          </div>

          <div className="cards-custom p-3">
            <div className="row mb-3">
              <div className="col-lg-5 marginbottoncustomCards">
                <div
                  className="border-grey p-3 ps-0  pr-0 mr-1 sliderpadding"
                  style={{ position: "relative" }}
                >
                  {isLoading && <LoadingComponent />}
                  <CardSlider
                    activeCardIndex={activeCard}
                    setActiveCard={setActiveCard}
                    details={activeCard !== null ? cardDetails : []}
                    handleCardFreezUnfreezAction={handleCardFreeze}
                  />
                </div>
              </div>
              <div
                className="col-lg-7 col-md-12"
                style={{
                  filter: isStatusInactiveOrSuspended ? "grayscale(2)" : "none",
                  cursor: isStatusInactiveOrSuspended
                    ? "not-allowed"
                    : "pointer",
                }}
              >
                <div
                  className="border-grey p-3"
                  style={{ position: "relative" }}
                >
                  {isLoading && <LoadingComponent />}
                  <div className="green-box">
                    <h2 className="balance-text ">
                      {t("users.current_balance")}
                    </h2>
                    <h1 className="text-green mb-1">
                      {wholePart.toLocaleString()}.
                      <span
                        className="text-grey"
                        style={{ fontSize: 16, fontWeight: "500" }}
                      >{`${decimalPart ? decimalPart : "00"} QAR`}</span>
                    </h1>
                    <h2
                      className={` mt-2 text-${cardDetails[activeCard]?.status === "active" &&
                        cardDetails[activeCard]?.isFreezed === false
                        ? "blue"
                        : "danger"
                        } mb-1`}
                      style={{ fontSize: 16 }}
                    >
                      {t("cards.card_is_active")}
                      {cardDetails[activeCard]?.status.charAt(0).toUpperCase() +
                        cardDetails[activeCard]?.status.slice(1)}
                    </h2>
                  </div>
                  <div className="cardlimitBoxCss">
                    <p
                      className="balance-text mb-1"
                      style={{ fontSize: 20, fontWeight: "600" }}
                    >
                      {t("cards.card_spending_limit")}
                    </p>
                    <SecondaryButton
                      type={'button'}
                      onClick={handleShow}
                      buttonText={t("cards.change")}
                      isEnabled={!isStatusInactiveOrSuspended}
                      id={'change'}
                      isHighlighted={true}
                    />
                  </div>
                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{
                        width: `${(cardDetails[activeCard]?.totalspends /
                          cardDetails[activeCard]?.limit) *
                          100
                          }%`,
                      }}
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p
                      className="text-grey mt-3 mb-0"
                      style={{ fontSize: 14, fontWeight: "500" }}
                    >
                      {t("cards.spend", {
                        spend: cardDetails[activeCard]?.totalspends
                          ? formatQAR(cardDetails[activeCard]?.totalspends)
                          : 0,
                      })}
                      <span className="text-blue" id="card_limit">
                        {t("cards.out_of", {
                          outof: formatQAR(
                            cardDetails[activeCard]?.cardSpendingLimit
                          ),
                        })}
                      </span>
                    </p>
                    <p
                      className="text-grey mt-3 mb-0"
                      style={{ fontSize: 16, fontWeight: "500" }}
                    >
                      {`${cardDetails[activeCard]?.frequency
                        ?.charAt(0)
                        .toUpperCase() +
                        cardDetails[activeCard]?.frequency
                          ?.slice(1)
                          .toLowerCase()
                        } Limit`}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="threeBtnLineCss">
              <PrimaryButton
                type={'button'}
                onClick={() => {
                  handleShowAddMoney();
                  setSelectedType("addFunds");
                }}
                buttonText={t("accounts.add_funds")}
                isEnabled={!isStatusInactiveOrSuspended}
                id={'add_single_card'}
                style={{ zIndex: 2, width: '15%' }}
              />
              <div className="pe-3"></div>
              <DangerButton
                type={'button'}
                onClick={handleShowWithdrawMoney}
                isEnabled={!isStatusInactiveOrSuspended}
                buttonText={t("accounts.withdraw_funds")}
                id={'withdraw_funds_wallet'}
                style={{ zIndex: 2, width: '15%' }}
              />
              <div className="pe-3"></div>

              {cardDetails[activeCard].status === "active" && (
                <button
                  type="button"
                  onClick={() => {
                    setPciWidgetOperation(
                      PCI_WIDGET_OPERATION.SHOW_CLEAR_CARD_DETAILS
                    );
                    getPciWidgetUrlAsync({
                      cardId: cardDetails[activeCard].cardId,
                      operation: PCI_WIDGET_OPERATION.SHOW_CLEAR_CARD_DETAILS,
                    });
                  }}
                  className="btn-secondary"
                  id="activate_physical_card"
                  disabled={isLoadingGetPciWidgetUrl}
                >
                  {isLoadingGetPciWidgetUrl &&
                    pciWidgetOperation ===
                    PCI_WIDGET_OPERATION.SHOW_CLEAR_CARD_DETAILS ? (
                    <>
                      <div
                        className="spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                      &nbsp;{t("cards.show_details")}
                      <img
                        src={showEye}
                        style={{
                          width: 20,
                          objectFit: "contain",
                          marginLeft: 5,
                        }}
                      />
                    </>
                  ) : (
                    <>
                      {t("cards.show_details")}
                      <img
                        src={showEye}
                        style={{
                          width: 20,
                          objectFit: "contain",
                          marginLeft: 5,
                        }}
                      />
                    </>
                  )}
                </button>
              )}
              <div className="pe-3"></div>
              {cardDetails[activeCard].cardType === "physical" &&
                cardDetails[activeCard].status === "inactive" && (
                  <button
                    type="button"
                    onClick={() => {
                      setIsShowActivate(true);
                    }}
                    className="btn-success-green threeBtnCustomMargin"
                    id="activate_physical_card"
                    disabled={isLoadingActivateCardStatus}
                  >
                    {isLoadingActivateCardStatus ? (
                      <>
                        <div
                          className="spinner-border spinner-border-sm"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                        &nbsp;Activate
                      </>
                    ) : (
                      "Activate"
                    )}
                  </button>
                )}
              <div className="pe-3"></div>
              {cardDetails[activeCard].cardType === "physical" &&
                cardDetails[activeCard].status === "active" && (
                  <button
                    type="button"
                    onClick={() => {
                      setPciWidgetOperation(PCI_WIDGET_OPERATION.CHANGE_PIN);
                      getPciWidgetUrlAsync({
                        cardId: cardDetails[activeCard].cardId,
                        operation: PCI_WIDGET_OPERATION.CHANGE_PIN,
                      });
                    }}
                    className="btn-success-green threeBtnCustomMargin"
                    id="activate_physical_card"
                    disabled={isLoadingGetPciWidgetUrl}
                  >
                    {isLoadingGetPciWidgetUrl &&
                      pciWidgetOperation === PCI_WIDGET_OPERATION.CHANGE_PIN ? (
                      <>
                        <div
                          className="spinner-border spinner-border-sm"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                        &nbsp;Set Pin
                      </>
                    ) : (
                      "Set Pin"
                    )}
                  </button>
                )}
              <div className="pe-3"></div>
              {cardDetails[activeCard].cardType === "physical" &&
                cardDetails[activeCard].status === "active" && (
                  <button
                    type="button"
                    onClick={() => {
                      setPciWidgetOperation(PCI_WIDGET_OPERATION.GET_PIN);
                      getPciWidgetUrlAsync({
                        cardId: cardDetails[activeCard].cardId,
                        operation: PCI_WIDGET_OPERATION.GET_PIN,
                      });
                    }}
                    className="btn-success-green threeBtnCustomMargin"
                    id="activate_physical_card"
                    disabled={isLoadingGetPciWidgetUrl}
                  >
                    {isLoadingGetPciWidgetUrl &&
                      pciWidgetOperation === PCI_WIDGET_OPERATION.GET_PIN ? (
                      <>
                        <div
                          className="spinner-border spinner-border-sm"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                        &nbsp;Get Pin
                      </>
                    ) : (
                      "Get Pin"
                    )}
                  </button>
                )}
            </div>
          </div>
          <SingleCardTabs
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            fetchCardSpendingFunc={fetchCardSpendings}
            clickedSpendingTab={clickedSpendingTab}
            spendings={
              activeCard !== null && spendings !== null ? spendings : []
            }
            details={activeCard !== null ? cardDetails[activeCard] : {}}
            handleCardControl={handleCardControl}
            isLoading={isLoading}
          />

          <Modal
            className="TaxcodeModalOne"
            show={show}
            // onHide={handleClose}
            style={{ paddingRight: 0 }}
          >
            <form onSubmit={handleCardLimitSubmit(handleCardLimit)}>
              <Modal.Body>
                <div className="d-flex justify-content-between align-items-start green-header p-2">
                  <div className="col-9">
                    <p
                      className="text-green modal-title"
                      style={{ fontSize: 20 }}
                    >
                      {t("cards.change")}
                    </p>
                  </div>
                  <div
                    onClick={() => {
                      handleClose();
                      resetField("card_limit");
                    }}
                    className="clo-2"
                    style={{ cursor: "pointer" }}
                    id="close_modal"
                  >
                    <img
                      src={ImagesCross}
                      style={{ width: 20, objectFit: "contain" }}
                    />
                  </div>
                </div>
                <p className="text-grey">{t("cards.change_card_limit_desc")}</p>
                <div className="border-grey p-3" style={{ borderRadius: 15 }}>
                  <p
                    className="modal-header-text"
                    style={{ fontSize: 16, fontWeight: 600 }}
                  >
                    {t("cards.current_limit")}
                  </p>
                  <p
                    className="text-grey modal-text-limit m-0"
                    style={{ fontSize: 14 }}
                  >
                    <span className="text-black" style={{ fontSize: 20 }}>
                      {formatQAR(cardDetails[activeCard].cardSpendingLimit)}{" "}
                    </span>
                    QAR
                  </p>
                </div>
                <div
                  className="border-grey pt-3 pb-2 px-4 mt-4"
                  style={{ borderRadius: 15 }}
                >
                  <Slider
                    id="card_limit_slider"
                    aria-label="Inter"
                    defaultValue={cardDetails[activeCard]?.cardSpendingLimit}
                    getAriaValueText={valuetext}
                    valueLabelDisplay="auto"
                    step={50}
                    marks
                    min={wholePart}
                    max={wholePart + 10000}
                    onChange={handleSliderChange}
                  />
                </div>
                <div className="form-outline my-3 mb-4">
                  <label className="form-label" htmlFor="card_limit">
                    {t("teams.newLimit")}
                  </label>
                  <Controller
                    name="card_limit"
                    control={controlCardLimitForm}
                    render={({ field }) => (
                      <CurrencyInput
                        type="text"
                        id="card_limit"
                        className="form-control form-control-lg"
                        placeholder={`${t("cards.enter_amount")}`}
                        decimalsLimit={2}
                        decimalScale={2}
                        min={wholePart}
                        max={10000}
                        disablenegative={"true"}
                        defaultValue={limit || ""}
                        value={field.value || ""}
                        onKeyDown={(e) => {
                          if (e.key === "-") {
                            e.preventDefault();
                          }
                        }}
                        onValueChange={(value) => {
                          const numericValue =
                            parseFloat(value?.replace(/,/g, "")) || 0;
                          if (numericValue < MAX_VALUE) {
                            field.onChange(
                              value === ""
                                ? ""
                                : value?.startsWith(".")
                                  ? `0${value}`
                                  : value
                            );
                          }
                        }}
                      />
                    )}
                  />
                  <p className="text-danger">
                    {errorsCardLimitForm?.card_limit?.message}
                  </p>
                </div>
                <div className="form-outline my-3 mb-4">
                  <label className="form-label" htmlFor="card_limit">
                    {t("teams.frequency")}
                  </label>
                  <div className="dropdown-frequency">
                    <Controller
                      name="frequency"
                      control={controlCardLimitForm}
                      render={({ field }) => (
                        <CustomDropdown
                          id="frequency"
                          options={frequency}
                          placeholder={t("cards.frequency")}
                          onChange={(selectedOption) =>
                            field.onChange(selectedOption.value)
                          }
                          value={field.value}
                        />
                      )}
                    />
                  </div>
                  {/**    <Controller
                    name="card_limit"
                    control={controlCardLimitForm}
                    render={({ field }) => (
                      <CurrencyInput
                        type="text"
                        id="card_limit"
                        className="form-control form-control-lg"
                        placeholder={`${t("cards.enter_amount")}`}
                        decimalsLimit={2}
                        decimalScale={2}
                        min={wholePart}
                        max={10000}
                        disablenegative={"true"}
                        defaultValue={limit || ""}
                        value={field.value || ""}
                        onKeyDown={(e) => {
                          if (e.key === "-") {
                            e.preventDefault();
                          }
                        }}
                        onValueChange={(value) => {
                          const numericValue =
                            parseFloat(value?.replace(/,/g, "")) || 0;
                          if (numericValue < MAX_VALUE) {
                            field.onChange(
                              value === ""
                                ? ""
                                : value?.startsWith(".")
                                  ? `0${value}`
                                  : value
                            );
                          }
                        }}
                      />
                    )}
                  /> ***/}
                  <p className="text-danger">
                    {errorsCardLimitForm?.frequency?.message}
                  </p>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className="d-flex justify-content-center mb-3 mt-4 gap-3">
                  <SecondaryButton
                    type={'button'}
                    onClick={() => {
                      handleClose();
                      resetField("card_limit");
                    }}
                    buttonText={t('cancel')}
                    id={'cancel_card_limit'}
                    style={{ width: '165px' }}
                  />
                  <PrimaryButton
                    type={'submit'}
                    buttonText={t("save")}
                    isEnabled={true}
                    id={'submit_card_limit'}
                    style={{ width: '149px' }}
                    isLoading={isLoading}
                  />
                </div>

              </Modal.Footer>
            </form>
          </Modal>
          <Modal
            className="TaxcodeModalOne"
            show={showAddMoney}
            onHide={handleCloseAddMoney}
            style={{ paddingRight: 0 }}
          >
            <form onSubmit={handleAddMoneySubmit(onClickVerifyModal)}>
              <Modal.Body>
                <div className="d-flex justify-content-between align-items-start green-header p-2">
                  <div className="col-9">
                    <p
                      className="text-green modal-title"
                      style={{ fontSize: 20 }}
                    >
                      {t("accounts.add_funds")}
                    </p>
                  </div>
                  <div
                    onClick={handleCloseAddMoney}
                    className="clo-2"
                    style={{ cursor: "pointer" }}
                    id="close_modal"
                  >
                    <img
                      src={ImagesCross}
                      style={{ width: 20, objectFit: "contain" }}
                    />
                  </div>
                </div>
                <p className="text-grey" style={{ fontWeight: 400 }}>
                  {t("cards.add_money_desc")}
                </p>
                <div className="form-outline my-3">
                  {requiredFieldLabel(
                    t("teams.amount"),
                    "add_card_fund_amount"
                  )}
                  <Controller
                    name="add_card_fund_amount"
                    control={controlAddMoneyForm}
                    defaultValue=""
                    render={({ field }) => (
                      <CurrencyInput
                        type="text"
                        id="add_card_fund_amount"
                        className="form-control form-control-lg"
                        placeholder={`${t("cards.enter_amount")}`}
                        decimalsLimit={2}
                        decimalScale={2}
                        value={field.value || ""}
                        disablenegative={"true"}
                        onKeyDown={(e) => {
                          if (e.key === "-") {
                            e.preventDefault();
                          }
                        }}
                        onValueChange={(value) => {
                          const numericValue =
                            parseFloat(value?.replace(/,/g, "")) || 0;
                          if (numericValue < MAX_VALUE) {
                            field.onChange(
                              value === ""
                                ? ""
                                : value?.startsWith(".")
                                  ? `0${value}`
                                  : value
                            );
                          }
                        }}
                      />
                    )}
                  />
                  <p className="text-danger">
                    {errorsAddMoneyForm?.add_card_fund_amount?.message}
                  </p>
                </div>
                <div className="form-outline my-3 mb-4">
                  {requiredFieldLabel(
                    t("cards.comments"),
                    "add_card_fund_comments"
                  )}
                  <Controller
                    name="add_card_fund_comments"
                    control={controlAddMoneyForm}
                    defaultValue=""
                    render={({ field }) => (
                      <textarea
                        {...field}
                        id="add_card_fund_comments"
                        rows="5"
                        className="form-control"
                        placeholder={`${t("cards.enter_a_description")}`}
                        autoComplete="false"
                      />
                    )}
                  />
                  <p className="text-danger">
                    {errorsAddMoneyForm?.add_card_fund_comments?.message}
                  </p>
                </div>
              </Modal.Body>
              <Modal.Footer className="px-0 pt-3">
                <SecondaryButton
                  type={'button'}
                  onClick={handleCloseAddMoney}
                  buttonText={t('cancel')}
                  id={'cancel_add_card_money'}
                  style={{ width: '165px', marginTop: '8px' }}
                />
                <PrimaryButton
                  type={'submit'}
                  buttonText={t("save")}
                  isEnabled={true}
                  id={'submit_add_card_money'}
                  style={{ width: '149px', marginTop: '8px' }}
                  isLoading={isLoading}
                />
              </Modal.Footer>
            </form>
          </Modal>
          <Modal
            className="TaxcodeModalOne"
            show={showWithdrawMoney}
            onHide={handleCloseWithdrawMoney}
            style={{ paddingRight: 0 }}
          >
            <form onSubmit={handleWithdrawMoneySubmit(onClickVerifyModal)}>
              <Modal.Body>
                <div className="d-flex justify-content-between align-items-start green-header p-2">
                  <div className="col-9">
                    <p
                      className="text-black modal-title"
                      style={{ fontSize: 20 }}
                    >
                      {t("accounts.withdraw_funds")}
                    </p>
                  </div>
                  <div
                    onClick={handleCloseWithdrawMoney}
                    className="clo-2"
                    style={{ cursor: "pointer" }}
                    id="close_modal"
                  >
                    <img
                      src={ImagesCross}
                      style={{ width: 20, objectFit: "contain" }}
                    />
                  </div>
                </div>
                <p className="text-grey" style={{ fontWeight: 400 }}>
                  {t("cards.withdraw_money_desc")}
                </p>
                <div className="form-outline my-3">
                  {requiredFieldLabel(
                    t("teams.amount"),
                    "withdraw_card_fund_amount"
                  )}
                  <Controller
                    name="withdraw_card_fund_amount"
                    control={controlWithdrawMoneyForm}
                    render={({ field }) => (
                      <CurrencyInput
                        type="text"
                        id="withdraw_card_fund_amount"
                        className="form-control form-control-lg"
                        placeholder={`${t("cards.enter_amount")}`}
                        decimalsLimit={2}
                        decimalScale={2}
                        disablenegative={"true"}
                        value={field.value || ""}
                        onKeyDown={(e) => {
                          if (e.key === "-") {
                            e.preventDefault();
                          }
                        }}
                        onValueChange={(value) => {
                          const numericValue =
                            parseFloat(value?.replace(/,/g, "")) || 0;
                          if (numericValue < MAX_VALUE) {
                            field.onChange(
                              value === ""
                                ? ""
                                : value?.startsWith(".")
                                  ? `0${value}`
                                  : value
                            );
                          }
                        }}
                      />
                    )}
                  />
                  <p className="text-danger">
                    {
                      errorsWithdrawMoneyForm?.withdraw_card_fund_amount
                        ?.message
                    }
                  </p>
                </div>
                <div className="form-outline my-3 mb-4">
                  {requiredFieldLabel(
                    t("cards.comments"),
                    "withdraw_card_fund_comments"
                  )}
                  <Controller
                    name="withdraw_card_fund_comments"
                    control={controlWithdrawMoneyForm}
                    render={({ field }) => (
                      <textarea
                        {...field}
                        id="withdraw_card_fund_comments"
                        rows="5"
                        className="form-control"
                        placeholder={`${t("cards.enter_a_description")}`}
                        autoComplete="false"
                      />
                    )}
                  />
                  <p className="text-danger">
                    {
                      errorsWithdrawMoneyForm?.withdraw_card_fund_comments
                        ?.message
                    }
                  </p>
                </div>
              </Modal.Body>
              <Modal.Footer className="px-0 pt-3">
                <SecondaryButton
                  type={'button'}
                  onClick={handleCloseWithdrawMoney}
                  buttonText={t('cancel')}
                  id={'cancel_withdraw_card_money'}
                  style={{ width: '165px', marginTop: '8px' }}
                />
                <PrimaryButton
                  type={'submit'}
                  buttonText={t("save")}
                  isEnabled={true}
                  id={'submit_withdraw_card_money'}
                  style={{ width: '149px', marginTop: '8px' }}
                  isLoading={isLoading}
                />
              </Modal.Footer>
            </form>
          </Modal>
          <Modal
            aria-labelledby="contained-modal-title-center"
            centered
            show={showVerifyModal}
            onHide={handleCloseVerifyModal}
          >
            <form
              className="form"
              style={{ padding: 24 }}
              onSubmit={handleOtpSubmit(
                selectedType === "addFunds"
                  ? handleAddMoney
                  : selectedType === "withdrawFunds"
                    ? handleWithdrawMoney
                    : selectedType === "showDetails"
                      ? toggleMask
                      : handleCardFreezUnfreezAction
              )}
            >
              <div className="d-flex align-items-start justify-content-between">
                <img
                  src={Logo2}
                  className="logoso mt-1 mb-4"
                  alt="Sample image"
                />
                <div onClick={handleCloseVerifyModal} id="close_modal">
                  <img
                    src={ImagesCross}
                    className="small-addjust"
                    style={{ width: 20, objectFit: "contain", marginLeft: 15 }}
                  />
                </div>
              </div>
              <h1 className="text-blue-login mb-2">
                {t("login.verify_your_mobile_number")}
              </h1>
              <div className="divider d-flex align-items-center my-2">
                <p className="mb-0 text-subtitle2" style={{ color: "#8B9299" }}>
                  {t("login.verify_your_mobile_number_desc")}
                </p>
              </div>

              <div className="otp form-outline d-flex justify-content-center mt-4 mb-3">
                <Controller
                  name="otp"
                  control={controlVerifyModal}
                  defaultValue={0}
                  render={({ field: { onChange, value } }) => (
                    <OtpInput
                      inputStyle="inputStyle"
                      value={value}
                      onChange={onChange}
                      numInputs={6}
                      shouldAutoFocus={true}
                      renderInput={(props) => (
                        <input
                          {...props}
                          id="input_otp"
                          onInput={(e) => {
                            const value = e.target.value;
                            if (!/^\d*$/.test(value)) {
                              e.target.value = value.slice(0, -1);
                            }
                          }}
                        />
                      )}
                    />
                  )}
                />
              </div>
              <p className="text-danger">{errorsOtpForm?.otp?.message}</p>
              <div className="d-flex justify-content-end">
                {timer === 0 ? (
                  <a href="javascript:void(0)" id="resend_otp">
                    Resend OTP
                  </a>
                ) : (
                  <p>
                    Resend OTP in{" "}
                    <span className="text-blue">{timer} seconds</span>{" "}
                  </p>
                )}
              </div>
              <div className="text-center text-lg-start mt-4 w-100">
                <PrimaryButton
                  type={'submit'}
                  buttonText={selectedType === "addFunds"
                    ? "Continue to Add Funds"
                    : selectedType === "withdrawFunds"
                      ? "Continue to Withdraw Funds"
                      : selectedType === "showDetails"
                        ? "Continue to Show Details"
                        : "Continue to Freeze Card"}
                  isEnabled={!isLoading}
                  isExportBtn={true}
                  id={'continue'}
                  style={{ position: "relative", zIndex: "1" }}
                  isLoading={isLoading}
                />
              </div>
            </form>
          </Modal>
          {/**  <Modal
            className="PciWidgetModal"
            show={isShowPciWidget}
            onHide={() => setIsShowPciWidget(false)}
            centered
            style={{ paddingRight: 0 , height: "100%", width: "100%" }}
          >
            <Modal.Body>
              <div style={{ width: "100%", height: "100%" }}>
                {pciWidgetUrl && (
                  <iframe
                    id="pciWidgetFrame"
                    className="responsive-iframe"
                    src={pciWidgetUrl}
                    allowTransparency="true"
                    style={{height: "100%", width: "100%" }}
                  ></iframe>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={() => {
                  setIsShowPciWidget(false);
                }}
                className="btn-secondary-small mt-2"
                id="cancel_card_limit"
              >
                {t("cancel")}
              </button>
            </Modal.Footer>
          </Modal>**/}
          <Modal show={isShowActivate} onHide={() => setIsShowActivate(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Activate Card</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p className="text-center">Did you received the card ?</p>
            </Modal.Body>
            <Modal.Footer>
              <SecondaryButton
                type={'button'}
                onClick={() => setIsShowActivate(false)}
                buttonText={'No'}
                id={'isShowActive'}
              />
              <button
                className="btn-primary-small"
                onClick={() =>
                  activateCardStatus({
                    cardId: cardDetails[activeCard].cardId,
                  })
                }
              >
                Yes
              </button>
            </Modal.Footer>
          </Modal>

          <Modal
            className=""
            show={isShowPciWidget}
            onHide={() => setIsShowPciWidget(false)}
            centered
          >
            <Modal.Body>
              <div>
                {pciWidgetUrl && (
                  <iframe
                    id="pciWidgetFrame"
                    src={pciWidgetUrl}
                    allowTransparency="true"
                    style={{ height: iframeHeight, width: "100%" }}
                  ></iframe>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <SecondaryButton
                type={'button'}
                onClick={() => setIsShowPciWidget(false)}
                id={'cancel_card_limit'}
                style={{ marginTop: '8px' }}
                buttonText={t("cancel")}
              />
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </>
  );
}

export default SingleCardDetails;
