import './Export.scss';

import { Modal, Nav, Tab, } from 'react-bootstrap';
import React, { useRef, useState } from 'react';
import { exportAllPagesRecords, exportTransactionRecords, getExportedRecords, getQueueRecords, getUnexportedRecords, moveBackToUnExport } from './exportSlice';
import { fetchCurrencies, getAllTagByGroup, getCategoryList, getExpenseDetails } from '../Expenses/expensesSlice';
import { useDispatch, useSelector } from 'react-redux';

import ExpensesModalTabs from '../../components/Expenses/ExpensesModalTabs';
import ExportQueue from '../../components/Export/ExportQueue';
import Exported from '../../components/Export/Exported';
import ImagesCross from '../../assets/images/cross.png';
import UnExported from '../../components/Export/UnExported';
import { createAuditTrail } from '../TopUpRequest/slice/topUpSlice';
import { fetchReimbursementDetails } from '../Reimbursement/reimbursementSlice';
import { getConnectedIntegration } from "../../pages/Settings/taxSlice";
import { toast } from 'react-toastify';
import useDidUpdateEffect from '../../utils/useDidUpdateHook';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Export() {
    const { t } = useTranslation();
    const { state } = useLocation();
    const [selectedTab, setSelectedTab] = useState(0);
    const { unExportedData, exportQueueData, exportedData, isLoading } = useSelector((state) => state?.exportReducer);
    const { connectedIntegration } = useSelector((state) => state?.taxSlice);
    const { isExpenseDetailLoading, categoryList, tags, expenseDetails, currencies } = useSelector(state => state.expenseReducer);
    const { reimbursementDetails } = useSelector((state) => state?.reimbursementSlice);
    const dispatch = useDispatch();
    const [pageNo, setPageNo] = useState(1);
    const [tab, setTab] = useState('');
    const [cardTransactionId, setCardTransactionId] = useState(null);
    const [type, setType] = useState(null);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [exportType, setExportType] = useState('expense');
    const [activeKey, setActiveKey] = useState("first");
    const exportedRef = useRef();
    const queueRef = useRef();
    const filters = [
        {
            label: 'Expense',
            value: 'expense'
        },
        {
            label: 'Reimbursement',
            value: 'reimbursement'
        },
    ];
    const handleFilterClick = (index) => {
        setSelectedIndex(index);
        if (index === 0) {
            setExportType('expense');
        } else if (index === 1) {
            setExportType('reimbursement');
        }
    };

    useEffect(() => {
        if (state?.prevPath && state?.prevPath.includes("/reimbursement/reimbursement-details")) {
            handleFilterClick(1);
        }
        changeTab(state?.activeKey);
        dispatch(createAuditTrail({ eventType: 'access', module: 'export', description: `Fetched exports records` }));
        dispatch(fetchCurrencies());
        dispatch(getConnectedIntegration());
    }, []);

    useEffect(() => {
        if (tab == 'queue') {
            let queryParams = `?page=${pageNo}&exportType=${exportType}`;
            dispatch(getQueueRecords(queryParams));
        }
        if (tab == 'unexported' || tab === '') {
            let queryParams = `&page=${pageNo}&exportType=${exportType}`;
            dispatch(getUnexportedRecords(queryParams));
            dispatch(getQueueRecords(`?page=${pageNo}&exportType=${exportType}`));
        }
        if (tab == 'exported') {
            let queryParams = `&page=${pageNo}&exportType=${exportType}`;
            dispatch(getExportedRecords(queryParams));
        }
    }, [pageNo]);

    useDidUpdateEffect(() => {
        if (tab == 'queue') {
            setPageNo(1);
            let queryParams = `?page=${1}&exportType=${exportType}`;
            dispatch(getQueueRecords(queryParams));
        }
        if (tab == 'unexported' || tab === '') {
            setPageNo(1);
            let queryParams = `&page=${1}&exportType=${exportType}`;
            dispatch(getUnexportedRecords(queryParams));
            dispatch(getQueueRecords(`?page=${pageNo}&exportType=${exportType}`));
        }
        if (tab == 'exported') {
            setPageNo(1);
            let queryParams = `&page=${1}&exportType=${exportType}`;
            dispatch(getExportedRecords(queryParams));
        }
    }, [exportType]);

    const searchListByEmployee = (searchVal) => {
        if (searchVal === '') {
            dispatch(getUnexportedRecords(`&exportType=${exportType}`));
        } else {
            let queryParams = `&search=${searchVal}&exportType=${exportType}`;
            dispatch(getUnexportedRecords(queryParams));
        }
    };

    const onPreviousClick = () => {
        setPageNo(prev => prev - 1);
    };

    const onNextClick = () => {
        setPageNo(prev => prev + 1);
    };

    const changeTab = (tab) => {
        console.log("tab:", tab);
        setTab(tab);
        if (tab == 'queue') {
            if (queueRef.current) {
                queueRef.current();
            }
            setPageNo(1);
            dispatch(getQueueRecords(`?exportType=${exportType}`));
            setSelectedTab(2);
            setActiveKey('second');
        }
        if (tab == 'unexported') {
            setPageNo(1);
            setSelectedTab(1);
            let queryParams = `&page=${1}&exportType=${exportType}`;
            dispatch(getUnexportedRecords(queryParams));
            setActiveKey('first');
        }
        if (tab == 'exported') {
            if (exportedRef.current) {
                exportedRef.current();
            }
            setSelectedTab(3);
            dispatch(getExportedRecords(`&exportType=${exportType}`));
            setActiveKey('third');
        }
    };

    const exportRecords = async (allPages = false, type = 'csv', selectedRecords = [], unCheckedRecords = []) => {
        try {
            let response = null;
            if (allPages) {
                const params = `?exportType=${exportType}&type=${tab}&unchecked=${JSON.stringify(unCheckedRecords)}`;
                response = await dispatch(exportAllPagesRecords(params)).unwrap();
            } else {
                const postData = { type, selectedRecords, eventType: 'export' };
                response = await dispatch(exportTransactionRecords(postData)).unwrap();
            }
            if (connectedIntegration?.isConnected) {
                let queryParams = `?page=${pageNo}&exportType=${exportType}`;
                if (selectedTab && selectedTab == 2)
                    dispatch(getQueueRecords(queryParams));
                dispatch(getUnexportedRecords(`&exportType=${exportType}`));
                toast.success('Record Exported Successfully!');
            } else {
                let blob = '';
                if (type == 'csv') {
                    blob = await new Blob([response.data], { type: response.headers['content-type'] });
                }
                if (type == 'json') {
                    blob = await new Blob([JSON.stringify(response.data)], { type: response.headers['content-type'] });
                }
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                const contentDisposition = response.headers['Content-disposition'];
                const match = contentDisposition && contentDisposition.match(/filename=(.*)/);
                const filename = match ? match[1] : 'exported';
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                let queryParams = `?page=${pageNo}&exportType=${exportType}`;
                if (selectedTab && selectedTab == 2)
                    dispatch(getQueueRecords(queryParams));
                dispatch(getUnexportedRecords(`&exportType=${exportType}`));
                if (exportedRef.current) {
                    exportedRef.current();
                }
                if (queueRef.current) {
                    queueRef.current();
                }
                toast.success('Record Exported Successfully!');
            }
        } catch (error) {
            toast.error(error.message || "Request Failed Please Try Again ");
        }
    };

    const moveBackRecords = (selectedRecords = []) => {
        const postData = { selectedRecords };
        dispatch(moveBackToUnExport(postData)).unwrap().then(async (res) => {
            dispatch(getQueueRecords(`?exportType=${exportType}`));
            dispatch(getUnexportedRecords(`&exportType=${exportType}`));
            toast.success(res?.message);
        }).catch((error) => {
            toast.error(error.message || "Request Failed Please Try Again ");
        });
    };

    const handleShow = (cardTransactionId) => {
        setShow(true);
        setCardTransactionId(cardTransactionId);
        setType(cardTransactionId.type);
        if (cardTransactionId.type === "reimbursementRequestTransaction") {
            dispatch(fetchReimbursementDetails(`?reimbursementRequestId=${cardTransactionId.cardTransactionId}&eventType=export`));
            dispatch(getAllTagByGroup());
            dispatch(getCategoryList());
        }
        else {
            dispatch(getExpenseDetails(`?cardTransactionId=${cardTransactionId.cardTransactionId}`));
            dispatch(getAllTagByGroup());
            dispatch(getCategoryList());
        }
    };

    console.log(activeKey);

    return (
        <div className='white-container export-main '>
            <h1 className='text-green mb-3'>{t('sidebar.export')}</h1>
            <div className='inner-tabs mt-4'>
                <Tab.Container activeKey={activeKey} onSelect={(k) => changeTab(k)}>
                    <div className='customNavBarExportMainContainer'>
                        <Nav variant="pills">
                            <div className='customNavBarExport'>
                                <Nav.Item onClick={() => changeTab('unexported')} id='unexported_tab'>
                                    <Nav.Link eventKey="first">{<span>{t('export.unexported')}<span className='count-export ms-2' style={{ marginRight: 4 }}>{Object.keys(unExportedData).length > 0 ? unExportedData?.totalRecords : 0}</span>
                                    </span>}</Nav.Link>
                                </Nav.Item>
                                <div className='customNavBarExportGrayBar'></div>
                                <Nav.Item onClick={() => changeTab('queue')} id='export_queue_tab'>
                                    <Nav.Link eventKey="second">{<span>{t('export.export_queue')} <span className='count-export ms-2' style={{ marginRight: 8 }}>{Object.keys(exportQueueData).length > 0 ? exportQueueData?.totalRecords : 0}</span></span>}</Nav.Link>
                                </Nav.Item>
                                <div className='customNavBarExportGrayBar'></div>
                                <Nav.Item onClick={() => changeTab('exported')} id='exported_tab'>
                                    <Nav.Link eventKey="third">{t('export.exported')}</Nav.Link>
                                </Nav.Item>
                            </div>
                        </Nav>
                    </div>
                    <Tab.Content className='mt-4 white_container'>
                        <Tab.Pane eventKey="first" style={{ marginTop: 60 }}>
                            {unExportedData && <UnExported isLoading={isLoading} unExportedData={unExportedData} searchListByEmployee={searchListByEmployee} dispatch={dispatch} currencies={currencies} pageNo={pageNo} onPreviousClick={onPreviousClick} onNextClick={onNextClick} showExpenseDetailModal={handleShow} type1='export' type={exportType} prevPath={'/export'} connectedIntegration={connectedIntegration} />}
                        </Tab.Pane>
                        <Tab.Pane eventKey="second" style={{ marginTop: 90 }}>
                            {exportQueueData && <ExportQueue isLoading={isLoading} exportQueueData={exportQueueData} dispatch={dispatch} currencies={currencies} pageNo={pageNo} onPreviousClick={onPreviousClick} onNextClick={onNextClick} handleOnExportClick={exportRecords} showExpenseDetailModal={handleShow} type1='export' type={exportType} handleOnMoveBackUnExported={moveBackRecords} setChildFunctionRef={(func) => (queueRef.current = func)} prevPath={'/export'} />}
                        </Tab.Pane>
                        <Tab.Pane eventKey="third" style={{ marginTop: 90 }}>
                            {exportedData && <Exported isLoading={isLoading} exportedData={exportedData} handleOnExportClick={exportRecords} currencies={currencies} dispatch={dispatch} pageNo={pageNo} onPreviousClick={onPreviousClick} onNextClick={onNextClick} showExpenseDetailModal={handleShow} type1='export' type={exportType} setChildFunctionRef={(func) => (exportedRef.current = func)} prevPath={'/export'} />}
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>
            <Modal className='UserModal' show={show} onHide={handleClose} style={{ paddingRight: 0 }}>
                <Modal.Body>
                    <div className='d-flex justify-content-between align-items-start green-header'>
                        <div className='col-9'>
                            <p className='text-black modal-title  text-green' style={{ fontSize: 20 }}>
                                {t('export.unexported')}
                            </p>
                        </div>
                        <div onClick={handleClose} className='clo-2' style={{ cursor: 'pointer' }}>
                            <img src={ImagesCross} style={{ width: 20, objectFit: 'contain' }} />
                        </div>
                    </div>
                    {
                        (reimbursementDetails || expenseDetails) && !isExpenseDetailLoading && <ExpensesModalTabs cardTransactionId={cardTransactionId} handleClose={handleClose} isLoading={isLoading} tagGroups={tags} details={type === 'reimbursementRequestTransaction' ? reimbursementDetails : expenseDetails} dispatch={dispatch} categoryList={categoryList} type1={'export'} type={exportType}
                            updateCardTransactionData={null}
                        />
                    }
                </Modal.Body>
            </Modal>
            <div className='export-dropdown mt-4'>
                <div className='col-lg-6 col-md-12 mt-2' style={{
                    border: 1,
                    borderColor: "#00D094",
                    borderStyle: "solid", borderRadius: 20
                }}>
                    <div className='d-flex  justify-content-between ' style={{ padding: 4 }}>
                        {filters?.map((item, index) => (
                            <div
                                key={index}
                                onClick={() => handleFilterClick(index)}
                                style={{
                                    backgroundColor: selectedIndex === index ? '#01DD9D' : 'transparent',
                                    color: selectedIndex === index ? '#FFFFFF' : '#667085',
                                    textAlign: 'center',
                                    paddingTop: 9,
                                    paddingBottom: 9,
                                    borderRadius: 20,
                                    cursor: 'pointer',
                                    width: '49%',
                                }}
                                id={`${item.value}`}
                            >
                                {item.label}
                            </div>
                        ))}
                    </div>
                </div>
            </div>

        </div>
    );

}

export default Export;
