import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { API_ENDPOINTS, METHOD_TYPE } from "../../utils/apiUrls";
import api from "../../utils/api";

const initialState = {
    pettyCashBalances: null,
    transactions: [],
    cashWithEmployees: [],
    employees: null,
    error: null,
    isLoading: false,
    isBalancesLoading: false
};

export const getpettyCashBalances = createAsyncThunk("pettycash/balances", async () => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.getPettyChashBalances,
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const getPettyCashes = createAsyncThunk("pettycash/transactions", async (queryParams) => {
    try {
        let url = queryParams
            ? `${API_ENDPOINTS.getPettyCashedTransactions}${queryParams}`
            : `${API_ENDPOINTS.getPettyCashedTransactions}`;
        let data = {
            method: METHOD_TYPE.get,
            url: url,
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const getEmployeesCashes = createAsyncThunk("pettycash/employees/balances", async (queryParams) => {
    try {
        let url = queryParams
            ? `${API_ENDPOINTS.getEmployeesCashes}${queryParams}`
            : `${API_ENDPOINTS.getEmployeesCashes}`;
        let data = {
            method: METHOD_TYPE.get,
            url: url,
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const getEmployees = createAsyncThunk("department/fetchEmpDetails", async () => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.fetchEmpDetails,
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const addCash = createAsyncThunk("pettycash/addCash", async (requestData) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.addCash,
            data: requestData
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const withdrawCash = createAsyncThunk("pettycash/withdraw", async (requestData) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.withdrawCash,
            data: requestData
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const disburseCash = createAsyncThunk("pettycash/disburse", async (requestData) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.disburseCash,
            data: requestData
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const returnCash = createAsyncThunk("pettycash/return", async (requestData) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.returnCash,
            data: requestData
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const deleteTransaction = createAsyncThunk("/pettycash/transaction", async (transactionID) => {
    try {
        let data = {
            method: METHOD_TYPE.delete,
            url: `${API_ENDPOINTS.deleteTransaction}/${transactionID}`,
        };
        const response = await api(data);
        return response;

    } catch (error) {
        throw error?.response?.error;
    }
});

const pettyCashReducer = createSlice({
    name: "pettyCashReducer",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getpettyCashBalances.fulfilled, (state, { payload }) => {
                state.isBalancesLoading = false;
                state.error = null;
                state.pettyCashBalances = payload.data;

            })
            .addCase(getPettyCashes.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
                state.transactions = payload;
            })
            .addCase(getPettyCashes.rejected, (state, { error }) => {
                state.isLoading = false;
                state.error = error.message ? error.message : "Request Failed Please Try Again";
                state.transactions = [];
            })
            .addCase(getEmployeesCashes.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
                state.cashWithEmployees = payload;
            })
            .addCase(getEmployeesCashes.rejected, (state, { error }) => {
                state.isLoading = false;
                state.error = error.message ? error.message : "Request Failed Please Try Again";
                state.cashWithEmployees = [];
            })
            .addCase(getEmployees.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.error = null;
                state.employees = payload;
            })
            .addCase(getpettyCashBalances.pending, (state) => {
                state.isBalancesLoading = true;
                state.pettyCashBalances = null;
            })
            .addCase(getPettyCashes.pending, (state) => {
                state.isLoading = true;
                state.transactions = null;
            })
            .addMatcher(getpettyCashBalances.rejected, (state, { error }) => {
                state.isBalancesLoading = false;
                state.error = error?.message ? error.message : "Request Failed Please Try Again ";
            })
            .addMatcher(isAnyOf(getEmployees.pending, getEmployeesCashes.pending), (state) => {
                state.isLoading = true;
            })
            .addMatcher((getEmployees.rejected), (state, { error }) => {
                state.isLoading = false;
                state.error = error?.message ? error.message : "Request Failed Please Try Again ";
            })
            .addMatcher(
                (action) =>
                    action.type === addCash.pending.type ||
                    action.type === addCash.fulfilled.type ||
                    action.type === addCash.rejected.type,
                (state, action) => {
                    state.isLoading = action.type === addCash.pending.type;
                }
            )
            .addMatcher(
                (action) =>
                    action.type === withdrawCash.pending.type ||
                    action.type === withdrawCash.fulfilled.type ||
                    action.type === withdrawCash.rejected.type,
                (state, action) => {
                    state.isLoading = action.type === withdrawCash.pending.type;
                }
            )
            .addMatcher(
                (action) =>
                    action.type === disburseCash.pending.type ||
                    action.type === disburseCash.fulfilled.type ||
                    action.type === disburseCash.rejected.type,
                (state, action) => {
                    state.isLoading = action.type === disburseCash.pending.type;
                }
            )
            .addMatcher(
                (action) =>
                    action.type === returnCash.pending.type ||
                    action.type === returnCash.fulfilled.type ||
                    action.type === returnCash.rejected.type,
                (state, action) => {
                    state.isLoading = action.type === returnCash.pending.type;
                }
            );
    }
});
const pettycashReducer = pettyCashReducer.reducer;

export default pettycashReducer;
