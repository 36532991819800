import "./index.scss";

import { Card, Modal } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import {
  connectIntegration,
  disconnectIntegration,
  getAllIntegrations,
  getConnectedIntegration,
} from "../../pages/Settings/taxSlice";
import { useDispatch, useSelector } from "react-redux";
import Mapping from "./Mapping";
import cookies from "js-cookie";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import LoadingComponent from "../Loader/LoadingComponent";
import { active, verifyCompanyStatus } from "../../utils/verifyCompanyStatus";
import { allowedUserRoles, verifyUserRole } from "../../utils/verifyUserRole";
import SecondaryButton from "../Buttons/SecondaryButton";
import PrimaryButton from "../Buttons/PrimaryButton";

function Setup(props) {
  const {
    selectedIntegration,
    setSelectedIntegration,
    connectedIntegration,
    setAccountingTabs,
  } = props;
  const [show, setShow] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { reset } = useForm();
  const dispatch = useDispatch();
  const { isLoading, integrations } = useSelector((state) => state?.taxSlice);

  const [activeAccordion, setActiveAccordion] = useState(1);
  const {
    register: connectionRegister,
    handleSubmit: connectionHandleSubmit,
    formState: { errors: connectionErrors },
    reset: connectionReset,
    setValue: connectionSetValue,
  } = useForm();
  useEffect(() => {
    dispatch(getAllIntegrations());
  }, []);


  useEffect(() => {
    if (
      connectedIntegration &&
      connectedIntegration?.configData &&
      Object.keys(connectedIntegration)?.length > 0
    ) {
      setActiveAccordion(2);
      connectionSetValue("url", connectedIntegration?.configData?.url);
      connectionSetValue(
        "username",
        connectedIntegration?.configData?.username
      );
      connectionSetValue(
        "password",
        connectedIntegration?.configData?.password
      );
      connectionSetValue("db", connectedIntegration?.configData?.db);
    }
  }, [connectedIntegration]);
  const languages = [
    {
      code: "en",
      name: "English",
      country_code: "gb",
    },
    {
      code: "ar",
      name: "العربية",
      dir: "rtl",
      country_code: "sa",
    },
  ];
  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  useEffect(() => {
    document.body.dir = currentLanguage.dir || "ltr";
    document.title = t("app_title");
  }, [currentLanguage, t]);
  const handleClose = () => {
    setShow(false);
    setSelectedIntegration(null);
    setActiveStep(1);
  };

  const handleConnectionSubmit = (data) => {
    const payload = {
      accountIntegrationId: selectedIntegration?.accountIntegrationId,
      configData: { ...data },
    };
    setLoading(true);
    dispatch(connectIntegration(payload))
      .unwrap()
      .then(() => {
        setActiveStep(activeStep + 1);
        if(verifyCompanyStatus(active) && verifyUserRole(allowedUserRoles.adminAndAccountant)){
        dispatch(getConnectedIntegration());
        }
      })
      .catch((error) => {
        toast.error(error?.message);
      })
      .finally(() => setLoading(false));
  };
  const handleDisconnect = () => {
    dispatch(disconnectIntegration(connectedIntegration?.companyIntegrationId))
      .unwrap()
      .then((res) => {
        if(verifyCompanyStatus(active) && verifyUserRole(allowedUserRoles.adminAndAccountant)){
        dispatch(getConnectedIntegration());
        }
        toast.success(res?.message);
        setActiveAccordion(1);
        setSelectedIntegration(null);
        connectionReset();
      })
      .catch((error) => {
        toast.error(error?.message);
      });
  };
  return (
    <div className="taxcodes" style={{position: 'relative'}}>
      {connectedIntegration?.isConnected ? (
        <Mapping
          setAccountingTabs={setAccountingTabs}
          handleDisconnect={handleDisconnect}
          connectedIntegration={connectedIntegration}
        />
      ) : (
        <div>
            <h2
              className={`card-title-black-text ${
                activeAccordion >= 1 ? "active" : ""
              }`}
            >
              {t("settingstab.setup_application")}
            </h2>
          <p>{t("settingstab.setup_description")}</p>
          <div className="row" style={{position: 'relative'}}>
            {isLoading && <LoadingComponent />}
            {integrations.map((integration, index) => (
              <div
                className="col-3"
                key={`integration-${
                  integration?.accountIntegrationId || index
                }`}
                onClick={() => {
                  setSelectedIntegration(integration);
                  setShow(true);
                }}
              >
                <Card
                  className={`setup-card text-center ${
                    selectedIntegration?.accountIntegrationId ===
                    integration?.accountIntegrationId
                      ? "active"
                      : ""
                  }`}
                >
                  <Card.Body>
                    <div className="icon-container">
                      <img
                        src={integration?.logoUrl}
                        alt={integration?.name}
                        className="card-icon"
                      />
                    </div>
                    <Card.Title>{integration?.name}</Card.Title>
                    <Card.Text className="truncated-text">{integration?.description}</Card.Text>
                  </Card.Body>
                </Card>
              </div>
            ))}
            {!integrations.length && (
              <div className="row justify-content-center">
                No integration found
              </div>
            )}
          </div>
        </div>
      )}

      <Modal
        centered
        id="integrationModal"
        backdrop="static"
        show={show}
        onHide={handleClose}
        style={{ paddingRight: 0 }}
      >
        <Modal.Body>
          <div
            className="d-flex align-items-center justify-content-between px-3 p-2"
            style={{ marginBottom: 8 }}
          >
            <div
              onClick={() => {}}
              className="clo-2"
              style={{ cursor: "pointer" }}
            ></div>
          </div>
          {activeStep !== 3 && (
            <div>
              <div className="image-container">
                <img
                  src={selectedIntegration?.logoUrl}
                  alt={selectedIntegration?.name}
                  className="image-icon"
                />
              </div>
              {activeStep !== 2 && (
                <h2 className="text-center">
                  <strong>{selectedIntegration?.setupMessage}</strong>
                </h2>
              )}
            </div>
          )}
          <div className="p-3 mt-4">
            {activeStep === 1 && (
              <div>
                <ul>
                  {selectedIntegration?.description
                    .split(". ")
                    .map((sentence) => (
                      <li>
                        {" "}
                        <h2>{sentence}</h2>
                      </li>
                    ))}
                </ul>
                <div className="d-flex justify-content-end mt-4 gap-3">
                  <SecondaryButton
                    type={'button'}
                    onClick={() => {
                      handleClose();
                      reset();
                    }}
                    buttonText={t("cancel")}
                    id={'cancel'}
                    style={{ width: '102px' }}
                  />
                  <PrimaryButton
                    type={'button'}
                    onClick={() => {
                      setActiveStep(activeStep + 1);
                    }}
                    buttonText={t("login.continue")}
                    isEnabled={true}
                    id={'continue'}
                    style={{ width: '149px' }}
                    isLoading={loading}
                  />
                </div>
              </div>
            )}
            {activeStep === 2 && (
              <div>
                <div className="d-flex justify-content-center  mb-3">
                  <div className="col-lg-10 col-md-6 col-sm-12 form-outline mt-4 ">
                    <label className="form-labels" htmlFor="url">
                      {t("settingstab.url")}
                    </label>
                    <input
                      type="text"
                      id="url"
                      className={`form-control form-control-lg ${
                        connectionErrors?.url ? "is-invalid" : ""
                      }`}
                      placeholder={t("settingstab.url_placeholder")}
                      autoComplete="off"
                      {...connectionRegister("url", {
                        required: `${t("settingstab.enter_url")}`,
                      })}
                    />
                    {connectionErrors?.url && (
                      <div className="invalid-feedback">
                        {connectionErrors?.url?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  <div className=" col-lg-10 col-md-6 col-sm-12 form-outline ">
                    <label className="form-labels" htmlFor="db">
                      {t("settingstab.database_name")}
                    </label>

                    <input
                      type="text"
                      id="db"
                      className={`form-control form-control-lg ${
                        connectionErrors?.db ? "is-invalid" : ""
                      }`}
                      placeholder="Enter the database name"
                      autoComplete="off"
                      {...connectionRegister("db", {
                        required: `${t("settingstab.enter_database_name")}`,
                      })}
                    />
                    {connectionErrors?.db && (
                      <div className="invalid-feedback">
                        {connectionErrors?.db?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  <div className=" col-lg-10 col-md-6 col-sm-12 form-outline ">
                    <label className="form-labels" htmlFor="username">
                      {t("settingstab.username")}
                    </label>

                    <input
                      type="text"
                      id="username"
                      className={`form-control form-control-lg ${
                        connectionErrors?.username ? "is-invalid" : ""
                      }`}
                      placeholder="***********************"
                      autoComplete="off"
                      {...connectionRegister("username", {
                        required: `${t("settingstab.enter_username")}`,
                      })}
                    />
                    {connectionErrors?.username && (
                      <div className="invalid-feedback">
                        {connectionErrors?.username?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  <div className="col-lg-10 col-md-6 col-sm-12 form-outline mt-4 ">
                    <label className="form-labels" htmlFor="password">
                      {t("settingstab.password")}
                    </label>

                    <input
                      type="password"
                      id="password"
                      className={`form-control form-control-lg ${
                        connectionErrors?.password ? "is-invalid" : ""
                      }`}
                      placeholder="***********************"
                      autoComplete="off"
                      {...connectionRegister("password", {
                        required: `${t("settingstab.enter_password")}`,
                      })}
                    />
                    {connectionErrors?.password && (
                      <div className="invalid-feedback">
                        {connectionErrors?.password?.message}
                      </div>
                    )}
                  </div>
                </div>

                <div className="d-flex justify-content-center mb-3 mt-4 gap-3">
                  <SecondaryButton
                    type={'button'}
                    onClick={() => {
                      handleClose();
                      reset();
                    }}
                    buttonText={t('settingstab.cancel_connect')}
                    id={'cancel_password'}
                    style={{ width: '165px' }}
                  />
                  <PrimaryButton
                    type={'button'}
                    onClick={connectionHandleSubmit(handleConnectionSubmit)}
                    buttonText={t("settingstab.connect")}
                    isEnabled={true}
                    id={'submit_connect'}
                    style={{ width: '149px' }}
                    isLoading={loading}
                  />
                </div>
              </div>
            )}
            {activeStep === 3 && (
              <div>
                <div className="text">Your integration has been successful</div>

                <div className="d-flex justify-content-center gap-3 mt-3 mb-4 ">
                  <button
                    onClick={() => {
                      handleClose();
                      reset();
                    }}
                    id="cancel"
                    className="btn-primary-small"
                  >
                    {t("settingstab.okay")}
                  </button>
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>

    </div>
  );
}

export default Setup;
