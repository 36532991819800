import React, { Fragment, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "./Cards.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  getAllCards,
  getEmployees,
  issueNewCard,
  getMccCategoryDetails,
} from "./slice/cardListSlice";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import ImagesCross from "../../assets/images/close-square-8.png";
import SearchImg from "../../assets/images/search_img.png";
import CardsImg from "../../assets/images/cards_img.png";
import LoadingComponent from "../../components/Loader/LoadingComponent";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { getAllDepartment } from "../Teams/teamSlice";
import {
  capitalizeFirstCharacter,
  formatQARWithoutDecimal,
} from "../../utils/XpendlessUtils";
import cookies from "js-cookie";
import Logo2 from "../../assets/images/polo.png";
import OtpInput from "react-otp-input";
import notFound from "../../assets/images/not_found.png";
import CustomDropdown from "../../components/CustomDropdown/CustomDropdown";
import PhysicalCard from "../../assets/images/physical_card.svg";
import VirtualCard from "../../assets/images/virtual_card.svg";
import Delete from "../../assets/images/delete.svg";
import Icon from "../../assets/images/icon.svg";
import { CARD_TYPE, MAX_VALUE } from "../../utils/constants";
import { getCompanyDetails } from "../CompanyVerification/companySlice";
import CurrencyInput from "react-currency-input-field";
import {
  activeBlocked,
  verifyCompanyStatus,
} from "../../utils/verifyCompanyStatus";
import { allowedUserRoles, verifyUserRole } from "../../utils/verifyUserRole";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../components/Buttons/SecondaryButton";
import IconButton from "../../components/Buttons/IconButton";
import { Stack } from "@mui/material";

const languages = [
  {
    code: "en",
    name: "English",
    country_code: "gb",
  },
  {
    code: "ar",
    name: "العربية",
    dir: "rtl",
    country_code: "sa",
  },
];

const maskCardNumber = (cardNumber, showUnmasked) => {
  const digitsOnly = cardNumber.replace(/\D/g, "");
  const masked =
    digitsOnly.slice(0, -4).replace(/\d/g, "*") + digitsOnly.slice(-4);
  const maskedCardNumber = masked.replace(/(.{4})/g, "$1 ").trim();
  return typeof showUnmasked !== "undefined" && showUnmasked
    ? cardNumber
    : maskedCardNumber;
};

function Cards() {
  const [show, setShow] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const [cardType, setCardType] = useState(CARD_TYPE.PHYSICAL);
  const mccCategory = useSelector((state) => state?.cardListReducer);
  const { cardLists, isLoading } = useSelector(
    (state) => state.cardListReducer
  );
  const { departmentData } = useSelector((state) => state?.teamSlice);
  const [pageNo, setPageNo] = useState(1);
  const [issueCardData, setIssueCardData] = useState(null);
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [timer, setTimer] = useState(60);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const employeeRef = useRef();
  const { t } = useTranslation();
  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);

  const [selectedOption, setSelectedOption] = useState("");
  const [categories, setCategories] = useState([]);
  const { companyDetail } = useSelector((state) => state?.companyVerification);

  useEffect(() => {
    dispatch(getMccCategoryDetails());
    if (verifyCompanyStatus() && verifyUserRole(allowedUserRoles.admin)) {
      dispatch(getCompanyDetails());
    }
  }, []);
  const handleShow = () => setShow(true);
  const handleClose1 = () => {
    setShow(false);
    resetAddCardForm();
    setActiveStep(1);
  };

  const ADD_CARDS_STEPS = {
    STEP_1: {
      label: t("teams.select_card_type"),
      description: t("teams.add_card_desc"),
    },
    STEP_2: {
      label: t("expenses.details"),
      description: t("teams.please_fill_info"),
    },
    STEP_3: {
      label: t("cards.shipping_address"),
      description: t("cards.shipment_address"),
    },
    STEP_4: {
      label: t("cards.restrictions"),
      description: t("cards.block_restrict"),
    },
  };
  const ADD_CARD_STEPS_VALIDATION = {
    STEP_1: {
      cardType: yup.string().required("Card type is required."),
    },

    STEP_2: {
      cardName: yup.string().required("Card name is required."),
      limit: yup
        .number()
        .required("Card balance limit is required.")
        .moreThan(0, "Card balance limit must be greater than zero."),
      employeeId: yup.string().required("Employee name is required."),
      amount: yup
        .number()
        .nullable()
        .test(
          "amount-required-if-frequency",
          "Amount is required.",
          function (value) {
            const { frequency } = this.parent;
            return frequency ? value != null : true;
          }
        )
        .moreThan(0, "Amount must be greater than zero."),
      frequency: yup
        .string()
        .nullable()
        .test(
          "frequency-required-if-amount",
          "Frequency is required.",
          function (value) {
            const { amount } = this.parent;
            return amount ? value != null : true;
          }
        ),
      transactionAmountLimit: yup
        .number()
        .optional("Transaction amount limit is required.")
        .moreThan(0, "Transaction amount must be greater than zero."),
    },
    STEP_3: {
      firstName: yup.string().required("First name is required."),
      lastName: yup.string().required("Last name is required."),
      phoneNumber: yup
        .string()
        .required("Phone number is required.")
        .min(8, "Mobile number must be at least 8 digits")
        .max(16, "Mobile number should not exceed 16 digits")
        .matches(/^\d+$/, "Mobile number must contain only digits"),
      addressLine1: yup.string().required("Address line 1 is required."),
      addressLine2: yup.string().optional("Address line 2 is required."),
      postalCode: yup.string().required("Postal code is required."),
      city: yup.string().required("City is required."),
    },
    STEP_4: {
      categoryRestriction: yup
        .string()
        .required("Category restriction is required."),
    },
  };

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption.value);
  };

  const handleAddCategory = () => {
    setCategories([...categories, { id: "", value: "" }]);
  };

  const handleCategoryChange = (index, selectedOption) => {
    const categoryId = selectedOption?.id;
    const category = mccCategory?.mccCategory?.find(
      (cat) => cat.id === categoryId
    );

    if (category) {
      const updatedCategories = [...categories];
      updatedCategories[index] = {
        id: category.id,
        value: category.category,
      };
      setCategories(updatedCategories);
    }
  };

  const handleDeleteCategory = (index) => {
    const updatedCategories = categories.filter((_, i) => i !== index);
    setCategories(updatedCategories);
  };

  const departments = departmentData?.map((department) => ({
    value: department.departmentName,
    label: `${department.departmentName}`,
  }));

  const employees = mccCategory?.employees?.map((employee) => ({
    value: employee.employeeId,
    label: `${employee.fullName}`,
    firstName: employee.firstName,
    lastName: employee.lastName,
  }));

  const categoriesList = mccCategory?.mccCategory?.map((category) => ({
    id: category.id,
    label: `${category.category}`,
  }));

  const selectCategory = [
    {
      label: `Allow Category`,
      value: "allow",
    },
    {
      label: `Block Category`,
      value: "block",
    },
    {
      label: `None`,
      value: "none",
    },
  ];

  const cardTypes = [
    {
      label: `Physical`,
      value: "physical",
    },
    {
      label: `Virtual`,
      value: "virtual",
    },
  ];

  const frequency = [
    {
      label: `DAILY`,
      value: "DAILY",
    },
    {
      label: `MONTHLY`,
      value: "MONTHLY",
    },
    {
      label: `YEARLY`,
      value: "YEARLY",
    },
    {
      label: `ALL TIME`,
      value: "ALL_TIME",
    },
  ];

  const {
    control: controlSearchForm,
    handleSubmit: handleSearchSubmit,
    formState: { errors: errorsSearchForm },
    reset: resetFilterForm,
    watch,
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        employeeName: yup.string(),
        department: yup.string(),
        cardType: yup.string(),
      })
    ),
  });

  const {
    control: controlAddCardForm,
    handleSubmit: handleAddCardSubmit,
    setValue: setAddCardValue,
    formState: { errors: errorsAddCardForm },
    reset: resetAddCardForm,
  } = useForm({
    resolver: yupResolver(
      yup.object().shape(ADD_CARD_STEPS_VALIDATION[`STEP_${activeStep}`])
    ),
  });

  useEffect(() => {
    if (companyDetail) {
      setAddCardValue("addressLine1", companyDetail?.addressLine1 || "");
      setAddCardValue("addressLine2", companyDetail?.addressLine2 || "");
      setAddCardValue("city", companyDetail?.city || "");
      setAddCardValue("postalCode", companyDetail?.postalCode || "");
      setAddCardValue("phoneNumber", companyDetail?.companyPhone || "");
    }
  }, [companyDetail, setAddCardValue]);

  const {
    control: controlVerifyModal,
    handleSubmit: handleOtpSubmit,
    formState: { errors: errorsOtpForm },
    reset: otpFormReset,
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        otp: yup
          .string()
          .required("Otp is required")
          .matches(/^123456$/, "OTP must be exactly 6 digits"),
      })
    ),
  });

  const handleShowVerifyModal = () => setShowVerifyModal(true);
  const handleCloseVerifyModal = () => setShowVerifyModal(false);
  const onPreviousClick = () => {
    setPageNo((prev) => prev - 1);
  };

  const onNextClick = () => {
    setPageNo((prev) => prev + 1);
  };

  useEffect(() => {
    let interval;

    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [timer]);

  useEffect(() => {
    if (
      verifyCompanyStatus(activeBlocked) &&
      verifyUserRole(allowedUserRoles.adminAccountantReviewerAndCardHolder)
    ) {
      dispatch(getAllDepartment());
    }
    dispatch(getEmployees());
  }, []);

  useEffect(() => {
    const allValues = watch();
    const { cardType, department, employeeName } = allValues;
    let queryParams = `?page=${pageNo}`;
    if (cardType || department || employeeName) {
      queryParams += "&";
      queryParams += Object.keys(allValues)
        .filter((key) => allValues[key] !== undefined && allValues[key] !== "")
        .map((key) => `${key}=${encodeURIComponent(allValues[key])}`)
        .join("&");
    }
    dispatch(getAllCards(queryParams));
  }, [dispatch, pageNo]);

  useEffect(() => {
    document.body.dir = currentLanguage.dir || "ltr";
    document.title = t("app_title");
  }, [currentLanguage, t]);

  const onSearchFormSubmit = (data) => {
    setPageNo(1);
    const { cardType, department, employeeName } = data;
    let queryParams = `?page=${1}&`;
    if (!cardType && !department && !employeeName) {
      dispatch(getAllCards(queryParams));
    } else {
      queryParams += Object.keys(data)
        .filter((key) => data[key] !== undefined && data[key] !== "")
        .map((key) => `${key}=${encodeURIComponent(data[key])}`)
        .join("&");
      dispatch(getAllCards(queryParams))
        .unwrap()
        .then(() => { })
        .catch((error) => {
          toast.error(error.message || "Request Failed Please Try Again ");
        });
    }
  };

  const handleResetForm = () => {
    resetFilterForm();
    setPageNo(1);
    let queryParams = `?page=${1}&`;
    dispatch(getAllCards(queryParams));
  };

  const routeChange = (id, cardId) => {
    let path = `/card/card-details/${id}`;
    if (cardId) {
      path = `/card/card-details/${id}?cardId=${cardId}`;
    }
    navigate(path);
  };

  const handleAddCard = () => {
    const payload = {
      cardType: issueCardData.cardType,
      employeeId: issueCardData.employeeId,
      cardName: issueCardData.cardName,
      limit: issueCardData.limit,
      cardSpendingLimit: issueCardData.amount,
      frequency: issueCardData.frequency,
      transactionAmountLimit: issueCardData.transactionAmountLimit,
      isFreezed: false,
      currentBalance: 0,
      eventType: "cards",
      expiryDate: "2025-10-06",
      status: "active",
      categories: categories.map((cat) => {
        return {
          allow: issueCardData.categoryRestriction === "allow" ? true : false,
          categoryId: cat.id,
        };
      }),
    };
    if (issueCardData.cardType === CARD_TYPE.PHYSICAL) {
      payload["address"] = {
        firstName: issueCardData.firstName,
        lastName: issueCardData.lastName,
        phoneNumber: issueCardData.phoneNumber,
        addressLine1: issueCardData.addressLine1,
        addressLine2: issueCardData.addressLine2,
        city: issueCardData.city,
        postalCode: issueCardData.postalCode,
      };
    }
    dispatch(issueNewCard(payload))
      .unwrap()
      .then(() => {
        toast.success("Card Added Successfully!");
        routeChange(issueCardData.employeeId);
        let queryParams = `?page=${pageNo}`;
        dispatch(getAllCards(queryParams));
        resetAddCardForm();
        if (employeeRef.current) employeeRef.current.value = "";
        handleClose1();
        handleCloseVerifyModal();
        otpFormReset({ otp: "" });
      })
      .catch((error) => {
        if (error.name === 'Subscription Limit') {
          handleCloseVerifyModal()
          setShowConfirmationModal(true)
        }
        toast.error(error.message || "Request Failed Please Try Again ");
      });
  };

  const onClickVerifyModal = (data) => {
    setIssueCardData(data);
    setTimer(60);
    // handleClose1();
    otpFormReset({ otp: "" });
    setTimeout(() => {
      handleShowVerifyModal();
    }, 200);
  };

  const handleNext = (isSubmit = false) => {
    handleAddCardSubmit((data) => {
      setIssueCardData((prevData) => ({
        ...prevData,
        ...data,
      }));

      if (activeStep === 1 && cardType === CARD_TYPE.VIRTUAL) {
        setActiveStep(2);
      } else if (activeStep === 2 && cardType === CARD_TYPE.VIRTUAL) {
        setActiveStep(4);
      } else if (activeStep < 4) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }

      if (isSubmit) {
        onClickVerifyModal(data);
      }
    })();
  };

  const handlePrevious = () => {
    if (cardType === "virtual") {
      if (activeStep === 4) {
        setActiveStep(2);
      } else if (activeStep === 2) {
        setActiveStep(1);
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  return (
    <>
      <div className="white-container cards-section-css">
        <div className="d-flex align-items-center justify-content-between">
          <h1 className="text-green  ms-3">{t("sidebar.xpendless_cards")}</h1>
          <PrimaryButton
            type={'button'}
            onClick={handleShow}
            buttonText={t("cards.+_add_new_card")}
            isEnabled={true}
            id={"show_add_card"}
            style={{ marginLeft: '16px', width: '170px' }}
          />

        </div>
        <div className="border-grey cards-custom  mt-3 p-3">
          <div className=" d-flex align-items-center mb-4  green-header">
            <img src={SearchImg} className="small-img-size1 me-3" />
            <h2 className="text-green ps-1 me-1">{t("cards.search")}</h2>
          </div>
          <form onSubmit={handleSearchSubmit(onSearchFormSubmit)}>
            <div className="row cards-search">
              <div className="col-lg-4 col-md-12 form-outline">
                <label className="form-label" htmlFor="employeeName">
                  {t("cards.employee_name")}
                </label>
                <Controller
                  name="employeeName"
                  control={controlSearchForm}
                  defaultValue=""
                  render={({ field }) => (
                    <input
                      {...field}
                      type="text"
                      id="employeeName"
                      className="form-control form-control-lg input1"
                      placeholder={`${t("cards.enter_employee_name")}`}
                    />
                  )}
                />
                <p className="text-danger">
                  {errorsSearchForm?.employeeName?.message}
                </p>
              </div>
              <div className="col-lg-4 col-md-12 form-outline">
                <label className="form-label" htmlFor="department">
                  {t("cards.department")}
                </label>
                <Controller
                  control={controlSearchForm}
                  name="department"
                  render={({ field }) => (
                    <CustomDropdown
                      id="department"
                      options={departments}
                      selectedOption={
                        departments?.find(
                          (option) => option.value === watch("department")
                        ) || t("cards.select_department")
                      }
                      placeholder={t("cards.select_department")}
                      onChange={(selectedOption) => {
                        field.onChange(selectedOption.value);
                      }}
                      onKeyUp={(e, selectedOption) => {
                        if (e.key === "Enter") {
                          handleSearchSubmit(onSearchFormSubmit)();
                        } else {
                          field.onChange(selectedOption?.value);
                        }
                      }}
                    />
                  )}
                />
                <p className="text-danger">
                  {errorsSearchForm?.department?.message}
                </p>
              </div>
              <div className="col-lg-4 col-md-12 form-outline">
                <label className="form-label" htmlFor="cardType">
                  {t("cards.physical_virtual")}
                </label>
                <Controller
                  control={controlSearchForm}
                  name="cardType"
                  render={({ field }) => (
                    <CustomDropdown
                      id="cardType"
                      options={cardTypes}
                      selectedOption={
                        cardTypes?.find(
                          (option) => option.value === watch("cardType")
                        ) || t("cards.select_card_type")
                      }
                      placeholder={t("cards.select_card_type")}
                      onChange={(selectedOption) => {
                        field.onChange(selectedOption.value);
                      }}
                      onKeyUp={(e, selectedOption) => {
                        if (e.key === "Enter") {
                          handleSearchSubmit(onSearchFormSubmit)();
                        } else {
                          field.onChange(selectedOption?.value);
                        }
                      }}
                    />
                  )}
                />
                <p className="text-danger">
                  {errorsSearchForm?.cardType?.message}
                </p>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-end mt-4">
              <SecondaryButton
                type={'button'}
                onClick={handleResetForm}
                buttonText={t("cards.clear_search")}
                isEnabled={true}
                id={"clear_cards"}
                style={{ marginTop: '16px', marginInline: '16px', width: '149px' }}
              />
              <PrimaryButton
                type={'submit'}
                buttonText={t("cards.search")}
                isEnabled={true}
                id={'search_cards'}
                style={{ marginTop: '16px', width: '100px' }}
              />
            </div>
            {errorsSearchForm.atLeastOneField && (
              <p className="text-danger">
                {errorsSearchForm?.atLeastOneField?.message}
              </p>
            )}
          </form>
        </div>
        <div className="cards-custom mt-4 p-3">
          <div className=" d-flex align-items-center mb-3 green-header">
            <img src={CardsImg} className="small-img-size1 me-3" />
            <h2 className="text-green ps-1">{t("sidebar.xpendless_cards")}</h2>
          </div>
          <div
            className="table-responsive p-3 py-0"
            style={{ position: "relative" }}
          >
            {(isLoading || !cardLists.rows) && <LoadingComponent />}
            <table
              id="cardsTable"
              style={{ borderCollapse: "separate", borderSpacing: "0 16px" }}
              cellSpacing="0"
              width="100%"
            >
              <thead>
                <tr style={{ borderTopWidth: 0 }} className="table-head">
                  <th scope="col">{t("cards.card_number")}</th>
                  <th scope="col">{t("cards.card_holder_name")}</th>
                  <th scope="col">{t("cards.card_name")}</th>
                  <th scope="col">{t("cards.card_type")}</th>
                  <th scope="col">{t("cards.card_status")}</th>
                  <th style={{ textAlign: "right" }} scope="col">
                    {t("cards.limit")}
                  </th>
                  <th scope="col">{t("actions")}</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(cardLists).length > 0 &&
                  Object.keys(cardLists["rows"]).length > 0 &&
                  cardLists["rows"].map((cardList, index) => {
                    const [wholePart, decimalPart] = cardList.limit
                      ? cardList.limit.toString().split(".").map(Number)
                      : ["0", "00"];
                    return (
                      <tr
                        onClick={() =>
                          routeChange(cardList.employeeId, cardList.cardId)
                        }
                        style={{ cursor: "pointer" }}
                        key={index}
                        className={`${currentLanguageCode === "en"
                          ? "table-body-tr"
                          : "table-body-tr-arabic"
                          }`}
                      >
                        <td>{maskCardNumber(cardList.cardToken, false)}</td>
                        <td>{`${cardList.firstName} ${cardList.lastName}`}</td>
                        <td>{`${cardList.cardName}`}</td>
                        <td>{capitalizeFirstCharacter(cardList.cardType)}</td>
                        <td>{capitalizeFirstCharacter(cardList.status)}</td>
                        <td>
                          <h4 style={{ textAlign: "right" }}>
                            {formatQARWithoutDecimal(wholePart)}.
                            <span
                              style={{ fontSize: 12, fontWeight: "500" }}
                            >{`${decimalPart ? decimalPart : "00"} QAR`}</span>
                          </h4>
                        </td>
                        <td>
                          <a
                            id={`view_card_${index}`}
                            onClick={(e) => console.log(e.target.id)}
                          >
                            <span
                              style={{
                                marginLeft: 10,
                                marginRight: 10,
                                cursor: "pointer",
                                color: "#00D094",
                              }}
                            >
                              {t("view")}
                            </span>
                          </a>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            {!(cardLists?.rows && cardLists?.rows?.length > 0) && (
              <div className="d-flex align-items-center justify-content-center p-3 px-2">
                <img src={notFound} width={200} height={200} />
              </div>
            )}
          </div>
          <div
            className="d-flex align-items-center justify-content-between mt-4"
            style={{ width: "100%", borderTop: "0px !imporant" }}
          >
            <nav aria-label="Page navigation example">
              <ul className="pagination mt-2 pt-1 mb-3 pe-0">
                <li className="page-item">
                  <SecondaryButton
                    type={'button'}
                    onClick={() => onPreviousClick()}
                    buttonText={t("previous")}
                    isEnabled={pageNo !== 1}
                    id={'prev_page_cards'}
                  />
                </li>
                <li className="page-item mx-3">
                  <SecondaryButton
                    type={'button'}
                    onClick={() => onNextClick()}
                    buttonText={t("next")}
                    isEnabled={!(pageNo == cardLists?.totalPages ||
                      cardLists?.totalPages == 0)}
                    id={'next_page_cards'}
                  />
                </li>
              </ul>
            </nav>
            <div
              className="page-count d-flex align-items-center"
              style={{ color: "#00D094" }}
            >
              <h4>
                Page {cardLists?.currentPage} of {cardLists?.totalPages}
              </h4>
            </div>
          </div>
        </div>
      </div>

      <Modal
        className="TeamModalOne"
        show={show}
        // onHide={handleClose1}
        style={{ paddingRight: 0 }}
      >
        <form onSubmit={handleAddCardSubmit(() => handleNext(activeStep >= 4))}>
          <Modal.Body>
            <div className="d-flex justify-content-between align-items-start green-header p-2 ps-3">
              <div className="col-9">
                <p className="text-green modal-title" style={{ fontSize: 20 }}>
                  {ADD_CARDS_STEPS[`STEP_${activeStep}`]["label"]}
                </p>
              </div>
              <div
                onClick={handleClose1}
                className="clo-2"
                style={{ cursor: "pointer" }}
                id="close_modal"
              >
                <img
                  src={ImagesCross}
                  style={{ width: 20, objectFit: "contain" }}
                />
              </div>
            </div>
            <p className="text-grey" style={{ fontWeight: 400 }}>
              {ADD_CARDS_STEPS[`STEP_${activeStep}`]["description"]}
            </p>
            {activeStep === 1 && (
              <Fragment>
                <div className="d-flex  mt-3">
                  <div className="form-check">
                    <img src={PhysicalCard} className="mx-3" />
                    <Controller
                      name="cardType"
                      control={controlAddCardForm}
                      render={({ field }) => (
                        <input
                          {...field}
                          id={CARD_TYPE.PHYSICAL}
                          className="form-check-input ml-2"
                          type="radio"
                          value={CARD_TYPE.PHYSICAL}
                          checked={field.value === CARD_TYPE.PHYSICAL}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                            setCardType(e.target.value);
                          }}
                          style={{ width: 25, height: 25 }}
                        />
                      )}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={CARD_TYPE.PHYSICAL}
                      style={{ fontWeight: 400 }}
                    >
                      {t("teams.physical_card")}
                    </label>
                    <p className="text-danger">
                      {errorsAddCardForm?.cardType?.message}
                    </p>
                  </div>
                </div>

                <div className="d-flex  mt-4">
                  <div className="form-check">
                    <img src={VirtualCard} className="mx-3" />
                    <Controller
                      name="cardType"
                      control={controlAddCardForm}
                      render={({ field }) => (
                        <input
                          {...field}
                          className="form-check-input ml-2"
                          id={CARD_TYPE.VIRTUAL}
                          type="radio"
                          value={CARD_TYPE.VIRTUAL}
                          checked={field.value === CARD_TYPE.VIRTUAL}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                            setCardType(e.target.value);
                          }}
                          style={{ width: 25, height: 25 }}
                        />
                      )}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={CARD_TYPE.VIRTUAL}
                      style={{ fontWeight: 400 }}
                    >
                      {t("teams.virtual_card")}
                    </label>
                    <p className="text-danger">
                      {errorsAddCardForm?.cardType?.message}
                    </p>
                  </div>
                </div>
              </Fragment>
            )}
            {activeStep === 2 && (
              <Fragment>
                <div className="form-outline mt-4 mb-3">
                  <label className="form-labels" htmlFor="searchName">
                    {t("cards.employee_name")} <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="employeeId"
                    control={controlAddCardForm}
                    render={({ field }) => (
                      <CustomDropdown
                        id="employeeId"
                        options={employees}
                        selectedOption={employees?.find(
                          (emp) => emp.value === field.value
                        )}
                        onChange={(selectedOption) => {
                          field.onChange(selectedOption?.value);
                          setAddCardValue(
                            "firstName",
                            selectedOption?.firstName || ""
                          );
                          setAddCardValue(
                            "lastName",
                            selectedOption?.lastName || ""
                          );
                        }}
                        onKeyUp={(e, selectedOption) => {
                          if (e.key === "Enter") {
                            field.onChange(selectedOption?.value);
                          }
                        }}
                        placeholder="Select Employee Name"
                      />
                    )}
                  />
                  <p className="text-danger">
                    {errorsAddCardForm?.employeeId?.message}
                  </p>
                </div>

                <div className="form-outline mt-4 mb-3">
                  <label className="form-labels" htmlFor="cardName">
                    {t("cards.card_name")} <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="cardName"
                    control={controlAddCardForm}
                    render={({ field }) => (
                      <input
                        {...field}
                        type="text"
                        id="cardName"
                        placeholder={t("cards.card_name")}
                        className="form-control form-control-lg me-2"
                      />
                    )}
                  />
                  <p className="text-danger">
                    {errorsAddCardForm?.cardName?.message}{" "}
                  </p>
                </div>
                <div className="form-outline mt-4 mb-3">
                  <label className="form-labels" htmlFor="limit">
                    {t("cards.card_balance_limit")} <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="limit"
                    control={controlAddCardForm}
                    render={({ field }) => (
                      <CurrencyInput
                        // {...field}
                        type="text"
                        min={0}
                        maxLength={6}
                        value={field.value}
                        id="limit"
                        placeholder={t("cards.enter_amount")}
                        className="form-control form-control-lg me-2"
                        decimalsLimit={2}
                        decimalScale={2}
                        // value={field.value || ""}
                        disablenegative={"true"}
                        onKeyDown={(e) => {
                          if (e.key === "-") {
                            e.preventDefault();
                          }
                        }}
                        onValueChange={(value) => {
                          const numericValue =
                            parseFloat(value?.replace(/,/g, "")) || 0;
                          if (numericValue < MAX_VALUE) {
                            field.onChange(
                              value === ""
                                ? ""
                                : value?.startsWith(".")
                                  ? `0${value}`
                                  : value
                            );
                          }
                        }}
                      />
                    )}
                  />
                  <p className="text-danger">
                    {errorsAddCardForm?.limit?.message}{" "}
                  </p>
                </div>
                <div className="form-outline mt-4 mb-3">
                  <div>
                    <label className="form-labels" htmlFor="searchName">
                      {t("cards.card_spending_limit")} 
                    </label>
                    <img src={Icon} className="mx-2" />
                  </div>
                  <div className="d-flex gap-2">
                    <div className="col-6">
                      <Controller
                        name="amount"
                        control={controlAddCardForm}
                        render={({ field }) => (
                          <CurrencyInput
                            // {...field}
                            type="text"
                            min={0}
                            maxLength={6}
                            id="amount"
                            onBlur={field.onBlur}
                            placeholder={t("cards.amount")}
                            className="form-control form-control-lg "
                            decimalsLimit={2}
                            decimalScale={2}
                            value={field.value || ""}
                            disablenegative={"true"}
                            onKeyDown={(e) => {
                              if (e.key === "-") {
                                e.preventDefault();
                              }
                            }}
                            onValueChange={(value) => {
                              const numericValue =
                                parseFloat(value?.replace(/,/g, "")) || 0;
                              if (numericValue < MAX_VALUE) {
                                field.onChange(
                                  value === ""
                                    ? ""
                                    : value?.startsWith(".")
                                      ? `0${value}`
                                      : value
                                );
                              }
                            }}
                          />
                        )}
                      />
                      <p className="text-danger">
                        {errorsAddCardForm?.amount?.message}
                      </p>
                    </div>
                    <div className="col-6 dropdown-frequency">
                      <Controller
                        name="frequency"
                        control={controlAddCardForm}
                        render={({ field }) => (
                          <CustomDropdown
                            id="frequency"
                            options={frequency}
                            placeholder={t("cards.frequency")}
                            onKeyUp={(e, selectedOption) => {
                              if (e.key === "Enter") {
                                field.onChange(selectedOption?.value);
                              }
                            }}
                            onChange={(selectedOption) =>
                              field.onChange(selectedOption.value)
                            }
                            value={field.value}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <p className="text-danger">
                    {errorsAddCardForm?.frequency?.message}
                  </p>
                </div>

                <div className="form-outline mt-4 mb-3">
                  <label className="form-labels" htmlFor="searchName">
                    {t("cards.transaction_amount_limit")} 
                  </label>
                  <Controller
                    name="transactionAmountLimit"
                    control={controlAddCardForm}
                    render={({ field }) => (
                      <CurrencyInput
                        // {...field}
                        type="text"
                        min={0}
                        maxLength={6}
                        id="transactionAmountLimit"
                        placeholder={t("cards.amount")}
                        className="form-control form-control-lg me-2"
                        decimalsLimit={2}
                        decimalScale={2}
                        value={field.value || ""}
                        disablenegative={"true"}
                        onKeyDown={(e) => {
                          if (e.key === "-") {
                            e.preventDefault();
                          }
                        }}
                        onValueChange={(value) => {
                          const numericValue =
                            parseFloat(value?.replace(/,/g, "")) || 0;
                          if (numericValue < MAX_VALUE) {
                            field.onChange(
                              value === ""
                                ? ""
                                : value?.startsWith(".")
                                  ? `0${value}`
                                  : value
                            );
                          }
                        }}
                      />
                    )}
                  />
                  <p className="text-danger">
                    {errorsAddCardForm?.transactionAmountLimit?.message}{" "}
                  </p>
                </div>
              </Fragment>
            )}
            {activeStep === 3 && (
              <Fragment>
                <div className="form-outline ">
                  <label className="form-labels" htmlFor="searchName">
                    {t("cards.first_name")} <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="firstName"
                    control={controlAddCardForm}
                    render={({ field }) => (
                      <input
                        {...field}
                        type="text"
                        id="firstName"
                        placeholder={t("cards.first_name")}
                        className="form-control form-control-lg me-2"
                      />
                    )}
                  />
                  <p className="text-danger">
                    {errorsAddCardForm?.firstName?.message}{" "}
                  </p>
                </div>
                <div className="form-outline ">
                  <label className="form-labels" htmlFor="searchName">
                    {t("cards.last_name")} <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="lastName"
                    control={controlAddCardForm}
                    render={({ field }) => (
                      <input
                        {...field}
                        type="text"
                        id="lastName"
                        placeholder={t("cards.last_name")}
                        className="form-control form-control-lg me-2"
                      />
                    )}
                  />
                  <p className="text-danger">
                    {errorsAddCardForm?.lastName?.message}{" "}
                  </p>
                </div>
                <div className="form-outline ">
                  <label className="form-labels" htmlFor="searchName">
                    {t("cards.phone_number")} <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="phoneNumber"
                    control={controlAddCardForm}
                    render={({ field }) => (
                      <input
                        {...field}
                        type="text"
                        id="phoneNumber"
                        placeholder={t("cards.phone_number")}
                        className="form-control form-control-lg me-2"
                        onInput={(e) => {
                          const value = e.target.value;
                          if (!/^\d*$/.test(value)) {
                            e.target.value = value.slice(0, -1);
                          }
                        }}
                      />
                    )}
                  />
                  <p className="text-danger">
                    {errorsAddCardForm?.phoneNumber?.message}{" "}
                  </p>
                </div>

                <div className="form-outline ">
                  <label className="form-labels" htmlFor="searchName">
                    {t("cards.shipments_address")} <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="addressLine1"
                    control={controlAddCardForm}
                    render={({ field }) => (
                      <input
                        {...field}
                        type="text"
                        id="addressLine1"
                        placeholder={t("cards.address_line_1")}
                        className="form-control form-control-lg me-2"
                      />
                    )}
                  />
                  <p className="text-danger">
                    {errorsAddCardForm?.addressLine1?.message}{" "}
                  </p>
                  <Controller
                    name="addressLine2"
                    control={controlAddCardForm}
                    render={({ field }) => (
                      <input
                        {...field}
                        type="text"
                        id="addressLine2"
                        placeholder={t("cards.address_line_2")}
                        className="form-control form-control-lg me-2"
                      />
                    )}
                  />
                  <p className="text-danger">
                    {errorsAddCardForm?.addressLine2?.message}{" "}
                  </p>
                </div>

                <div className="form-outline ">
                  <label className="form-labels" htmlFor="searchName">
                    {t("cards.city")} <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="city"
                    control={controlAddCardForm}
                    render={({ field }) => (
                      <input
                        {...field}
                        type="text"
                        id="city"
                        placeholder={t("cards.city")}
                        className="form-control form-control-lg me-2"
                      />
                    )}
                  />
                  <p className="text-danger">
                    {errorsAddCardForm?.city?.message}{" "}
                  </p>
                </div>

                <div className="form-outline ">
                  <label className="form-labels" htmlFor="searchName">
                    {t("cards.postal_code")} <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="postalCode"
                    control={controlAddCardForm}
                    render={({ field }) => (
                      <input
                        {...field}
                        type="number"
                        min={0}
                        id="postalCode"
                        placeholder={t("cards.postal_code")}
                        className="form-control form-control-lg me-2"
                      />
                    )}
                  />
                  <p className="text-danger">
                    {errorsAddCardForm?.postalCode?.message}{" "}
                  </p>
                </div>
              </Fragment>
            )}
            {activeStep === 4 && (
              <Fragment>
                <div>
                  <div className="form-outline mt-4 mb-3">
                    <label className="form-labels" htmlFor="selectCategory">
                      {t("cards.category_restriction")} <span className="text-danger">*</span>
                    </label>
                    <Controller
                      name="categoryRestriction"
                      control={controlAddCardForm}
                      render={({ field }) => (
                        <CustomDropdown
                          id="selectCategory"
                          options={selectCategory}
                          placeholder="Select an option"
                          onKeyUp={(e, selectedOption) => {
                            if (e.key === "Enter") {
                              field.onChange(selectedOption.value);
                            
                            }
                          }}
                          onChange={(selectedOption) => {
                            handleSelectChange(selectedOption);
                            field.onChange(selectedOption.value);
                          }}
                          value={selectCategory.find(
                            (option) => option.value === field.value
                          )}
                        />
                      )}
                    />
                    <p className="text-danger">
                      {errorsAddCardForm?.categoryRestriction?.message}{" "}
                    </p>
                  </div>

                  {["allow", "block"].includes(selectedOption) && (
                    <>
                      <div className="d-flex justify-content-end ">
                        {categories.length === 0 && (
                          <SecondaryButton
                              type={'button'}
                              onClick={handleAddCategory}
                              buttonText={t("cards.add_category")}
                              isEnabled={true}
                              id={'addCategory'}
                              style={{ marginBlock: '8px', width: '400px', textAlign: 'center' }}
                              isHighlighted={true}
                            />
                        )}
                      </div>

                      {categories.map((category, index) => (
                        <div
                          key={index}
                          className="d-flex justify-content-between align-items-center form-outline mt-2"
                        >
                        <div
                        className="form-control-lg d-flex justify-content-between align-items-center w-100"
                        style={{
                          backgroundColor: "#f8f9fa",
                          padding: "10px",
                          borderRadius: "8px",
                          }}
                          >
                          {category.id ? (
                              <span>{category.value}</span>
                          ) : (
                            <CustomDropdown
                              id={`category-${index}`}
                              options={categoriesList}
                              onKeyUp={(e, selectedOption) => {
                                if (e.key === "Enter") {
                                  handleCategoryChange(index, selectedOption);
                                }
                              }}
                              selectedOption={categoriesList.find(
                                (option) => option.value === category.value
                              )}
                              placeholder="Select an option"
                              onChange={(selectedOption) => {
                                handleCategoryChange(index, selectedOption);
                              }}
                              value={categoriesList.find(
                                (option) => option.value === category.value
                              )}
                            />
                          )}
                            <button 
                            type="button"
                            className="btn btn-link text-danger"
                            onClick={() => handleDeleteCategory(index)}
                          >
                            <img src={Delete} />
                          </button>
                        </div>
                        </div>
                      ))}
                      <div className="d-flex justify-content-end">
                        {categories.length > 0 && (
                          <SecondaryButton
                            type={'button'}
                            onClick={handleAddCategory}
                            buttonText={t("cards.add_another")}
                            isEnabled={true}
                            id={'addAnotherCategory'}
                            style={{ marginBlock: '8px', width: '400px', textAlign: 'center' }}
                            isHighlighted={true}
                          />
                        )}
                      </div>
                    </>
                  )}
                </div>
              </Fragment>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Stack direction={'row'} spacing={1}>
              <SecondaryButton
                type={'button'}
                onClick={handleClose1}
                buttonText={t("close")}
                isEnabled={true}
                id={'closeAddCard'}
              />
              {activeStep > 1 && (
                <SecondaryButton
                type={'button'}
                onClick={handlePrevious}
                buttonText={t("previous")}
                isEnabled={true}
                id={'addCardPrev'}
                isHighlighted={true}
              />
              )}
              <PrimaryButton 
                type={'submit'}
                buttonText={activeStep < 4 ? t("teams.next") : t("cards.add_card")}
                isEnabled={true}
                id={'next'}
              />
            </Stack>
          </Modal.Footer>
        </form>
      </Modal>

      <Modal
        aria-labelledby="contained-modal-title-center"
        centered
        show={showVerifyModal}
        onHide={handleCloseVerifyModal}
      >
        <form
          className="form"
          style={{ padding: 24 }}
          onSubmit={handleOtpSubmit(handleAddCard)}
        >
          <div className="d-flex align-items-start justify-content-between">
            <img src={Logo2} className="logoso mt-1 mb-4" alt="Sample image" />
            <div onClick={handleCloseVerifyModal} id="close_modal">
              <img
                src={ImagesCross}
                className="small-addjust"
                style={{ width: 20, objectFit: "contain", marginLeft: 15 }}
              />
            </div>
          </div>
          <h1 className="text-blue-login mb-2">
            {t("login.verify_your_mobile_number")}
          </h1>
          <div className="divider d-flex align-items-center my-2">
            <p className="mb-0 text-subtitle2" style={{ color: "#8B9299" }}>
              {t("login.verify_your_mobile_number_desc")}
            </p>
          </div>

          <div className="otp form-outline d-flex justify-content-center mt-4 mb-3">
            <Controller
              name="otp"
              control={controlVerifyModal}
              defaultValue={0}
              render={({ field: { onChange, value, ref } }) => (
                <OtpInput
                  inputStyle="inputStyle"
                  ref={ref}
                  value={value}
                  onChange={onChange}
                  numInputs={6}
                  shouldAutoFocus={true}
                  renderInput={(props) => (
                    <input
                      {...props}
                      id="input_otp"
                      onInput={(e) => {
                        const value = e.target.value;
                        if (!/^\d*$/.test(value)) {
                          e.target.value = value.slice(0, -1);
                        }
                      }}
                    />
                  )}
                />
              )}
            />
          </div>
          <p className="text-danger">{errorsOtpForm?.otp?.message}</p>
          <div className="d-flex justify-content-end">
            {timer === 0 ? (
              <a href="javascript:void(0)" id="resend_otp">
                Resend OTP
              </a>
            ) : (
              <p>
                Resend OTP in <span className="text-blue">{timer} seconds</span>{" "}
              </p>
            )}
          </div>
          <div className="text-center text-lg-start mt-4 w-100">
            <PrimaryButton 
              type={'submit'}
              buttonText={t("cards.continue_to_issue_card")}
              isEnabled={!isLoading}
              isExportBtn={true}
              id={'continue'}
              style={{ position: "relative", zIndex: "1" }}
              isLoading={isLoading}
            />
          </div>
        </form>
      </Modal>

      <ConfirmationDialog
        show={showConfirmationModal}
        onConfirm={() => {
          navigate('/settings');
        }}
        onClose={() => setShowConfirmationModal(false)}
        message="Please update your subscription to add new card"
        confirmTitle="Update"
      />
    </>
  );
}

export default Cards;
