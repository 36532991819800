import React, { useEffect, useState } from 'react';
import './Login.scss';
import Logo2 from '../../assets/images/polo.png';
import LoginLeftImage from '../../assets/images/login_left_image.png';
import login_bottom_image from '../../assets/images/login_bottom_image.png';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { verifyAndSendOtp } from '../Login/loginSlice';
import { useDispatch, useSelector } from 'react-redux';
import LoadingComponent from '../../components/Loader/LoadingComponent';
import Loader from '../../components/Loader/Loader';
import cookies from 'js-cookie';

function Login() {
    const currentLanguageCode = cookies.get('i18next') || 'en';
    const [passwordType, setPasswordType] = useState("password");
    const [rememberme, setRememberme] = useState(false);
    const [isLoadingPage, setIsLoadingPage] = useState(true);
    const { isLoading } = useSelector(state => state.loginSliceReducer);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const date = new Date().getFullYear();
    const schema = yup.object().shape({
        email: yup
            .string()
            .required('Email is required')
            .matches(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/, 'Please enter a valid email address'),
        password: yup
            .string()
            .required('Password is required')
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%?&^/()"'{}-])[A-Za-z\d@#$!%?&^/()"'{}-]{8,}$/,
                'Password must contain at least one uppercase letter, one lowercase letter, one number, one special character, and be at least 8 characters long.'
            )
    });
    const { control, register, handleSubmit, setValue, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        const token = localStorage.getItem('token');
        const hasAccess = localStorage.getItem('hasAccess');

        if (token) {
            if (hasAccess === 'true')
                navigate('/companyverification');
            else
                navigate('/expenses');
        } else {
            setIsLoadingPage(false);
        }
    }, []);

    useEffect(() => {
        const isRemebered = localStorage.getItem('isRemebered');
        const token = localStorage.getItem('token');
        if (isRemebered && token) {
            navigate('/companyverification');
        } else {
            const savedEmail = localStorage.getItem('savedEmail');
            if (savedEmail) {
                setValue('email', savedEmail);
                setRememberme(true);
            }
        }
    }, [setValue]);

    const togglePassword = () => {
        setPasswordType((prevType) => (prevType === "password" ? "text" : "password"));
    };

    const handleEmailChange = (e) => {
        const trimmedValue = e.target.value.trim();
        setValue('email', trimmedValue, { shouldValidate: true });
    };

    let navigate = useNavigate();
    const routeSignUpChange = () => {
        let path = `/signUp`;
        navigate(path);
    };
    const routeInvitationCode = () => {
        let path = `/invitationCode`;
        navigate(path);
    };

    const routeForgotPasswordChange = () => {
        let path = `/forgotPassword`;
        navigate(path);
    };

    const onSubmit = (data) => {
        if (rememberme) {
            localStorage.setItem('savedEmail', data.email);
        } else {
            localStorage.removeItem('savedEmail');
        }
        localStorage.setItem('isRemebered', rememberme);

        dispatch(verifyAndSendOtp(data))
            .unwrap()
            .then((res) => {
                toast.success(res?.message);
                reset();
                navigate('/otpVerification', { state: { LoginData: data, rememberme } });
            }).catch((error) => {
                toast.error(error?.message);
            });
    };

    if (isLoadingPage) {
        return <Loader />;
    }

    return (
        <section>
            <div className="container-fluid" style={{ backgroundColor: '#24473D', position: 'relative' }}>
                <div className="row  login-forms" >
                    <div className="col-md-7 col-lg-5 col-xl-6  my-2 soop" style={{position: 'relative'}}>
                        {isLoading && <LoadingComponent />}
                        <div className='d-flex flex-column justify-content-between align-items-center h-100'>
                            <div></div>
                            <form className='form' onSubmit={handleSubmit(onSubmit)}>
                                <div>
                                    <img src={Logo2} className="logo mt-1 mb-4" alt="Sample image" />
                                </div>
                                <h1 className='text-blue-login my-2'>
                                    {t('login.login')}
                                </h1>
                                <div className="divider d-flex align-items-center my-2">
                                    <p className="mb-0 text-subtitle">
                                        {t('login.login_desc')}
                                    </p>
                                </div>
                                <div className="form-outline mt-4 mb-3">
                                    <label className="form-label" htmlFor="email">{t('email_address')}</label>
                                    <div style={{ maxWidth: '320px' }} className="input-group">
                                        <input
                                            type="text"
                                            id="email"
                                            className={`form-control form-control-lg ${errors?.email ? 'is-invalid' : ''}`}
                                            placeholder={`${t('company.enter_email_address')}`}
                                            {...register('email', {
                                                required: `${t('company.enter_email_address')}`,
                                                pattern: {
                                                    value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/,
                                                    message: 'Invalid email address'
                                                },
                                                onChange: handleEmailChange
                                            })}
                                        />
                                        {errors?.email && (
                                            <div className="invalid-feedback">{errors?.email?.message}</div>
                                        )}
                                    </div>
                                </div>

                                <div className="form-outline mb-4 position-relative">
                                    <label className="form-label" htmlFor="password">{t('teams.password')}</label>
                                    <Controller
                                        name="password"
                                        control={control}
                                        render={({ field }) => (
                                            <input
                                                id='password'
                                                type={passwordType}
                                                value={field.value}
                                                onChange={(e) => {
                                                    field.onChange(e);
                                                }}
                                                className={`form-control form-control-lg ${errors.password ? 'is-invalid' : ''}`}
                                                placeholder={`${t('login.enter_password')}`}
                                            />
                                        )}
                                    />
                                    {errors?.password && (
                                        <p className="invalid-feedback" style={{ width: 305 }}>{errors?.password?.message}</p>
                                    )}
                                    {!errors.password && <div className={`${currentLanguageCode === 'en' ? 'eye-icon' : 'eye-icon-arabic'}`} onClick={togglePassword} id='show_password'>
                                        {passwordType === "password" ? <FaEyeSlash size={25} color='#24473D' /> : <FaEye color='#24473D' size={25} />}
                                    </div>}
                                </div>

                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="form-check d-flex justify-content-between align-items-center">
                                        <input className="form-check-input" type="checkbox" id="rememberme" checked={rememberme} onChange={e => setRememberme(e?.target?.checked)} />
                                        <label className="ms-2 form-check-label" htmlFor="rememberme">
                                            {t('login.remember_me')}
                                        </label>
                                    </div>

                                    <a onClick={routeForgotPasswordChange} className="text-blue forgotPassword" id='forgot_password'>{t('login.forgot_passcode')}</a>
                                </div>

                                <div className="text-center text-lg-start mt-4 w-100">
                                    <button type="submit" className="btn btn-primary btn-lg btn-block w-100" id='login'>{t('login.login')}</button>
                                </div>
                            </form>
                            <div className=" d-flex align-items-center justify-content-center" style={{ marginTop: -50 }}>
                                <div className='text-center'>
                                    <p className="text-center mb-2">
                                        {t('login.don’t_have_an_account?')}
                                    </p>
                                    <a href='#!' onClick={routeSignUpChange} className='text-blue forgotPassword' id='create_company_account'> {t('login.create_account_for_company')}</a>
                                    <p className='d-inline mx-2 bold'>{t('or')}</p>
                                    <a style={{ cursor: 'pointer' }} onClick={routeInvitationCode} className='text-blue forgotPassword' id='join_company'> {t('login.join_company')}</a>
                                </div>
                            </div>

                            <div className='copyright'>
                                <p className='copyright-text'>{`© Xpendless ${date}`}</p>
                            </div>

                        </div>
                    </div>
                    <div className="col-md-8 col-lg-7 col-xl-6  d-flex justify-content-center align-items-center imagecontainer">
                        <img src={LoginLeftImage} className='loginImage' />
                        <img className='login_bottom_image' src={login_bottom_image} />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Login;
