/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import ImagesCross from "../../assets/images/cross.png";
import EditIcon from "../../assets/images/editicon.png";
import TickGreenIcon from "../../assets/images/tick-circle.png";
import "./Teams.scss";
import PersonSpending from "../../components/Teams/PersonSpending";
import Modal from "react-bootstrap/Modal";
import CustomDropdown from "../../components/CustomDropdown/CustomDropdown";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getEmployeeDetailsById,
  resendInviteCode,
  updateEmployeeDetails,
} from "./Slice/departmentSlice";
import { disburseCash, returnCash } from "../PettyCash/pettyCashSlice";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { getAllReviewerPolicy } from "../Settings/taxSlice";
import { toast } from "react-toastify";
import profileEdit from "../../assets/images/profile_edit.png";
import {
  issueNewCard,
  getMccCategoryDetails,
  getAllCards,
} from "../Cards/slice/cardListSlice";
import {
  uploadDocument,
  getAllCountries,
  getAllDepartment,
  getDepartmentEmployee,
  getEmployeeTransactions,
} from "./teamSlice";
import LoadingComponent from "../../components/Loader/LoadingComponent";
import { decodeToken } from "react-jwt";
import cookies from "js-cookie";
import OtpInput from "react-otp-input";
import Logo2 from "../../assets/images/polo.png";
import moment from "moment";
import { Grid, Stack } from "@mui/material";
import { createEndDateWithTimezone, createStartDateWithTimezone, formatQAR, formatTime } from "../../utils/XpendlessUtils";
import CurrencyInput from "react-currency-input-field";
import BackArrow from "../../assets/images/backarrow1.png";
import { MAX_VALUE } from "../../utils/constants";
import { CARD_TYPE } from "../../utils/constants";
import { Fragment } from "react";
import PhysicalCard from "../../assets/images/physical_card.svg";
import VirtualCard from "../../assets/images/virtual_card.svg";
import Icon from "../../assets/images/icon.svg";
import Delete from "../../assets/images/delete.svg";
import { activeBlocked, verifyCompanyStatus } from "../../utils/verifyCompanyStatus";
import { allowedUserRoles, verifyUserRole } from "../../utils/verifyUserRole";
import IconButton from "../../components/Buttons/IconButton";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../components/Buttons/SecondaryButton";
function TeamDetails() {
  const { t } = useTranslation();
  //   const urlParams = new URLSearchParams(window.location.pathname);
  const url = new URL(window.location.href);
  const pathSegments = url.pathname.split("/");
  const id = pathSegments[pathSegments.length - 1];

  //   console.log(urlParams);
  const languages = [
    {
      code: "en",
      name: "English",
      country_code: "gb",
    },
    {
      code: "ar",
      name: "العربية",
      dir: "rtl",
      country_code: "sa",
    },
  ];

  const userDetailsSchema = yup.object().shape({
    firstName: yup
      .string()
      .matches(/^[A-Za-z ]+$/, "Only alphabets are required")
      .min(2, `${t("login.min_name_length")}`)
      .max(30, `${t("login.max_name_length")}`)
      .required(t("login.first_name")),
    lastName: yup
      .string()
      .matches(/^[A-Za-z ]+$/, "Only alphabets are required")
      .min(2, `${t("login.min_name_length")}`)
      .max(30, `${t("login.max_name_length")}`)
      .required(t("login.last_name")),
    dob: yup.string().nullable().optional(),
    email: yup
      .string()
      .matches(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/,
        "Please enter valid Email Id"
      )
      .required("Email Id is required"),
    mobileNumber: yup.string().required("Mobile Number is required"),
    gender: yup.string().nullable().optional(),
    nationalId: yup
      .string()
      .nullable()
      .optional()
      .test(
        "is-valid-id",
        "National ID must be a valid number",
        (value) => !value || /^\d+$/.test(value)
      )
      .test(
        "is-valid-length",
        "National ID must be exactly 11 digits",
        (value) => !value || value.length === 11
      ),
    employeeHrId: yup.string().nullable().optional(),
    nationality: yup.string().nullable().optional(),
    monthlySpendingLimit: yup
      .number()
      .typeError("Amount Should be Number")
      .nullable()
      .optional(),
    singleSpendingLimit: yup
      .number()
      .typeError("Amount Should be Number")
      .nullable()
      .optional(),
    spendLimit: yup
      .number()
      .typeError("Amount Should be Number")
      .nullable()
      .optional(),
    isAccountant: yup.bool().nullable().optional(),
    isAdmin: yup.bool().nullable().optional(),
    isGlobalReviewer: yup.bool().nullable().optional(),
    reviewerPolicyId: yup.number().when("isGlobalReviewer", {
      is: true,
      then: () =>
        yup
          .number()
          .typeError("Reviewer Policy Id should be Number")
          .nullable()
          .optional(),
      otherwise: () => yup.mixed().nullable(),
    }),
    departmentId: yup.string().nullable().optional(),
  });

  const dispatch = useDispatch();
  const { state } = useLocation();
  const { employeeId } = useParams();

  const isDeleted = state?.isDeleted ?? false;
  const navigate = useNavigate();
  const fileInputRef = useRef();
  const [show, setShow] = useState(false);
  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const [preview, setPreview] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const { userDetails, isLoading } = useSelector(
    (state) => state?.departmentSlice
  );
  const { isLoading: isLoading2 } = useSelector(
    (state) => state.cardListReducer
  );
  const { reviewerPolicyData } = useSelector((state) => state?.taxSlice);
  const { countriesData, departmentData, departmentEmployees } = useSelector(
    (state) => state?.teamSlice
  );
  const [cardNumber, setCardNumber] = useState(0);
  const [employeeDepartment, setEmployeeDepartment] = useState("");
  const token = localStorage.getItem("token");
  const decodedtoken = decodeToken(token);
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const handleShowVerifyModal = () => setShowVerifyModal(true);
  const handleCloseVerifyModal = () => setShowVerifyModal(false);
  const [timer, setTimer] = useState(60);
  const [issueCardData, setIssueCardData] = useState("");
  const [schema, setSchema] = useState(userDetailsSchema);
  const [showDisburseModal, setShowDisburseModal] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const [selectedOption, setSelectedOption] = useState("");
  const [categories, setCategories] = useState([]);
  const employeeRef = useRef();
  const [cardType, setCardType] = useState(CARD_TYPE.PHYSICAL);
  const mccCategory = useSelector((state) => state?.cardListReducer);
  const handleShowDisburseModal = () => {
    resetDisburseForm();
    setShowDisburseModal(true);
  };
  const handleCloseDisburseModal = () => setShowDisburseModal(false);

  const [showReturnModal, setShowReturnModal] = useState(false);
  const handleShowReturnModal = () => {
    resetReturnForm();
    setShowReturnModal(true);
  };
  const handleCloseReturnModal = () => setShowReturnModal(false);

  const [showCardTrans, setShowCardTrans] = useState(false);
  const handleShowCardTrans = () => {
    handleResetForm();
    setShowCardTrans(true);
  };
  const handleCloseCardTrans = () => setShowCardTrans(false);

  const [employeeTrans, setEmployeeTrans] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [filterQuery, setFilterQuery] = useState(``);
  useEffect(() => {
    dispatch(getMccCategoryDetails());
  }, []);

  useEffect(() => {
    dispatch(getEmployeeTransactions(filterQuery))
      .unwrap()
      .then((res) => {
        setEmployeeTrans(res);
      })
      .catch((error) => {
        toast.error(error?.message);
      });
  }, [filterQuery]);

  useEffect(() => {
    setFilterQuery((prev) => {
      const urlParams = new URLSearchParams(prev);
      urlParams.set("page", pageNo);
      return `?${urlParams.toString()}`;
    });
  }, [pageNo]);

  const categoriesList = mccCategory?.mccCategory?.map((category) => ({
    id: category.id,
    label: `${category.category}`,
  }));

  const selectOptions = [
    {
      label: `${t("pettyCash.all")}`,
      value: "all",
    },
    {
      label: `${t("pettyCash.add")}`,
      value: "add",
    },
    {
      label: `${t("pettyCash.withdraw")}`,
      value: "withdraw",
    },
    {
      label: `${t("pettyCash.disburse")}`,
      value: "disburse",
    },
    {
      label: `${t("pettyCash.return")}`,
      value: "return",
    },
    {
      label: `${t("pettyCash.settlement")}`,
      value: "settlement",
    },
  ];

  const frequency = [
    {
      label: `DAILY`,
      value: "DAILY",
    },
    {
      label: `MONTHLY`,
      value: "MONTHLY",
    },
    {
      label: `YEARLY`,
      value: "YEARLY",
    },
    {
      label: `ALL TIME`,
      value: "ALL_TIME",
    },
  ];
  const selectCategory = [
    {
      label: `Allow Category`,
      value: "allow",
    },
    {
      label: `Block Category`,
      value: "block",
    },
    {
      label: `None`,
      value: "none",
    },
  ]



  const {
    control: controlDisburseForm,
    handleSubmit: handleDisburseSubmit,
    formState: { errors: errorsDisburseForm },
    reset: resetDisburseForm,
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        amount: yup
          .number()
          .min(1, `${t("pettyCash.amount_check")}`)
          .required(`${t("pettyCash.amount_required")}`)
          .typeError(`${t("pettyCash.type_error")}`),
        description: yup
          .string()
          .min(2, `${t("pettyCash.desc_min_length")}`)
          .max(255, `${t("pettyCash.desc_max_length")}`)
          .required(`${t("pettyCash.desc_required")}`),
      })
    ),
    defaultValues: {
      amount: "",
      description: "",
    },
  });

  const {
    control: controlReturnForm,
    handleSubmit: handleReturnSubmit,
    formState: { errors: errorsReturnForm },
    reset: resetReturnForm,
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        amount: yup
          .number()
          .min(1, `${t("pettyCash.amount_check")}`)
          .required(`${t("pettyCash.amount_required")}`)
          .typeError(`${t("pettyCash.type_error")}`),
        description: yup
          .string()
          .min(2, `${t("pettyCash.desc_min_length")}`)
          .max(255, `${t("pettyCash.desc_max_length")}`)
          .required(`${t("pettyCash.desc_required")}`),
      })
    ),
    defaultValues: {
      amount: "",
      description: "",
    },
  });

  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      isAccountant: false,
      isAdmin: false,
      isGlobalReviewer: false,
    },
  });

  const onClickDisburseModal = (data) => {
    const requestData = {
      amount: data.amount,
      description: data.description,
      recipientEmployeeId: userDetails.employeeId,
    };
    dispatch(disburseCash(requestData))
      .unwrap()
      .then((res) => {
        dispatch(
          getEmployeeDetailsById({
            employeeId: Number(employeeId),
            eventType: state?.eventType ?? "teams",
          })
        );
        toast.success(res?.message);
        handleCloseDisburseModal();
      })
      .catch((error) => {
        toast.error(error?.name);
      });
  };

  const onClickReturneModal = (data) => {
    const requestData = {
      amount: data.amount,
      description: data.description,
      employeeId: userDetails.employeeId,
    };
    dispatch(returnCash(requestData))
      .unwrap()
      .then((res) => {
        dispatch(
          getEmployeeDetailsById({
            employeeId: Number(employeeId),
            eventType: state?.eventType ?? "teams",
          })
        );
        toast.success(res?.message);
        handleCloseReturnModal();
      })
      .catch((error) => {
        toast.error(error?.name);
      });
  };

  const onFilterFormSubmit = (requestData) => {
    setFilterQuery((prev) => {
      const urlParams = new URLSearchParams(prev);
      if (requestData.filterTransactions) {
        if (requestData.filterTransactions !== "all") {
          urlParams.set("type", requestData.filterTransactions);
        } else {
          urlParams.delete("type");
        }
      }
      if (requestData.fromDate) {
        const fromDate = createStartDateWithTimezone(requestData.fromDate);
        urlParams.set("fromDate", fromDate);
      } else {
        urlParams.delete("fromDate");
      }
      if (requestData.toDate) {
        const toDate = createEndDateWithTimezone(requestData.toDate);
        urlParams.set("toDate", toDate);
      } else {
        urlParams.delete("toDate");
      }
      if (requestData.selectEmployee) {
        urlParams.set("performedBy", requestData.selectEmployee);
      } else {
        urlParams.delete("performedBy");
      }
      return `?${urlParams.toString()}`;
    });
    setPageNo(1);
  };

  const {
    control: controlFilterForm,
    handleSubmit: handleFilterSubmit,
    formState: { errors: errorsFilterForm },
    reset: resetFilterForm,
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        filterTransactions: yup.string().optional(),
        fromDate: yup.string().optional(),
        toDate: yup
          .string()
          .optional()
          .test(
            "is-valid-end-date",
            "End date should not be before start date",
            function (value) {
              const startDate = this.resolve(yup.ref("fromDate"));
              return !startDate || !value || value >= startDate;
            }
          ),
        selectEmployee: yup.string().optional(),
      })
    ),
  });



  const handleNext = (isSubmit = false) => {
    handleAddCardSubmit((data) => {
      setIssueCardData((prevData) => ({
        ...prevData,
        ...data,
      }));


      if (activeStep === 1 && cardType === CARD_TYPE.VIRTUAL) {
        setActiveStep(2);
      } else if (activeStep === 2 && cardType === CARD_TYPE.VIRTUAL) {
        setActiveStep(4);
      } else if (activeStep < 4) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }

      if (isSubmit && activeStep === 4) {
        onClickVerifyModal(data);
      }
    })();
  };


  const handlePrevious = () => {
    if (cardType === "virtual") {
      if (activeStep === 4) {
        setActiveStep(2);
      } else if (activeStep === 2) {
        setActiveStep(1);
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption.value);
  };

  const handleAddCategory = () => {
    setCategories([...categories, { id: "", value: "" }]);
  };

  const handleCategoryChange = (index, selectedOption) => {
    const categoryId = selectedOption?.id;
    const category = mccCategory?.mccCategory?.find(
      (cat) => cat.id === categoryId
    );


    if (category) {
      const updatedCategories = [...categories];
      updatedCategories[index] = {
        id: category.id,
        value: category.category,
      };
      setCategories(updatedCategories);
    }
  };

  const handleDeleteCategory = (index) => {
    const updatedCategories = categories.filter((_, i) => i !== index);
    setCategories(updatedCategories);
  };

  const ADD_CARDS_STEPS = {
    STEP_1: {
      label: t("teams.select_card_type"),
      description: t("teams.add_card_desc"),
    },
    STEP_2: {
      label: t("expenses.details"),
      description: t("teams.please_fill_info"),
    },
    STEP_3: {
      label: t("cards.shipping_address"),
      description: t("cards.shipment_address"),
    },
    STEP_4: {
      label: t("cards.restrictions"),
      description: t("cards.block_restrict"),
    },
  };

  const ADD_CARD_STEPS_VALIDATION = {
    STEP_1: {
      cardType: yup.string().required("Card type is required."),
    },
    STEP_2: {
      cardName: yup.string().required("Card name is required."),
      limit: yup.number().required("Card balance limit is required.").moreThan(0, "Card balance limit must be greater than zero."),
      amount: yup
        .number()
        .nullable()
        .test(
          'amount-required-if-frequency',
          'Amount is required.',
          function (value) {
            const { frequency } = this.parent;
            return frequency ? value != null : true;
          }
        ).moreThan(0, "Amount must be greater than zero."),
      frequency: yup
        .string()
        .nullable()
        .test(
          'frequency-required-if-amount',
          'Frequency is required.',
          function (value) {
            const { amount } = this.parent;
            return amount ? value != null : true;
          }
        ),
      transactionAmountLimit: yup.number().optional("Transaction amount limit is required.").moreThan(0, "Transaction amount must be greater than zero."),
    },
    STEP_3: {
      firstName: yup.string().required("First name is required."),
      lastName: yup.string().required("Last name is required."),
      phoneNumber: yup
        .string()
        .required("Phone number is required.")
        .min(8, "Mobile number must be at least 8 digits")
        .max(16, "Mobile number should not exceed 16 digits")
        .matches(/^\d+$/, "Mobile number must contain only digits"),
      addressLine1: yup.string().required("Address line 1 is required."),
      addressLine2: yup.string().optional("Address line 2 is required."),
      postalCode: yup.number().required("Postal code is required."),
      city: yup.string().required("City is required."),
    },
    STEP_4: {
      categoryRestriction: yup
        .string()
        .required("Category restriction is required."),
    },
  };

  const policyId = watch("reviewerPolicyId");

  const isGlobalReviewerSelected = watch("isGlobalReviewer");

  const currentBalance = useMemo(() => {
    return userDetails?.cards?.length > 0
      ? userDetails?.cards?.reduce((acc, card) => acc + card.currentBalance, 0)
      : 0;
  }, [userDetails]);

  const selectedPolicy = useMemo(() => {
    const id = watch("reviewerPolicyId");
    const selected = reviewerPolicyData.find(
      (el) => Number(el?.reviewerPolicyId) === Number(id)
    );
    return selected;
  }, [policyId, reviewerPolicyData]);

  const onPreviousClick = () => {
    setPageNo((prev) => prev - 1);
  };

  const onNextClick = () => {
    setPageNo((prev) => prev + 1);
  };

  useEffect(() => {
    dispatch(getDepartmentEmployee({ departmentId: null, para: null }));
  }, []);

  useEffect(() => {
    if (employeeId) {
      setFilterQuery(`?employeeId=${employeeId}&limit=4`);
      setSchema(userDetailsSchema);
      dispatch(
        getEmployeeDetailsById({
          employeeId: Number(employeeId),
          eventType: state?.eventType ?? "teams",
        })
      );
      dispatch(getAllReviewerPolicy());
      dispatch(getAllCountries());
      if (verifyCompanyStatus(activeBlocked) && verifyUserRole(allowedUserRoles.adminAccountantReviewerAndCardHolder)) {
        dispatch(getAllDepartment());
      }
      dispatch(getEmployeeTransactions(`?employeeId=${employeeId}&limit=4`))
        .unwrap()
        .then((res) => {
          setEmployeeTrans(res);
        })
        .catch((error) => {
          toast.error(error?.message);
        });
    }
  }, [employeeId]);

  useEffect(() => {
    document.body.dir = currentLanguage.dir || "ltr";
    document.title = t("app_title");
  }, [currentLanguage, t]);

  useEffect(() => {
    if (userDetails) {
      setValue("employeeHrId", userDetails?.employeeHrId);
      setValue("firstName", userDetails?.firstName);
      setValue("lastName", userDetails?.lastName);
      setValue("dob", userDetails?.dob);
      setValue("email", userDetails?.email);
      setValue("mobileNumber", userDetails?.mobileNumber);
      setValue("gender", userDetails?.gender);
      setValue("nationalId", userDetails?.nationalId);
      setValue("nationality", userDetails?.nationality);
      if (userDetails?.hasOwnProperty("monthlySpendingLimit"))
        setValue(
          "monthlySpendingLimit",
          userDetails?.monthlySpendingLimit ?? 0
        );
      if (userDetails?.hasOwnProperty("singleSpendingLimit"))
        setValue("singleSpendingLimit", userDetails?.singleSpendingLimit ?? 0);
      if (userDetails?.hasOwnProperty("spendLimit"))
        setValue("spendLimit", userDetails?.spendLimit ?? 0);
      setValue("isAccountant", userDetails?.isAccountant);
      setValue("isAdmin", userDetails?.isAdmin);
      setValue("isGlobalReviewer", userDetails?.isGlobalReviewer);
      setValue("eKYCRequired", userDetails?.eKYCRequired);
      setValue("reviewerPolicyId", userDetails?.reviewerPolicyId);
      setCardNumber(userDetails?.cards.length);
      setValue("departmentId", userDetails?.departmentId);

      let keys = [];
      if (!userDetails?.hasOwnProperty("monthlySpendingLimit")) {
        keys.push("monthlySpendingLimit");
      }
      if (!userDetails?.hasOwnProperty("singleSpendingLimit")) {
        keys.push("singleSpendingLimit");
      }
      if (!userDetails?.hasOwnProperty("spendLimit")) {
        keys.push("spendLimit");
      }
      if (keys?.length > 0) {
        const modifiedSchema = schema.omit(keys);
        setSchema(modifiedSchema);
      }
    }
  }, [userDetails]);

  const updateDetails = async (details) => {
    console.log(JSON.stringify(details));
    if (!details?.isGlobalReviewer) {
      delete details?.reviewerPolicyId;
    }
    delete details?.email;
    if (preview) {
      const imageURl = await uploadProfileImage();
      details["profilePic"] = imageURl[0]?.data?.url;
    }

    console.log(details);

    const requestData = {
      eKYCRequired: details.eKYCRequired,
      firstName: details.firstName,
      lastName: details.lastName,
      mobileNumber: details.mobileNumber,
      email: userDetails?.email,
      ...(details.dob !== null && { dob: details.dob }),
      ...(details.gender !== null && { gender: details.gender }),
      ...(details.nationality !== null && { nationality: details.nationality }),
      ...(details.nationalId !== null && { nationalId: details.nationalId }),
      ...(details.employeeHrId !== null && { employeeHrId: details.employeeHrId }),
      ...(details.departmentId !== null && { departmentId: details.departmentId }),
      ...(details.spendLimit !== null && { spendLimit: details.spendLimit }),
      ...(details.monthlySpendingLimit !== null && {
        monthlySpendingLimit: details.monthlySpendingLimit,
      }),
      ...(details.singleSpendingLimit !== null && {
        singleSpendingLimit: details.singleSpendingLimit,
      }),
      ...(details.isAccountant !== null && {
        isAccountant: details.isAccountant,
      }),
      ...(details.isAdmin !== null && { isAdmin: details.isAdmin }),
      ...(details.isGlobalReviewer !== null && {
        isGlobalReviewer: details.isGlobalReviewer,
      }),
      ...(details.reviewerPolicyId !== null && {
        reviewerPolicyId: details.reviewerPolicyId,
      }),
    };

    dispatch(
      updateEmployeeDetails({ employeeId: Number(employeeId), ...requestData })
    )
      .unwrap()
      .then((res) => {
        console.log("res", res);
        toast.success("Details updated successfully");
        dispatch(
          getEmployeeDetailsById({
            employeeId: Number(employeeId),
            eventType: state?.eventType ?? "teams",
          })
        );
      })
      .catch((err) => {
        toast.error(err?.message || "Unable to update employee details.");
      });
  };
  const sendInviteAgain = async (emailId) => {
    dispatch(
      resendInviteCode({ employeeId: Number(employeeId), email: emailId })
    )
      .then((res) => {
        console.log("res", res);
        toast.success(res?.payload?.message);
        dispatch(
          getEmployeeDetailsById({
            employeeId: Number(employeeId),
            eventType: state?.eventType ?? "teams",
          })
        );
      })
      .catch(() => {
        toast.error("Failed to sent invitation code.");
      });
  };

  const uploadProfileImage = async () => {
    const formData = new FormData();
    formData.append("file", selectedFile);
    const response = await dispatch(uploadDocument(formData)).unwrap();
    return response;
  };

  const {
    control: controlAddCardForm,
    handleSubmit: handleAddCardSubmit,
    formState: { errors: errorsAddCardForm },
    setValue: addCardSetValue,
    watch: addCardWatch,
    reset: resetAddCardForm,
  } = useForm({
    resolver: yupResolver(
      yup.object().shape(ADD_CARD_STEPS_VALIDATION[`STEP_${activeStep}`])
    ),
  });

  const {
    control: controlVerifyModal,
    handleSubmit: handleOtpSubmit,
    formState: { errors: errorsOtpForm },
    reset: otpFormReset,
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        otp: yup
          .string()
          .required("Otp is required")
          .matches(/^123456$/, "OTP must be exactly 6 digits"),
      })
    ),
  });

  //   const handleAddCard = () => {
  //     issueCardData.limit = 0;
  //     issueCardData.status = "active";
  //     issueCardData.currentBalance = 0;
  //     issueCardData.isFreezed = false;
  //     issueCardData.employeeId = userDetails?.employeeId;
  //     issueCardData.expiryDate = "2024-10-06";
  //     issueCardData.eventType = state?.eventType ?? "teams";
  //     dispatch(issueNewCard(issueCardData))
  //       .unwrap()
  //       .then(() => {
  //         toast.success("Card Added Successfully!");
  //         setCardNumber((prev) => prev + 1);
  //         resetAddCardForm();
  //         handleClose();
  //         handleCloseVerifyModal();
  //         otpFormReset({ otp: "" });
  //       })
  //       .catch((error) => {
  //         toast.error(error.message || "Request Failed Please Try Again ");
  //       });
  //   };

  const routeChange = (id) => {
    let path = `/card/card-details/${id}`;
    console.log("routeChange", path);
    navigate(path);
  };

  const handleAddCard = () => {
    const payload = {
      cardType: issueCardData.cardType,
      employeeId: id,
      cardName: issueCardData.cardName,
      limit: issueCardData.limit,
      cardSpendingLimit: issueCardData.amount,
      frequency: issueCardData.frequency,
      transactionAmountLimit: issueCardData.transactionAmountLimit,
      isFreezed: false,
      currentBalance: 0,
      eventType: "cards",
      expiryDate: "2025-10-06",
      status: "active",
      categories: categories.map((cat) => {
        return {
          allow: issueCardData.categoryRestriction === "allow" ? true : false,
          categoryId: cat.id,
        };
      }),
    };
    if (issueCardData.cardType === CARD_TYPE.PHYSICAL) {
      payload['address'] = {
        firstName: issueCardData.firstName,
        lastName: issueCardData.lastName,
        phoneNumber: issueCardData.phoneNumber,
        addressLine1: issueCardData.addressLine1,
        addressLine2: issueCardData.addressLine2,
        city: issueCardData.city,
        postalCode: issueCardData.postalCode,
      }
    }
    dispatch(issueNewCard(payload))
      .unwrap()
      .then(() => {
        toast.success("Card Added Successfully!");
        routeChange(id);
        let queryParams = `?page=${pageNo}`;
        dispatch(getAllCards(queryParams));
        resetAddCardForm();
        if (employeeRef.current) employeeRef.current.value = "";
        handleClose();
        handleCloseVerifyModal();
        otpFormReset({ otp: "" });
      })
      .catch((error) => {
        toast.error(error.message || "Request Failed Please Try Again ");
      });
  };

  const handleClose = () => {
    resetAddCardForm();
    setShow(false);
  };

  const handleResetForm = () => {
    resetFilterForm();
  };

  const handleShow = () => {
    if (isDeleted) return;
    else if (!userDetails?.eKYCStatus || userDetails?.eKYCStatus === null)
      return toast.error("Identity Verification is not completed");
    else setShow(true);
  };

  const handleFileInputClick = () => {
    fileInputRef.current.click();
  };

  const handleFileSelect = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }
    const selectedFile = e.target.files[0];
    setSelectedFile(selectedFile);
    setPreview(URL.createObjectURL(selectedFile));
  };

  useEffect(() => {
    if (employeeId) {
      const [ddata] = departmentData.filter(
        (res) => res.departmentId == userDetails?.departmentId
      );
      if (ddata) setEmployeeDepartment(ddata?.departmentName);
    }
  }, [employeeId, departmentData]);

  const onCardClicked = () => {
    if (cardNumber > 0 && !isDeleted)
      navigate(`/card/card-details/${userDetails?.employeeId}`);
  };

  const addCardValues = addCardWatch();

  useEffect(() => {
    let interval;

    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [timer]);

  const onClickVerifyModal = (data) => {
    setIssueCardData(data);
    setTimer(60);
    handleClose();
    otpFormReset({ otp: "" });
    setTimeout(() => {
      handleShowVerifyModal();
    }, 200);
  };

  const requiredFieldLabel = (labelText, htmlFor) => {
    return (
      <label
        className="form-label"
        htmlFor={htmlFor}
        style={{ fontWeight: 600 }}
      >
        {labelText}
        <span
          className="absolute top-0 left-0"
          style={{ transform: "translate(-50%, -50%)", color: "#FF0000" }}
        >
          {" "}
          *
        </span>
      </label>
    );
  };

  const handleCardClick = (type) => {
    addCardSetValue("cardType", type, { shouldValidate: true });
  };

  return (
    <>
      <div
        className={`mainCompnay ${currentLanguageCode === "en"
          ? "white-container"
          : "white-container-arabic"
          }`}
      >
        <div className="d-flex align-items-center justify-content-between" style={{ position: 'relative' }}>
          {isLoading && <LoadingComponent />}
          <h1 className="text-green mb-3">
            {userDetails?.firstName
              ? `${userDetails?.firstName}'s Profile`
              : "Profile"}
          </h1>
          <IconButton
            type={'button'}
            onClick={() => navigate(-1)}
            buttonText={t('users.back')}
            id={'back'}
            src={BackArrow}
            btnStyle={{ marginBottom: '16px' }}
            isFilled={false}
            currentLanguageCode={currentLanguageCode}
          />
        </div>
        <div>
          <div className="row">
            <div className="col-12">
              <div className="team_white-container ">
                <div
                  className="d-flex align-items-center justify-content-between green-header-profile"
                  style={{ marginBottom: 24 }}
                >
                  <div className="d-flex">
                    <img
                      src={profileEdit}
                      width={20}
                      style={{ objectFit: "contain" }}
                      className="mx-3"
                    />
                    <h2 className="green-header-text mb-0">
                      {" "}
                      {t("teams.profile")}
                    </h2>
                  </div>
                  {(!userDetails?.isEmailVerified ||
                    !userDetails?.isMobileVerified) &&
                    !userDetails?.password &&
                    !isDeleted && (
                      <PrimaryButton
                        type={'button'}
                        onClick={() => sendInviteAgain(userDetails?.email)}
                        buttonText={t("teams.invite_again")}
                        isEnabled={true}
                        id={'send_invite_profile'}
                        isLoading={isLoading}
                      />
                    )}
                </div>
                <div className="userIconTopLineCss">
                  <Grid
                    container
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Grid item xs={12} sm={12} md={3.9} style={{ position: 'relative' }}>
                      {isLoading && <LoadingComponent />}
                      <Stack
                        direction={{ sm: "column", md: "row" }}
                        alignItems={"center"}
                      >
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={4}
                          className="position-relative"
                          style={{ marginRight: 10 }}
                        >
                          {preview ? (
                            <img
                              src={preview}
                              style={{
                                width: 80,
                                objectFit: "cover",
                                borderRadius: "21.43px",
                                height: 80,
                              }}
                            />
                          ) : userDetails?.profilePic ? (
                            <img
                              src={userDetails?.profilePic}
                              style={{
                                width: 80,
                                objectFit: "cover",
                                borderRadius: 21.43,
                                height: 80,
                              }}
                            />
                          ) : (
                            <span
                              className="mb-0 d-flex align-items-center justify-content-center text-uppercase"
                              style={{
                                fontSize: "40px",
                                fontWeight: 600,
                                backgroundColor: "#F2F4F7",
                                borderRadius: 60,
                                width: 108,
                                height: 108,
                              }}
                            >
                              {userDetails?.firstName &&
                                userDetails?.lastName &&
                                `${userDetails?.firstName?.charAt(
                                  0
                                )}${userDetails?.lastName?.charAt(0)}`}
                            </span>
                          )}
                          <div className="bg-green">
                            <img
                              src={EditIcon}
                              style={{
                                width: 24,
                                objectFit: "contain",
                                borderRadius: 5,
                                position: "absolute",
                                top: 1,
                                left: 68,
                                cursor: "pointer",
                              }}
                              onClick={() => handleFileInputClick()}
                            />
                            <input
                              type="file"
                              id="avatar"
                              accept="image/*"
                              ref={fileInputRef}
                              style={{ display: "none", height: 12 }}
                              onChange={handleFileSelect}
                              disabled={isDeleted}
                            />
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={8}
                          style={{ marginRight: 10 }}
                        >
                          {userDetails?.firstName && userDetails?.lastName && (
                            <h1 className="text-black heading_text text-capitalize truncated-text">{`${userDetails?.firstName} ${userDetails?.lastName}`}</h1>
                          )}
                          <Stack direction="column">
                            <p className="text-grey mt-1 mb-0">
                              {t("teams.eKYC_status")}
                              <span>
                                {userDetails?.eKYCStatus
                                  ? t("teams.eKYC_completed")
                                  : t("teams.eKYC_pending")}
                                {userDetails?.eKYCStatus && (
                                  <img
                                    src={TickGreenIcon}
                                    style={{
                                      width: 14,
                                      height: 14,
                                      objectFit: "contain",
                                      marginLeft: "2px",
                                    }}
                                  />
                                )}
                              </span>
                            </p>
                            <div className="pe-3"></div>
                            {!userDetails?.eKYCStatus && (
                              <div
                                className="form-check "
                                style={{ marginTop: 6 }}
                              >
                                <input
                                  type="checkbox"
                                  className="form-check-input "
                                  id="eKYCRequired"
                                  {...register("eKYCRequired")}
                                  disabled={isDeleted}
                                />
                                <label
                                  style={{ color: "#667085" }}
                                  className="form-check-label"
                                  htmlFor="eKYCRequired"
                                >
                                  {t("users.kycRequired")}
                                </label>
                              </div>
                            )}
                          </Stack>
                          {userDetails?.eKYCResult && <Stack direction="column">
                            <p className="text-grey mt-1 mb-0">
                              {t("teams.eKYC_result")}
                              <span className="text-capitalize">
                                {userDetails?.eKYCResult}
                              </span>
                            </p>
                          </Stack>}
                        </Grid>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={12} md={7.9}>
                      <div className="bordered_container">
                        <Stack
                          direction={{ sm: "column", md: "row" }}
                          spacing={2}
                        >
                          <Grid container justifyContent={"space-between"}>
                            <Grid item xs={12} sm={12} md={5.9} style={{ position: 'relative' }}>
                              {isLoading && <LoadingComponent />}
                              <div
                                className="cards_section"
                                style={{
                                  height: "182px",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Stack
                                  direction={{ sm: "column", md: "row" }}
                                  style={{
                                    gap: "20px",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div>
                                    <h3
                                      className="cards_section_text mb-4"
                                      style={{ color: "#101828" }}
                                    >
                                      {t("sidebar.xpendless_cards")}
                                    </h3>
                                    <p
                                      onClick={onCardClicked}
                                      className="cards_section_text"
                                      style={{
                                        cursor: cardNumber > 0 ? "pointer" : "",
                                        color: "#00D094",
                                        textDecoration:
                                          cardNumber > 0 ? "Underline" : "",
                                        borderRadius:
                                          currentLanguageCode === "en"
                                            ? "12px 0px 0px 12px"
                                            : "0px 12px 12px 0px",
                                      }}
                                    >
                                      {cardNumber + ` Cards`}
                                    </p>
                                  </div>
                                  <div className="right_alighned_text">
                                    <h3
                                      className="cards_section_text mb-4"
                                      style={{ color: "#101828" }}
                                    >
                                      {t("users.total_balance")}
                                    </h3>
                                    <h3
                                      className="cards_section_text"
                                      style={{
                                        color: "#00D094",
                                        paddingLeft: "5px",
                                      }}
                                    >
                                      {`${Math.floor(
                                        currentBalance || 0
                                      ).toLocaleString()}.`}
                                      <span className="light-text">
                                        {`${currentBalance
                                          ?.toFixed(2)
                                          .split(".")[1] === "0000"
                                          ? "00"
                                          : currentBalance
                                            ?.toFixed(2)
                                            .split(".")[1]
                                          }`}
                                      </span>
                                      <span className="light-text">QAR</span>
                                    </h3>
                                  </div>
                                </Stack>
                                <PrimaryButton
                                  type={'button'}
                                  onClick={handleShow}
                                  buttonText={t("teams.issue_new_card")}
                                  isEnabled={true}
                                  id={'show_issue_new_card'}
                                  isLoading={isLoading}
                                />
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={5.9} style={{ position: 'relative' }}>
                              {isLoading && <LoadingComponent />}
                              <div
                                className="cards_section"
                                style={{
                                  height: "182px",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Stack direction={'column'}>
                                  <Stack direction={{ sm: "column", md: "row" }} justifyContent={'space-between'}>
                                    <h3
                                      className="cards_section_text mb-4"
                                      style={{ color: "#101828" }}
                                    >
                                      {t("users.cash_on_hand")}
                                    </h3>
                                    <h3
                                      className="cards_section_text"
                                      style={{
                                        color: "#00D094",
                                        paddingLeft: "5px",
                                      }}
                                    >
                                      {`${Math.floor(
                                        parseFloat(userDetails?.pettyCashBalance || 0)
                                      ).toLocaleString()}.`}
                                      <span className="light-text">
                                        {`${parseFloat(userDetails?.pettyCashBalance || 0)
                                          ?.toFixed(2)
                                          .split(".")[1] === "0000"
                                          ? "00"
                                          : parseFloat(userDetails?.pettyCashBalance || 0)
                                            ?.toFixed(2)
                                            .split(".")[1]
                                          }`}
                                      </span>
                                      <span className="light-text">QAR</span>
                                    </h3>
                                  </Stack>
                                  <Stack
                                    spacing={1}
                                    direction={{ sm: "column", md: "row" }}
                                  >
                                    <button
                                      onClick={handleShowDisburseModal}
                                      className="bordered_header_btn blue truncated-text"
                                      id="disburse_cash_profile"
                                    >
                                      {t("users.disburse")}
                                    </button>

                                    <button
                                      onClick={handleShowReturnModal}
                                      className="bordered_header_btn orange truncated-text"
                                      id="return_cash_profile"
                                    >
                                      {t("users.return")}
                                    </button>
                                  </Stack>
                                </Stack>
                                <PrimaryButton
                                  type={'button'}
                                  onClick={handleShowCardTrans}
                                  buttonText={t("teams.card_trans_title")}
                                  isEnabled={true}
                                  id={'show_trans_profile'}
                                  isLoading={isLoading}
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </Stack>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <div className="mt-4">
                  <h1 className="text-green form-title">
                    {t("users.personal_title")}
                  </h1>
                  <form className="row" onSubmit={handleSubmit(updateDetails)} style={{ position: 'relative' }}>
                    {isLoading && <LoadingComponent />}
                    <div className="col-lg-4 col-md-6 col-sm-12 form-outline mt-4 ">
                      {requiredFieldLabel(
                        t("login.first_name"),
                        "profileFirstName"
                      )}
                      <input
                        type="text"
                        id="profileFirstName"
                        className={`form-control form-control-lg ${errors?.firstName ? "is-invalid" : ""
                          }`}
                        placeholder={`David Elson`}
                        disabled={isDeleted}
                        {...register("firstName")}
                      />
                      {errors?.firstName && (
                        <div className="invalid-feedback">
                          {errors?.firstName?.message}
                        </div>
                      )}
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 form-outline mt-4 ">
                      {requiredFieldLabel(
                        t("login.last_name"),
                        "profileLastName"
                      )}
                      <input
                        type="text"
                        id="profileLastName"
                        className={`form-control form-control-lg ${errors?.lastName ? "is-invalid" : ""
                          }`}
                        placeholder={`David Elson`}
                        disabled={isDeleted}
                        {...register("lastName")}
                      />
                      {errors?.lastName && (
                        <div className="invalid-feedback">
                          {errors?.lastName?.message}
                        </div>
                      )}
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 form-outline mt-4 ">
                      <label
                        className="form-label"
                        htmlFor="profileDOB"
                        style={{ fontWeight: 600 }}
                      >
                        {t("teams.date_of_birth")}
                      </label>
                      <input
                        type="date"
                        id="profileDOB"
                        className={`form-control form-control-lg ${errors?.dob ? "is-invalid" : ""
                          }`}
                        placeholder={`25/04/1922`}
                        disabled={isDeleted}
                        max={moment()
                          .subtract(18, "years")
                          .format("YYYY-MM-DD")}
                        {...register("dob")}
                      />
                      {errors?.dob && (
                        <div className="invalid-feedback">
                          {errors?.dob?.message}
                        </div>
                      )}
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 form-outline mt-2 ">
                      <label
                        className="form-label"
                        htmlFor="selectGender"
                        style={{ fontWeight: 600 }}
                      >
                        {t("users.gender")}
                      </label>
                      <select
                        className={`form-control form-select ${errors?.gender ? "is-invalid" : ""
                          }`}
                        id="selectGender"
                        {...register("gender")}
                        disabled={isDeleted}
                      >
                        <option disabled value="">
                          {t("users.select_gender")}
                        </option>
                        <option value={"male"}>Male</option>
                        <option value={"female"}>Female</option>
                      </select>
                      {errors?.gender && (
                        <div className="invalid-feedback">
                          {errors?.gender?.message}
                        </div>
                      )}
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 form-outline mt-2">
                      <label
                        className="form-label"
                        htmlFor="nationalitySelect"
                        style={{ fontWeight: 600 }}
                      >
                        {t("users.nationality")}
                      </label>
                      <select
                        className={`form-control form-select form-control-lg ${errors?.nationality ? "is-invalid" : ""
                          }`}
                        id="nationalitySelect"
                        {...register("nationality")}
                        disabled={isDeleted}
                      >
                        <option value="">
                          {t("users.select_nationality")}
                        </option>
                        {countriesData?.map((country) => (
                          <option
                            key={country.countryId}
                            value={country.countryId}
                          >
                            {country.name}
                          </option>
                        ))}
                      </select>
                      {errors?.nationality && (
                        <div className="invalid-feedback">
                          {errors?.nationality?.message}
                        </div>
                      )}
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 form-outline mt-2">
                      <label
                        className="form-label"
                        htmlFor="nationalId"
                        style={{ fontWeight: 600 }}
                      >
                        {t("teams.national_Id_number")}
                      </label>
                      <input
                        type="text"
                        id="nationalId"
                        className={`form-control form-control-lg ${errors?.nationalId ? "is-invalid" : ""
                          }`}
                        placeholder={`28366476677`}
                        {...register("nationalId")}
                        disabled={isDeleted}
                      />
                      {errors?.nationalId && (
                        <div className="invalid-feedback">
                          {errors?.nationalId?.message}
                        </div>
                      )}
                    </div>

                    <div className="row">
                      <div className="col-lg-4 col-md-6 col-sm-12 form-outline mt-2">
                        <label
                          className="form-label"
                          htmlFor="employeeHrId"
                          style={{ fontWeight: 600 }}
                        >
                          {t("teams.employee_hr")}
                        </label>
                        <input
                          type="text"
                          id="employeeHrId"
                          className={`form-control form-control-lg ${errors?.employeeHrId ? "is-invalid" : ""
                            }`}
                          placeholder={`28366476677`}
                          {...register("employeeHrId")}
                          disabled={isDeleted}
                        />
                        {errors?.employeeHrId && (
                          <div className="invalid-feedback">
                            {errors?.employeeHrId?.message}
                          </div>
                        )}
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12 form-outline mt-2">
                        <label
                          className="form-label"
                          htmlFor="departmentId"
                          style={{ fontWeight: 600 }}
                        >
                          {t("cards.department")}
                        </label>
                        {!decodedtoken?.isAdmin && (
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            id="department"
                            value={employeeDepartment}
                            aria-label="department"
                            readOnly
                            style={{
                              pointerEvents: "none",
                              backgroundColor: "var(--bs-border-color)",
                            }}
                          />
                        )}
                        {decodedtoken?.isAdmin && (
                          <div>
                            <select
                              className={`form-control form-select form-control-lg ${errors?.departmentId ? "is-invalid" : ""
                                }`}
                              id="departmentId"
                              disabled={isDeleted}
                              {...register("departmentId")}
                            >
                              <option value="">{t("cards.department")}</option>
                              {departmentData?.map((department) => (
                                <option
                                  key={department.departmentId}
                                  value={department.departmentId}
                                >
                                  {department.departmentName}
                                </option>
                              ))}
                            </select>
                            {errors?.departmentId && (
                              <div className="invalid-feedback">
                                {errors?.departmentId?.message}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-12 form-outline mt-4 ">
                      {requiredFieldLabel(
                        t("mobile_number"),
                        "profileMobileNo"
                      )}
                      {currentLanguageCode === "en" ? (
                        <input
                          type="text"
                          id="profileMobileNo"
                          className={`form-control form-control-lg text-right ${errors?.mobileNumber ? "is-invalid" : ""
                            }`}
                          placeholder={`+974 77808664`}
                          disabled={isDeleted}
                          {...register("mobileNumber")}
                        />
                      ) : (
                        <input
                          dir="ltr"
                          type="text"
                          id="profileMobileNo"
                          className={`form-control form-control-lg ${errors?.mobileNumber ? "is-invalid" : ""
                            }`}
                          placeholder={`+974 77808664`}
                          disabled={isDeleted}
                          style={{ textAlign: "end" }}
                          {...register("mobileNumber")}
                        />
                      )}
                      {errors?.mobileNumber && (
                        <div className="invalid-feedback">
                          {errors?.mobileNumber?.message}
                        </div>
                      )}
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 form-outline mt-4 ">
                      {requiredFieldLabel(t("email_address"), "profileEmail")}
                      <input
                        type="email"
                        disabled
                        id="profileEmail"
                        autoComplete="true"
                        className={`form-control form-control-lg ${errors?.email ? "is-invalid" : ""
                          }`}
                        placeholder={`David@xpendless.com`}
                        {...register("email", {
                          pattern: {
                            value:
                              /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/,
                            message: "Invalid email address",
                          },
                        })}
                      />
                      {errors?.email && (
                        <div className="invalid-feedback">
                          {errors?.email?.message}
                        </div>
                      )}
                    </div>
                    <div className="row my-4">
                      <h1 className="text-green form-title">
                        {t("users.roles_title")}
                      </h1>
                      <div className="col-lg-6 col-sm-12">
                        <div className="checkedBoxLineCss">
                          <div className="form-check" style={{ marginRight: 20 }}>
                            <input
                              type="radio"
                              className="form-check-input"
                              id="roleAccountant"
                              name="role"
                              value="accountant"
                              onChange={() => {
                                setValue("isAccountant", true);
                                setValue("isAdmin", false);
                                setValue("isGlobalReviewer", false);
                              }}
                              disabled={isDeleted}
                              checked={watch("isAccountant")}

                            />
                            <label className="form-check-label" htmlFor="roleAccountant">
                              {t("users.accountant")}
                            </label>
                          </div>
                          <div className="form-check" style={{ marginRight: 20 }}>
                            <input
                              type="radio"
                              className="form-check-input"
                              id="roleAdmin"
                              name="role"
                              value="admin"
                              onChange={() => {
                                setValue("isAccountant", false);
                                setValue("isAdmin", true);
                                setValue("isGlobalReviewer", false);
                              }}
                              disabled={isDeleted}
                              checked={watch("isAdmin")}
                            />
                            <label className="form-check-label" htmlFor="roleAdmin">
                              {t("users.admin")}
                            </label>
                          </div>
                          <div className="form-check" style={{ marginRight: 20 }}>
                            <input
                              type="radio"
                              className="form-check-input"
                              id="roleGlobalReviewer"
                              name="role"
                              value="globalReviewer"
                              onChange={() => {
                                setValue("isAccountant", false);
                                setValue("isAdmin", false);
                                setValue("isGlobalReviewer", true);
                              }}
                              disabled={isDeleted}
                              checked={watch("isGlobalReviewer")}
                            />
                            <label className="form-check-label" htmlFor="roleGlobalReviewer">
                              {t("users.is_global_reviewer")}
                            </label>
                          </div>
                        </div>
                      </div>
                      {isGlobalReviewerSelected && (
                        <div className="col-lg-6 col-sm-12 black_border">
                          <div className="row align-items-center align-self-center ">
                            <div className="col-lg-4 col-md-12 mt-3">
                              <p>
                                {requiredFieldLabel(
                                  t("teams.reviewer_policy"),
                                  "selectReviewPolicy"
                                )}
                              </p>
                            </div>
                            <div className="col-lg-8 col-md-12">
                              <div className="form-outline mt-2 mb-2">
                                <select
                                  className={`form-control font-weight-bold  form-select ${errors?.reviewerPolicyId ? "is-invalid" : ""
                                    }`}
                                  id="selectReviewPolicy"
                                  {...register("reviewerPolicyId")}
                                  style={{ borderRadius: 12 }}
                                >
                                  {reviewerPolicyData?.map((item) => (
                                    <option
                                      value={item?.reviewerPolicyId}
                                      defaultValue={
                                        Number(item?.reviewerPolicyId) ==
                                        userDetails?.reviewerPolicyId
                                      }
                                      key={item?.reviewerPolicyId}
                                    >
                                      {item?.policyName}
                                    </option>
                                  ))}
                                </select>
                                {errors?.reviewerPolicyId && (
                                  <div className="invalid-feedback">
                                    {errors?.reviewerPolicyId?.message}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="row mt-4">
                            <div className="col-lg-9 col-md-12">
                              <p className="bullet_text">
                                {t("teams.max_card_transactions_amount")}
                              </p>
                              <p className="bullet_text">
                                {t("teams.max_topup_requests_amount")}
                              </p>
                              <p className="bullet_text">
                                {t("teams.max_reimbursement_requests_amount")}
                              </p>
                            </div>
                            <div className="col-lg-3 col-md-12">
                              <p>
                                {formatQAR(
                                  selectedPolicy?.maxcardTransactionsAmount
                                ) || 0}{" "}
                                {t("teams.QAR")}
                              </p>
                              <p>
                                {formatQAR(
                                  selectedPolicy?.maxCardTopupAmount
                                ) || 0}{" "}
                                {t("teams.QAR")}
                              </p>
                              <p>
                                {formatQAR(
                                  selectedPolicy?.maxReimbursementsAmount
                                ) || 0}{" "}
                                {t("teams.QAR")}
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="mt-2">
                      <PrimaryButton
                        type={'submit'}
                        buttonText={t("save")}
                        isEnabled={true}
                        id={'submit_update_user'}
                        isLoading={isLoading}
                        style={{ width: '85px' }}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="">
            <PersonSpending employeeId={Number(employeeId)} />
          </div>
        </div>

        <Modal
          className="TeamModalOne"
          show={show}
          // onHide={handleClose}
          style={{ paddingRight: 0 }}
        >
          <form
            onSubmit={handleAddCardSubmit(() => handleNext(activeStep >= 4))}
          >
            <Modal.Body>
              <div className="d-flex justify-content-between align-items-start green-header p-2 ps-3">
                <div className="col-9">
                  <p
                    className="text-green modal-title"
                    style={{ fontSize: 20 }}
                  >
                    {ADD_CARDS_STEPS[`STEP_${activeStep}`]["label"] || ""}
                  </p>
                </div>
                <div
                  onClick={handleClose}
                  className="clo-2"
                  style={{ cursor: "pointer" }}
                  id="close_modal"
                >
                  <img
                    src={ImagesCross}
                    style={{ width: 20, objectFit: "contain" }}
                  />
                </div>
              </div>
              <p className="text-grey" style={{ fontWeight: 400 }}>
                {ADD_CARDS_STEPS[`STEP_${activeStep}`]["description"]}
              </p>
              {activeStep === 1 && (
                <Fragment>
                  <div className="d-flex  mt-3">
                    <div className="form-check">
                      <img src={PhysicalCard} className="mx-3" />
                      <Controller
                        name="cardType"
                        control={controlAddCardForm}
                        render={({ field }) => (
                          <input
                            {...field}
                            id={CARD_TYPE.PHYSICAL}
                            className="form-check-input ml-2"
                            type="radio"
                            value={CARD_TYPE.PHYSICAL}
                            checked={field.value === CARD_TYPE.PHYSICAL}
                            onChange={(e) => {
                              field.onChange(e.target.value);
                              setCardType(e.target.value);
                            }}
                            style={{ width: 25, height: 25 }}
                          />
                        )}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={CARD_TYPE.PHYSICAL}
                        style={{ fontWeight: 400 }}
                      >
                        {t("teams.physical_card")}
                      </label>
                      <p className="text-danger">
                        {errorsAddCardForm?.cardType?.message}
                      </p>
                    </div>
                  </div>

                  <div className="d-flex  mt-4">
                    <div className="form-check">
                      <img src={VirtualCard} className="mx-3" />
                      <Controller
                        name="cardType"
                        control={controlAddCardForm}
                        render={({ field }) => (
                          <input
                            {...field}
                            className="form-check-input ml-2"
                            id={CARD_TYPE.VIRTUAL}
                            type="radio"
                            value={CARD_TYPE.VIRTUAL}
                            checked={field.value === CARD_TYPE.VIRTUAL}
                            onChange={(e) => {
                              field.onChange(e.target.value);
                              setCardType(e.target.value);
                            }}
                            style={{ width: 25, height: 25 }}
                          />
                        )}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={CARD_TYPE.VIRTUAL}
                        style={{ fontWeight: 400 }}
                      >
                        {t("teams.virtual_card")}
                      </label>
                      <p className="text-danger">
                        {errorsAddCardForm?.cardType?.message}
                      </p>
                    </div>
                  </div>
                </Fragment>
              )}
              {activeStep === 2 && (
                <Fragment>
                  <div className="form-outline mt-4 mb-3">
                    <label className="form-labels" htmlFor="cardName">
                      {t("cards.card_name")} <span className="text-danger">*</span>
                    </label>
                    <Controller
                      name="cardName"
                      control={controlAddCardForm}
                      render={({ field }) => (
                        <input
                          {...field}
                          type="text"
                          id="cardName"
                          placeholder={t("cards.card_name")}
                          className="form-control form-control-lg me-2"
                        />
                      )}
                    />
                    <p className="text-danger">
                      {errorsAddCardForm?.cardName?.message}{" "}
                    </p>
                  </div>
                  <div className="form-outline mt-4 mb-3">
                    <label className="form-labels" htmlFor="limit">
                      {t("cards.card_balance_limit")} <span className="text-danger">*</span>
                    </label>
                    <Controller
                      name="limit"
                      control={controlAddCardForm}
                      render={({ field }) => (
                        <CurrencyInput
                          // {...field}
                          type="text"
                          min={0}
                          maxLength={6}
                          id="limit"
                          placeholder={t("cards.enter_amount")}
                          className="form-control form-control-lg me-2"
                          decimalsLimit={2}
                          decimalScale={2}
                          value={field.value || ""}
                          disablenegative={"true"}
                          onKeyDown={(e) => {
                            if (e.key === "-") {
                              e.preventDefault();
                            }
                          }}
                          onValueChange={(value) => {
                            const numericValue =
                              parseFloat(value?.replace(/,/g, "")) || 0;
                            if (numericValue < MAX_VALUE) {
                              field.onChange(
                                value === ""
                                  ? ""
                                  : value?.startsWith(".")
                                    ? `0${value}`
                                    : value
                              );
                            }
                          }}
                        />
                      )}
                    />
                    <p className="text-danger">
                      {errorsAddCardForm?.limit?.message}{" "}
                    </p>
                  </div>
                  <div className="form-outline mt-4 mb-3">
                    <div>
                      <label className="form-labels" htmlFor="searchName">
                        {t("cards.card_spending_limit")}
                      </label>
                      <img src={Icon} className="mx-2" />
                    </div>
                    <div className="d-flex gap-2">
                      <div className="col-6">
                        <Controller
                          name="amount"
                          control={controlAddCardForm}
                          render={({ field }) => (
                            <CurrencyInput
                              // {...field}
                              type="text"
                              id="amount"
                              min={0}
                              maxLength={6}
                              placeholder={t("cards.amount")}
                              className="form-control form-control-lg "
                              decimalsLimit={2}
                              decimalScale={2}
                              value={field.value || ""}
                              disablenegative={"true"}
                              onKeyDown={(e) => {
                                if (e.key === "-") {
                                  e.preventDefault();
                                }
                              }}
                              onValueChange={(value) => {
                                const numericValue =
                                  parseFloat(value?.replace(/,/g, "")) || 0;
                                if (numericValue < MAX_VALUE) {
                                  field.onChange(
                                    value === ""
                                      ? ""
                                      : value?.startsWith(".")
                                        ? `0${value}`
                                        : value
                                  );
                                }
                              }}
                            />
                          )}
                        />
                        <p className="text-danger">
                          {errorsAddCardForm?.amount?.message}
                        </p>
                      </div>
                      <div className="col-6 dropdown-frequency">
                        <Controller
                          name="frequency"
                          control={controlAddCardForm}
                          render={({ field }) => (
                            <CustomDropdown
                              id="frequency"
                              options={frequency}
                              placeholder={t("cards.frequency")}
                              onKeyUp={(e, selectedOption) => {
                                if (e.key === "Enter") {
                                  field.onChange(selectedOption?.value);
                                }
                              }}
                              onChange={(selectedOption) =>
                                field.onChange(selectedOption.value)
                              }
                              value={field.value}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <p className="text-danger">
                      {errorsAddCardForm?.frequency?.message}
                    </p>
                  </div>
                  <div className="form-outline mt-4 mb-3">
                    <label className="form-labels" htmlFor="searchName">
                      {t("cards.transaction_amount_limit")}
                    </label>
                    <Controller
                      name="transactionAmountLimit"
                      control={controlAddCardForm}
                      render={({ field }) => (
                        <CurrencyInput
                          // {...field}
                          type="text"
                          min={0}
                          maxLength={6}
                          id="transactionAmountLimit"
                          placeholder={t("cards.amount")}
                          className="form-control form-control-lg me-2"
                          decimalsLimit={2}
                          decimalScale={2}
                          value={field.value || ""}
                          disablenegative={"true"}
                          onKeyDown={(e) => {
                            if (e.key === "-") {
                              e.preventDefault();
                            }
                          }}
                          onValueChange={(value) => {
                            const numericValue =
                              parseFloat(value?.replace(/,/g, "")) || 0;
                            if (numericValue < MAX_VALUE) {
                              field.onChange(
                                value === ""
                                  ? ""
                                  : value?.startsWith(".")
                                    ? `0${value}`
                                    : value
                              );
                            }
                          }}

                        />
                      )}
                    />
                    <p className="text-danger">
                      {errorsAddCardForm?.transactionAmountLimit?.message}{" "}
                    </p>
                  </div>
                </Fragment>
              )}
              {activeStep === 3 && (
                <Fragment>
                  <div className="form-outline ">
                    <label className="form-labels" htmlFor="searchName">
                      {t("cards.first_name")} <span className="text-danger">*</span>
                    </label>
                    <Controller
                      name="firstName"
                      control={controlAddCardForm}
                      render={({ field }) => (
                        <input
                          {...field}
                          type="text"
                          id="firstName"
                          placeholder={t("cards.first_name")}
                          className="form-control form-control-lg me-2"
                        />
                      )}
                    />
                    <p className="text-danger">
                      {errorsAddCardForm?.firstName?.message}{" "}
                    </p>
                  </div>
                  <div className="form-outline ">
                    <label className="form-labels" htmlFor="searchName">
                      {t("cards.last_name")} <span className="text-danger">*</span>
                    </label>
                    <Controller
                      name="lastName"
                      control={controlAddCardForm}
                      render={({ field }) => (
                        <input
                          {...field}
                          type="text"
                          id="lastName"
                          placeholder={t("cards.last_name")}
                          className="form-control form-control-lg me-2"
                        />
                      )}
                    />
                    <p className="text-danger">
                      {errorsAddCardForm?.lastName?.message}{" "}
                    </p>
                  </div>
                  <div className="form-outline ">
                    <label className="form-labels" htmlFor="searchName">
                      {t("cards.phone_number")} <span className="text-danger">*</span>
                    </label>
                    <Controller
                      name="phoneNumber"
                      control={controlAddCardForm}
                      render={({ field }) => (
                        <input
                          {...field}
                          type="text"
                          id="phoneNumber"
                          placeholder={t("cards.phone_number")}
                          className="form-control form-control-lg me-2"
                          onInput={(e) => {
                            const value = e.target.value;
                            if (!/^\d*$/.test(value)) {
                              e.target.value = value.slice(0, -1);
                            }
                          }}
                        />
                      )}
                    />
                    <p className="text-danger">
                      {errorsAddCardForm?.phoneNumber?.message}{" "}
                    </p>
                  </div>

                  <div className="form-outline ">
                    <label className="form-labels" htmlFor="searchName">
                      {t("cards.shipments_address")} <span className="text-danger">*</span>
                    </label>
                    <Controller
                      name="addressLine1"
                      control={controlAddCardForm}
                      render={({ field }) => (
                        <input
                          {...field}
                          type="text"
                          id="addressLine1"
                          placeholder={t("cards.address_line_1")}
                          className="form-control form-control-lg me-2"
                        />
                      )}
                    />
                    <p className="text-danger">
                      {errorsAddCardForm?.addressLine1?.message}{" "}
                    </p>
                    <Controller
                      name="addressLine2"
                      control={controlAddCardForm}
                      render={({ field }) => (
                        <input
                          {...field}
                          type="text"
                          id="addressLine2"
                          placeholder={t("cards.address_line_2")}
                          className="form-control form-control-lg me-2"
                        />
                      )}
                    />
                    <p className="text-danger">
                      {errorsAddCardForm?.addressLine2?.message}{" "}
                    </p>
                  </div>

                  <div className="form-outline ">
                    <label className="form-labels" htmlFor="searchName">
                      {t("cards.city")} <span className="text-danger">*</span>
                    </label>
                    <Controller
                      name="city"
                      control={controlAddCardForm}
                      render={({ field }) => (
                        <input
                          {...field}
                          type="text"
                          id="city"
                          placeholder={t("cards.city")}
                          className="form-control form-control-lg me-2"
                        />
                      )}
                    />
                    <p className="text-danger">
                      {errorsAddCardForm?.city?.message}{" "}
                    </p>
                  </div>

                  <div className="form-outline ">
                    <label className="form-labels" htmlFor="searchName">
                      {t("cards.postal_code")} <span className="text-danger">*</span>
                    </label>
                    <Controller
                      name="postalCode"
                      control={controlAddCardForm}
                      render={({ field }) => (
                        <input
                          {...field}
                          type="number"
                          min={0}
                          id="postalCode"
                          placeholder={t("cards.postal_code")}
                          className="form-control form-control-lg me-2"
                        />
                      )}
                    />
                    <p className="text-danger">
                      {errorsAddCardForm?.postalCode?.message}{" "}
                    </p>
                  </div>
                </Fragment>
              )}
              {activeStep === 4 && (
                <Fragment>
                  <div>
                    <div className="form-outline mt-4 mb-3">
                      <label className="form-labels" htmlFor="selectCategory">
                        {t("cards.category_restriction")} <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="categoryRestriction"
                        control={controlAddCardForm}
                        render={({ field }) => (
                          <CustomDropdown
                            id="selectCategory"
                            options={selectCategory}
                            placeholder="Select an option"
                            onKeyUp={(e, selectedOption) => {
                              if (e.key === "Enter") {
                                field.onChange(selectedOption.value);

                              }
                            }}
                            onChange={(selectedOption) => {
                              handleSelectChange(selectedOption);
                              field.onChange(selectedOption.value);
                            }}
                            value={selectCategory.find(
                              (option) => option.value === field.value
                            )}
                          />
                        )}
                      />
                      <p className="text-danger">
                        {errorsAddCardForm?.categoryRestriction?.message}
                      </p>
                    </div>

                    {["allow", "block"].includes(selectedOption) && (
                      <>
                        <div className="d-flex justify-content-center ">
                          {categories.length === 0 && (
                            <SecondaryButton
                              type={'button'}
                              onClick={handleAddCategory}
                              buttonText={t("cards.add_category")}
                              isEnabled={true}
                              id={'addCategory'}
                              style={{ marginBlock: '8px', width: '400px', textAlign: 'center' }}
                              isHighlighted={true}
                            />
                          )}
                        </div>

                        {categories.map((category, index) => (
                          <div
                            key={index}
                            className="d-flex justify-content-between align-items-center form-outline mt-2"
                          >
                            <div
                              className="form-control-lg d-flex justify-content-between align-items-center w-100"
                              style={{
                                backgroundColor: "#f8f9fa",
                                padding: "10px",
                                borderRadius: "8px",
                              }}
                            >
                              {category.id ? (
                                <span>{category.value}</span>

                              ) : (
                                <CustomDropdown
                                  id={`category-${index}`}
                                  options={categoriesList}
                                  selectedOption={categoriesList.find(
                                    (option) => option.value === category.value
                                  )}
                                  placeholder="Select an option"
                                  onKeyUp={(e, selectedOption) => {
                                    if (e.key === "Enter") {
                                      handleCategoryChange(index, selectedOption);
                                    }
                                  }}
                                  onChange={(selectedOption) => {
                                    handleCategoryChange(index, selectedOption);
                                  }}
                                  value={categoriesList.find(
                                    (option) => option.value === category.value
                                  )}
                                />
                              )}
                              <button
                                type="button"
                                className="btn btn-link text-danger"
                                onClick={() => handleDeleteCategory(index)}
                              >
                                <img src={Delete} />
                              </button>
                            </div>
                          </div>
                        ))}
                        <div className="d-flex justify-content-center ">
                          {categories.length > 0 && (
                            <SecondaryButton
                              type={'button'}
                              onClick={handleAddCategory}
                              buttonText={t("cards.add_another")}
                              isEnabled={true}
                              id={'addAnotherCategory'}
                              style={{ marginBlock: '8px', width: '400px', textAlign: 'center' }}
                              isHighlighted={true}
                            />
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </Fragment>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Stack direction={'row'} spacing={1}>
                <SecondaryButton
                  type={'button'}
                  onClick={handleClose}
                  buttonText={t("close")}
                  isEnabled={true}
                  id={'closeAddCard'}
                />
                {activeStep > 1 && (
                  <SecondaryButton
                    type={'button'}
                    onClick={handlePrevious}
                    buttonText={t("previous")}
                    isEnabled={true}
                    id={'addCardPrev'}
                    isHighlighted={true}
                  />
                )}
                <PrimaryButton
                  type={'submit'}
                  buttonText={activeStep < 4 ? t("teams.next") : t("cards.add_card")}
                  onClick={() => handleAddCardSubmit()}
                  isEnabled={true}
                  id={'next'}
                />
              </Stack>
            </Modal.Footer>
          </form>
        </Modal>

        <Modal
          aria-labelledby="contained-modal-title-center"
          centered
          show={showVerifyModal}
          onHide={handleCloseVerifyModal}
        >
          <form
            className="form"
            style={{ padding: 24 }}
            onSubmit={handleOtpSubmit(handleAddCard)}
          >
            <div className="d-flex align-items-start justify-content-between">
              <img
                src={Logo2}
                className="logoso mt-1 mb-4"
                alt="Sample image"
              />
              <div onClick={handleCloseVerifyModal}>
                <img
                  src={ImagesCross}
                  className="small-addjust"
                  style={{ width: 20, objectFit: "contain", marginLeft: 15 }}
                />
              </div>
            </div>
            <h1 className="text-blue-login mb-2">
              {t("login.verify_your_mobile_number")}
            </h1>
            <div className="divider d-flex align-items-center my-2">
              <p className="mb-0 text-subtitle2" style={{ color: "#8B9299" }}>
                {t("login.verify_your_mobile_number_desc")}
              </p>
            </div>

            <div className="otp form-outline d-flex justify-content-center mt-4 mb-3">
              <Controller
                name="otp"
                control={controlVerifyModal}
                defaultValue={0}
                render={({ field: { onChange, value } }) => (
                  <OtpInput
                    inputStyle="inputStyle"
                    shouldAutoFocus={true}
                    value={value}
                    onChange={onChange}
                    numInputs={6}
                    renderInput={(props) => (
                      <input
                        {...props}
                        id="input_otp"
                        onInput={(e) => {
                          const value = e.target.value;
                          if (!/^\d*$/.test(value)) {
                            e.target.value = value.slice(0, -1);
                          }
                        }}
                      />
                    )}
                  />
                )}
              />
            </div>
            <p className="text-danger">{errorsOtpForm?.otp?.message}</p>
            <div className="d-flex justify-content-end">
              {timer === 0 ? (
                <a href="javascript:void(0)" id="resend_otp">
                  Resend OTP
                </a>
              ) : (
                <p>
                  Resend OTP in{" "}
                  <span className="text-blue">{timer} seconds</span>{" "}
                </p>
              )}
            </div>
            <div className="text-center text-lg-start mt-4 w-100">
              <PrimaryButton
                type={'submit'}
                buttonText={t("cards.continue_to_issue_card")}
                isEnabled={true}
                id={'continue'}
                isLoading={isLoading2}
              />
            </div>
          </form>
        </Modal>
        <Modal
          className="TaxcodeModalOne"
          show={showDisburseModal}
          onHide={handleCloseDisburseModal}
          style={{ paddingRight: 0 }}
        >
          <form onSubmit={handleDisburseSubmit(onClickDisburseModal)}>
            <Modal.Body>
              <div className="d-flex justify-content-between align-items-start green-header">
                <div className="col-9">
                  <p
                    className="text-green addjust pettyCach-model-title"
                    style={{ fontSize: 16, marginRight: 15 }}
                  >
                    {t("teams.disburseTitle")}
                  </p>
                </div>
                <div
                  onClick={handleCloseDisburseModal}
                  className="clo-2"
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={ImagesCross}
                    className="small-addjust"
                    style={{ width: 20, objectFit: "contain", marginLeft: 15 }}
                  />
                </div>
              </div>
              <div className="form-outline mt-4 mb-3">
                <label className="label-form" htmlFor="amount">
                  {t("teams.disburse_label")}
                </label>
                <Controller
                  name="amount"
                  control={controlDisburseForm}
                  defaultValue=""
                  render={({ field }) => (
                    <CurrencyInput
                      type="text"
                      id="amount"
                      className="form-control form-control-lg"
                      placeholder={`${t("pettyCash.amount")}`}
                      value={field.value || ""}
                      decimalsLimit={2}
                      decimalScale={2}
                      disablenegative={"true"}
                      onKeyDown={(e) => {
                        if (e.key === "-") {
                          e.preventDefault();
                        }
                      }}
                      onValueChange={(value) => {
                        const numericValue =
                          parseFloat(value?.replace(/,/g, "")) || 0;
                        if (numericValue < MAX_VALUE) {
                          field.onChange(
                            value === ""
                              ? ""
                              : value?.startsWith(".")
                                ? `0${value}`
                                : value
                          );
                        }
                      }}
                    />
                  )}
                />
                <p className="text-danger">
                  {errorsDisburseForm?.amount?.message}
                </p>
              </div>
              <div className="form-outline my-4">
                <label className="label-form" htmlFor="description">
                  {t("pettyCash.desc")}
                </label>
                <Controller
                  name="description"
                  control={controlDisburseForm}
                  defaultValue=""
                  render={({ field }) => (
                    <input
                      {...field}
                      type="text"
                      id="description"
                      className="form-control form-control-lg"
                      placeholder={`${t("pettyCash.desc")}`}
                    />
                  )}
                />
                <p className="text-danger">
                  {errorsDisburseForm?.description?.message}
                </p>
              </div>
              <div
                style={{ borderBottom: "1px solid #EAECF0", marginBottom: 24 }}
              ></div>
              <div className="d-flex justify-content-end align-items-center">

                <PrimaryButton
                  type={'submit'}
                  buttonText={t("teams.submit_disbursement")}
                  isEnabled={true}
                  id={'submit_user_disburse'}
                  isLoading={isLoading}
                  style={{ width: '222px' }}
                />
              </div>
            </Modal.Body>
          </form>
        </Modal>
        <Modal
          className="TaxcodeModalOne"
          show={showReturnModal}
          onHide={handleCloseReturnModal}
          style={{ paddingRight: 0 }}
        >
          <form onSubmit={handleReturnSubmit(onClickReturneModal)}>
            <Modal.Body>
              <div className="d-flex justify-content-between align-items-start green-header">
                <div className="col-9">
                  <p
                    className="text-green addjust pettyCach-model-title"
                    style={{ fontSize: 16, marginRight: 15 }}
                  >
                    {t("teams.returnTitle")}
                  </p>
                </div>
                <div
                  onClick={handleCloseReturnModal}
                  className="clo-2"
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={ImagesCross}
                    className="small-addjust"
                    style={{ width: 20, objectFit: "contain", marginLeft: 15 }}
                  />
                </div>
              </div>
              <div className="form-outline mt-4 mb-3">
                <label className="label-form" htmlFor="amount">
                  {t("teams.return_label")}
                </label>
                <Controller
                  name="amount"
                  control={controlReturnForm}
                  defaultValue=""
                  render={({ field }) => (
                    <CurrencyInput
                      type="text"
                      id="amount"
                      className="form-control form-control-lg"
                      placeholder={`${t("pettyCash.amount")}`}
                      value={field.value || ""}
                      decimalsLimit={2}
                      decimalScale={2}
                      disablenegative={"true"}
                      onKeyDown={(e) => {
                        if (e.key === "-") {
                          e.preventDefault();
                        }
                      }}
                      onValueChange={(value) => {
                        const numericValue =
                          parseFloat(value?.replace(/,/g, "")) || 0;
                        if (numericValue < MAX_VALUE) {
                          field.onChange(
                            value === ""
                              ? ""
                              : value?.startsWith(".")
                                ? `0${value}`
                                : value
                          );
                        }
                      }}
                    />
                  )}
                />
                <p className="text-danger">
                  {errorsReturnForm?.amount?.message}
                </p>
              </div>
              <div className="form-outline my-4">
                <label className="label-form" htmlFor="description">
                  {t("pettyCash.desc")}
                </label>
                <Controller
                  name="description"
                  control={controlReturnForm}
                  defaultValue=""
                  render={({ field }) => (
                    <input
                      {...field}
                      type="text"
                      id="description"
                      className="form-control form-control-lg"
                      placeholder={`${t("pettyCash.desc")}`}
                    />
                  )}
                />
                <p className="text-danger">
                  {errorsReturnForm?.description?.message}
                </p>
              </div>
              <div
                style={{ borderBottom: "1px solid #EAECF0", marginBottom: 24 }}
              ></div>
              <div className="d-flex justify-content-end align-items-center">
                <PrimaryButton
                  type={'submit'}
                  buttonText={t("teams.submit_return")}
                  isEnabled={true}
                  id={'submit_return_user'}
                  style={{ width: '222px' }}
                  isLoading={isLoading}
                />
              </div>
            </Modal.Body>
          </form>
        </Modal>
        <Modal
          className="pettyCash-employee-model"
          show={showCardTrans}
          onHide={handleCloseCardTrans}
          style={{ maxHeight: "667px" }}
        >
          <Modal.Body style={{ margin: "0px" }}>
            <div className="d-flex justify-content-between align-items-start green-header w-100">
              <div className="col-9">
                <p
                  className="green-header-text addjust pettyCach-model-title"
                  style={{ fontSize: 16, marginRight: 15 }}
                >
                  {t("teams.card_trans_title")}
                </p>
              </div>
              <div
                onClick={handleCloseCardTrans}
                className="clo-2"
                style={{ cursor: "pointer" }}
              >
                <img
                  src={ImagesCross}
                  className="small-addjust"
                  style={{ width: 20, objectFit: "contain", marginLeft: 15 }}
                />
              </div>
            </div>
            <form
              onSubmit={handleFilterSubmit(onFilterFormSubmit)}
              className="mt-3"
            >
              <div className="row align-items-end">
                <div className="col-lg-3 col-md-6 col-sm-12 form-outline">
                  <label className="label-form" htmlFor="filterTransactions">
                    {t("pettyCash.filter_trans")}
                  </label>
                  <Controller
                    control={controlFilterForm}
                    name="filterTransactions"
                    render={({ field }) => (
                      <select
                        id="filterTransactions"
                        value={field.value || ""}
                        onChange={(e) => {
                          field.onChange(e);
                          e.target.classList.add("select-placeholder-selected");
                        }}
                        onBlur={field.onBlur}
                        className={`form-select form-control-lg ${currentLanguageCode === "en"
                          ? "form-control"
                          : "form-control-arabic"
                          } ${!field.value ? "select-placeholder" : ""}`}
                      >
                        <option disabled value="">
                          {"Select Options"}
                        </option>
                        {selectOptions.map((option, i) => (
                          <option
                            value={option?.value}
                            key={"transactionFilter" + i}
                          >
                            {option?.label}
                          </option>
                        ))}
                      </select>
                    )}
                  />
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 form-outline">
                  <label className="label-form" htmlFor="fromDate">
                    {t("pettyCash.from_date")}
                  </label>
                  <Controller
                    control={controlFilterForm}
                    name="fromDate"
                    render={({ field }) => (
                      <input
                        type="date"
                        id="fromDate"
                        className="form-control form-control-lg"
                        placeholder="dd-mm-yyyy"
                        autoComplete="off"
                        value={field.value || ""}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        max={moment().format("YYYY-MM-DD")}
                        style={{
                          color: field.value ? "black" : "grey",
                        }}
                      />
                    )}
                  />
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 form-outline">
                  <label className="label-form" htmlFor="toDate">
                    {t("pettyCash.to_date")}
                  </label>
                  <Controller
                    control={controlFilterForm}
                    name="toDate"
                    render={({ field }) => (
                      <div>
                        <input
                          type="date"
                          id="toDate"
                          className="form-control form-control-lg"
                          placeholder="dd-mm-yyyy"
                          autoComplete="off"
                          value={field.value || ""}
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                          max={moment().format("YYYY-MM-DD")}
                          style={{
                            color: field.value ? "black" : "grey",
                          }}
                        />
                        {errorsFilterForm?.toDate && (
                          <div style={{ color: "red" }}>
                            {errorsFilterForm?.toDate?.message}
                          </div>
                        )}
                      </div>
                    )}
                  />
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 form-outline">
                  <label className="label-form" htmlFor="selectEmployee">
                    {t("pettyCash.performed_by")}
                  </label>
                  <Controller
                    control={controlFilterForm}
                    name="selectEmployee"
                    render={({ field }) => (
                      <select
                        id="selectEmployee"
                        value={field.value || ""}
                        className={`form-select form-control-lg ${!field.value ? "select-placeholder" : ""
                          }`}
                        onChange={(e) => {
                          field.onChange(e);
                          e.target.classList.add("select-placeholder-selected");
                        }}
                        onBlur={field.onBlur}
                      >
                        <option disabled value="">
                          {"Select Options"}
                        </option>
                        {departmentEmployees?.data.map((employee) => (
                          <option
                            className="text-capitalize"
                            key={employee.employeeId}
                            value={employee.employeeId}
                          >
                            {`${employee.firstName} ${employee.lastName}`}
                          </option>
                        ))}
                      </select>
                    )}
                  />
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-end mt-3">
                <PrimaryButton
                  type={'submit'}
                  buttonText={t("pettyCash.search")}
                  isEnabled={true}
                  id={'search_pettycash_user'}
                  style={{ width: '180px' }}
                  isLoading={isLoading}
                />

                <SecondaryButton
                  type={'button'}
                  onClick={handleResetForm}
                  buttonText={t("pettyCash.reset")}
                  id={'reset_pettycash_user'}
                  style={{ width: '140px', marginInline: '16px' }}
                />
              </div>
            </form>
            <div className="bordered-container mt-3 p-1">
              <div className="table-responsive" style={{ border: 0, position: 'relative' }}>
                {isLoading && <LoadingComponent />}
                <table
                  id="employeeTransactions"
                  style={{
                    borderCollapse: "separate",
                    borderSpacing: "0 16px",
                  }}
                  cellSpacing="0"
                  width="100%"
                >
                  <thead>
                    <tr style={{ borderTopWidth: 0 }} className="table-head">
                      <th scope="col" style={{ textAlign: "center" }}>
                        {t("pettyCash.type")}
                      </th>
                      <th scope="col" style={{ textAlign: "center" }}>
                        {t("pettyCash.amount")}
                      </th>
                      <th scope="col" style={{ textAlign: "center" }}>
                        {t("pettyCash.date")}
                      </th>
                      <th scope="col" style={{ textAlign: "center" }}>
                        {t("pettyCash.desc")}
                      </th>
                      <th scope="col" style={{ textAlign: "center" }}>
                        {t("pettyCash.preformed_by")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {employeeTrans?.data?.rows?.map((list, index) => {
                      return (
                        <tr
                          key={index}
                          className={`${currentLanguageCode === "en"
                            ? "table-body-tr"
                            : "table-body-tr-arabic"
                            }`}
                        >
                          <td style={{ textAlign: "center" }}>
                            {list.type.charAt(0).toUpperCase() +
                              list.type.slice(1)}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {formatQAR(list.amount)}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {formatTime(list.createdAt)}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {list.comments}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {list.performedByName}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div
                className="d-flex align-items-center justify-content-between"
                style={{ width: "100%", borderTop: "0px !imporant" }}
              >
                <nav aria-label="Page navigation example">
                  <ul className="pagination pe-0 pt-1">
                    <li className="page-item">
                      <SecondaryButton
                        type={'button'}
                        onClick={() => onPreviousClick()}
                        buttonText={t("previous")}
                        isEnabled={pageNo !== 1}
                        id={'prev_user'}
                        style={{ width: '15%' }}
                      />
                    </li>
                    <li className="page-item mx-3">
                      <SecondaryButton
                        type={'button'}
                        onClick={() => onNextClick()}
                        buttonText={t("next")}
                        isEnabled={!(pageNo >= employeeTrans?.data?.totalPages)}
                        id={'next_user'}
                        style={{ width: '15%' }}
                      />
                    </li>
                  </ul>
                </nav>
                <div className="page-count d-flex align-items-center">
                  {employeeTrans?.data?.totalPages ? (
                    <h4 className="pagination-textcss">
                      Page {pageNo} of {employeeTrans?.data?.totalPages}
                    </h4>
                  ) : (
                    <h4 className="pagination-textcss">
                      Page {pageNo} of {1}
                    </h4>
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default TeamDetails;
