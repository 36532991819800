import { createSlice } from "@reduxjs/toolkit";
import { routes } from "../../utils/routes";          
import { checkHasAccess } from "../../utils/checkAccess";

const initialState = {
    loggedInUser: {
        token: localStorage.getItem('token') || '',
        firstName: localStorage.getItem('firstName') || '',
        lastName: localStorage.getItem('lastName') || '',
        email: localStorage.getItem('email') || '',
        departmentParent: localStorage.getItem('departmentParent') || '',
        departmentParentName: localStorage.getItem('departmentParentName') || '',
        profilePic: localStorage.getItem('profilePic') || '',
        hasAccess: localStorage.getItem('hasAccess') || 'false',
    },
    routes:[],
    isLoading: false,
    error: null
};

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUserLoggedInData: (state, action) => {
            console.log("xx- action?.payload=================================================>",action?.payload);
            if (action?.payload?.token) {
                localStorage.setItem('token', action.payload.token);
                state.loggedInUser.token = action.payload.token || '';
            }

            if (action?.payload?.firstName) {
                localStorage.setItem('firstName', action.payload.firstName);
                state.loggedInUser.firstName = action.payload.firstName || '';
            }

            if (action?.payload?.lastName) {
                localStorage.setItem('lastName', action.payload.lastName);
                state.loggedInUser.lastName = action.payload.lastName || '';
            }
            if (action?.payload?.profilePic) {
                localStorage.setItem('profilePic', action.payload.profilePic);
                state.loggedInUser.profilePic = action.payload.profilePic || '';
            }

            if (action?.payload?.email) {
                localStorage.setItem('email', action.payload.email);
                state.loggedInUser.email = action.payload.email || '';
            }
    
            if (action?.payload?.status) {
                localStorage.setItem('companyStatus', action.payload.status);
                state.loggedInUser.companyStatus = action.payload.status || '';
            }

            localStorage.setItem('hasAccess', action.payload.hasAccess);
            state.loggedInUser['hasAccess'] = action.payload.hasAccess || 'false';
            
            if (action?.payload?.parentDepartment) {
                localStorage.setItem('departmentParent', action.payload.parentDepartment.departmentId);
                localStorage.setItem('departmentParentName', action.payload.parentDepartment.departmentName);
                state.loggedInUser.departmentParent = action.payload.parentDepartment.departmentId || '';
                state.loggedInUser.departmentParentName = action.payload.parentDepartment.departmentName || '';
            }

            if (action?.payload?.featuresList) {
                const featuresList = action.payload.featuresList;
                const fomattedFeatureList = featuresList ? featuresList
                ?.filter(item => !item.startsWith("MOB_")) // Remove items starting with "MOB_"
                ?.map(item => 
                  item
                    .replace("WEB_", "") // Remove "WEB_"
                    .replace(/_/g, "") // Remove underscores
                ) : [];
                localStorage.setItem('featuresList', JSON.stringify(fomattedFeatureList || []));
                state.loggedInUser.featuresList = fomattedFeatureList || [];
            }else{
                localStorage.setItem('featuresList', JSON.stringify([] || []));
            }

        },
        updateRoutes: (state, action) => {
           state.routes = routes
            .filter(route => checkHasAccess(route.path))
        },
        updateFeaturesList: (state, action) => {
            if (action?.payload?.featuresList) {
                const featuresList = action.payload.featuresList;
                const fomattedFeatureList = featuresList ? featuresList
                ?.filter(item => !item.startsWith("MOB_"))
                ?.map(item => 
                  item
                    .replace("WEB_", "")
                    .replace(/_/g, "")
                ) : [];
                localStorage.setItem('featuresList', JSON.stringify(fomattedFeatureList || []));
                state.loggedInUser.featuresList = fomattedFeatureList || [];
            }else{
                localStorage.setItem('featuresList', JSON.stringify([] || []));
            }
        }
    }
});

export const { setUserLoggedInData, updateRoutes, updateFeaturesList } = userSlice.actions;
export default userSlice.reducer;