import React, { useEffect, useState } from 'react';
// import IconButton from '@mui/material/IconButton';
// import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';
import Chart from 'chart.js/auto';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import ImagesCross from '../../assets/images/close-square-8.png';
import moment from 'moment';
import List1 from '../../assets/images/list.png';
import List2 from '../../assets/images/list1.png';
import './Graph.scss';
import Dropdown from 'react-bootstrap/Dropdown';
// const ITEM_HEIGHT = 48;

function Graph(props) {

  const { fetchTransactionByVolumeLoading, fetchTransactionByVolume, section, handleFilterClick, globalSelectedFil, setSelectedFilterGraph, selectedFilterGraph } = props;

  // const [anchorEl, setAnchorEl] = useState(null);
  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  const options = [
    { value: 'current_month', label: t('dashboard.current_month') },
    { value: 'current_year', label: t('dashboard.current_year') },
    { value: 'last_3_year', label: t('dashboard.last_3_years') },
    { value: 'custom', label: t('dashboard.custom') },
  ];
  const schema = yup.object().shape({
    startDate: yup
      .string()
      .required('Start Date is required'),
    endDate: yup
      .string()
      .required('End Date is required')
      .test('is-valid-end-date', 'End date should not be before start date', function (value) {
        const startDate = this.resolve(yup.ref('startDate'));
        return !startDate || !value || value >= startDate;
      }),
  });

  const { control, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });

  // const open = Boolean(anchorEl);
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  const handleClose = () => setShow(false);

  // const handleCloseMenu = (option) => {
  //   if (option === 'current_month') {
  //     setSelectedFilterGraph('Current Month')
  //   }
  //   if (option === 'current_year') {
  //     setSelectedFilterGraph('Current Year')
  //   }

  //   if (option === 'last_3_year') {
  //     setSelectedFilterGraph('Last 3 Years')
  //   }
  //   setAnchorEl(null);
  //   if (option != '' && typeof option === 'string') {
  //     if (option === 'custom') {
  //       reset();
  //       setShow(true)
  //     } else {
  //       handleFilterClick(section, option, {}, true);
  //     }
  //   }
  // };
  const handleCloseMenu = (selectedOption) => {
    if (selectedOption) {
      const { value, label } = selectedOption;
      setSelectedFilterGraph(label);

      if (value === 'custom') {
        setShow(true);
      } else {
        handleFilterClick(section, value, {}, true);
      }
    }

    // setAnchorEl(null);
  };


  const onSubmit = (data) => {
    setSelectedFilterGraph(`${moment(data?.startDate).format('DD/MM/YYYY')} - ${moment(data?.endDate).format('DD/MM/YYYY')}`);
    handleFilterClick(section, 'custom', data, true);
    setShow(false);
  };

  useEffect(() => {
    // Access the canvas element using a ref after the component has mounted.
    const ctx = document.getElementById('myChart').getContext('2d');

    // Check if a chart instance already exists
    const existingChart = Chart.getChart(ctx);

    // Define gradient colors
    const gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
    gradientStroke.addColorStop(0, 'rgba(0, 211,50, 1)');
    gradientStroke.addColorStop(1, 'rgba(0, 211,50, 1)');

    const gradientFill = ctx.createLinearGradient(0, 0, 0, 200);
    gradientFill.addColorStop(0, 'rgba(0, 211,50, 0.5)');
    gradientFill.addColorStop(1, 'rgba(0, 211,50, 0.05)');

    // Create the chart
    if (existingChart) {
      existingChart.destroy();
    }
    const XAxis = fetchTransactionByVolume?.map((obj) => obj.x_axis)?.reverse();
    const YAxis = fetchTransactionByVolume?.map((obj) => obj.y_axis)?.reverse();

    new Chart(ctx, {
      type: 'line',
      data: {
        labels: XAxis,
        datasets: [
          {
            borderColor: gradientStroke,
            pointBorderColor: gradientStroke,
            pointBackgroundColor: gradientStroke,
            pointHoverBackgroundColor: gradientStroke,
            pointHoverBorderColor: gradientStroke,
            pointBorderWidth: 0,
            pointHoverRadius: 0,
            pointHoverBorderWidth: 1,
            pointRadius: 0,
            fill: true,
            backgroundColor: gradientFill,
            borderWidth: 2,
            data: YAxis,
            tension: 0.2
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          y: {
            ticks: {
              fontColor: 'rgba(0,0,0,0.5)',
              fontStyle: 'bold',
              beginAtZero: true,
              maxTicksLimit: 5,
              padding: 20,
            },
            grid: {
              zeroLineColor: 'transparent',
            },
            title: {
              display: true,
              text: `${t('dashboard.transactions')}`,
              padding: 0,
            }
          },
          x: {
            grid: {
              display: false,
            },
            ticks: {
              padding: 20,
              fontColor: 'rgba(0,0,0,0.5)',
              fontStyle: 'bold',
            },
            title: {
              display: true,
              text: `${t('teams.date')}`,
              padding: 0,
            }
          },
        },
      },
    });
  }, [fetchTransactionByVolume]);

  return (
    <div className="p-3 cards-custom position-relative GraphCustomCss">
      {fetchTransactionByVolumeLoading && <div className="dashboardloader-container" style={{ height: '100%', width: '100%', left: 0, top: 0 }}>
        <div className="spinner-border text-light" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>}

      <div className='d-flex align-items-center justify-content-between green-header'>
        <div className='d-flex align-items-center'>
          <img src={List2} width={32} style={{ objectFit: 'contain' }} className='mx-2' />
          <h2 className='green-header-text mb-0'>{t('dashboard.transactions')}{t('dashboard.by_amount')}</h2>
          {/* <h4 className='text-green'></h4> */}
        </div>
        <div className='d-flex align-items-center'>
          <p style={{ fontSize: '12px' }} className='text-grey mb-0' >{selectedFilterGraph ? selectedFilterGraph : globalSelectedFil}</p>
          {/* <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <img src={List1} className='option-image' />
          </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleCloseMenu}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: '20ch',
              },
            }}
          >
            {options?.map((option, index) => (
              <MenuItem key={index} onClick={() => handleCloseMenu(option.value)}>
                {option.label}
              </MenuItem>
            ))}
          </Menu> */}
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic">
              <img src={List1} style={{ objectFit: 'contain' }} className='option-image' alt="Options" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {options.map((option, index) => (
                <Dropdown.Item key={index} onClick={() => handleCloseMenu(option)}>
                  {option.label}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      <hr />
      {/* <div className="d-flex justify-content-end">
        <div className="mr-2" style={{ marginRight: 10 }}>
          <div className="d-flex align-items-center">
            <FaCircle fontSize='8' color='rgba(21, 112, 239, 1)' />
            <h4 className='text-grey' style={{ marginLeft: 5,marginRight: 5 }}>2021</h4>
          </div>
        </div>
        <div className="mr-2" style={{ marginRight: 10 }}>
          <div className="d-flex align-items-center">
            <FaCircle fontSize='8' color='rgba(83, 177, 253, 1)' />
            <h4 className='text-grey' style={{ marginLeft: 5,marginRight: 5 }}>2020</h4>
          </div>
        </div>
        <div className="mr-2" style={{ marginRight: 10 }}>
          <div className="d-flex align-items-center">
            <FaCircle fontSize='8' color='rgba(24, 73, 169, 1)' />
            <h4 className='text-grey' style={{ marginLeft: 5,marginRight: 5 }}>2019</h4>
          </div>
        </div>
      </div> */}
      <div className='graph-dashboard'>
        <canvas id="myChart"></canvas>
      </div>
      <Modal className='TaxcodeModalOne' show={show} onHide={handleClose} style={{ paddingRight: 0 }}>
        <form className='form' onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <div className='d-flex justify-content-between align-items-start green-header p-2'>
              <div className='col-9'>
                <p className='text-green modal-title' style={{ fontSize: 18 }}>
                  Select Custom Date
                </p>
              </div>
              <div onClick={handleClose} className='clo-2' style={{ cursor: 'pointer' }}>
                <img src={ImagesCross} style={{ width: 20, objectFit: 'contain' }} />
              </div>
            </div>
            <div className="form-outline my-3">
              <label className="form-label" htmlFor="startDate">
                Start Date
              </label>

              <Controller
                name="startDate"
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <input
                    type="date"
                    id="startDate"
                    className="form-control form-control-lg"
                    placeholder="Start Date"
                    autoComplete="off"
                    {...field}
                  />
                )}
              />
              {errors?.startDate && (
                <div style={{ color: 'red' }}>{errors?.startDate?.message}</div>
              )}
            </div>
            <div className="form-outline my-3">
              <label className="form-label" htmlFor="endDate">
                End Date
              </label>

              <Controller
                name="endDate"
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <input
                    type="date"
                    id="endDate"
                    className="form-control form-control-lg"
                    placeholder="Start Date"
                    autoComplete="off"
                    {...field}
                  />
                )}
              />
              {errors?.endDate && (
                <div style={{ color: 'red' }}>{errors?.endDate?.message}</div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button onClick={handleClose} className='btn-secondary-small' id='cancel_custom_date'>{t('cancel')}</button>
            <button className='btn-primary-small' type='submit' id='submit_custom_date'>{t('submit')}</button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
}

export default Graph;
