import React, { useState } from 'react';
import ReimbursementIcon from '../../assets/images/reimbursement-icon.png';
import Receipt from "../../assets/images/receipt.png";
import UploadIcon from '../../assets/images/Upload_icon.png';
import InfoSection from './ContentSection';
import { useTranslation } from 'react-i18next';

export default function ReimbursementPage() {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState("");
const { t } = useTranslation()
  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };
  const handleFilterClick = (index) => {
    setSelectedIndex(index);
  };

  const items = [
    { label: t('walkThrough.details') },
    { label: t("walkThrough.activities") }
  ];
const content=[
  t("walkThrough.export_content_1"),
  t("walkThrough.export_content_2")

  ]
  return (
   
  
        <div className="" style={{ padding: "4%",  paddingLeft: "8%", overflowX: "hidden", overflowY: "hidden"}}>
          <div className="export-dropdown mt-4">
            <div className="col-lg-3 col-md-6 mt-2 balance-box" style={{
              fontWeight:600
            }}>
              <div className="d-flex justify-content-between" style={{ padding: 5 }}>
                {items.map((item, index) => (
                  <div
                    key={index}
                    onClick={() => handleFilterClick(index)}
                    className={`dropdown-item ${selectedIndex === index ? 'selected' : ''}`}
                  >
                    {item.label}
                  </div>
                ))}
              </div>
            </div>
          </div>
  
          <div className="export-dropdown mt-4">
            <div className="col-lg-3 col-md-6 mt-2 p-2 balance-box">
              <div className="d-flex justify-content-between text-white mb-3" style={{ padding: 5 }}>
                <div>{t("walkThrough.reimbursement_amount")}<span className='mt-5 ms-1' style={{fontSize:"12px", color:"white", opacity: 0.8}}>{t("walkThrough.reimbursement_currency")}</span></div>
                <div>{t("walkThrough.john_doe")}</div>

              </div>
  
              <div className="d-flex justify-content-between text-white opacity-80" style={{ padding: 5, opacity: 0.8,fontSize:"14px" }}>
                <div>{t("walkThrough.date")}</div>
                <div>{t("walkThrough.time")}</div>
              </div>
            </div>
          </div>
  
          <div className="export-dropdown mt-4">
            <div className="col-lg-3 col-md-6 mt-2 p-2 balance-box" style={{
              fontWeight: 600
            }}>
              <div className="d-flex justify-content-between text-white mb-3" style={{ padding: 5 }}>
                <div>{t("walkThrough.name")}</div>
                <div style={{ fontWeight: 400 }}>{t("walkThrough.john_doe")}</div>
              </div>
  
              <div className="d-flex justify-content-between text-white mb-3" style={{ padding: 5 }}>
                <div>{t("walkThrough.department")}</div>
                <div style={{ fontWeight: 400 }}>{t("walkThrough.department_name")}</div>
              </div>
  
              <div className="d-flex flex-column gap-3 text-white mb-3" style={{ padding: 5 }}>
  <div className="d-flex align-items-center">
    <div className="flex-shrink-0" style={{ width: "40%" }}>{t("walkThrough.category_label")}</div>
    <div className="flex-grow-1">
      <select
        value={selectedCategory}
        onChange={handleCategoryChange}
        className="category-select"
      >
        <option value="">{t("walkThrough.category_select")}</option>
        <option value="travel">{t("walkThrough.travel")}</option>
        <option value="food">{t("walkThrough.food")}</option>
        <option value="office-supplies">{t("walkThrough.office_supplies")}</option>
      </select>
    </div>
  </div>

  <div className="d-flex align-items-center" style={{color: "white"}}>
    <div className="flex-shrink-0" style={{ width: "40%", color: "white" }}>{t("walkThrough.description")}</div>
    <div className="flex-grow-1" style={{color: "white"}}>
      <input
        type="text"
        placeholder={t("walkThrough.description")}
        className="description-input" 
      />
    </div>
  </div>
</div>
            </div>
          </div>
  
          <div className= "upload-container col-lg-3 col-md-6 mt-4 ">
          <div className="dotted-box">
            <img className="receipt-img" src={Receipt} alt="Image" />
            <div className="upload-info mt-2">
              <div style={{ fontWeight: 500}}>{t("walkThrough.upload_title")}</div>
              <div className='receipt-text'>{t("walkThrough.upload_new_receipt")}</div>
              <div>{t("walkThrough.upload_info")}</div>
            </div>
            <div className="upload-icon mt-2">
              <img src={UploadIcon} width="25px" height="25px" />
            </div>
          </div>
        </div>

        <InfoSection content={content} title={t("walkThrough.reimbursement")} iconSrc={ReimbursementIcon} className="reimbursement-content-container"/>
      </div>
         
  
  );
}